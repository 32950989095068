import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState, useEffect} from "react";
import {CardMedia, IconButton, Stack, TextField, Tooltip} from "@mui/material";
import {useMainStore} from "../../state";
import BugReportModal from "../BugReportModal";

const SupportButton = () => {
    const [hover, setHover] = useState(false)
    const [open, setOpen] = useState(false)
    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <>
        <BugReportModal open={open} setClosed={closeModal} />
        <IconButton sx={{p:0, m:0}} onClick={() => openModal()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "secondary.main" : "none",
                    height:'30px',
                    width:'30px'}}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0833 19.6665H12.75V21.9998H15.0833V19.6665Z" fill={"#C9C9C9"}/>
                        <path fill={"#C9C9C9"} d="M16.25 8H11V9.16667H16.25C17.1833 9.16667 18 9.98333 18 10.9167V12.0833C18 13.0167 17.1833 13.8333 16.25 13.8333H13.3333V17.3333H14.5V15H16.25C17.8833 15 19.1667 13.7167 19.1667 12.0833V10.9167C19.1667 9.28333 17.8833 8 16.25 8Z"/>
                    </svg>
                </CardMedia>
        </IconButton>
        </>)
}

export default SupportButton