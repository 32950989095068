import {setHotkey, useMainStore} from "../state";
import {TextField, Tooltip, Box, Slider} from "@mui/material";
import {useEffect, useState} from "react";
import {resizeWindow} from "../utils/ow_utils";
import {throttle} from "lodash";

export const ScaleSlider = ({marks, inGame=false} : {marks:any[], inGame:boolean}) => {
  const currentWindow = useMainStore(state => state.activeWindow)
  const settingKey = `scaleFactor_${currentWindow}`
  const currentSetting = useMainStore(state => state.settings[settingKey])
  const changeSetting = useMainStore(state => state.updateSettings)
  const throttleOnChange = throttle((event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    changeSetting(settingKey, value)
    resizeWindow(currentWindow, inGame)
  }, 2000)

  useEffect(() => {
    !currentSetting && changeSetting(currentSetting, 1) && resizeWindow(currentWindow)
  }, [])

    return (
        <>
      <Slider
            sx={{
            '& input[type="range"]': {
              WebkitAppearance: 'slider-vertical',
            },
          }}
          orientation="vertical"
          aria-label="size"
          value={currentSetting}
          defaultValue={1.0}
          onChangeCommitted={throttleOnChange}
          valueLabelDisplay="off"
          step={0.1}
          marks={marks}
          min={0.8}
          max={1.2}
        />
    </>
    )
}

export default ScaleSlider