import SKSwitch from "./SKSwitch";
import * as React from "react";
import {useMainStore} from "../state";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";

const AutoplayToggle = () => {
    const changeSetting = useMainStore(state => state.updateSettings)
    const autoplayVideos = useMainStore(state => state.settings.autoplayVideos)
    const [hover, setHover] = React.useState(false)

    return (<Tooltip open={hover} title={`Autoplay is ${autoplayVideos ? "on" : "off"}`}>
        <Box onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}><SKSwitch

        checked={autoplayVideos}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeSetting('autoplayVideos', event.target.checked)}
            color="primary" /></Box>
    </Tooltip>)
}

export default AutoplayToggle

