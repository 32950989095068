import {useEffect, useState} from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {useMainStore, useDesktopStore} from "../state"
import {mainLogo, logoText, settingsLogo, flexLogo, flexLogoText} from "../icons/side_icons";
import {PAGE_NAMES, WINDOW_NAMES} from "../utils/consts";
import theme from "../theme";
import {CardMedia, Grid, IconButton, List, ListItem, Paper, Stack} from "@mui/material";
import MatchupPage from "../pages/MatchupPage";
import WindowControls from "../components/WindowControls";
import SettingsPage from "../pages/SettingsPage";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import GeneralButton from "../components/GeneralButton";
import ListItemText from "@mui/material/ListItemText";
import OppositeGeneralButton from "../components/OppositeGeneralButton";
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Link from "@mui/material/Link";
import CircleIcon from '@mui/icons-material/Circle';

const drawerWidth = 60;

//avoid the use of static text, use i18n instead, each language has its own text, and the text is stored in the
//locales folder in the project root
const FTUEWindow = () => {
  const logo = <Box>{flexLogoText("362px", "66px")}</Box>
  const [ftueIndex, setFtueIndex] = useState(0)
  const [ftueElement, setFtueElement] = useState(<></>)
  const changeSetting = useMainStore(state => state.updateSettings)
  const isOW = useMainStore(state => state.isOverwolf)
  const controls = <Stack direction={"row"} sx={{pt:8, width:"100%"}} justifyContent={"space-between"}>
   <Box sx={{visibility:ftueIndex == 0 ? "hidden" : "unset"}}><OppositeGeneralButton height="30px" clickCallback={() => {setFtueIndex(ftueIndex - 1)}} color={theme.palette.secondary.lighter} >
            <Box sx={{fontWeight:500, fontSize:"15px", textTransform:"none", fontStyle:"italic"}}>Previous</Box>
   </OppositeGeneralButton></Box>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <CircleIcon sx={{color: ftueIndex == 0 ? "primary.main" : "secondary.light"}} className={"ftueCircle"}/>
          <CircleIcon sx={{color: ftueIndex == 1 ? "primary.main" : "secondary.light"}} className={"ftueCircle"}/>
          <CircleIcon sx={{color: ftueIndex == 2 ? "primary.main" : "secondary.light"}} className={"ftueCircle"}/>
      </Stack>
    <GeneralButton height="30px" clickCallback={() => {setFtueIndex(ftueIndex + 1)}}>
            <Box sx={{fontWeight:500, fontSize:"15px", textTransform:"none", fontStyle:"italic", width:"60px"}}>{ftueIndex <= 1 ? "Next" : "Start!"}</Box>
    </GeneralButton>
  </Stack>

  const generateListItem = (text : any) => {
      return (<ListItem
              sx={{fontSize:"15px", display: 'list-item', listStyleType: 'disc'}}
              key={"p1-1"}
              disableGutters
            >
              <ListItemText primary={text} />
            </ListItem>)
  }
  useEffect(() => {
        if (ftueIndex == 0)
            setFtueElement(<>
                {/*<Box>*/}
                    <CardMedia image={"/assets/ftue-1-content.png"} sx={{transition: "opacity 1000ms", opacity:1, height:'100%',
                                ml:"60px", width:'calc(100vh * 720 / 677 - 60px)', backgroundSize: "cover", objectFit:'cover', bgcolor:"secondary.darker"}}/>
                    {/*<CardMedia image={"/assets/ftue-1-content.png"} sx={{top:0, left:"60px", position:"absolute", width:'calc(100vh * 720 / 677)', height:"100%", backgroundSize: "contain", objectFit:'contain'}}></CardMedia>*/}
                {/*</Box>*/}
            <Stack direction={"column"} spacing={0} sx={{maxWidth:"362px"}} alignItems={"center"}>
                {logo}
                <Box sx={{fontSize:"20px", pt:4, fontStyle:"italic", width:"100%"}}>MASTER 1V1 MATCHUPS</Box>
                <Box sx={{height:"180px"}}>
                    <List sx={{p:1}} dense={true}>
                        {generateListItem("Choose your 1V1 matchup and watch short clips of how high-rank players outplay in this matchup.")}
                        {generateListItem("With each clip watch the abilities used ON-SCREEN, and learn to nail those combos!")}
                    </List>
                </Box>

                {controls}
            </Stack>
            </>)

      if (ftueIndex == 1) {
          setFtueElement(<>
                {/*<Box>*/}
                    <CardMedia image={"/assets/ftue-2-content.png"} sx={{transition: "opacity 1000ms", opacity:1, height:'100%',
                                ml:"60px", width:'calc(100vh * 720 / 677 - 60px)', backgroundSize: "cover", objectFit:'cover', bgcolor:"secondary.darker"}}/>
                    {/*<CardMedia image={"/assets/ftue-1-content.png"} sx={{top:0, left:"60px", position:"absolute", width:'calc(100vh * 720 / 677)', height:"100%", backgroundSize: "contain", objectFit:'contain'}}></CardMedia>*/}
                {/*</Box>*/}
            <Stack direction={"column"} spacing={0} sx={{maxWidth:"362px"}} alignItems={"center"} >
                {logo}
                <Box sx={{fontSize:"20px", pt:4, fontStyle:"italic", width:"100%"}}>BUILD FOR YOUR MATCHUP</Box>
                <Box sx={{height:"180px"}}>
                    <List sx={{p:1}} dense={true}>
                        {generateListItem(<Box>In the <b>Full Guide</b> section find everything you need to know about the matchup. Runes, items, skill order, and combos. </Box>)}
                        {generateListItem(<Box><b>Real-time Champ Select guide</b>. Get all the relevant information to outplay your opponents, and set the right runes for your matchup during champion selection with a single click!</Box>)}
                    </List>
                    <Box sx={{fontSize:"12px"}}>*Updates every two days with latest data from high rank matches.</Box>
                </Box>

                {controls}
            </Stack>
            </>)
      }

      if (ftueIndex == 2) {
                    setFtueElement(<>
                {/*<Box>*/}
                    <CardMedia image={"/assets/ftue-3-content.png"} sx={{transition: "opacity 1000ms", opacity:1, height:'100%',
                                ml:"60px", width:'calc(100vh * 720 / 677 - 60px)', backgroundSize: "cover", objectFit:'cover', bgcolor:"secondary.darker"}}/>
                    {/*<CardMedia image={"/assets/ftue-1-content.png"} sx={{top:0, left:"60px", position:"absolute", width:'calc(100vh * 720 / 677)', height:"100%", backgroundSize: "contain", objectFit:'contain'}}></CardMedia>*/}
                {/*</Box>*/}
            <Stack direction={"column"} spacing={0} sx={{maxWidth:"362px"}} alignItems={"center"}>
                {logo}
                <Box sx={{fontSize:"20px", pt:4, fontStyle:"italic", width:"100%"}}>KNOW YOUR CHANCES</Box>
                <Box sx={{height:"180px"}}>
                    <List sx={{p:1}} dense={true}>
                        {generateListItem(<Box>Use the <b>Kill Chance</b> guide in-game to know EXACTLY, based on level, when to play safe, or when to go for that kill!</Box>)}
                    </List>
                </Box>

                {controls}
            </Stack>
            </>)
      }

      if (ftueIndex == 3) {
          setFtueElement(<></>)
          changeSetting('firstTime', false)
      }
      },
      [ftueIndex])
 return (
    <Box className="ftueWindow" sx={{ display: 'flex', height:`calc(100vh - ${drawerWidth}px)`}}>
         <AppBar
                position="fixed"
                sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
              >
             {!isOW ? <></> : <Paper onMouseDown={() => isOW ? overwolf.windows.dragMove(WINDOW_NAMES.DESKTOP) : ''}
                       sx={{ bgcolor: "black", backgroundImage:"none", p:0}}>
                    {(!isOW) ? <></> : <WindowControls closeFunction={(windowName:string) => overwolf.windows.close(WINDOW_NAMES.BACKGROUND)} update={false} discord={false}
                                                       support={false} maximize={false} minimize={true} close={true}/> }
                </Paper>
             }
          </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
        PaperProps={{ sx: {overflow: 'hidden'} }}
      >
        <Toolbar variant="dense" sx={{minHeight:"60px"}} disableGutters={true}>
            {mainLogo}
        </Toolbar>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{ position:"absolute", height:'100%', width:"100%", flexGrow: 1, bgcolor: 'background.default', pb:0 }} flexDirection='column'
      >
        <Stack direction={"row"} sx={{height:'100%'}} alignItems={"center"} spacing={6}>
            {ftueElement}
                {/**/}
                {/*<Box  id='ftue-3' sx={{pt:5, pb:1, opacity:0, transition: "opacity 1000ms", color:"secondary.lighter"}} className={"titleText"}>WELCOME TO SOLOKILL</Box>*/}
                {/*<Box  id='ftue-4' sx={{opacity:0, pb:5, transition: "opacity 1000ms", color:"secondary.lighter"}}>Select a match-up and learn how to outplay your opponent from actual gameplay videos. View a collection of 100,000+ short clips, as well as match-up specific builds and guides.</Box>*/}
                {/*<Box  id='ftue-5' sx={{opacity:0, transition: "opacity 1000ms",  borderColor:"primary.main", height:'30px', width:'200px'}}>*/}
                {/*    <GeneralButton clickCallback={() => {changeSetting('firstTime', false)}}>*/}
                {/*        <Box sx={{fontWeight:500, fontSize:"18px", textTransform:"none", fontStyle:"italic"}}>Let's Start</Box>*/}
                {/*    </GeneralButton>*/}
                {/*</Box>*/}

        </Stack>
          <Link sx={{fontSize:"15px", cursor:"pointer", position:"absolute", bottom:20, right:20, color:"secondary.light"}} onClick={() => setFtueIndex(3)}>Skip</Link>
      </Box>
    </Box>)
}

export default FTUEWindow