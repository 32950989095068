import {Box, CardMedia, Stack, Tooltip} from "@mui/material";
import * as React from "react";
import {CLOUDFRONT_URL, TOOLTIP_STYLE} from "../utils/consts";
import {useMainStore} from "../state";

const Item = ({itemData, size="45px", tooltipPosition="left"} : {itemData:any, size?:string, tooltipPosition?:any}) => {
    const serverUrl = CLOUDFRONT_URL
    if (!itemData) {
        return <></>
    }

    // console.log()
    const itemKey = itemData[0]
    const itemName = itemData[1]
    const itemCost = itemData[2]
    const itemDesc = itemData[3]
    const path = itemKey == undefined ? "" : `${serverUrl}/assets/items/${itemKey}.png`
    const itemTooltip = (<Stack direction="column" spacing={1}>
                        <Box className={"titleText"} sx={{color:"secondary.lighter", fontSize:'15px'}}>
                            {itemName}
                        </Box>
                        <Box sx={{color:"secondary.lighter", fontSize:'12px'}}>
                            Cost: <Box component="span" sx={{color:"primary.main", fontSize:'12px'}}>{itemCost}g</Box>
                        </Box>
                        <Box sx={{color:"secondary.lighter", fontSize:'12px', whiteSpace:'pre-line'}}>{itemDesc}</Box>
                    </Stack>
                    )
    return <Tooltip componentsProps={{
                    tooltip: TOOLTIP_STYLE,
                  }} placement={tooltipPosition} arrow={true} title={itemTooltip}>
            <CardMedia image={path}
                      sx={{bgcolor:'#1b1b1b', height:size, width:size, position:'relative', borderRadius:'2px'}}>
            </CardMedia>
            </Tooltip>
    // return <>{abilityKey}</>
}

export default Item