import {cleanCheckedIcon} from "../icons/side_icons";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Fade from "@mui/material/Fade";

export const ChangeNotifier = ({trackedParameter} : {trackedParameter:any}) => {
    const [count, setCount] = useState(0);
    const [fadeIn, setFadeIn] = useState(false)
    let fadeTimeout : any = undefined;

    useEffect(() => {
        if ((count >= 1) && (trackedParameter != null) && (trackedParameter.toString() != "")) {
            setFadeIn(true)
            fadeTimeout = window.setTimeout(() => {
                setFadeIn(false)
            }, 2000)
        }
        setCount(count + 1)
    }, [trackedParameter])

    return <Fade in={fadeIn} timeout={{appear:0, enter:500, exit:500}}>
        <Box sx={{width:"20px", height:"24px"}}>{cleanCheckedIcon}</Box>
    </Fade>
}

export default ChangeNotifier;