import Button from "@mui/material/Button";
import { useState } from "react";
import { keyframes } from "@emotion/react"; // Import keyframes from Emotion

const glowAnimation = keyframes`
  0% {
    border-color: transparent;
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.8);
  }
  50% {
    border-color: #FFE901;
    box-shadow: 0 0 10px rgba(255, 255, 0, 1);
  }
  100% {
    border-color: transparent;
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.8);
  }
`;

const OppositeGeneralButton = ({
  clickCallback,
  disabled = false,
  children,
  height = "unset",
  width = undefined,
  color = "#FFE901",
  animated = false,
}: {
  clickCallback: Function;
  disabled?: boolean;
  children: React.ReactNode;
  height?: string;
  color?: string;
  width?: string;
  animated?: boolean;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Button
      className={"oppositeGeneralButton"}
      onClick={() => clickCallback()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant={!hover ? "outlined" : "contained"}
      sx={{
        height: height,
        minWidth: width,
        width: width,
        color: !hover ? color : "black",
        borderRadius: 0,
        textTransform: "none",
        backgroundImage: "none",
        border: !hover ? `1px solid ${color}` : "none",
        animation: animated ? `${glowAnimation} 2.5s infinite alternate` : "none"
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default OppositeGeneralButton;
