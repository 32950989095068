import ChampionBrowser from "./ChampionBrowser";
import {smallVs} from "../icons/side_icons";
import * as React from "react";
import {useDesktopStore, useMainStore} from "../state";
import {RefObject, useEffect} from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import ChampionPortrait from "./ChampionPortrait";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import {Box, Stack} from "@mui/material";
import Swap from "./Swap";
import {champToDisplayName} from "../utils/general";

const SingleChampionSelector = ({champion, usable, championClass, secondaryAnchorId, secondaryPlacement, size="48px", primaryOnly=false, forcePrimary=false, mt=0.5,
                                    children, showName, placement='bottom-start', nameSide = "left", showChangeText=false} : {champion:string | null,
                                                                                                         usable:boolean, championClass:string,
                                                                                                        children:React.ReactNode, showName?:boolean, placement?:any,
                                                                                                         nameSide?:string, showChangeText?:boolean,
                                                                                                        primaryOnly?:boolean,
                                                                                                        mt?:number,
                                                                                                        secondaryAnchorId?:string, secondaryPlacement?:string, size?:string, forcePrimary?:boolean
                                                                                                        }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isPrimary, setIsPrimary] = React.useState(forcePrimary)
  const open = useDesktopStore(state => state.champSelectOpen)
  const setOpen = useDesktopStore(state => state.setChampSelectOpen)
  const main_anchor_ref : RefObject<HTMLButtonElement> = React.createRef()
  const rotateChampionSelectHotkey = useMainStore(state => state.settings.rotateChampionSelectHotkey)

  useEffect(() => {
    setAnchorEl(main_anchor_ref.current)
  }, [])

  useEffect(() => {

    if (!isPrimary && secondaryAnchorId && !forcePrimary) {
        console.log('secondary open!')
        let selectorAnchor: any = document.getElementById(secondaryAnchorId) as Element
        setAnchorEl(selectorAnchor)
    }
    if ((!open) && (!forcePrimary))
        setIsPrimary(false);

    if (forcePrimary)
        setIsPrimary(true)
  }, [open])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!usable) {
        return;
    }

    setIsPrimary(true)

    console.log('finding closest')
    // let elem : Element = event.target as Element
    let selectorAnchor : any = event.currentTarget.closest('.championSelectAnchor') as Element
    setAnchorEl(selectorAnchor)
    setOpen(!open);
    event.stopPropagation()
  };

  const handleClickAway = (event : MouseEvent | TouchEvent) => {
      let elem : Element = event.target as Element

      if (elem.closest('.championSelector'))
          return;

      if (usable)
        setOpen(false);
        setIsPrimary(forcePrimary);
  };

 const canBeOpen = open && Boolean(anchorEl) && usable;
  const id = canBeOpen ? 'transition-popper' : undefined;
  const getChampionButton = (champion : string | null, btnClass: string) => {
      let textComponent = (showName ?
                            (<Stack direction={"column"} alignItems={"flex-end"} justifyContent={"center"}>
                                <Box className={'titleText'} sx={{color:'white'}}>{champToDisplayName(champion)}</Box>
                                {!showChangeText ? <></> : <Box component="span" sx={{fontSize:'12px'}}><Box component="span" sx={{color:'primary.main'}}>{rotateChampionSelectHotkey == "" ? "Unassigned" : rotateChampionSelectHotkey}</Box> to change</Box>}
                            </Stack>) : <></>)
      return (<Stack direction={"row"} spacing={1} alignItems={"center"}>
          {nameSide == "right" ? <></> : textComponent}
          <Button ref={main_anchor_ref} size='small'
                        sx={{width: size, minWidth: size, p: 0, borderRadius:0, outline: (open && usable) ? "1px solid" : "none"}}
                        aria-describedby={id} onClick={handleClick} className={usable ? btnClass : "unclickable"}>
                <ChampionPortrait grey={false} championName={champion} size={size}/>
            </Button>
          {nameSide == "left" ? <></> : textComponent}
      </Stack>)
  }

    return <React.Fragment>
          <Stack direction={"row"}>
                {getChampionButton(champion, championClass)}
          </Stack>
        <ClickAwayListener onClickAway={handleClickAway} >
            <Box sx={{position:"absolute"}}>
                <Popper sx={{zIndex:100}} open={open && usable && (!primaryOnly || isPrimary)} anchorEl={anchorEl} keepMounted disablePortal={true} placement={isPrimary ? placement : secondaryPlacement}>
                        <Stack id={id} direction={"row"} spacing={1} mt={mt} pl={isPrimary ? 0 : 1} pr={isPrimary ? 0 : 1} >
                            {children}
                        </Stack>
                </Popper>
            </Box>
        </ClickAwayListener>
    </React.Fragment>
}
export default SingleChampionSelector


