import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";

const GeneralButton = ({clickCallback, disabled=false, height="unset", width=undefined, children} : {clickCallback:Function, disabled?:boolean, children:React.ReactNode, height?:string, width?:string}) => {
    const [hover, setHover] = useState(false)

    return (
        <Button onClick={() => clickCallback()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            variant={(hover) ? 'outlined' : 'contained'}

            sx={{
                height:height,
                minWidth:width,
                width:width,
                bgcolor:hover ? "unset" : "primary.main",
                 color:hover ? "primary.main" : "black",
                borderRadius:0,
                 border:"none"}}
            disabled={disabled}>
                {children}
        </Button>)
}

export default GeneralButton