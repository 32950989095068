import {Backdrop, CircularProgress, Stack} from "@mui/material";
import { Offline } from "react-detect-offline";
import * as React from "react";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";

const Loading = ({open, fullscreen=false, zIndex=0, showOfflineWarning=true, bgcolor='black'} : {open:boolean, fullscreen?:boolean, zIndex?:number, showOfflineWarning?:boolean, bgcolor?:string}) => {
    const [offline, setOffline] = useState(false)
    const [wasConnectionLost, setWasConnectionLost] = useState(false)
    const offlineCheck = () => {
        if (open) {
            const timeout = window.setTimeout(() => {
                setWasConnectionLost(true)
                setOffline(true)
                window.setTimeout(offlineCheck, 2500)
            }, 5000)

            fetch("https://icanhazip.com/").then(() => {
                window.clearTimeout(timeout)
                setOffline(false)
            })
        }
    }

    useEffect(() => {
       setOffline(false)
       setWasConnectionLost(false)
       offlineCheck()
    }, [open])

    useEffect(() => {
        console.log('offline', offline, 'wasConnectionLost', wasConnectionLost)
        if ((wasConnectionLost) && (!offline))
            window.location.reload()
    }, [offline])

    return (<Backdrop
                      sx={{zIndex:zIndex, color: 'primary.main', bgcolor:bgcolor, position:'absolute' }}
                          open={open}
                        >
                        <Stack direction={"column"} alignItems={"center"} justifyContent="center" spacing={2}>
                        {open && <CircularProgress color="inherit" />}
                        {offline &&
                            <Stack alignItems={"center"} sx={{opacity:0.9}}>
                                <Box sx={{fontWeight:700}}>It looks like you're offline.</Box>
                                <Box>SOLOKILL requires an internet connection to run.</Box>
                            </Stack>}
                        </Stack>
                    </Backdrop>)
    // return <></>
}

export default Loading;