import {Stack} from "@mui/material";
import UpdateButton from "./ButtonControls/UpdateButton";
import DiscordButton from "./ButtonControls/DiscordButton";
import MaximizeButton from "./ButtonControls/MaximizeButton";
import SupportButton from "./ButtonControls/SupportButton";
import MinimizeButton from "./ButtonControls/MinimizeButton";
import CloseButton from "./ButtonControls/CloseButton";
import TwitchButton from "./ButtonControls/TwitchButton";
import ResizeButton from "./ButtonControls/ResizeButton";
import FeatureHighlightTooltip from "./FeatureHighlightTooltip";
import {VERSION} from "../utils/consts";

const default_close_function = (window_name:string) => { overwolf.windows.hide(window_name) }
const WindowControls = ({update = false, discord = false,
                         support = false, maximize = true, twitch = false,
                         minimize = true, close = true, closeFunction=default_close_function, resizer = false, inGame = false}) => {
    return (<Stack className={"windowControls"} alignItems="center" direction={"row"} sx={{position:'absolute', top:0, right:0
    }} spacing={0.5}>
                {twitch ? <TwitchButton/> : <></>}
                {update ? <FeatureHighlightTooltip 
                            tooltipText={`Updated to version ${VERSION}! See whats new.`}
                            featureName={`newver-${VERSION}`}><div><UpdateButton/></div></FeatureHighlightTooltip>: <></>}
                {discord ? <DiscordButton/> : <></>}
                {support ? <SupportButton/> : <></>}
                {minimize ? <MinimizeButton/> : <></>}
                {maximize ? <MaximizeButton/> : <></>}
                {resizer ? <ResizeButton inGame={inGame}/> : <></>}
                {close ? <CloseButton closeFunction={closeFunction}/> : <></>}
            </Stack>)
}

export default WindowControls