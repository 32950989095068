import {PICK_TYPE, WINDOW_NAMES} from "./consts";
import {useDesktopStore, useMainStore} from "../state";
import JSZip from "jszip";
import { champToDisplayName } from "./general";
import { v5 as uuidv5 } from 'uuid';
const UUID_NAMESPACE = 'c306c281-2d9e-407d-8fe7-e8435723b226'

export const ow_getCurrentWindow = (): Promise<string> =>
  new Promise((resolve) =>
    overwolf.windows.getCurrentWindow((result : any) => {
      resolve(result.window.name)
    }),
  )

export const resizeWindow = (requestedWindow:string, refresh=false) => {
    console.log('resizeWindow', requestedWindow, refresh)
    if (useMainStore.getState().isOverwolf) {
        // @ts-ignore
        overwolf.windows.getWindow(requestedWindow, (window_info) => {
            overwolf.utils.getMonitorsList((monitors_info) => {
                let monitor = monitors_info.displays.filter((v) => v.id == window_info.window.monitorId)[0]
                let scaleFactor = useMainStore.getState().settings[`scaleFactor_${window_info.window.name}`]
                let REQUESTED_SIZE_FACTOR = (scaleFactor ? scaleFactor : 1) + (monitor.height > 2000 ? 0.5 : monitor.height > 1400 ? 0.2 : 0)
                let BASE_WIDTH = window_info.window.name == WINDOW_NAMES.CHAMP_SELECT ? 400 : 1300
                let BASE_HEIGHT = 720
                let DPI_FACTOR = Math.max(100, (monitor.dpiX - 12.5)) / 100
                let NEW_WIDTH = Math.round(BASE_WIDTH * REQUESTED_SIZE_FACTOR / DPI_FACTOR)
                let NEW_HEIGHT = Math.round(BASE_HEIGHT * REQUESTED_SIZE_FACTOR / DPI_FACTOR)
                overwolf.windows.setZoom((REQUESTED_SIZE_FACTOR / DPI_FACTOR - (scaleFactor > 1 ? 1.1 : 1)) * 7, '')
                overwolf.windows.changeSize({"window_id":window_info.window.id, "width":NEW_WIDTH, "height":NEW_HEIGHT, "auto_dpi_resize":false}, () => {
                    refresh && location.reload()
                })
            })
        })
    }
}

export const zipAppLogFolder = async () => {
   return new Promise((resolve, reject) => {
       console.log('zipAppLogFolder called')
       let path = `${overwolf.io.paths.localAppData}/overwolf/Log/Apps/Solokill`
        overwolf.io.dir(path, async (dirListingResult) => {
            try {
                // It should be a flat structure for us
                // @ts-ignore
                let files = dirListingResult.data.filter((d) => d.type === 'file').map((file) => file.name);
                let fileContents : any= []
                for (let file of files) {

                    let contentsFunction = async (file:any) => {
                        return new Promise((resolve, reject) => {
                            // @ts-ignore
                            overwolf.io.readTextFile(path + '/' + file, { encoding: 'UTF8', maxBytesToRead: null as any, offset: null as any }, (res) => {
                                fileContents.push({
                                    fileName: file,
                                    contents: res.content,
                                })
                                resolve("")
                            })
                            })
                        }
                    await contentsFunction(file)
                }
                let zipFile = new JSZip()
                console.log('fileContents', fileContents)
                fileContents.forEach((file : any) => zipFile.file(file.fileName, file.contents));

                console.log('jszip', zipFile)
                let blob = await zipFile.generateAsync({type: 'base64',
                    compression: 'DEFLATE',
                    })
                console.log('blob', blob)
                resolve(blob)
            }
            catch (e) {
                reject("")
            }
        })
  })
}

const _generateBlock = (items : any[], name : string) => {
    return { 
        "type": name, 
        "recMath": false, 
        "minSummonerLevel": -1, 
        "maxSummonerLevel": -1,
        "showIfSummonerSpell": "", 
        "hideIfSummonerSpell": "", 
        "items":items.map((item) => ({
            "id":item[0],
            "count": 1
        }))
    }
}

export const writeItemSet = (startingItems : any[], coreItems : any[], lateGameOptions : any[], 
                             playerChampion:string|null, enemyChampion:string|null, pickType:string|null, lane?:string|null, aramVariant?:number,
                             name:string|undefined=undefined,
                             lcu:boolean=false
                             ) => {
    console.log('writeItemSet called')
        
    if (!playerChampion)
        return;
    
    let ms = useMainStore.getState()
    if (!name) {
        name = `Solokill: ${champToDisplayName(playerChampion)} vs ${champToDisplayName(enemyChampion)}`
                            
        if (pickType == PICK_TYPE.BLIND) {
            name = `Solokill: ${champToDisplayName(playerChampion)} ${lane}`
        }
    
        if (pickType == PICK_TYPE.ARAM) {
            name = `Solokill: ${champToDisplayName(playerChampion)} ARAM (${aramVariant})`
        }

        if (pickType == PICK_TYPE.ARENA) {
            name = `Solokill: ${champToDisplayName(playerChampion)} ARENA`
        }
    }


    let set : any = { "title": name, 
        "type": "custom", 
        "map": pickType == PICK_TYPE.ARAM ? "HA" : pickType == PICK_TYPE.ARENA ? "any" : "SR", 
        "mode": "any", 
        "associatedMaps": [pickType == PICK_TYPE.ARAM ? 12 : 11],
        // @ts-ignore
        "associatedChampions": [Number(ms.champion2key[playerChampion])],
        "priority": true,
        "sortrank": 0, 
        "blocks": [_generateBlock(startingItems, "Starting Items"),
                   _generateBlock(coreItems, `Core Build`),
                   _generateBlock(lateGameOptions, "Late Game Options")]
        }
    

    if (!lcu) {
        overwolf.games.launchers.getRunningLaunchersInfo((launcherInfo) => {
            let path = launcherInfo.launchers[0].path
            path = path.substring(0, path.lastIndexOf("/"))
            path = `${path}/Config/Champions/${playerChampion}/Recommended/SK-${playerChampion}.json`
            console.log('path', path)
            // @ts-ignore
            overwolf.io.writeFileContents(path, JSON.stringify(set), 'UTF8', false, console.log)
        })
    }
    else {
        set['uid'] = uuidv5(name, UUID_NAMESPACE)
        // set['isGlobalForMaps'] = true

        overwolf.games.launchers.events.getInfo(10902, (client_info) => {
            let credentials = client_info.res.credentials
            let base_lcu_url = `https://127.0.0.1:${credentials?.port}`
            let headers = [
                {key:'Accept', value: 'application/json'},
                {key:'Content-Type', value: 'application/json'},
                {key:'Authorization', value: 'Basic ' + credentials.token},
            ]
            
            overwolf.web.sendHttpRequest(
                `${base_lcu_url}/lol-item-sets/v1/item-sets/${client_info.res.summoner_info.summoner_id}/sets`,
                // @ts-ignore
                'GET',
                headers,
                {},
                (get_itemset_response) => {
                    // @ts-ignore
                    let itemset_data = JSON.parse(get_itemset_response.data)
                    let existing_sets = itemset_data.itemSets.filter((v : any) => v['uid'] != set['uid'])
                    existing_sets.push(set)
                    itemset_data.itemSets = existing_sets
                    itemset_data['timestamp'] = new Date().getTime()
                    
                    overwolf.web.sendHttpRequest(
                        `${base_lcu_url}/lol-item-sets/v1/item-sets/${client_info.res.summoner_info.summoner_id}/sets`,
                        // @ts-ignore
                        'PUT',
                        headers,
                        JSON.stringify(itemset_data),
                        (set_itemset_response) => console.log(set_itemset_response)
                    )
                }
            )
        })
    }
}