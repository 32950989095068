import * as React from "react";
import {CardMedia, Container, IconButton, List, ListItem, Modal, Paper, Stack, SvgIcon} from "@mui/material";
import Box from "@mui/material/Box";
import {useMainStore} from "../state";
import {useState} from "react";
import OppositeGeneralButton from "./OppositeGeneralButton";
import {VERSION} from "../utils/consts";
import ListItemText from "@mui/material/ListItemText";

let whats_new_updates = [
    "(26/11/2023):",
    "Stability and bug improvements - Solokill now supports the new account naming system by Riot",
]

let bug_fixes = [
    "Fixed a bug that would prevent the in-game AI from working",
    "Fixed a bug that would cause Overwolf to say `Solokill cannot be loaded, check your internet connection`",
]

let general_updates = [
    ""
]

const new_icon = <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" fill="white" fill-opacity="0.07"/>
    <path d="M18.5 9.49984L23.7498 9.50046L21.5001 30.4995L15.4998 30.4999L18.5 9.49984Z" fill="#FFE901"/>
    <path d="M30.5 17.0002L29 21.6057L9.5 21.6057L11 17.0002L30.5 17.0002Z" fill="#FFE901"/>
</svg>

const generate_updates = (arr : string[]) => {
    return (<List sx={{p:0}} dense={true}>
              {arr.map((value) => (
                <ListItem
                  sx={{p:0}}
                  key={value}
                  disableGutters
                >
                  <ListItemText primary={value} />
                </ListItem>
              ))}
            </List>)
}

const bugfix_icon = <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" fill="white" fill-opacity="0.07"/>
                <rect x="20" y="14" width="14" height="14" transform="rotate(45 20 14)" fill="#FFE901"/>
                <rect x="20" y="5.33545" width="7.86889" height="7.86889" transform="rotate(45 20 5.33545)" fill="#FFE901"/>
                <rect x="25" y="16.2935" width="6" height="1" transform="rotate(-45.6881 25 16.2935)" fill="#FFE901"/>
                <rect x="10.6562" y="12.0426" width="6" height="1" transform="rotate(44.3119 10.6562 12.0426)" fill="#FFE901"/>
                <rect x="28" y="19.2935" width="6" height="1" transform="rotate(-45.6881 28 19.2935)" fill="#FFE901"/>
                <rect x="7.65625" y="15.0426" width="6" height="1" transform="rotate(44.3119 7.65625 15.0426)" fill="#FFE901"/>
        </svg>

const general_icon = <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" fill="white" fill-opacity="0.07"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.1891 12.0301C24.1219 10.145 21.3188 8.98071 18.2429 8.98071C11.9228 8.98071 6.75439 13.8963 6.75439 19.8793C6.75439 23.0267 8.13237 25.8744 10.3354 27.8929L13.4087 24.8178C13.1639 23.4362 13.0464 21.791 13.0464 19.8793C13.0464 12.7166 14.7598 9.62677 18.2429 9.62677C20.9014 9.62677 22.4561 11.4411 23.0957 15.1253L26.1891 12.0301ZM13.5267 27.8299C15.594 29.7152 18.3973 30.8797 21.4734 30.8797C27.7935 30.8797 32.9619 25.9641 32.9619 19.9811C32.9619 16.8335 31.5837 13.9856 29.3805 11.9671L26.3075 15.0419C26.5524 16.4236 26.6699 18.069 26.6699 19.9811C26.6699 27.1438 24.9565 30.2336 21.4734 30.2336C18.8148 30.2336 17.26 28.419 16.6205 24.7344L13.5267 27.8299ZM21.3625 17.5959C20.3474 16.5551 18.6807 16.5343 17.6399 17.5495C16.5992 18.5646 16.5784 20.2312 17.5935 21.272C18.6086 22.3128 20.2753 22.3336 21.3161 21.3184C22.3568 20.3033 22.3776 18.6367 21.3625 17.5959Z" fill="#FFE901"/>
</svg>



const WhatsNewModal = ({open, setClosed} : {open:boolean, setClosed:Function}) => {
    const [closeHover, setCloseHover] = useState(false)
    const vidStats = useMainStore(state => state.vidStats)

    return (<Modal onMouseDown={(e) => e.stopPropagation()} open={open} onClose={() => setClosed()} sx={{zIndex:10000}}>
          <Stack direction={"column"}  sx={{outline:"none", boxShadow:"none", p:"20px", bgcolor:"secondary.darker", height:"580px", width:"580px", maxHeight:"580px", maxWidth:"580px", position:"absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)"}} spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                  <Box className={"titleText"}>Solokill patch notes: v{VERSION}</Box>
                   <IconButton sx={{p:0, m:0, position:"absolute", top:0, right:0}}
                                onClick={() => {setClosed(); setCloseHover(false)}}
                               onMouseEnter={() => setCloseHover(true)}
                               onMouseLeave={() => setCloseHover(false)}
                    >
                            <CardMedia sx={{
                                bgcolor:closeHover ? "primary.main" : "none",
                                height:'30px',
                                width:'30px'}}>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill={closeHover ? "black" : "#C9C9C9"} d="M15.5917 14.775L20.375 9.99167C20.6083 9.75833 20.6083 9.40833 20.375 9.175C20.1416 8.94167 19.7916 8.94167 19.5583 9.175L14.775 13.9583L9.99166 9.175C9.75833 8.94167 9.40833 8.94167 9.175 9.175C8.94167 9.40833 8.94167 9.75833 9.175 9.99167L13.9583 14.775L9.175 19.5583C8.94167 19.7917 8.94167 20.1417 9.175 20.375C9.29167 20.4917 9.40833 20.4917 9.64167 20.4917C9.875 20.4917 9.99166 20.4917 10.1083 20.375L14.8917 15.5917L19.675 20.375C19.7916 20.4917 19.9083 20.4917 20.1416 20.4917C20.375 20.4917 20.4916 20.4917 20.6083 20.375C20.8416 20.1417 20.8416 19.7917 20.6083 19.5583L15.5917 14.775Z" />
                                </svg>
                            </CardMedia>
                    </IconButton>
              </Stack>
              <Stack direction={"column"} sx={{maxHeight:"460px", overflow:"auto", bgcolor:"secondary.dark", p:"15px"}} spacing={0.75}>
                         <Stack direction={"row"} spacing={1} sx={{color:"secondary.lighter"}}>
                             <Stack sx={{pb:1}} spacing={0.5} direction={"row"} alignItems={"center"}><Box className={"smallTitle"} sx={{color:"white"}}>Videos:</Box></Stack>
                            <Stack sx={{color:"secondary.lighter"}} direction={"row"} spacing={0.5} className={"smallTitle"}>
                                <Box>Total videos</Box><Box sx={{color:"primary.main"}}>{new Intl.NumberFormat().format(vidStats.total)}</Box>
                            </Stack>
                            <Stack sx={{color:"secondary.lighter"}} direction={"row"} spacing={0.5} className={"smallTitle"}>
                                <Box>Added on current patch</Box><Box sx={{color:"primary.main"}}>{new Intl.NumberFormat().format(vidStats.thisPatch)}</Box>
                            </Stack>
                         </Stack>
                        <Box className={"smallTitle"} sx={{color:"white", pb:0.5}}>New Updates</Box>
                         <Stack direction={"column"} spacing={2.5}>

                             <Stack direction={"row"} spacing={1}>
                                 <SvgIcon sx={{width:"40px", height:"40px"}}>
                                     {new_icon}
                                 </SvgIcon>
                                 <Stack direction={"row"} >
                                     <Paper elevation={0} sx={{bgcolor:"secondary.dark", color:"secondary.lighter"}}>
                                         <Box component="span" sx={{fontWeight:700, color:"white"}}>What's new?</Box>
                                         {generate_updates(whats_new_updates)}
                                     </Paper>
                                 </Stack>
                             </Stack>
                              <Stack direction={"row"} spacing={1}>
                                 <SvgIcon sx={{width:"40px", height:"40px"}}>
                                     {bugfix_icon}
                                 </SvgIcon>
                                  <Stack direction={"row"}>
                                     <Paper elevation={0} sx={{bgcolor:"secondary.dark", color:"secondary.lighter"}}>
                                         <Box component="span" sx={{fontWeight:700, color:"white"}}>Bugfixes</Box>
                                         {generate_updates(bug_fixes)}
                                     </Paper>
                                 </Stack>
                             </Stack>
                             <Stack direction={"row"} spacing={1}>
                                 <SvgIcon sx={{width:"40px", height:"40px"}}>
                                     {general_icon}
                                 </SvgIcon>
                                 <Stack direction={"row"}>
                                     <Paper elevation={0} sx={{bgcolor:"secondary.dark", color:"secondary.lighter"}}>
                                         <Box component="span" sx={{fontWeight:700, color:"white"}}>General</Box>
                                         {generate_updates(general_updates)}
                                     </Paper>
                                 </Stack>
                             </Stack>
                         </Stack>
                    </Stack>
              <Stack sx={{pt:1}} justifyContent={"center"} alignItems={"flex-end"}>
                  <OppositeGeneralButton height="30px" clickCallback={() => setClosed()}>
                  <Box sx={{width:"55px"}} className={"smallTitle"}>Got it</Box>
              </OppositeGeneralButton>
              </Stack>
            </Stack>
            </Modal>
        )
}


export default WhatsNewModal