import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {useEffect, Suspense} from "react";
import {WINDOW_NAMES} from "./utils/consts";
import {ow_getCurrentWindow} from "./utils/ow_utils";
import {useMainStore} from "./state"
import {CurrentWindow} from "./windows/CurrentWindow"
import {GlobalStyles} from "@mui/material";
import theme from "./theme"
import AdManager from "./components/AdManager";
import {
    testChampSelectARAM, testChampSelectArena, testChampSelectBlind,
    testChampSelectDraft, testChampSelectLobby,
    testInGameARAM,
    testInGameBlind, testInGameDraft, testInGameOverlay,
    testPlayerInfo,
    testPostGame, testInGameOverlayArena
} from "./tests";
import LargeLoading from './components/LargeLoading';

window.addEventListener('keydown', function(e) {
  if(e.code == 'Space' && e.target == document.body) {
    e.preventDefault();
  }
});

const globalStyles = <GlobalStyles styles={{
    "*::-webkit-scrollbar": {
        width: "0.2em"
    },
    "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#77777A",
        outline: "1px solid slategrey"
    },
    ".MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root": {
        backgroundColor: "initial"
    },
    ".MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.08)"
    },
    ".MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.filter": {
      backgroundColor:"#343333",
      color:theme.palette.secondary.light
    },
    ".MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.filter:hover": {
      color:theme.palette.secondary.lighter
    },
    ".MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.filter svg": {
      backgroundColor:"#343333",
      color:theme.palette.secondary.light
    },
    ".MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.filter:hover svg": {
      color:theme.palette.secondary.lighter
    },
    ".filter fieldset": {
      border:"none"
    },
    "@font-face": {
      fontFamily: 'LeagueFont',
      src: "local('BeaufortforLOL-Bold'), url('/assets/fonts/BeaufortforLOL-Bold.ttf') format('truetype')",
      fontWeight: "normal",
      fontStyle: "normal"
    }
}} />;

export default function App() {
  const activeWindow = useMainStore(state => state.activeWindow)
  const setActiveWindow = useMainStore(state => state.setActiveWindow)
  const isOW = useMainStore(state => state.isOverwolf)
  const initMainStore = useMainStore(state => state.initMainStore)
  const setCurrentUser = useMainStore(state => state.setCurrentUser)
  const mainLoading = useMainStore(state => state.mainLoading)
  const desktopWindow = useMainStore(state => state.desktopWindow)
  // App initialization
  useEffect(() => {
    async function preLoad() {
        if (!isOW) {
            await initMainStore()
        }
        else {
            overwolf.profile.getCurrentUser(async (userInfo) => {
                if (userInfo.username != undefined) {
                    setCurrentUser(userInfo.username)
                }
                else if (userInfo.userId != undefined) {
                    setCurrentUser(userInfo.userId)
                }
                await initMainStore()
            })
        }

      if (!isOW) {
          setActiveWindow(desktopWindow)
          // testPlayerInfo()
          // testInGameOverlayArena()
          // testInGameOverlay()
          // testChampSelectBlind()
          // testChampSelectARAM()
          // testChampSelectDraft()
          // testInGameDraft()
          // testChampSelectArena()

      } else {
        const currentWindow = await ow_getCurrentWindow()
        setActiveWindow(currentWindow)
        console.info(
          '[App.tsx][useEffect - preLoad]',
          JSON.stringify({ currentWindow }, null, 2),
        )
      }
    }
    preLoad()

    // Scroll listener
  }, [])

  return (
    <Suspense fallback={<LargeLoading/>}>
      {globalStyles}
      <CurrentWindow window={mainLoading ? WINDOW_NAMES.LOADING : activeWindow}></CurrentWindow>
    </Suspense>

  );
}
