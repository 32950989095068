import {CardMedia} from "@mui/material";
import {useMainStore} from "../state";
import React from "react";
import {CLOUDFRONT_URL} from "../utils/consts";

const ChampionPortrait = ({championName, size, grey, displayClass = 'champion-portrait', usable=true} : {championName:string | null, size:any, grey:boolean, displayClass?:string, usable?:boolean}) => {
    const serverUrl = CLOUDFRONT_URL
    const [hover, setHover] = React.useState(false)
    const init_tiles = grey == false ? 'portraits' : 'portraits_grey'
    const className = championName != null ? displayClass : ''

    const calcPath = (tiles:string) : string => {
        if (championName != null)
            return `${serverUrl}/assets/${tiles}/${championName == "FiddleSticks" ? "Fiddlesticks" : championName}.png` // TODO: Fix this

        if (hover && usable)
            return `${serverUrl}/assets/champion_empty_state_hover.png`

        if (usable)
            return `${serverUrl}/assets/champion_empty_state.png`

        return ''
    }
    const [path, setPath] = React.useState(calcPath(init_tiles))

    React.useEffect(() => {
        let tiles = 'portraits'
        if ((grey == true) && (hover == false)) {
            tiles = 'portraits_grey'
        }
        setPath(calcPath(tiles))
    }, [hover, championName, grey])

    return (<CardMedia onMouseOver={() => setHover(true)}
                       onMouseOut={() => setHover(false)}
                                       sx={{height:size,
                            width:size, bgcolor:'secondary.dark'}} className={className} component="span"
                       image={path}></CardMedia>)
}

export default ChampionPortrait