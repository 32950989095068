import Rune from "./Rune";
import Box from "@mui/material/Box";
import RuneTree from "./RuneTree";
import {Stack} from "@mui/material";
import {tree2color} from "../utils/general";
import StatRune from "./StatRune";


const StatRunes = ({runes, size="22px", variant="standard", reverse=false} : {runes:any[], size?:string, variant?:string, reverse?:boolean}) => {

    return (<Stack direction={"column"} justifyContent={"center"} spacing={variant == "dense" ? 3.2 : 3}>
        {variant != "dense" && <Box/>}
        <Stack direction={reverse ? "row-reverse" : "row"} alignItems={"center"} justifyContent={"center"}>
        {runes.map((rune : string, idx) =>
            <Box sx={{mr: variant == "dense" ? 0.2 :1.5}}><StatRune runeId={rune[2]} size={size}/></Box>
        )}
        </Stack>
    </Stack>)
}

export default StatRunes