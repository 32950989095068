import {CardMedia} from "@mui/material";
import {tree2color} from "../utils/general";

// const tree2treename = Object({
//     'domination':'7200_domination',
//     'precision':'7201_precision',
//     'sorcery':'7202_sorcery',
//     'inspiration':'7203_whimsy',
//     'resolve':'7204_resolve'
// })

const RuneTree = ({firstRune, size}:{firstRune:string[], size:string}) => {
    const base_url = '/assets/runes'
    const tree : any = firstRune[0]
    // const treeName = tree2treename[tree]
    return <CardMedia image={tree == "" ? "" : `${base_url}/${tree}.png`}
                      sx={{width:size, bgcolor:"secondary.dark", height:size, border:`1px solid ${tree2color[tree]}`,
                          borderRadius:"50%"}} />
}

export default RuneTree