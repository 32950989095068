import React, { ReactElement, ReactNode, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Box, Stack } from '@mui/material';
import { useMainStore } from '../state';

interface FeatureHighlightTooltipProps {
  tooltipText: any;
  children: ReactElement<any, any>;
  featureName: string
  disabled?: boolean,
  placement?: any,
  showIcon?:boolean
}

export const FeatureHighlightTooltip: React.FC<FeatureHighlightTooltipProps> = ({
  tooltipText,
  children,
  featureName,
  disabled=false,
  placement=undefined,
  showIcon=true
}) => {
    const tooltipRef = useRef(null);
    const seenFeature = useMainStore(store => store.settings.seenFeatures.includes(featureName))
    const [open, setOpen] = React.useState(!seenFeature);
    const updateSeenFeatures = useMainStore(store => store.updateSeenFeatures)

  
    const handleTooltipClose = () => {
      
      setOpen(false);
      !seenFeature && updateSeenFeatures(featureName)
    };

    if (disabled) return children
  
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div ref={tooltipRef}>
          <Tooltip
            placement={placement}
            open={open}
            arrow={true}
            componentsProps={{
              tooltip: {
                   sx: {
                     bgcolor: 'secondary.dark',
                   },
                 },
              arrow: {
                   sx: {
                     color: 'secondary.dark',
                   },
                 },
            }}
            disableHoverListener
            onClose={handleTooltipClose}
            className={"featureHighlightTooltip"}
            title={<Stack direction="row" onClick={handleTooltipClose}>
                        {showIcon && <NewReleasesIcon sx={{height:20, color:'primary.main'}}/>}
                        <Box className="generalText" sx={{color:'secondary.lighter'}}>{tooltipText}</Box>
                    </Stack>}
          >
            {children}
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  };

export default FeatureHighlightTooltip