import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton, Paper, Stack, SvgIcon, Tooltip} from "@mui/material";
import {useMainStore} from "../../state";
import WhatsNewModal from "../WhatsNewModal";




const UpdateButton = () => {
    const [open, setOpen] = useState(false)
    const [hover, setHover] = useState(false)
    const openModal = () => {
        setOpen(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
    return (<>
            <WhatsNewModal open={open} setClosed={closeModal} />
            <IconButton sx={{p:0, m:0}}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => openModal()}>
                        <CardMedia sx={{
                            bgcolor:hover ? "secondary.main" : "none",
                            height:'30px',
                            width:'30px'}}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill={"#C9C9C9"} fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.5 23L15.5 7L25.5 23H5.5ZM16.7 12.9999C16.7 13.6627 16.1628 14.1999 15.5 14.1999C14.8373 14.1999 14.3 13.6627 14.3 12.9999C14.3 12.3372 14.8373 11.7999 15.5 11.7999C16.1628 11.7999 16.7 12.3372 16.7 12.9999ZM16.7 21.4V15H14.3V21.4H16.7Z"/>
                            </svg>
                        </CardMedia>
            </IconButton>
        </>
    )
}

export default UpdateButton