import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import {useMainStore, useDesktopStore} from "../state";
import * as React from "react";
import ChampionPortrait from "./ChampionPortrait";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import Swap from "./Swap";
import Clear from "./Clear";
import { champToDisplayName } from "../utils/general";

const selectChampionSize = 68
const ChampionBrowser = ({id="championbrowser", source, target, label, bgcolor, height, width, gridXs=3, inputBorder=false, containerClassName,
                             champPb, hideClearButton, variant="standard", limitedChoice=null, inputP=0} : {id?:string, source:any, target:Function, label:string,
                                                                              bgcolor?:string, height?:string, width?:string,
                                                                   champPb?:number, hideClearButton?:boolean, inputBorder?:boolean, variant?:string, gridXs?:number, limitedChoice?:any, containerClassName?:string, inputP?:number}) => {
    const extra_width = (window.innerWidth - 1536 - 275) / 12
    // const extra_width = 0
    const championPortraitSize = variant == "phone" ? "28px" : variant == "standard" ? (selectChampionSize + (extra_width > 0 ? extra_width : 0)) : '42px'
    const open = useDesktopStore(state => state.champSelectOpen)
    const allChampions = useMainStore(state => state.allChampions)
    const [filteredChampions, setFilteredChampions] = useState(limitedChoice != null ? limitedChoice : allChampions)

    const [searchValue, setSearchValue] = useState("")
    const [searchFocused, setSearchFocused] = useState(false)
    const id_prefix = id.toLowerCase().replaceAll(" ", "-")

    const generateItem = (champion : any) => {
        return (<Grid item id={`${id_prefix}-${champion}`} xs={gridXs} xl={gridXs - 1}
                                      sx={{pt:0.5}}>
                                <Stack direction={"row"} justifyContent={"center"}>
                                    <IconButton sx={{ visibility:champion == null ? "hidden" : "unset", p:0, outline: champion == source ? '2px solid' : 'none', color:'primary.main', borderRadius:0}} onClick={() => target(champion)}>
                                        <ChampionPortrait displayClass='champion-portrait-browse' grey={champion != source ? true : false} championName={champion} size={championPortraitSize} ></ChampionPortrait>
                                        {(champion == source) && (!hideClearButton) ? <Clear size={24} target={target}/> : <></>}
                                    </IconButton>
                                </Stack>
                        </Grid>)
    }
    useEffect(() => {
        setSearchValue("")

        if (source) {
            document.getElementById(`${id_prefix}-${source}`)?.scrollIntoView({ behavior: 'auto', block:'nearest' });
        }
    }, [open])

    useEffect(() => {
        if (limitedChoice != null)
            return setFilteredChampions(limitedChoice)

        return setFilteredChampions(allChampions)
    }, [limitedChoice, allChampions])

    const filterChampions = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (limitedChoice)
            return
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        if (limitedChoice)
            return

        let s = searchValue.toLowerCase()
        if (s == "")
            setFilteredChampions(allChampions)
        else {
            let filteredRes: any = allChampions.filter((champion: string) => champToDisplayName(champion).toLowerCase().includes(s))
            setFilteredChampions(filteredRes)
            if (filteredRes.length == 1) {
                target(filteredRes[0])
            }
        }



    }, [searchValue])

    return (
        <Stack id={id} className={'championSelector'} alignItems={"center"} sx={{height:"100%"}}>
            { limitedChoice != null ? <></> : <Box className={"championSelectorSearchbox"} sx={{color: "secondary.light", position:"relative", width:'100%', mt:0.25, mb:0.5, pr:inputP, pl:inputP}} justifyContent={"center"} alignItems="center" display="flex">
                    <TextField sx={{borderRadius:0, border:"none", bgcolor:'#292929', width:'100%'}} value={searchValue}
                               autoComplete='off' fullWidth={true} variant="outlined"
                               onFocus={() => setSearchFocused(true)}
                               onBlur={() => setSearchFocused(false)}
                               size="small" onChange={filterChampions}
                               InputProps={{
                                   sx: {
                                       "input": {
                                           opacity: !searchFocused && source != null ? "0" : "1",
                                           height:"1em"
                                       },
                                       borderRadius: 0,
                                       border: inputBorder ? "1px solid" : "none",
                                       borderColor: "secondary.main"
                                   },
                                   endAdornment: <InputAdornment position="end" sx={{width:variant == "phone" ? "8px" : "unset"}}><SearchIcon/></InputAdornment>
                               }}
                    >
                    </TextField>
                    <Box sx={{fontSize: variant == "phone" ? "8px" : "unset", pointerEvents:"none", visibility: searchFocused ? "hidden" : "visible", position:"absolute", left:variant == "phone" ? 10 : 22, color: source != null && !searchFocused ? "white" : "secondary.light"}}>{source != null ? champToDisplayName(source) : label}</Box>
            </Box> }

            <Box sx={{height:height ? height : '75vh',
                    width: width ? width : undefined,
                    bgcolor:bgcolor ? bgcolor : 'secondary.dark', overflow:"auto",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "200px",
                    backgroundPosition: "50% 40%",
                    backgroundImage:filteredChampions.length == 0 ? "url(/assets/champion_vanished.svg)" : "none"}}
                className={containerClassName}>
                <Grid container direction={"row"} spacing={0} >
                    {filteredChampions.map((champion : any, idx:number) => {
                        return generateItem(champion)
                      })}
                    {filteredChampions.length > 10 ? <></> : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value : any, idx:number) => {
                        return generateItem(null)
                      })}
                </Grid>
            </Box>

        </Stack>)
}


export default ChampionBrowser