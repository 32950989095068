import ReactPlayer from 'react-player/vimeo'
import {Card, Paper, Stack} from "@mui/material";
import {CardMedia} from "@mui/material";
import {useEffect, useState, createRef, RefObject, useRef} from "react";
import VimeoPlayer from "react-player/vimeo";
import {useMainStore} from "../state";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useIdleTimer } from 'react-idle-timer';
import {clipActiveTrigger} from "../utils/general";
import ChampionPortrait from "./ChampionPortrait";
import RuneTree from "./RuneTree";
import PrimaryTree from "./PrimaryTree";
import SecondaryTree from "./SecondaryTree";
import StatRunes from "./StatRunes";
import Spell from "./Spell";
import * as React from "react";
import ItemSet from "./ItemSet";
import Rank from "./Rank";
import {} from "../utils/export"

const PlayerContext = ({data, reverse=false, forceLarge=false, minWidth=undefined, showRank=true, withCooldowns=true} : {data:any, reverse?:boolean, forceLarge?:boolean, minWidth?:string, showRank?:boolean, withCooldowns?:boolean}) => {
    const large = window.innerWidth >= 2000 || forceLarge
    const metadata = useMainStore(state => state.metadata)
    const general_runes = data.general_runes.map((e : any) => {
        //@ts-ignore
        return metadata.general_runes[e]
    })
    const stat_runes = data.stat_runes.map((e : any) => {
        //@ts-ignore
        return metadata.stat_runes[e]
    })

    const items = data.items.map((e : any) => {
        //@ts-ignore
        return metadata.items[e]
    }).concat([" ", " ", " ", " ", " ", " "].slice(data.items.length))

    return <Paper id={reverse ? "enemyContext" : "playerContext"} sx={{position:"relative", minWidth:minWidth ? minWidth : !large ? "240px" : "360px", height:!large ? "72px" : "108px", p:0.5, backgroundImage:"none", bgcolor:"#121212"}} className={`playerContext ${reverse ? "reversed" : ""}`}>
        <Box sx={{display:showRank ? "block" : "none", position:"absolute", top:"-4px", right: reverse ? "-1px" : "unset", left:reverse ? "unset" : "-1px"}}><Rank rank={data.rank}/></Box>
        <Box sx={{position:"absolute", top:!large ? "28px" : "42px", right: reverse ? (!large ? "38px" : "54px") : "unset", left:reverse ? "unset" : (!large ? "38px" : "54px"), color:"secondary.lighter", fontSize:large ? "20px" : 'unset', fontWeight: large ? "500" : "unset", textShadow: '1px 1px 2px rgb(0 0 0)',}}>{data.level}</Box>
        <Stack direction={"column"} spacing={0.5}>
            <Stack direction={reverse ? "row-reverse" : "row"} spacing={1.5} justifyContent={"space-between"}>
                <ChampionPortrait championName={data.champion} size={!large ? "40px" : "60px"} grey={false}/>
                <Stack direction={"column"}>
                    <PrimaryTree runes={general_runes.slice(0, 4)} bigRuneSize={!large ? "20px" : "30px"} smallRuneSize={!large ? "18px" : "27px"} variant="dense" showName={false} reverse={reverse} denseSpacing={0.6}/>
                    <Stack direction={reverse ? "row-reverse" : "row"} spacing={0.5}>
                        <SecondaryTree runes={general_runes.slice(4)} bigRuneSize={!large ? "20px" : "30px"} smallRuneSize={!large ? "18px" : "27px"} variant="dense" showName={false} reverse={reverse}/>
                        <StatRunes runes={stat_runes} size={"14px"} variant={"dense"} reverse={reverse}/>
                    </Stack>
                </Stack>
                <Stack direction={"column"} spacing={0} alignItems={"center"}>
                    <Spell champion={data.champion} abilityKey={data.summonerSpells[0]} showKey={false} size={!large ? "20px" : "30px"} cooldown={!withCooldowns ? false : data.spell_d.available == false}/>
                    <Spell champion={data.champion} abilityKey={data.summonerSpells[1]} showKey={false} size={!large ? "20px" : "30px"} cooldown={!withCooldowns ? false : data.spell_f.available == false}/>
                </Stack>
            </Stack>
            <Stack direction={reverse ? "row-reverse" : "row"} spacing={1.15} justifyContent={"space-between"}>
                <ItemSet items={items} itemSize={!large ? "20px" : "30px"} tooltipPosition={"top"} reverse={reverse}/>
                <Stack direction={"row"} divider={<Box>/</Box>} sx={{color:"secondary.lighter", fontWeight:500, fontSize:!large ? "15px" : "20px"}}>
                    <Box>{data.kills_score}</Box>
                    <Box>{data.deaths_score}</Box>
                    <Box>{data.assists_score}</Box>
                </Stack>
                <Box sx={{color:"#e6eba5", fontWeight:500, fontSize:!large ? "15px" : "20px"}}>{data.creep_score}</Box>
            </Stack>
        </Stack>
    </Paper>
}

export default PlayerContext