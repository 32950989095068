import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton} from "@mui/material";

const MaximizeButton = () => {
    const [hover, setHover] = useState(false)
    const [maximized, setMaximized] = useState(false)

    return (
        <IconButton sx={{p:0, m:0}} onClick={() => overwolf.windows.getCurrentWindow((window) => {
            if (maximized)
                overwolf.windows.restore(window.window.name)
            else
                overwolf.windows.maximize(window.window.name)
            setMaximized(!maximized)
        })}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "secondary.main" : "none",
                    height:'30px',
                    width:'30px'}}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 22H9V23H21V22Z" fill={"#C9C9C9"}/>
                        <path d="M10 23L10 11L9 11L9 23L10 23Z" fill={"#C9C9C9"}/>
                        <path d="M21 23L21 11L20 11L20 23L21 23Z" fill={"#C9C9C9"}/>
                        <path d="M9 12L21 12L21 11L9 11L9 12Z" fill={"#C9C9C9"}/>
                    </svg>
                </CardMedia>
        </IconButton>)
}

export default MaximizeButton