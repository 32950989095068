import ReactPlayer from 'react-player'
import {Card, IconButton, LinearProgress, Slider, Stack} from "@mui/material";
import {CardMedia} from "@mui/material";
import {useEffect, useState, createRef, RefObject, useRef} from "react";
import VimeoPlayer from "react-player/vimeo";
import {useDesktopStore, useMainStore} from "../state";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useIdleTimer } from 'react-idle-timer';
import {clipActiveTrigger} from "../utils/general";
import PlayerContext from "./PlayerContext";
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import AutoplayToggle from "./AutoplayToggle";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Tag from "./Tag";

const Clip = ({vimeoId=0, filename, muted, controls, playing, loadedCallback, preload, maxHeight="unset", quality="1080p", onVideoOver, context, loop=true, visible=true, inGame=false, nextFunction=undefined, previousFunction=undefined, stream=false, defaultVolume=0.75, trackView=true, nextDisabled=false, previousDisabled=false, disableFade=false, showAutoplay=true} : {vimeoId?:number, filename:string, muted:boolean, controls:boolean, playing:boolean, loadedCallback?:Function, preload:boolean, maxHeight?:string, quality?:string, onVideoOver?:Function, loop?:boolean, visible?:boolean, inGame?:boolean, context?:any, nextFunction?:Function, previousFunction?:Function, stream?:boolean, defaultVolume?:number, trackView?:boolean, nextDisabled?:boolean, previousDisabled?:boolean, disableFade?:boolean, showAutoplay?:boolean}) => {
    const isPhone = useMainStore(state => state.isPhone)
    const viewedFunction = useMainStore(state => state.viewed)
    const videosMuted = useMainStore(state => state.videosMuted)
    const goToPreviousVideo = previousFunction ? previousFunction : useDesktopStore(state => state.goToPreviousVideo)
    const goToNextVideo = nextFunction ? nextFunction : useDesktopStore(state => state.goToNextVideo)
    const setVideosMuted = useMainStore(state => state.setVideosMuted)
    const [loaded, setLoaded] = useState(false);
    const [viewed, setViewed] = useState(false);
    const [ready, setReady] = useState(false);
    const [ended, setEnded] = useState(false);
    const [fading, setFading] = useState(false);
    const [paused, setPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(0);
    const playerRef : any = useRef();
    // const base_url = `http://player.vimeo.com/video/${vimeoId}`
    // const base_url = `https://stream.mux.com/${filename}.m3u8`
    const base_url = `https://stream.mux.com/${filename}/high.mp4`
    const displayed = visible && ready && !ended


    const [progressInterval, setProgressInterval] = useState(false);
    useEffect(() => {
        console.log('clip context', JSON.stringify(context))
        return () => {
            playerRef.current = null
        }
    }, [])

    useEffect(() => {
        setReady(false)
        setLoaded(false)
        setFading(false)
        setEnded(false)
        setPaused(false)
        setProgress(0)
        setBuffer(0)
    }, [vimeoId])

    useEffect(() => {
        setEnded(false)
        setFading(false)
        if (ready) {
            playerRef.current.player.player.seekTo(0)
            // if (playing && visible) {
            //     playerRef.current.player.player.play()
            // }
            // if (playing || (preload && !loaded)) {
            //     console.log('playing!', playing, vimeoId)
            //     playerRef.current.player.player.play()
            // }
        }
    }, [ready, loaded, playing, visible])
    const onReady = () => {
        setReady(true)
         if (!loop) {
            playerRef.current.player.player.setLoop(false)
        }

        if (stream) {
             playerRef?.current?.player?.player?.setVolume(defaultVolume)
        }
    }

    useEffect(() => {
        if (!playing) {
            playerRef?.current?.player?.player.secondsLoaded && playerRef?.current?.player?.player?.pause()
        }
        else {
            playerRef?.current?.player?.player?.play()
        }
    }, [playing])
    // const playerRef : any = useRef()

    const onProgress = (e: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number; }) => {

        // if ((preload) && (!loaded)) {
        // if ((preload && !playing && !loaded && (e.loadedSeconds > 0))) {
        //     console.log(`preload done - video ${vimeoId}`)
        //     playerRef?.current?.player?.player?.pause()
        //     setLoaded(true)
        // }

        if ((e.loadedSeconds > 0) && (playing) && (visible)) {
            loadedCallback && loadedCallback()
            clipActiveTrigger()
        }

       if (e.played > 0.7) {
            if ((!viewed) && (trackView)) {
                viewedFunction(vimeoId)
                setViewed(true)
            }
        }

        if ((e.playedSeconds / e.played - e.playedSeconds <= 0.4) && (!loop) && (!inGame)) {
            setFading(true)
        }

        setBuffer(e.loaded * 100)
        setProgress(e.played * 100)
    }

    const videoOver = () => {
        console.log('video over!')
        onVideoOver != undefined && onVideoOver()
        if (!inGame)
            setEnded(true)
        playerRef.current.player.player.seekTo(0)
    }

    const sliderChange = (event: Event, value: any, activeThumb: number) => {
        playerRef.current.player.seekTo((value + 1)/ 100)
    }
    const togglePause = () => {
        if (paused) {
                playerRef?.current?.player?.player?.play()
                setPaused(false)
        }
        else {
             playerRef?.current?.player?.player?.pause()
            setPaused(true)
        }
    }
    const toggleMute = () => {
        if (videosMuted) {
            playerRef?.current?.player?.player?.setVolume(defaultVolume)
            setVideosMuted(false)
        }
        else {
            playerRef?.current?.player?.player?.mute()
            setVideosMuted(true)
        }
    }
    return (
        <Box sx={{height:"100%"}}>
        <Fade in={(displayed && !fading) || disableFade} timeout={{appear:750, enter:750, exit:750}}>
        <Box sx={{display:displayed ? "unset" : "none", opacity:displayed ? 1 : 0, transition:"opacity 2s ease-in-out"}}>
            <Stack direction={"column"} spacing={0.25} justifyContent={"center"}>
                <Box>
                    {context && context.killer &&
                    (<Box sx={{position: "absolute", bottom: isPhone ? -208 : 0, zIndex: 100, pointerEvents:"all"}}>
                        <PlayerContext data={context['killer']} forceLarge={stream}/>
                    </Box>)
                    }
                    {context && context.victim &&
                    (<Box sx={{position: "absolute", bottom: isPhone ? -285 : 0, right:0, zIndex: 100, pointerEvents:"all"}}>
                        <PlayerContext data={context['victim']} reverse={true} forceLarge={stream}/>
                    </Box>)
                    }
                    {context && context.tags &&
                    (<Stack spacing={0.5} direction={"row"} sx={{position: "absolute", top: 2, left:2, zIndex: 100, pointerEvents:"all"}}>{context.tags.map((t : any) => {
                        return <Tag data={t}></Tag>
                    })}
                    </Stack>)
                    }
                    {controls && <Box onClick={() => {
                        togglePause()
                        console.log('magic box clicked!')
                    }} className={"overlayBox"} sx={{
                        pointerEvents:"all",
                        cursor:"pointer",
                        zIndex:9999,
                        top:"25px", left:0, position:"absolute", width:"100%", height:"calc(100% - 98px)", backgroundColor:"black", opacity:0}}>
                    </Box>}
                    <Box>
                        <ReactPlayer onProgress={(e) => onProgress(e)} onReady={onReady} ref={playerRef} className='react-player' url={base_url}
                                 // stopOnUnmount={true}
                                 muted={muted || videosMuted}
                                    controls={false}
                                    playing={(playing && visible)}
                                 onEnded={() => videoOver()}
                                 loop={loop}
                                 progressInterval={50}
                                 style={{maxHeight:maxHeight}}
                                    width='100%'
                                    height='100%' playIcon={<></>}
                                    playsinline={true}
                                //  config={{
                                //      playerOptions:{playsinline:true, byline:false, title:false, quality:quality, autoplay:playing, autopause:false}
                                // }}
                            />
                    </Box>
                </Box>
                {controls &&
                    <Stack direction={"row"} sx={{bottom:"-25px", width:"100%", height: "25px", pointerEvents: "all", position:"absolute"}} spacing={1.5}>
                        <IconButton disabled={previousDisabled} sx={{p: 0, color: "secondary.lighter"}} onClick={() => {
                            setFading(true)
                            window.setTimeout(() => {
                                goToPreviousVideo()
                            }, 50)

                        }}><SkipPreviousIcon/></IconButton>
                        <IconButton sx={{p: 0, color: "secondary.lighter"}} onClick={() => {
                            togglePause()
                        }}>{paused ? <PlayArrowIcon/> : <PauseIcon/>}</IconButton>
                        <Stack direction={"column"} sx={{height: "100%", width: "calc(100% - 25px)"}}
                               justifyContent={"center"} alignItems={"center"}>
                            <Stack sx={{height: "100%", width: "100%"}} alignItems={"center"} justifyContent={"center"}>
                                <Slider sx={{pt: 1, pb: 1}} size={"small"} value={progress} onChange={sliderChange}/>
                            </Stack>
                        </Stack>
                        <IconButton sx={{p: 0, color: "secondary.lighter"}} onClick={() => toggleMute()}>{videosMuted ?
                            <VolumeOffIcon/> : <VolumeUpIcon/>}</IconButton>
                        <IconButton disabled={nextDisabled} sx={{p: 0, color: "secondary.lighter"}}
                                    onClick={() => {
                                        setFading(true)
                                        window.setTimeout(() => {
                                            goToNextVideo()
                                        }, 50)

                                    }}><SkipNextIcon/></IconButton>
                        {showAutoplay && <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                            <Box className={"generalText"}
                                 sx={{fontSize: "12px", color: "secondary.light", pr: "2px"}}>Autoplay</Box>
                            <AutoplayToggle/>
                        </Stack>}
                    </Stack>
                }
            </Stack>
            </Box>
    </Fade>
        </Box>
)
}

export default Clip