import {useEffect} from "react";
import {useScript} from "../utils/useScript"
import {OwAd} from "@overwolf/types/owads";
import {useMainStore} from "../state";
import Box from "@mui/material/Box";
import {debounce} from "@mui/material";
let kWindowName : string = "";

declare global {
    interface Window { OwAd: any; }
}

let adEnabled = false,
  updateWindowIsVisibleInterval = null,
  windowIsOpen = false,
  windowIsVisible = false,
  adInstance : any = null;

// let adsbygoogle;
// @ts-ignore
// (adsbygoogle = window.adsbygoogle || []).push({})

async function initOwAds() {
  // localStorage.owAdsForceAdUnit = "Ad_test"

  overwolf.windows.onStateChanged.addListener(onWindowStateChanged);

  updateWindowIsVisibleInterval = setInterval(updateWindowIsVisible, 500);

  windowIsOpen = await getWindowIsOpen();

  windowIsVisible = await getWindowIsVisible();

  updateAd();
}

async function initGoogleAds(isMobile : boolean) {
  // @ts-ignore
  await loadGoogleAds(isMobile)
}



function loadGoogleAds(isMobile : boolean) {
  let remove_id = isMobile ? 'amain' : 'mob'
  document.getElementById(remove_id)?.remove()
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5714669125486759';
    el.crossOrigin = 'anonymous'
    el.async = true;
    el.onload = resolve;
    el.onerror = reject;
    document.body.appendChild(el);
  });
}

function loadAdLib() {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = 'https://content.overwolf.com/libs/ads/latest/owads.min.js';
    el.async = true;
    el.onload = resolve;
    el.onerror = reject;
    document.body.appendChild(el);
  });
}

async function getWindowIsVisible() {
  const state : overwolf.windows.IsWindowVisibleToUserResult = await new Promise(resolve => {
    overwolf.windows.isWindowVisibleToUser(resolve);
  });

  return (state && state.success && state.visible !== 'hidden');
}

async function updateWindowIsVisible() {
  const isVisible = await getWindowIsVisible();

  if (windowIsVisible !== isVisible) {
    console.log(`updateWindowIsVisible():`, isVisible);
    windowIsVisible = isVisible;
    updateAd();
  }
}

async function getWindowIsOpen() {
  const state : overwolf.windows.GetWindowStateResult = await new Promise(resolve => {
    overwolf.windows.getWindowState(kWindowName, resolve);
  });

  if (state && state.success && state.window_state_ex) {
    const isOpen = (
      state.window_state_ex === 'normal' ||
      state.window_state_ex === 'maximized'
    );

    console.log(`getWindowIsOpen():`, state.window_state_ex, isOpen);

    return isOpen;
  }

  return false;
}

const onWindowStateChanged = debounce((state : overwolf.windows.WindowStateChangedEvent) => {
  if (state && state.window_state_ex && state.window_name === kWindowName) {
    const isOpen = (
      state.window_state_ex === 'normal' ||
      state.window_state_ex === 'maximized'
    );

    console.log(`onWindowStateChanged:`, state.window_state_ex, isOpen);

    if (windowIsOpen !== isOpen) {
      windowIsOpen = isOpen;
      updateAd();
    }
  }
}, 3000)

function updateAd() {
  const shouldEnable = (windowIsOpen && windowIsVisible);

  if (adEnabled !== shouldEnable) {
    adEnabled = shouldEnable;

    if (shouldEnable) {
      createAd();
    } else {
      removeAd();
    }
  }
}

async function createAd() {
  if (!('OwAd' in window)) {
    await loadAdLib();

    if (!('OwAd' in window)) {
      console.error('Couldn\'t load OwAd');
      return;
    }
  }

  if (adInstance !== null) {
    adInstance.refreshAd();
    console.log('createAd(): refreshAd');
    return;
  }

  const adCont = document.getElementById('adco');

  // @ts-ignore
  adInstance = new window.OwAd(adCont, {
    size: {
      width: 400,
      height: 300
    }
  });

  adInstance.addEventListener('player_loaded', () => console.log('OwAd player_loaded'));
  adInstance.addEventListener('display_ad_loaded', () => console.log('OwAd display_ad_loaded'));
  adInstance.addEventListener('play', () => console.log('OwAd play'));
  adInstance.addEventListener('impression', () => console.log('OwAd impression'));
  adInstance.addEventListener('complete', () => console.log('OwAd complete'));
  adInstance.addEventListener('ow_internal_rendered', () => console.log('OwAd ow_internal_rendered'));

  adInstance.addEventListener('error', (e : any) => {
    console.log('OwAd instance error:');
    console.error(e);
  });

  console.log('createAd(): new Ad instance');
}

function removeAd() {
  if (adInstance !== null) {
    console.log('removeAd()');
    adInstance.removeAd();
  }
}

const AdManager = ({windowName} : {windowName : string}) => {
      const isOw = useMainStore(state => state.isOverwolf)
      const isMobile = useMainStore(state => state.isPhone)

      useEffect(() => {
        kWindowName = windowName;
      })

     useEffect(() => {
          kWindowName = windowName;
          // isOw ? initOwAds() : initGoogleAds(isMobile)
          isOw && initOwAds()


     }, [isOw])

    return (isMobile || !isOw) ? <></> : <Box component={"div"} className={`adco_${windowName}`} id="adco" sx={{pointerEvents:"none"}}></Box>
}

export default AdManager