import { Box, Stack } from "@mui/material"
import { useDesktopStore, useMainStore } from "../state"
import Clip from "./Clip"
import Loading from "./Loading";
import Rank from "./Rank";
import ChampionPortrait from "./ChampionPortrait";
import Share from "./Share";
import ShareHighlight from "./ShareHighlight";
import { useEffect, useState } from "react";

const SPARE_ROOM = 275;
const MontageVideoViewer = ({shareMode = false} : {shareMode?:boolean}) => {
    const montageVideos = useDesktopStore(store => store.magicHighlights)
    const montagePlayerData : any = useDesktopStore(store => store.magicHighlightsPlayer)
    const [currentClipIndex, setCurrentClipIndex]  = useState(0)
    const [activeClip, setActiveClip] : any = useState(montageVideos ? montageVideos[currentClipIndex] : null)
    const spare_room = shareMode ? 0 : SPARE_ROOM
    useEffect(() => {
        setActiveClip(montageVideos[currentClipIndex])
        console.log('changed active clip')
    }, [currentClipIndex, montageVideos])

    return (!montagePlayerData || !activeClip ? <Loading open={true}/> :
            <Stack sx={{position:"relative", height:"100%", width:"100%", pt:2, justifyContent:"start"}}>
                {/* { */}
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className="titleText" >{montagePlayerData.name}</Box>
                        <Rank rank={montagePlayerData.rank} size={"40px"}/>
                    </Stack>
                    {!shareMode && <Box className="generalText" sx={{color:"secondary.lighter"}}>Highlight <Box component="span" sx={{fontWeight:"bold"}}>{currentClipIndex + 1}</Box> / {montageVideos.length}</Box>}
                </Stack>
                <Stack sx={{position:'relative', height:'100%', maxHeight:`calc(100vh  - ${spare_room}px)`, mb:5}}>
                    {activeClip && <Clip 
                        filename={activeClip['mux_playback_id']}
                        maxHeight={"100hv"}
                        muted={false}
                        controls={true}
                        playing={true} preload={false}
                        trackView={false}
                        visible={true}
                        disableFade={true}
                        nextFunction={() => {
                            setCurrentClipIndex(currentClipIndex + 1)
                        }}
                        previousFunction={() => {
                            setCurrentClipIndex(currentClipIndex - 1)
                        }}
                        showAutoplay={false}
                        nextDisabled={currentClipIndex == montageVideos.length - 1}
                        previousDisabled={currentClipIndex == 0}
                    />}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="column" alignItems="center">
                            <Box className="titleText" sx={{fontSize:"16px", color:"secondary.light"}}>Featured Champions</Box>
                            <Stack direction="row" spacing={0.75}>
                                {
                                    activeClip['champions'].map((champ : string) => {
                                        return <ChampionPortrait championName={champ} size={40} grey={false}></ChampionPortrait>
                                    })
                                }
                            </Stack>
                        </Stack>
                        <Stack>
                            <ShareHighlight highlightId={activeClip['highlight_id']} size={30} text={true}/>
                        </Stack>
                </Stack>

                {/* } */}
            </Stack>
    )
}

export default MontageVideoViewer