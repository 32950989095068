
import { useEffect, useRef, useState } from "react";
import { useDesktopStore, useMainStore } from "../state";
import { Box, Stack, Grid, keyframes, CircularProgress, Tooltip, IconButton } from "@mui/material";
import ChampionPortrait from "../components/ChampionPortrait";
import { smallVs } from "../icons/side_icons";
import OppositeGeneralButton from "../components/OppositeGeneralButton";
import ReactPlayer from "react-player";
import PlayerContext from "../components/PlayerContext";
import GeneralButton from "../components/GeneralButton";
import KillChanceOverlay from "../components/KillChanceOverlay";
import Xarrow from "react-xarrows";
import theme from "../theme";
import Loading from "../components/Loading";
import {allowed_matchups} from "../utils/kill_chance_demo_consts"
import useMediaQuery from '@mui/material/useMediaQuery';
import SingleChampionSelector from "../components/SingleChampionSelector";
import ChampionBrowser from "../components/ChampionBrowser";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from "react-helmet";
// import {swapTransition} from "../utils/animations"

//avoid the use of static text, use i18n instead, each language has its own text, and the text is stored in the
//locales folder in the project root
// const transparent_bg = "#000000a1"
const transparent_bg = "#aeaeae17"


const glowAnimation = keyframes`
  0% {
    border-color: transparent;
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.5);
  }
  50% {
    border-color: rgba(255, 233, 1, 1);
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
  }
  100% {
    border-color: transparent;
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.5);
  }
`;

const KillChanceDemoWindow = () => {
  const killer = useDesktopStore(store => store.killer)
  const victim = useDesktopStore(store => store.victim)
  const setKiller = useDesktopStore(store => store.setKiller)
  const setVictim = useDesktopStore(store => store.setVictim)
  const displayedVideos : any = useDesktopStore(store => store.displayedVideos)
  const context1Ref = useRef(null);
  const context2Ref = useRef(null);
  const overlayRef = useRef(null);
  const isPhone = useMainStore(store => store.isPhone)
  const mdOrGreater = useMediaQuery(theme.breakpoints.up("md"))
  const smOrGreater = useMediaQuery(theme.breakpoints.up("sm"))
  const activeVideo : any = Object.keys(displayedVideos).length > 0 ? Object.values(displayedVideos)[0] : null
  const [fading, setFading] = useState(false)
  const [clipViewMode, setClipViewMode] = useState(false)
  const [killChanceTooltipShown, setKillChanceTooltipShown] = useState(0)
  const [showInfoTooltip, setShowInfoTooltip] = useState(true)


  // @ts-ignore
  const [url, setUrl] = useState("")

  // @ts-ignore
  const context = activeVideo ? activeVideo.context : null
  console.log('context', context)
  const lineColor = "rgba(255, 234, 1, 0.5)"

  useEffect(() => {
     let url = window.location.href.replace(/\/+$/, '');
     const urlParts = url.split('/');
     const champion = urlParts[urlParts.length - 1];
     console.log('load champion', champion)

     if (allowed_matchups.filter((v) => v[0] == champion).length) {
          randomizeMatchupByKiller(champion)
     }
  }, [])

  useEffect(() => {
     if (activeVideo) {
          setUrl(`https://stream.mux.com/${activeVideo.filename}/high.mp4`)
     }
  }, [activeVideo])

  const randomMatchupByKiller = (killer : string) => {
     const killerMatchups = allowed_matchups.filter((v) => v[0] == killer)
     const randomIndex = Math.floor(Math.random() * killerMatchups.length);
     return killerMatchups[randomIndex];
  }

  const randomizeMatchupByKiller = (killer : any) => {
     const [newKiller, newVictim] = randomMatchupByKiller(killer);
     setFading(true);
     setClipViewMode(true);
     setKiller(newKiller, false);
     setVictim(newVictim, true, false);
  }

  const preparePredicitionDataFromContext = (player_context : any, color : string) => {
     let itemData : any = useMainStore.getState().item2data
     let out : any = {}
     out[`${color}_champion`] = player_context.champion
     out[`${color}_items`] = player_context.items
     out[`${color}_gold`] = out[`${color}_items`].reduce((partial_sum : number, itemID : any) => partial_sum + itemData[itemID.toString()][2], 0)
     out[`${color}_level`] = player_context.level
     out[`${color}_general_runes`] = player_context.general_runes[0]
     out[`${color}_summoner`] = player_context.summonerSpells

     return out
  }

  const fadeClass = fading ? 'fade-exit-active' : 'fade-enter-active'
  const fadeOrClipViewClass = (fading || clipViewMode) ? 'fade-exit-active' : 'fade-enter-active'
  const clipViewFadeClass = clipViewMode ? "fade-exit-active" : "fade-enter-active"

  useEffect(() => {
     if (context) {
          let ds = useDesktopStore.getState()
          let player_prepped = preparePredicitionDataFromContext(context.killer, "blue")
          let enemy_prepped = preparePredicitionDataFromContext(context.victim, "red")
    
          // console.log('respondToLiveData, player_prepped', player_prepped)
          // console.log('respondToLiveData, enemy_prepped', enemy_prepped)
    
          ds.getKillChancePrediction(player_prepped, enemy_prepped)
     }
  }, [context])

  const renderPlayer = () => {
     return (<Box sx={{pointerEvents: "none"}} component="div" className={clipViewMode ? `clipView ${fadeClass}` : fadeClass}><ReactPlayer 
          className={'video-background-react-player'} url={url}
          // stopOnUnmount={true}
          muted={true}
          controls={false}
          playing={true}
          onReady={() => {
               setFading(false)
               if (killChanceTooltipShown == 0) {
                    setKillChanceTooltipShown(killChanceTooltipShown + 1)
               }
          }}
          loop={true}
          playsinline={true}
          playbackRate={clipViewMode ? 1 : 0.6}
          width="100vw"
          height='100%' playIcon={<></>}/></Box>)
  }

  const getOverlayTooltip = (clipViewMode : any) => {
     if (clipViewMode)
          return (<>
               <IconButton onClick={() => {setKillChanceTooltipShown(killChanceTooltipShown + 1)}} sx={{position:"absolute", right:-20, top:-5, height:"20px", width:"20px", color:"secondary.lighter"}}><CloseIcon sx={{width:"20px", height:"20px"}}/></IconButton>
               <Stack direction="column" spacing={1}>
                    <Box sx={{fontSize:"18px"}}><Box component="span" sx={{color:"primary.main"}}>Kill %</Box> shows your live chance to kill the enemy.</Box> 
                    <Box sx={{fontSize:"15px"}}>Uses an AI model trained on millions of solokills, in all different matchups.</Box>
               </Stack>
               </>)

     return <>
          <IconButton onClick={() => {setKillChanceTooltipShown(killChanceTooltipShown + 1); setShowInfoTooltip(false);}} sx={{position:"absolute", right:-20, top:-5, height:"20px", width:"20px", color:"secondary.lighter"}}><CloseIcon sx={{width:"20px", height:"20px"}}/></IconButton>
          <Stack direction="column" spacing={1}>
                    <Box className="generalText">To keep the app <Box component="span" sx={{color:"primary.main"}}>100% safe to use</Box>, the AI only uses data allowed by the League client.</Box>
                    <Box className="generalText" sx={{color:"secondary.lighter"}}><Box component="span" sx={{fontWeight:500, color:"white"}}>The AI uses:</Box> Matchup power spikes, both players' current items, level, and runes.</Box>
                    <Box className="generalText" sx={{color:"secondary.lighter"}}><Box component="span" sx={{fontWeight:500, color:"white"}}>The AI can't use:</Box> Ability cooldowns and current player health. Those two are still on you!</Box>
          </Stack>
     </>
  }


// if (!context) {
//      return <Loading open={true}/>
// }

const cb = <ChampionBrowser source={killer}
               target={randomizeMatchupByKiller}
               label={'Choose a champion'}
               height={!mdOrGreater ? "65vh" : "65vh"}
               width={!mdOrGreater ? "65vw" : undefined}
               bgcolor={"secondary.darker"}
               containerClassName={"popupChampionBrowser"}
               inputBorder={true}
               hideClearButton={true}
               limitedChoice={null}
               champPb={1}
               gridXs={!smOrGreater ? 4 : 3}/>

const playerOverlay = (Boolean(context) && <><Grid item xs={0} lg={1} md={0} display={{ xs: "none", lg: "block" }}></Grid>
                              <Grid item xs={12} lg={3} md={5} className={fadeOrClipViewClass} >
                                   <Stack direction="column" maxWidth={{md:"360px"}}>
                                        <Box className="generalText" sx={{color:"secondary.lighter", fontStyle:"italic"}}>Player's Live Game Data</Box>
                                        <Box ref={context1Ref}><PlayerContext data={context['killer']} forceLarge={true} showRank={false} minWidth={smOrGreater ? "360px" : "180px"} withCooldowns={false}/></Box>
                                   </Stack>
                              </Grid>
                         <Grid item xs={0} md={0} lg={2} display={{ xs: "none", lg: "block" }}></Grid></>)

const enemyOverlay = (Boolean(context) && <><Grid item container={mdOrGreater ? true : undefined} xs={12} md={5} lg={3} justifyContent={{md:'flex-end'}} className={fadeOrClipViewClass}>
                         <Stack direction="column" maxWidth={{md:"360px"}}>
                         <Box className="generalText" sx={{color:"secondary.lighter", fontStyle:"italic"}}>Enemy's Live Game Data</Box>
                              <Box ref={context2Ref}>
                                   <PlayerContext data={context['victim']} reverse={true} forceLarge={true} showRank={false} minWidth={smOrGreater ? "360px" : "180px"} withCooldowns={false}/>
                              </Box>
                         </Stack>
                    </Grid>
                    <Grid item xs={0} md={0} lg={1} display={{ xs: "none", lg: "block" }}></Grid></>)
const inGameOverlay = (Boolean(context) && <>
                         <Grid item xs={12} md={12} alignItems="center" justifyContent={"center"} className={clipViewMode ? "overlayClipView" : fadeOrClipViewClass}>
                              <Stack direction="row" alignItems="center" justifyContent={"center"} spacing={2}>
                                   <Stack  direction="column" alignItems="center" justifyContent={"center"}>
                                        <Box className="generalText" sx={{color:"secondary.lighter", fontStyle:"italic", fontWeight:500}}>In Game Overlay</Box>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                             <Tooltip leaveTouchDelay={0} arrow={true} open={(killChanceTooltipShown == 1 && clipViewMode) || (!clipViewMode && showInfoTooltip)} 
                                             title={getOverlayTooltip(clipViewMode)}>
                                                  <Box className="demoOverlayContainer" component="div" ref={overlayRef} sx={{width:"250px", animation: `${glowAnimation} 2.5s infinite alternate`}}>
                                                       <KillChanceOverlay isReversed={false} width={"100%"} disableMinimapHighlight={true}/>
                                                  </Box>
                                                  
                                             </Tooltip>
                                        </Stack>
                                   </Stack>
                                   {clipViewMode && (<Stack sx={{pt:2}} direction="column-reverse" spacing={2}>
                                        <Tooltip title={`Roll a new matchup for ${killer}`}>
                                             <Box component="div">
                                                  <GeneralButton width={"40px"} height={"40px"} clickCallback={() => randomizeMatchupByKiller(killer)} >
                                                       <AutorenewIcon/>
                                                  </GeneralButton></Box>
                                             </Tooltip>
                                             <Tooltip title={`Choose a new champion`}>
                                                  <div>
                                                  <Box sx={{cursor:"pointer"}} onClick={() => {setKiller(null); setClipViewMode(false)}} className={"championSelectAnchor"}>
                                                       <ChampionPortrait championName={killer} size={"40px"} grey={false}/>
                                                  </Box>
                                                  </div></Tooltip>
                                        </Stack>)}
                                   </Stack>
                         </Grid>
                         {clipViewMode && <Grid item xs={12} md={12} sx={{height:"180px"}}></Grid>}
                         </>)

let component_order = [playerOverlay, enemyOverlay, inGameOverlay]
if (!mdOrGreater) {
     component_order = [playerOverlay, inGameOverlay, enemyOverlay]
}

  return (
     <Box component='div' className='video-background' padding={{xs:2, md:0}}>
          {renderPlayer()}
          <Grid container sx={{height:context ? '100%' : '40%', width:'100%', pl:1, pr:1, pt:4}} className='video-background-content' 
                                                        justifyContent="space-between" rowSpacing={1}>
               {!killer && (<Grid item xs={12} md={12} alignItems="center" className={clipViewFadeClass}>
                    <Stack className="titleText" sx={{fontFamily: "'LeagueFont', sans-serif", fontSize: {sx: "20px", md:"24px"}}}>
                         <Box>Solokill's <Box component="span" sx={{color:"primary.main"}}>AI Kill Chance</Box> Overlay</Box>
                         <Box>Interactive Demo</Box>
                    </Stack>
               </Grid>)}

               {/* Randomize buttons */}
               {!killer && <Grid item xs={12} md={12} alignItems="center" justifyContent="center">
                    <Grid container alignItems="center" justifyContent={"center"}>
                         <Grid item xs={12} md={3}>
                              <Stack direction="column" spacing={3} p={2} alignItems="center" className={clipViewFadeClass}>
                                   <Stack direction="row" spacing={4}>
                                        {!killer && <Tooltip open={false} title={'Choose a champion'} arrow={true} placement="top"
                                             >
                                             <Box className={"championSelectAnchor"}>
                                                  {/* <SingleChampionSelector forcePrimary={true} champion={killer}
                                                        usable={true} championClass={""}
                                                       placement={"bottom-start"}
                                                       showName={false}
                                                       size={"60px"}
                                                       > */}
                                                  {cb}
                                                  {/* </SingleChampionSelector> */}
                                             </Box>
                                        </Tooltip>}
                                        {/* {smallVs('60px', '60px')} */}
                                        {/* <ChampionPortrait championName={victim} size={60} grey={false}/> */}
                                   </Stack>
                                   <Stack sx={{position:"relative", visibility:!killer ? "hidden" : "visible"}} direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        <Tooltip title={"Roll a new enemy"}>
                                             <Box>
                                             <OppositeGeneralButton clickCallback={() => {randomizeMatchupByKiller(killer)}}>
                                                  Re-roll Matchup
                                             </OppositeGeneralButton>
                                             </Box>
                                        </Tooltip>
                                        {fading && <CircularProgress size="20px" sx={{left:-45, position:"absolutex", color:"primary.main", mr:0.25}}/>}
                                   </Stack>
                              </Stack>
                         </Grid>
                    </Grid>
               </Grid>}

          {!context ? <></> : (<>
               {component_order.map((v) => {
                    return v
               })}

                    <Grid item xs={12} className={fadeOrClipViewClass}>
                         {!clipViewMode && <Xarrow
                              start={context1Ref} //can be react ref
                              end={overlayRef} //or an id
                              startAnchor={mdOrGreater ? "right" : "left"}
                              endAnchor={"left"}
                              showHead={false}
                              dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: 2 }}
                              gridBreak="0"
                              path="grid"
                              color={lineColor}
                              _cpx1Offset={-10 * (mdOrGreater ? -1 : 1)}
                              _cpx2Offset={-10 * (mdOrGreater ? -1 : 1)}
                         />}
                    </Grid>
                    <Grid item xs={12} className={fadeOrClipViewClass}>
                         {!clipViewMode && <Xarrow
                              start={context2Ref} //can be react ref
                              end={overlayRef} //or an id
                              startAnchor={mdOrGreater ? "left" : "right"}
                              endAnchor={"right"}
                              showHead={false}
                              dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: 2 }}
                              path="grid"
                              gridBreak="0"
                              color={lineColor}
                              _cpx1Offset={10 * (mdOrGreater ? -1 : 1)}
                              _cpx2Offset={10 * (mdOrGreater ? -1 : 1)}
                         />}
                    </Grid>
               
               <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent={"center"} rowSpacing={4} spacing={4} sx={{pl:4, pr:4}}>
                         <Grid item xs={12} lg={3}>
                                   <Stack direction="column" className={fadeClass}>
                                        {
                                             clipViewMode ? (
                                        <Tooltip onClick={() => {setKillChanceTooltipShown(killChanceTooltipShown + 1);}} open={(killChanceTooltipShown == 2)} title={<Box sx={{fontSize:"15px"}}>Try detailed view to see what <Box component="span" sx={{fontStyle:"italic"}}>live data</Box> the AI uses.</Box>} placement="top" arrow={true}>
                                             <div><GeneralButton clickCallback={() => {
                                                  setClipViewMode(!clipViewMode)
                                                  setKillChanceTooltipShown(killChanceTooltipShown + 1)
                                                  }}>
                                             <Box className="titleText">{"VIEW MORE DETAILS"}</Box>
                                        </GeneralButton></div></Tooltip>) :
                                             (
                                             <div><GeneralButton clickCallback={() => {
                                                  setClipViewMode(!clipViewMode)}}>
                                                  <Box className="titleText">{"BACK TO VIDEO"}</Box>
                                             </GeneralButton></div>
                                             )
                                        }

                                   </Stack>
                         </Grid>
                         <Grid item xs={0} md={0} lg={7} display={{ xs: "none", lg: "block" }}></Grid>
                         <Grid item xs={12} lg={2}>
                              <Stack direction="column">
                                   <GeneralButton clickCallback={() => {
                                             if (!isPhone)
                                                  // location.href = 'https://download.overwolf.com/install/Download?Name=Solokill&ExtensionId=akgjkadnlpbcdnjogeppfadbfpmdcndminencmmp&Channel=web_dl_btn'
                                                  location.href = 'https://www.overwolf.com/app/Solokill'
                                             else
                                                  location.href = 'https://www.overwolf.com/app/Solokill'
                                        }}>
                                        <Box className="titleText">GET SOLOKILL</Box>
                                   </GeneralButton>
                              </Stack>
                         </Grid>
                         {/* <Grid item xs={0} md={0} lg={1} display={{ xs: "none", lg: "block" }}></Grid> */}
                    </Grid>
               </Grid>
               </>)}
               </Grid>
          </Box>)
}

export default KillChanceDemoWindow