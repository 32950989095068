import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton, Tooltip, Stack} from "@mui/material";
import ScaleSlider from "../ScaleSlider";
import {SCALE_SIZES} from "../../utils/consts";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const ResizeButton = ({inGame=false} : {inGame:boolean}) => {
    const [hover, setHover] = useState(false)
    const [maximized, setMaximized] = useState(false)

    return (
        <Tooltip componentsProps={{
           tooltip: {
                sx: {
                  bgcolor: 'secondary.dark',
                },
              },
           arrow: {
                sx: {
                  color: 'secondary.dark',
                },
              },
            }}
    arrow title={<Stack direction={"column"} sx={{height:"200px", width:"100px", pr:1, pl:1}} spacing={2}>
            <Box className={"generalText"} sx={{color:"secondary.lighter", fontSize:"14px", fontWeight:500}}>Window size</Box>
            <Box sx={{pb:2, height:"100%", width:"100%"}}><ScaleSlider marks={SCALE_SIZES} inGame={inGame}/></Box>
        </Stack>} PopperProps={{
            disablePortal:true,
             modifiers: [
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: 'document',
                padding: {left:8, right:40},
              },
            },
          ],
        }}>
        <IconButton sx={{p:0, m:0}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "secondary.main" : "none",
                    height:'30px',
                    width:'30px'}}>
                    <AspectRatioIcon sx={{height:"30px", width:"30px", p:0.5, color:"#C9C9C9"}}/>
                </CardMedia>
        </IconButton>
        </Tooltip>)
}

export default ResizeButton