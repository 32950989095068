import {InputAdornment, styled, TextFieldProps, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
export const SKTextField = styled((props: TextFieldProps) => (<TextField
                               sx={{borderRadius:0, border:"none", bgcolor:'secondary.dark', width:'100%'}}
                               autoComplete='off' fullWidth={true} variant="outlined"
                               size="small"
                               InputProps={{
                                   sx: {
                                       fontSize:"15px",
                                       pl:1,
                                       "input": {
                                           pl:0,
                                           fontSize:"15px",
                                           height:"1em"
                                       },
                                       borderRadius: 0,
                                       border: "none",
                                       borderColor: "secondary.main"
                                   }
                               }}
                            {...props}
                    >
                    </TextField>))(({ theme }) => ({
    'fieldset': {
        border:"none",
        },
    'textarea': {
        color:theme.palette.secondary.lighter
    }
}))


export default SKTextField;