import {logoText, mainLogo, smallVs} from "../icons/side_icons";
import {useDesktopStore, useMainStore} from "../state";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import {Grid, Popover, Stack, SvgIcon, Tooltip } from '@mui/material';
import ChampionPortrait from "./ChampionPortrait";
import useEventListener from "@use-it/event-listener";
import {useEffect, useRef, useState} from "react";
import TipStepper from "./TipStepper";
import WindowControls from "./WindowControls";
import CloseButton from "./ButtonControls/CloseButton";
import {WINDOW_NAMES} from "../utils/consts";
import SingleChampionSelector from "./SingleChampionSelector";
import ChampionBrowser from "./ChampionBrowser";
import PopperUnstyled from '@mui/base/PopperUnstyled';
import WindowSettingsButton from "./ButtonControls/WindowSettingsButton";
import ChangeNotifier from "./ChangeNotifier";
import SKSwitch from "./SKSwitch";
import GeneralButton from "./GeneralButton";
import FeatureHighlightTooltip from "./FeatureHighlightTooltip";

export const KillChanceOverlay = ({isReversed, width="180px", disableMinimapHighlight=false} : {isReversed:boolean, width?:string, disableMinimapHighlight?:boolean}) => {
 const isOW = useMainStore(state => state.isOverwolf)
 const matchupData : any = useDesktopStore(state => state.matchupMetadata)
 const killChance = useDesktopStore(state => state.killChancePrediction)
 const enemyKillChance = killChance ? 1 - killChance : undefined
 const predictionInfo : any = useDesktopStore(state => state.killChancePredictionInfo)
 // const killer = useDesktopStore(state => state.killer)
 const victim = useDesktopStore(state => state.victim)
 // const rotateChampionSelectHotkey = useMainStore(state => state.settings.rotateChampionSelectHotkey)
 const bgcolor = 'rgba(20, 20, 20, 0.6)'
 const bgcolorDarker = 'rgba(33, 33, 33, 0.5)'
 const [tabOpen, setTabOpen] = useState(false)
 const placeholder = <Box sx={{color:"secondary.light"}}>-</Box>
 const [tipIndex, setTipIndex] = useState(0)
 const inGameEnemies = useDesktopStore(state => state.inGameEnemyChampions)
 const setVictim = useDesktopStore(state => state.setVictim)
 const setChampSelectOpen = useDesktopStore(state => state.setChampSelectOpen)
 const tipWidth = 325
 const h = "115px"
 const anchorRef : any = useRef();
 const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
 const [settingsOpen, setSettingsOpen] = useState(false)
 const settings = useMainStore(state => state.settings)
 const changeSetting = useMainStore(state => state.updateSettings)

 // const rotateChampionSelectHotkey = useMainStore(state => state.settings.rotateChampionSelectHotkey)
 const keyDownHandler = (info : any) => {
  info.key == "9" && setTabOpen(true)
 }
 const keyUpHandler = (info : any) => {
  if(info.key == "9") {
   setTabOpen(false)
   console.log('closed', 'tipindex', tipIndex)
   setTipIndex((tipIndex) => tipIndex + 1)
  }
 }

 const testKeyDownHandler = (key : any) => {
   key.key == 'a' && setTipIndex(tipIndex + 1)
  console.log(key.key == 'a')
  console.log(tipIndex)
 }

!isOW && useEventListener('keydown', testKeyDownHandler)

 useEffect(() => {
     isOW && overwolf.games.inputTracking.onKeyDown.addListener(keyDownHandler);
     isOW && overwolf.games.inputTracking.onKeyUp.addListener(keyUpHandler);
     setAnchorEl(anchorRef.current)
     return () => {
      isOW && overwolf.games.inputTracking.onKeyDown.removeListener(keyDownHandler);
      isOW && overwolf.games.inputTracking.onKeyUp.removeListener(keyUpHandler);
     }
   },
  [])

 useEffect(() => {
   setTipIndex(0)
   setChampSelectOpen(false)
 }, [victim])

 const showMain = () => {
  return (<Grid container direction={"row"} sx={{color:"secondary.light", justifyContent:"center"}}>
  <Grid item xs={3}>
   <Stack direction={"column"} sx={{fontSize:"14px"}}>
    <Box sx={{height:"30px"}}>
     <br/>
    </Box>
    <Box>
     Level
    </Box>
    <Box>
     Items
    </Box>
    <Box sx={{color: "primary.main", opacity:0.9, fontWeight:500}}>
     Kill %
    </Box>
   </Stack>
  </Grid>
  <Grid item xs={3.5}>
   <Stack direction={"column"} sx={{fontSize:"14px"}} alignItems={"center"} >
    <Box>
     {/*<ChampionPortrait championName={killer} size={30} grey={false}/>*/}
     <SingleChampionSelector champion={matchupData.killer}
                               usable={false} championClass={'championSelectAnchor'}
                               showName={false} size={"30px"}>
     </SingleChampionSelector>
    </Box>
    <Box sx={{color:predictionInfo?.blue_level > predictionInfo?.red_level ? "secondary.lighter" : "secondary.light"}}>
     <Box>{predictionInfo ? predictionInfo.blue_level : placeholder}</Box>
    </Box>
    <Box sx={{color:predictionInfo?.blue_gold > predictionInfo?.red_gold ? "secondary.lighter" : "secondary.light"}}>
     {predictionInfo ? `${predictionInfo.blue_gold}g` : placeholder}
    </Box>
    <Box>
      {killChance ? <Box sx={{fontWeight:500, fontSize:"14px", color:killChance > 0.6 ? '#01A4FF' : killChance > 0.5 ? 'secondary.lighter' : 'secondary.light', fontStyle:"italic"}}>{Math.round(killChance * 100)}%</Box> : placeholder}
    </Box>
   </Stack>
  </Grid>
  <Grid item xs={1.5}>
   <Stack direction={"column"} sx={{fontSize:"14px", height:"30px"}} justifyContent={"center"}>
    <Box sx={{pt:1}}>{smallVs('20px', '20px')}</Box>
   </Stack>
  </Grid>
  <Grid item xs={3.5}>
   <Stack direction={"column"} sx={{fontSize:"14px"}} alignItems={"center"}>
    <Box>
     <Box>
     {<SingleChampionSelector champion={matchupData.victim}
                               usable={true} championClass={'championSelectAnchor'}
                               showName={false} size={"30px"}>
          <ChampionBrowser source={victim}
                           target={setVictim}
                           label={'Select matchup'}
                           variant={"phone"}
                           height={"35px"}
                           bgcolor={"secondary.darker"}
                           containerClassName={"overlayPopupChampionBrowser"}
                           inputBorder={true}
                           hideClearButton={true}
                           limitedChoice={inGameEnemies}
                           champPb={0}
                           gridXs={12 / 5}/>
      </SingleChampionSelector>}
    </Box>
    </Box>
    <Box sx={{color:predictionInfo?.red_level > predictionInfo?.blue_level ? "secondary.lighter" : "secondary.light"}}>
     <Box>{predictionInfo ? predictionInfo.red_level : placeholder}</Box>
    </Box>
    <Box sx={{color:predictionInfo?.red_gold > predictionInfo?.blue_gold ? "secondary.lighter" : "secondary.light"}}>
     {predictionInfo ? `${predictionInfo.red_gold}g` : placeholder}
    </Box>
    <Box>
       {enemyKillChance ? <Box sx={{fontWeight:500, fontSize:"14px", color:enemyKillChance > 0.6 ? '#FF1F01' : enemyKillChance > 0.5 ? 'secondary.lighter' : 'secondary.light', fontStyle:"italic"}}>{Math.round(enemyKillChance * 100)}%</Box> : placeholder}
    </Box>
   </Stack>
  </Grid>
 </Grid>)
 }

 const showSettings = () => {
    return (<Stack sx={{height:'100%'}}>
      <Stack direction={"row"} alignItems={"center"} >
        <Box className={"generalText"} sx={{color:"secondary.lighter", fontSize:'14px', width:'150px'}}>Show only on Tab</Box>
        <Stack alignItems={"flex-end"}><SKSwitch checked={settings.overlayOnlyOnTab} onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeSetting('overlayOnlyOnTab', event.target.checked)} color="primary" /></Stack>
        <ChangeNotifier trackedParameter={settings.overlayOnlyOnTab}></ChangeNotifier>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Box className={"generalText"} sx={{color:"secondary.lighter", fontSize:'14px', width:'150px'}}>Show jungle timers (minimap)</Box>
        <Stack alignItems={"flex-end"}><SKSwitch checked={settings.showJungleTimers} onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeSetting('showJungleTimers', event.target.checked)} color="primary" /></Stack>
        <ChangeNotifier trackedParameter={settings.showJungleTimers}></ChangeNotifier>
      </Stack>
      <Stack direction='row' justifyContent={'flex-end'} sx={{mt:'1px'}}>
        <GeneralButton clickCallback={() => setSettingsOpen(false)} height='20px'>Done</GeneralButton>
      </Stack>
    </Stack>)
 }
 return (
     <>
  <Stack className="killChanceOverlayContainer" ref={anchorRef} sx={{display:settings.overlayOnlyOnTab && !tabOpen && !settingsOpen ? 'none' : 'initial', height:h, position:"relative", border:"2px solid", borderColor:"secondary.dark", borderRadius:0.5}}>
   <Stack direction={"column"} sx={{backgroundColor:bgcolor, width:width}} spacing={0.25}>  
    <Stack sx={{backgroundColor:bgcolorDarker}} direction={"row"} spacing={0.75} alignItems={"center"} justifyContent={"space-between"} >
     <Stack direction={"row"} alignItems={"center"} sx={{height:"20px"}}>
      <SvgIcon viewBox='0 0 60 60' sx={{height:"20px", width:"20px"}}>{mainLogo}</SvgIcon>
      <SvgIcon  viewBox='0 0 134 24' sx={{height:"12px", width:"70px", opacity:0.8}}>{logoText}</SvgIcon>
     </Stack>
     <FeatureHighlightTooltip 
              tooltipText="Minimap now shows Jungle Timers! Click wheel above to change settings." 
              featureName='jungleTimers'
              disabled={disableMinimapHighlight}>
        <Stack direction={'row'}>
          <WindowSettingsButton size={22} clickFunction={() => {setSettingsOpen(!settingsOpen)}}/>
          <CloseButton size={22} closeFunction={() => overwolf.windows.close(WINDOW_NAMES.IN_GAME_OVERLAY)}/>
        </Stack>
     </FeatureHighlightTooltip>
    </Stack>
    {!settingsOpen ? showMain() : showSettings()}
   </Stack>
  </Stack>
   <PopperUnstyled
    open={tabOpen}
    anchorEl={anchorEl}
    keepMounted={false}
    placement={isReversed ? "top" : "bottom"}
    popperOptions={{  modifiers: [
    {
      name: 'flip',
      options: {
        flipVariations: false,
      },
    },
  ]}}
  >
    <Stack direction={"column"} sx={{pt:0.5, pb:0.5}}>
        <Box sx={{backgroundColor:bgcolor, width:tipWidth, height:h, border:"2px solid", borderColor:"secondary.dark", borderRadius:0.5}}>
         {matchupData.ai_tip && <TipStepper matchupData={matchupData} height={"100px"} maxWidth={tipWidth} stepper={false}
                                           title={true} randomize={true} forcedIndex={tipIndex} inGame={true}/>}
        </Box>
     </Stack>
  </PopperUnstyled>

  </>
)
}

export default KillChanceOverlay