// same name in the public/app/manifest.json  "windows"
export const WINDOW_NAMES = {
  BACKGROUND: 'background',
  SETTINGS: 'settings',
  DEVELOPMENT: 'development',
  CHAMP_SELECT: 'champ_select',
  INGAME: 'in_game',
  DESKTOP: 'desktop',
  SECOND_SCREEN: 'second_screen',
  LOADING: 'loading',
  STREAM: 'stream',
  IN_GAME_OVERLAY: 'in_game_overlay',
  IN_GAME_OVERLAY_MINIMAP: 'in_game_overlay_minimap',
  SHARED_HIGHLIGHT: 'shared_highlight',
  KILL_CHANCE_DEMO: 'kill_chance_demo'
}

export const PAGE_NAMES = {
  MOST_POPULAR: 'popular',
  MATCHUP: 'matchup',
  SAVED: 'saved',
  SETTINGS: 'settings',
  PRIVACY: 'privacy',
  MATCH_HISTORY: 'match_history',
  MULTIKILL: 'multi_kill',
  OTPS: 'otps',
  MONTAGE: 'montage'
}

export const SIDE_VIEWS = {
  PLAYLIST: 'playlist',
  KILL_CHANCE: 'kill_chance',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  MINI_GUIDE: 'mini_guide',
  IN_GAME_MINI_GUIDE: 'in_game_mini_guide',
  MATCH_HISTORY: 'match_history',
  OTPS:'otps',
  MONTAGE:'montage'
}

export const selectorWidth = '64px';
export const removeAdsHeight = '44px';
export const adHeight = '300px';

export const LAUNCHER_REQUIRED_FEATURES = [
      "summoner_info",
      "champ_select",
      "game_flow",
      "lobby_info"
];

export const INGAME_REQUIRED_FEATURES = [
      "level",
      "live_client_data",
      "jungle_camps",
      'events',
      'augments'
];

export const OVERWOLF_HOOKS_OPTIONS = {
  displayLog: process.env.NODE_ENV === 'production',
}

export const VERSION = '3.2.0'

export const TOOLTIP_STYLE = {
  sx: {
    bgcolor: 'secondary.main',
    borderRadius: 0,
      '& .MuiTooltip-arrow': {
      color: 'secondary.main',
    },
  },
}


export const PICK_TYPE = {
  DRAFT: 'draft',
  BLIND: 'blind',
  ARAM: 'aram',
  ARENA: 'arena',
  UNSUPPORTED: 'unsupported',
}

export const HOTKEY_KEYS =  ["inGameHideShowKey", "secondScreenSwitchKey", "rotateChampionSelectHotkey"]
export const LANE_RENAMES: any = {'UTILITY': 'Support', 'MIDDLE':'Mid', 'TOP':'Top', 'JUNGLE':'Jungle', 'BOTTOM':'Bottom'}

export const SCALE_SIZES = [
  {
    value: 0.8,
    label: 'smallest'
  },
  {
    value: 0.9,
    label: 'small'
  },
  {
    value: 1,
    label: 'normal'
  },
  {
    value: 1.1,
    label: 'large'
  },
  {
    value: 1.2,
    label: 'largest'
  }
]

export const CLOUDFRONT_URL = "https://d27hxndnm3otog.cloudfront.net"