import Item from "./Item";
import {Box, Stack} from "@mui/material";
import {secondsToTime} from "../utils/general";
import * as React from "react";

const ItemSet = ({items, itemSize="45px", tooltipPosition="left", reverse=false} : {items:any[], itemSize?:string, tooltipPosition?:any, reverse?:boolean}) => {
    return (<Stack direction={reverse ? "row-reverse" : "row"} spacing={0.5}>
        {items.map((iData : any) => {
            return <Item itemData={iData} size={itemSize} tooltipPosition={tooltipPosition}/>
        })}
    </Stack>)
}

export default ItemSet