import {styled, Switch, SwitchProps} from "@mui/material";

export const SKSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 22,
  padding: 3,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 6,
    color:'#77777A',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#FFE901',
      '& + .MuiSwitch-track': {
        backgroundColor:'black',
        opacity: 1,
        border: '1px solid grey'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,

    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 10,
    height: 10,
    borderRadius:0,
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'black',
    opacity: 1,
    border:'1px solid grey',
    borderRadius:0,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default SKSwitch;