import domtoimage from "dom-to-image";
import {saveAs} from "file-saver";

window.export_context = () => {
    export_single(false)
    export_single(true)
}

const export_single = (reverse) => {
    let domNode = document.getElementById(reverse ? "enemyContext" : "playerContext")
    let scale = 4;
    domtoimage.toPng(domNode, {
        width: domNode.clientWidth * scale,
         height: domNode.clientHeight * scale,
         style: {
          transform: 'scale('+scale+')',
          transformOrigin: 'top left'
    }}).then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            img.id = `context_${reverse ? "enemy" : "player"}.png`
            document.body.appendChild(img);
    });
}
