import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton} from "@mui/material";

const WindowSettingsButton = ({clickFunction, size=30} : {clickFunction:Function, size?:number}) => {
    const [hover, setHover] = useState(false)

    return (
        <IconButton sx={{p:0, m:0}}
                    onClick={() => clickFunction()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "primary.main" : "none",
                    height:size,
                    width:size}}>
                    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.3333 16.6833V13.65H20.7667C20.65 13.1833 20.4167 12.7167 20.1833 12.25L22.05 10.3833L19.8333 8.28333L17.9667 10.15C17.5 9.91667 17.0333 9.68333 16.5667 9.56667V7H13.5333V9.56667C13.0667 9.68333 12.6 9.91667 12.1333 10.15L10.5 8.28333L8.28333 10.5L10.15 12.3667C9.91667 12.8333 9.68333 13.3 9.56667 13.7667H7V16.8H9.56667C9.68333 17.2667 9.91667 17.7333 10.15 18.2L8.28333 19.8333L10.3833 21.9333L12.25 20.0667C12.7167 20.3 13.1833 20.5333 13.65 20.65V23.3333H16.6833V20.7667C17.15 20.65 17.6167 20.4167 18.0833 20.1833L19.95 22.05L22.05 19.95L20.1833 18.0833C20.4167 17.6167 20.65 17.15 20.7667 16.6833H23.3333ZM15.1667 18.4333C13.4167 18.4333 11.9 17.0333 11.9 15.1667C11.9 13.4167 13.3 11.9 15.1667 11.9C17.0333 11.9 18.4333 13.3 18.4333 15.1667C18.4333 16.9167 16.9167 18.4333 15.1667 18.4333Z"
                         fill={hover ? "black" : "#C9C9C9"}/>
                    </svg>
                    
                </CardMedia>
        </IconButton>)
}

export default WindowSettingsButton