import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {Badge, CardMedia, IconButton, SvgIcon, Tooltip} from "@mui/material";
import {WINDOW_NAMES} from "../../utils/consts";
const twitchIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M2.149 0l-1.612 4.119v16.836h5.731v3.045h3.224l3.045-3.045h4.657l6.269-6.269v-14.686h-21.314zm19.164 13.612l-3.582 3.582h-5.731l-3.045 3.045v-3.045h-4.836v-15.045h17.194v11.463zm-3.582-7.343v6.262h-2.149v-6.262h2.149zm-5.731 0v6.262h-2.149v-6.262h2.149z" fill-rule="evenodd" clip-rule="evenodd"/>
                    </svg>

const TwitchButton = () => {
    const [hover, setHover] = useState(false)

    return (
        <IconButton sx={{p:0, m:0, color:"secondary.lighter"}} href={"https://www.twitch.tv/solokill_gg"} target={"_blank"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
        <Badge overlap="circular" badgeContent={""} color="error" sx={{
                                "& .MuiBadge-badge": {
                                   lineHeight: "0.1em",
                                   minWidth: "8px",
                                   fontSize: "8px",
                                   right:0,
                                    top:6,
                                   height: "8px",
                                   fontWeight: 700,
                                   padding: 0
                                }}}>
                <CardMedia sx={{
                    height:'30px',
                    width:'30px'}}>
        <Tooltip title={<Box sx={{fontSize:"12px"}}>New! 24/7 stream on Twitch - plays and chill LoFi</Box>}><SvgIcon sx={{height:"20px", width:"20px"}}>{twitchIcon}</SvgIcon></Tooltip>
                </CardMedia>
    </Badge>
        </IconButton>)
}

export default TwitchButton