import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {Box, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import Spell from "./Spell";
import reactStringReplace from 'react-string-replace';

export const TipStepper = ({matchupData, height="60px", maxWidth=400, title=false, stepper=true, randomize=false, forcedIndex=undefined, inGame=false} : {matchupData:any, height?:string, maxWidth?:number, title?:boolean, stepper?:boolean, randomize?:boolean, forcedIndex?:number, inGame?:boolean}) => {
  const theme = useTheme();
  const [tips, setTips] : any = useState(undefined)
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    console.log('TipStepper', matchupData.ai_tip)
    setTips(matchupData.ai_tip.map((tip : any) => (<Stack alignItems={"center"} justifyContent={"center"}>{generateTip(tip)}</Stack>)))
    setActiveStep(forcedIndex ? forcedIndex % matchupData.ai_tip.length : 0)
  }, [matchupData])

  useEffect(() => {
      setActiveStep(forcedIndex ? forcedIndex % matchupData.ai_tip.length : 0)
  }, [forcedIndex])

    const generateTip = (tip : any) => {
        ['P', 'Q', 'W', 'E', 'R'].map((ab) => {
            tip = reactStringReplace(tip, `${matchupData.victim}-${ab}`, (match, i) => (
                <Box sx={{pr:"4px"}}><Spell champion={matchupData.victim} abilityKey={ab.toLowerCase()} size={window.innerHeight > 850 ? "24px" : "16px"} showKey={true} videoSizeFactor={inGame ? 0.6 : 1} showTooltipText={!inGame}/></Box>
            ))
        })

        return <Box className={"spellTipInline generalText"} sx={{color:"secondary.light", maxWidth:maxWidth}}>{tip}</Box>
   }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % tips.length)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep == 0 ? tips.length - 1 : prevActiveStep - 1);
  };

  return (
    !tips ? <></> : <>
    {title && <Box sx={{fontWeight:500, backgroundColor: 'rgba(33, 33, 33, 0.4)', height:"20px", fontSize:"15px", color:"secondary.lighter"}}>Matchup tip vs {matchupData.victim} ({activeStep + 1}/{matchupData.ai_tip.length})</Box>}
    <Stack sx={{height:height}} justifyContent={"center"}>{tips[activeStep]}</Stack>
    {stepper && <MobileStepper
      variant="dots"
      steps={tips.length}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: maxWidth, flexGrow: 1, bgcolor:"transparent" }}
      nextButton={
        <Button size="small" onClick={handleNext}>

          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}

        </Button>
      }
    />}
  </>
  );
}

export default TipStepper