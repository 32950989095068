import {Box, CardMedia, Popover, Stack, Tooltip} from "@mui/material";
import * as React from "react";
import {useMainStore} from "../state";
import {TOOLTIP_STYLE} from "../utils/consts";
import parse from 'html-react-parser'

const Spell = ({champion, abilityKey, size, showKey, tooltipPosition="top", customLetter, backgroundStyle="light", cooldown=false, showVideo=true, preventOverflow=false, videoSizeFactor=1, showTooltipText=true} : {champion:string|null, abilityKey:string, size:string, showKey:boolean, tooltipPosition?:any, customLetter?:string, backgroundStyle?:string, cooldown?:boolean, showVideo?:boolean, preventOverflow?:boolean, videoSizeFactor?:number, showTooltipText?:boolean}) => {
    const champion2key : any = useMainStore(state => state.champion2key)
    const championId = champion ? champion2key[champion]?.toString().padStart(4, '0') : ""
    const allAbilityDescriptions : any= useMainStore(state => state.abilityDescriptions)
    const basicAbility = abilityKey.length <= 2
    const championAbilities : any = useMainStore(state => {
        let ab : any = state.abilityDescriptions
        if (champion == null) {
            return {"":""}
        }
        return ab[champion]
    })
    const currentAbility = abilityKey.length <= 2 ? championAbilities[abilityKey[0].toLowerCase()] : allAbilityDescriptions[abilityKey]
    const path = abilityKey == 'none' ? "" : abilityKey.length <= 2 ? `https://cdn.communitydragon.org/latest/champion/${champion}/ability-icon/${abilityKey[0]}` : `/assets/summoner-spells/Summoner${cooldown ? abilityKey + "_cooldown" : abilityKey}.png`
    const abilityTooltip = (<Stack direction="column" spacing={1} sx={{textAlign:"left", zIndex:1, width:basicAbility ? 363 * videoSizeFactor : "unset", minHeight:basicAbility ? 246.84 * videoSizeFactor : "0px", p:0}}>
        {showVideo && abilityKey.length <= 2 && <Box sx={{position:"absolute", zIndex:0, top:0, left:0, p:0}}><video loop={true} src={`https://d28xe8vt774jo5.cloudfront.net/champion-abilities/${championId}/ability_${championId}_${abilityKey[0].toUpperCase()}1.webm`} height={246.84 * videoSizeFactor} width={363 * videoSizeFactor} autoPlay={true} muted={true}></video></Box>}
        <Box className={"titleText"} sx={{color:"secondary.lighter", fontSize:'15px', zIndex:1, pb:0, mt:0, pl:basicAbility ? 0.5 : 0}}>{currentAbility?.name} {abilityKey.length <= 2 && (`(${abilityKey == "p" ? "Passive" : abilityKey.toUpperCase()})`)} {cooldown && "(on cooldown)"}</Box>
        <Box sx={{width:363 * videoSizeFactor, position:"absolute", bottom:0, zIndex:1, bgcolor:basicAbility ? "secondary.dark" : "secondary.main", opacity:basicAbility ? 0.65 : 1, pl:basicAbility ? 0.5 : 0}}>
            <Box sx={{display:showTooltipText ? "unset" : "none", color:"secondary.lighter", fontSize:'12px', maxHeight:"100px", overflow:"auto"}}>{currentAbility && parse(currentAbility?.description)}</Box>
            {currentAbility?.cooldown && <Stack sx={{fontSize:'12px'}} direction={"row"} spacing={1}><Box>Cooldown: </Box><Box>{currentAbility?.cooldown}</Box></Stack>}
        </Box>
    </Stack>)
    const abilityContent = (<CardMedia image={path}
                                      sx={{bgcolor:basicAbility ? "secondary.dark" : "secondary.main", height:size, width:size, position:'relative', borderRadius:'1px'}}
                            >
                            {showKey ? <Box sx={{backgroundColor:backgroundStyle == "light" ? '#c9c9c9ab' : "black",
                                opacity: backgroundStyle == "light" ? 1 : 0.7,
                                 position:'absolute',fontWeight:700, color:backgroundStyle == "light" ? "black" : "white",
                                'bottom':0, height:'15px', width:'15px', textAlign:'center',
                                'fontSize':'12px'}}>{champion != null ? customLetter ? customLetter : abilityKey[0].toUpperCase() : ""}</Box> : <></>
                                }
                                </CardMedia>)
    return (champion != null) ? <Tooltip
        sx={{width:"363px"}}

        PopperProps={!preventOverflow ? undefined : {
            disablePortal:true,
             modifiers: [
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: 'document',
                padding: {left:8, right:40},
              },
            },
          ],
        }}
        placement={tooltipPosition} arrow={true} componentsProps={{
                    tooltip: basicAbility ? {
                                          sx: {
                                            p:0,
                                            maxWidth:330 * videoSizeFactor,
                                            bgcolor: 'secondary.darker',
                                            borderRadius: 0,
                                              '& .MuiTooltip-arrow': {
                                              color: 'secondary.darker',
                                            },
                                          },
                                        } : TOOLTIP_STYLE
        }} title={abilityTooltip}>{abilityContent}</Tooltip> : abilityContent
    // return <>{abilityKey}</>
}

export default Spell