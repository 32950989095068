import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton} from "@mui/material";
import {DISCORD_INVITE} from "../../utils/general";

const DiscordButton = () => {
    const [hover, setHover] = useState(false)

    return (
        <IconButton target="_blank" href={DISCORD_INVITE} sx={{p:0, m:0}} onClick={() => true}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                    <CardMedia sx={{
                        bgcolor:hover ? "secondary.main" : "none",
                        height:'30px',
                        width:'30px'}}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill={"#C9C9C9"} fill-rule="evenodd" clip-rule="evenodd"
                                  d="M18.2776 19.7359C18.7404 20.3133 19.2956 20.9844 19.2956 20.9844C22.5251 20.8809 23.8579 18.8584 23.9891 18.6592C23.9964 18.6481 24 18.6427 24 18.6435C24 13.6651 21.7789 9.62316 21.7789 9.62316C19.5733 7.95331 17.4602 8.00013 17.4602 8.00013L17.2442 8.24983C19.8663 9.04574 21.0848 10.2162 21.0848 10.2162C19.4807 9.34226 17.9075 8.90529 16.4422 8.73362C15.3316 8.60877 14.2674 8.63998 13.3265 8.76483C13.2457 8.76483 13.1767 8.77672 13.0989 8.79013C13.0875 8.79208 13.076 8.79406 13.0643 8.79604C12.5244 8.85847 11.2134 9.04574 9.56298 9.77923C8.99229 10.0289 8.65296 10.2162 8.65296 10.2162C8.65296 10.2162 9.91774 8.98332 12.6941 8.18741L12.5398 8.00013C12.5398 8.00013 10.4422 7.95331 8.22108 9.62316C8.22108 9.62316 6 13.6651 6 18.6435C6 18.6435 7.29563 20.8908 10.7044 21C10.7044 21 11.2751 20.3133 11.7378 19.7203C9.77892 19.1273 9.03856 17.8944 9.03856 17.8944C9.03856 17.8944 9.1928 18.0036 9.47044 18.1597C9.47997 18.1597 9.4895 18.1657 9.50268 18.1739C9.51082 18.179 9.52035 18.1849 9.53213 18.1909C9.55527 18.2065 9.57841 18.2182 9.60154 18.2299C9.62468 18.2416 9.64782 18.2533 9.67095 18.2689C10.0566 18.4874 10.4422 18.6591 10.7969 18.7995C11.4293 19.0648 12.1851 19.2989 13.0643 19.4706C14.2211 19.6891 15.5784 19.7671 17.0591 19.4862C17.7841 19.3458 18.5244 19.1429 19.2956 18.8151C19.8355 18.6123 20.437 18.3158 21.0694 17.8944C21.0694 17.8944 20.2982 19.1585 18.2776 19.7359ZM16.1806 15.1878C16.1806 14.2724 16.8747 13.5371 17.7539 13.5371C18.6176 13.5371 19.3271 14.2724 19.3271 15.1878C19.3271 16.1031 18.633 16.8384 17.7539 16.8384C16.8901 16.8384 16.1806 16.1031 16.1806 15.1878ZM10.5508 15.1878C10.5508 14.2724 11.2449 13.5371 12.124 13.5371C13.0032 13.5371 13.7127 14.2724 13.6973 15.1878C13.6973 16.1031 13.0032 16.8384 12.124 16.8384C11.2603 16.8384 10.5508 16.1031 10.5508 15.1878Z"/>
                        </svg>
                    </CardMedia>
        </IconButton>)
}

export default DiscordButton