import {lazy, useEffect} from 'react'
import { WINDOW_NAMES } from '../utils/consts'
import FTUEWindow from "./FTUEWindow";
import {useMainStore} from "../state";
import AdManager from '../components/AdManager';
import LargeLoading from "../components/LargeLoading";
import {resizeWindow} from "../utils/ow_utils";
import SharedHighlightWindow from './SharedHighlightWindow';
import KillChanceDemoWindow from './KillChanceDemoWindow';

//window name in manifest file

//lazy load window components, so that they are not loaded until they are needed
//this is done to reduce the amount of time spent loading
const BackgroundWindow = lazy(() => import('./BackgroundWindow'))
const DesktopWindow = lazy(() => import('./DesktopWindow'))
const StreamWindow = lazy(() => import('./StreamWindow'))
const ChampSelectWindow = lazy(() => import('./ChampSelectWindow'))
const InGameOverlayWindow = lazy(() => import('./InGameOverlayWindow'))
const InGameOverlayMinimapWindow = lazy(() => import('./InGameMinimapOverlayWindow'))
const InGameWindow = lazy(() => import('./InGameWindow'))

interface IWindowPageProps {
  window: string
}
//return the current page based on the window name, the current window name is passed in as a prop
//this is used to determine which page to render
export const CurrentWindow = ({ window }: IWindowPageProps) => {
  const firstTime = useMainStore(state => state.settings.firstTime)
  const isOW = useMainStore(state => state.isOverwolf)

  useEffect(() => {
    window != WINDOW_NAMES.BACKGROUND && window != WINDOW_NAMES.LOADING && resizeWindow(window)
  },
  [window])

  switch (window) {
    case WINDOW_NAMES.BACKGROUND:
      return <BackgroundWindow />
    case WINDOW_NAMES.DESKTOP:
      if (firstTime && isOW)
          return <FTUEWindow/>
           
      return <>
        <AdManager windowName={window}/>
        <DesktopWindow firstTime={firstTime}/>
      </>
    case WINDOW_NAMES.CHAMP_SELECT:
      return <>
        <AdManager windowName={window}/>
        <ChampSelectWindow/>
      </>
    case WINDOW_NAMES.INGAME:
      return <>
        <AdManager windowName={window}/>
        <InGameWindow/>
      </>
    case WINDOW_NAMES.SECOND_SCREEN:
      return <>
        <AdManager windowName={window}/>
        <InGameWindow secondScreen={true}/>
      </>
    case WINDOW_NAMES.STREAM:
      return <>
        <StreamWindow/>
      </>
    case WINDOW_NAMES.LOADING:
      return <LargeLoading/>
    case WINDOW_NAMES.IN_GAME_OVERLAY:
      return <InGameOverlayWindow/>
    case WINDOW_NAMES.IN_GAME_OVERLAY_MINIMAP:
        return <InGameOverlayMinimapWindow/>
    case WINDOW_NAMES.SHARED_HIGHLIGHT:
        return <SharedHighlightWindow/>
    case WINDOW_NAMES.KILL_CHANCE_DEMO:
      return <KillChanceDemoWindow/>
    default:
      return <p>Loading ...</p>
  }
}
