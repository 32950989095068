import Rune from "./Rune";
import Box from "@mui/material/Box";
import RuneTree from "./RuneTree";
import {Stack} from "@mui/material";
import {tree2color} from "../utils/general";

const PrimaryTree = ({runes, bigRuneSize="50px", smallRuneSize="33px", variant="standard", showName=true, reverse=false, denseSpacing=0.2, showTreeIcon=false} : {runes:any[], bigRuneSize?:string, smallRuneSize?:string, variant?:string, showName?:boolean, reverse?:boolean, denseSpacing?:number, showTreeIcon?:boolean}) => {
    const tree_name = (<Box sx={{color:tree2color[runes[0][0].toLowerCase()],
                  fontSize:"15px",
                  fontWeight:500,
                  width:variant=="dense" ? '90px' : "unset",
                  fontStyle:"italic"}}>{runes[0][0] == "" ? "Primary" : runes[0][0]}</Box>)

    return (<Stack direction={"column"} justifyContent={"center"} spacing={0.5} className={variant == "dense" ? "runePrimaryTreeDense" : "runePrimaryTree"}>
        {/*<Box sx={{position:"absolute", top:"50%", height:"8px", width:"253px", left:`calc(${BIG_RUNE_SIZE} + 5px)`, }}></Box>*/}
        {variant == "standard" && showName && tree_name}
        <Stack direction={reverse ? "row-reverse" : "row"} alignItems={"center"}>
            {variant == "dense" && showName && tree_name}
            {showTreeIcon && <Box sx={{mr:reverse ? 0 : variant == "dense" ? 2 : 8, ml:reverse ? variant == "dense" ? 2 : 8 : 0}}>
                <RuneTree firstRune={runes[0]} size={bigRuneSize}/>
            </Box>}
            <Box sx={{mr:reverse ? 0 : variant == "dense" ? 1 : 4, ml:reverse ? variant == "dense" ? 1 : 4 : 0}}><Rune runeId={runes[0][6]} size={bigRuneSize}/></Box>
            <Stack direction={reverse ? "row-reverse" : "row"} spacing={variant == "dense" ? denseSpacing : 2}>
                {runes.slice(1).map((rune : string[], idx) =>
                    <Box ><Rune runeId={rune[6]} size={smallRuneSize}/></Box>
                )}
            </Stack>

        </Stack>
    </Stack>)
}

export default PrimaryTree