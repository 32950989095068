import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton} from "@mui/material";

const CloseButton = ({closeFunction, size=30} : {closeFunction:Function, size?:number}) => {
    const [hover, setHover] = useState(false)

    return (
        <IconButton sx={{p:0, m:0}} 
                    onClick={() => overwolf.windows.getCurrentWindow((window) => closeFunction(window.window.name))
        }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "primary.main" : "none",
                    height:size,
                    width:size}}>
                    <svg width={size} height={size} viewBox={`0 0 30 30`} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={hover ? "black" : "#C9C9C9"} d="M15.5917 14.775L20.375 9.99167C20.6083 9.75833 20.6083 9.40833 20.375 9.175C20.1416 8.94167 19.7916 8.94167 19.5583 9.175L14.775 13.9583L9.99166 9.175C9.75833 8.94167 9.40833 8.94167 9.175 9.175C8.94167 9.40833 8.94167 9.75833 9.175 9.99167L13.9583 14.775L9.175 19.5583C8.94167 19.7917 8.94167 20.1417 9.175 20.375C9.29167 20.4917 9.40833 20.4917 9.64167 20.4917C9.875 20.4917 9.99166 20.4917 10.1083 20.375L14.8917 15.5917L19.675 20.375C19.7916 20.4917 19.9083 20.4917 20.1416 20.4917C20.375 20.4917 20.4916 20.4917 20.6083 20.375C20.8416 20.1417 20.8416 19.7917 20.6083 19.5583L15.5917 14.775Z" />
                    </svg>
                </CardMedia>
        </IconButton>)
}

export default CloseButton