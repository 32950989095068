import {Backdrop, CircularProgress, Stack, SvgIcon} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";

const LargeLoading = ({fullscreen=true, zIndex=0} : {fullscreen?:boolean, zIndex?:number}) => {
    return (<Backdrop
                      sx={{zIndex:zIndex, height:"100vh", width:"100vw", color: 'primary.main', bgcolor:'black', position:'absolute' }}
                          open={true}
                        >
        <Stack direction={"column"} alignItems={"center"}>
                      <SvgIcon sx={{height:"125px", width:"125px", animation:"fadeInOut 3s infinite"}} >
                          <svg viewBox="0 0 89 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M65.6178 10.5697C58.6435 4.04596 49.0844 0 38.5774 0C17.3551 0 0 16.5062 0 36.5966C0 47.3184 4.76232 57.0048 12.3482 63.7985L22.4416 53.7128C21.5536 48.9655 21.128 43.2638 21.128 36.5966C21.128 12.5447 26.8815 2.16939 38.5774 2.16939C47.6419 2.16939 52.885 8.45116 54.9697 21.2097L65.6178 10.5697Z" fill="#77777A"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3868 64.2662C29.3611 70.79 38.9202 74.8359 49.4273 74.8359C70.6495 74.8359 88.0046 58.3297 88.0046 38.2393C88.0046 27.5176 83.2423 17.8311 75.6565 11.0374L65.563 21.1231C66.451 25.8703 66.8767 31.5722 66.8767 38.2393C66.8767 62.2912 61.1231 72.6665 49.4273 72.6665C40.3627 72.6665 35.1196 66.3848 33.0349 53.6262L22.3868 64.2662Z" fill="#77777A"/>
                            <rect width="17.625" height="17.6253" rx="8.81249" transform="matrix(0.698507 0.715603 -0.716138 0.697958 42.8491 23.2812)" fill="#77777A"/>
                            </svg>
                      </SvgIcon>
                      <Box>LOADING</Box>
        </Stack>
                    </Backdrop>)
}

export default LargeLoading;