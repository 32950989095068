
import { useEffect } from "react";
import { useDesktopStore } from "../state";
import MontageVideoViewer from "../components/MontageVideoViewer";
import Loading from "../components/Loading";
import { Stack } from "@mui/material";
// import {swapTransition} from "../utils/animations"

//avoid the use of static text, use i18n instead, each language has its own text, and the text is stored in the
//locales folder in the project root
const SharedHighlightWindow = () => {
    const getHighlight = useDesktopStore(store => store.getMagicHighlight)
    const magicHighlights = useDesktopStore(store => store.magicHighlights)
    const magicHighlightsPlayer = useDesktopStore(store => store.magicHighlightsPlayer)
    console.log('magicHighlights', magicHighlights)
    useEffect(() => {
        let url = window.location.href.replace(/\/+$/, '');
        const urlParts = url.split('/');
        const uuid = urlParts[urlParts.length - 1];
        getHighlight(uuid)
    }, [])

  return (<>
       {!magicHighlights.length ? <Loading open={true}/> : 
       <Stack sx={{pr:3, pl:3, pt:1, pb:2}}>
            <MontageVideoViewer shareMode={true}/>
       </Stack>}
     </>)
}

export default SharedHighlightWindow