import {Box, CardMedia, Stack, Tooltip} from "@mui/material";
import {tree2color} from "../utils/general";
import * as React from "react";
import {useMainStore} from "../state";

const StatRune = ({runeId, size, tooltipPosition="top"}:{runeId:string, size:string, tooltipPosition?:any}) => {
    const base_url = '/assets/runes/perk-images/StatMods'
    //@ts-ignore
    const rune = useMainStore.getState().metadata.stat_runes[runeId]
    const runeKey = rune ? rune[0] : ""
    const runeDesc = rune ? rune[1] : ""
    const statTooltip = (<Stack direction="column" spacing={1}>
                            <Box className={"titleText"} sx={{color:"secondary.lighter", fontSize:'12px'}}>
                                {runeDesc}
                            </Box>
                        </Stack>
                    )
    return <Tooltip placement={tooltipPosition} sx={{bgcolor:"secondary.main"}} title={statTooltip}>
            <CardMedia image={runeKey == undefined ? "" : `${base_url}/StatMods${runeKey}Icon.png`}
                                  sx={{bgcolor:'secondary.dark', width:size, height:size, border:`1px solid`, borderColor:"secondary.main",
                                      borderRadius:"50%"}} />
            </Tooltip>

}

export default StatRune