export const allowed_matchups = [["Singed","Jax"],
["Twitch","Karthus"],
["Urgot","Warwick"],
["Akali","Ashe"],
["Camille","Graves"],
["Yone","MonkeyKing"],
["Taliyah","Neeko"],
["Jax","Ekko"],
["Nocturne","Xerath"],
["Renekton","Irelia"],
["Mordekaiser","Aatrox"],
["Gnar","Poppy"],
["Gangplank","Gnar"],
["Nocturne","Graves"],
["Jax","Malphite"],
["Sett","Shaco"],
["Olaf","Karthus"],
["Gnar","Olaf"],
["Ezreal","Rakan"],
["Qiyana","Vex"],
["Garen","Illaoi"],
["Kaisa","Anivia"],
["Ahri","Zed"],
["Yasuo","Garen"],
["Vayne","Jinx"],
["Nunu","Kaisa"],
["Shen","Camille"],
["Graves","Jinx"],
["Akali","Rumble"],
["Vladimir","Samira"],
["Viktor","Jayce"],
["Illaoi","Xerath"],
["Sylas","Khazix"],
["Rumble","KSante"],
["Aphelios","LeeSin"],
["Rammus","Yone"],
["Riven","Soraka"],
["Volibear","Yasuo"],
["Heimerdinger","Zed"],
["Rumble","Nasus"],
["Vayne","Ahri"],
["Brand","Ahri"],
["Zed","Karthus"],
["Sylas","Fiddlesticks"],
["Aatrox","Ahri"],
["Teemo","TahmKench"],
["Tryndamere","Volibear"],
["Rumble","Diana"],
["Draven","Belveth"],
["Vayne","Singed"],
["Trundle","Illaoi"],
["Sett","Camille"],
["Evelynn","Lucian"],
["Gnar","Sett"],
["MonkeyKing","Yorick"],
["Illaoi","Zac"],
["Rengar","Jax"],
["Twitch","Kalista"],
["Gnar","Garen"],
["Urgot","Vladimir"],
["Draven","Brand"],
["Ahri","TwistedFate"],
["Olaf","Kayle"],
["DrMundo","Fiora"],
["Illaoi","Diana"],
["Akshan","Talon"],
["KSante","Yasuo"],
["Pantheon","Kayle"],
["Teemo","Yone"],
["Olaf","Gwen"],
["Neeko","Talon"],
["Qiyana","Heimerdinger"],
["Anivia","Syndra"],
["Kindred","Evelynn"],
["Evelynn","Kindred"],
["Warwick","Urgot"],
["Darius","Belveth"],
["Renekton","Sylas"],
["Zoe","Lissandra"],
["Shaco","Aphelios"],
["Zed","Fizz"],
["Aphelios","Zeri"],
["Zed","Naafiri"],
["Kayn","Ashe"],
["Xayah","Yasuo"],
["Aphelios","Xayah"],
["Tristana","Rakan"],
["Rumble","Malphite"],
["Taliyah","Kaisa"],
["Mordekaiser","Ivern"],
["Chogath","Fiora"],
["Yone","Viego"],
["Pantheon","Khazix"],
["Vayne","Senna"],
["Azir","Jayce"],
["Belveth","Viego"],
["Zoe","Yasuo"],
["KSante","Yorick"],
["Zoe","Xerath"],
["Ekko","Lissandra"],
["Pantheon","Viktor"],
["MissFortune","Draven"],
["Tryndamere","Aatrox"],
["Darius","Ekko"],
["Yasuo","Ryze"],
["Kled","Warwick"],
["Swain","Jax"],
["Viego","LeeSin"],
["Akshan","Katarina"],
["Jax","Urgot"],
["Volibear","Sion"],
["Aatrox","Chogath"],
["Kennen","Tristana"],
["Vayne","Ezreal"],
["Darius","Sylas"],
["Rengar","Vayne"],
["Irelia","Jayce"],
["Tryndamere","Olaf"],
["Caitlyn","Rakan"],
["Yasuo","Fiora"],
["Kaisa","Nautilus"],
["Kalista","Aphelios"],
["Sion","Gwen"],
["Ezreal","Draven"],
["JarvanIV","Belveth"],
["Ornn","Fiora"],
["Fiora","Diana"],
["Olaf","Pantheon"],
["MonkeyKing","Ezreal"],
["Aphelios","Talon"],
["Talon","Karma"],
["Kaisa","Vayne"],
["Yone","Talon"],
["Jhin","Varus"],
["Draven","Aphelios"],
["Renekton","RekSai"],
["Malphite","Illaoi"],
["Camille","Shyvana"],
["Hecarim","Xayah"],
["Urgot","Kled"],
["Katarina","Pantheon"],
["Fiora","Khazix"],
["Riven","Lulu"],
["Kled","Pantheon"],
["Ashe","Garen"],
["Sylas","Irelia"],
["Akali","Mordekaiser"],
["Malphite","Tryndamere"],
["MasterYi","Katarina"],
["Talon","Viego"],
["Tristana","Swain"],
["Camille","Neeko"],
["Yasuo","Gwen"],
["Viego","Leblanc"],
["Kled","Jax"],
["Warwick","MasterYi"],
["Vayne","Gangplank"],
["Belveth","Fiddlesticks"],
["Draven","Kalista"],
["Vayne","Chogath"],
["Kennen","Poppy"],
["Sylas","Vex"],
["Vayne","Swain"],
["Viego","Jhin"],
["Qiyana","Khazix"],
["Gangplank","Khazix"],
["Fiora","Warwick"],
["Poppy","Olaf"],
["Illaoi","Nasus"],
["Tryndamere","Ryze"],
["Quinn","Sion"],
["Talon","Aphelios"],
["Jayce","Syndra"],
["Cassiopeia","Yone"],
["Kaisa","Rakan"],
["Kaisa","Draven"],
["Teemo","Warwick"],
["KSante","Kennen"],
["Irelia","Belveth"],
["MonkeyKing","Rumble"],
["Vladimir","Sion"],
["Yasuo","Sivir"],
["Diana","Jayce"],
["Malphite","Garen"],
["Yasuo","Olaf"],
["Zac","Gnar"],
["Lissandra","Yone"],
["Riven","Akali"],
["Rengar","Hecarim"],
["Aphelios","Akshan"],
["DrMundo","Gnar"],
["Lillia","Kaisa"],
["Sion","Pantheon"],
["Kaisa","Sylas"],
["Trundle","Aatrox"],
["Akshan","Riven"],
["Zoe","Vladimir"],
["Varus","LeeSin"],
["Riven","Ekko"],
["Gwen","Trundle"],
["Warwick","Nocturne"],
["Yone","Aatrox"],
["Akali","Irelia"],
["Illaoi","Akali"],
["Darius","RekSai"],
["Ashe","Yasuo"],
["Zed","Lissandra"],
["Riven","Ornn"],
["Khazix","Akshan"],
["Riven","Aphelios"],
["Gragas","Xerath"],
["TahmKench","Chogath"],
["Ashe","Lux"],
["Chogath","Pantheon"],
["Kaisa","Senna"],
["MasterYi","Diana"],
["Volibear","Chogath"],
["Yasuo","Malzahar"],
["Akshan","Gragas"],
["Olaf","Jayce"],
["Sylas","Camille"],
["Ezreal","Shaco"],
["Jax","Tristana"],
["Vayne","Belveth"],
["Xerath","Sylas"],
["Quinn","Zed"],
["Neeko","Kaisa"],
["MasterYi","Nocturne"],
["Sylas","Jinx"],
["Teemo","Jax"],
["Renekton","Cassiopeia"],
["Irelia","Kassadin"],
["Veigar","Syndra"],
["JarvanIV","Orianna"],
["Olaf","Chogath"],
["Ezreal","Tristana"],
["Kayn","Ornn"],
["Vayne","Alistar"],
["Khazix","Lucian"],
["Malphite","Camille"],
["Jhin","Draven"],
["Pantheon","Jax"],
["Yasuo","Vladimir"],
["Talon","Kassadin"],
["Riven","DrMundo"],
["Belveth","Talon"],
["Fiora","Udyr"],
["Akali","Veigar"],
["Irelia","Warwick"],
["Olaf","Qiyana"],
["Yone","Ahri"],
["Twitch","Thresh"],
["Fiora","Malphite"],
["Rumble","Qiyana"],
["Vayne","Khazix"],
["KSante","Azir"],
["Jinx","Pyke"],
["RekSai","Ezreal"],
["Talon","Neeko"],
["Jinx","Caitlyn"],
["RekSai","JarvanIV"],
["Ezreal","Senna"],
["Aphelios","Graves"],
["Jayce","Udyr"],
["Viego","Aatrox"],
["JarvanIV","Kindred"],
["Nasus","Irelia"],
["Garen","Talon"],
["Nocturne","Jhin"],
["Xayah","Varus"],
["MasterYi","Pyke"],
["Varus","Zed"],
["Akshan","Yone"],
["TahmKench","Gwen"],
["Karthus","Senna"],
["Olaf","Urgot"],
["Rengar","Naafiri"],
["Jayce","Illaoi"],
["Irelia","Syndra"],
["Akali","Gangplank"],
["Varus","Yasuo"],
["Kayn","Lux"],
["Zoe","Taliyah"],
["Yone","Ivern"],
["RekSai","Khazix"],
["Sylas","Fizz"],
["Kaisa","Lulu"],
["Camille","Pantheon"],
["Azir","Renekton"],
["Ahri","Renekton"],
["Gragas","Akshan"],
["Qiyana","Karthus"],
["Vayne","Kled"],
["Viego","Quinn"],
["Rengar","Diana"],
["Irelia","Garen"],
["Kaisa","TwistedFate"],
["Kled","Sett"],
["Ahri","Anivia"],
["Sion","Sett"],
["Viego","Ziggs"],
["Qiyana","Ahri"],
["Kindred","Fiddlesticks"],
["Kalista","Zed"],
["MissFortune","Ashe"],
["Varus","Ornn"],
["Mordekaiser","Teemo"],
["Twitch","Xerath"],
["Taliyah","Syndra"],
["Yone","Orianna"],
["Rumble","Warwick"],
["Evelynn","Draven"],
["Riven","Yorick"],
["Yone","Kled"],
["Vladimir","Chogath"],
["Pantheon","Singed"],
["Yone","JarvanIV"],
["Xerath","Tristana"],
["Yasuo","Xerath"],
["Xerath","Veigar"],
["Tryndamere","Garen"],
["Jax","Akshan"],
["Garen","Teemo"],
["Ashe","Varus"],
["Kayle","Poppy"],
["Graves","Kindred"],
["MonkeyKing","Yasuo"],
["Kayn","Jhin"],
["Illaoi","Rengar"],
["Zeri","Swain"],
["Ekko","Yasuo"],
["Caitlyn","Lucian"],
["Ashe","Tristana"],
["Camille","Talon"],
["Vayne","Lulu"],
["Graves","Darius"],
["TahmKench","Camille"],
["Syndra","Yone"],
["Malphite","Akshan"],
["Heimerdinger","Sylas"],
["Sylas","Yone"],
["Riven","Shaco"],
["Vayne","Jayce"],
["Sylas","Akali"],
["Garen","Vayne"],
["Vayne","Cassiopeia"],
["Malphite","Ornn"],
["Talon","Garen"],
["Katarina","Irelia"],
["Camille","Gangplank"],
["Gnar","Akshan"],
["Poppy","DrMundo"],
["Varus","Camille"],
["Draven","Renata"],
["Fiora","Rumble"],
["Malphite","Kaisa"],
["Varus","Renekton"],
["Udyr","Garen"],
["Gwen","Camille"],
["Jayce","Gnar"],
["Kaisa","Alistar"],
["Kled","Urgot"],
["Yone","Ziggs"],
["Twitch","Xayah"],
["Irelia","Viktor"],
["KSante","Kaisa"],
["Nasus","Kaisa"],
["Ezreal","Alistar"],
["XinZhao","Xerath"],
["Renekton","Urgot"],
["Sylas","Karthus"],
["Katarina","AurelionSol"],
["Illaoi","Kaisa"],
["Katarina","Zoe"],
["Talon","Lillia"],
["Ekko","Nocturne"],
["Syndra","Leblanc"],
["Jhin","MissFortune"],
["Garen","Aatrox"],
["Riven","Jax"],
["Kalista","Samira"],
["Kled","Shen"],
["Vladimir","Illaoi"],
["Khazix","Aatrox"],
["RekSai","Aatrox"],
["Ekko","Syndra"],
["LeeSin","Pyke"],
["Sylas","Ezreal"],
["Warwick","Jax"],
["Irelia","Vex"],
["Annie","Jayce"],
["Belveth","Nidalee"],
["Kindred","Syndra"],
["Kled","Varus"],
["Belveth","Graves"],
["Kaisa","Zoe"],
["Jax","Varus"],
["Kayle","Zed"],
["KSante","Tristana"],
["Xayah","Ashe"],
["Akshan","Gangplank"],
["Ekko","Jayce"],
["Talon","Malzahar"],
["Ornn","Warwick"],
["Darius","Senna"],
["Katarina","Varus"],
["Kayle","Chogath"],
["Sion","Teemo"],
["Fiora","Ziggs"],
["Ashe","Rell"],
["Renekton","Rengar"],
["Ashe","Ezreal"],
["Irelia","Ezreal"],
["Pantheon","Malphite"],
["Irelia","Sett"],
["Talon","Galio"],
["Kaisa","Rumble"],
["Tryndamere","Teemo"],
["Vex","Irelia"],
["Khazix","LeeSin"],
["Karthus","Kaisa"],
["LeeSin","Kayn"],
["Jax","Naafiri"],
["Diana","Veigar"],
["Fizz","Lux"],
["Zoe","Zed"],
["Chogath","Gwen"],
["Volibear","Riven"],
["Sett","Chogath"],
["Vladimir","Sylas"],
["XinZhao","Shaco"],
["Illaoi","Fiora"],
["Anivia","Sylas"],
["Jax","Lissandra"],
["Samira","Nilah"],
["Yasuo","Viktor"],
["Shen","Renekton"],
["KSante","Quinn"],
["Singed","Malphite"],
["Khazix","Ekko"],
["Vayne","Twitch"],
["Kindred","Kayn"],
["Vayne","Poppy"],
["Hecarim","Samira"],
["Twitch","Zyra"],
["Illaoi","Shen"],
["Kalista","Xayah"],
["Caitlyn","Xayah"],
["Kayn","Leblanc"],
["Jax","Kayn"],
["Kled","Yone"],
["Draven","Zed"],
["Fiora","Urgot"],
["Taliyah","Khazix"],
["Azir","Lux"],
["Samira","Lulu"],
["MasterYi","Janna"],
["Syndra","Talon"],
["Jinx","Senna"],
["Tryndamere","Swain"],
["Quinn","Fiora"],
["Cassiopeia","Pyke"],
["Elise","Rengar"],
["Rumble","Poppy"],
["LeeSin","Fiddlesticks"],
["Ekko","Irelia"],
["Singed","Ornn"],
["Illaoi","Pantheon"],
["Diana","Viktor"],
["Gwen","Urgot"],
["Darius","Swain"],
["Kaisa","MissFortune"],
["Vladimir","Kled"],
["Nocturne","Vi"],
["Darius","Karthus"],
["Akshan","Jax"],
["Khazix","Nunu"],
["Ekko","Jinx"],
["Diana","Jhin"],
["Poppy","Fiora"],
["Vex","Akali"],
["LeeSin","Senna"],
["Sion","Poppy"],
["Fiora","Kennen"],
["Olaf","TwistedFate"],
["Viktor","Aatrox"],
["Swain","Yasuo"],
["Tristana","Lucian"],
["Rumble","Quinn"],
["Zoe","Pantheon"],
["JarvanIV","LeeSin"],
["Kaisa","Sivir"],
["Akshan","Irelia"],
["Akali","Qiyana"],
["Darius","Talon"],
["Kaisa","Leblanc"],
["Ashe","Ziggs"],
["Samira","Swain"],
["Akshan","Kassadin"],
["Urgot","Gwen"],
["Ezreal","Karthus"],
["Camille","Kled"],
["Tryndamere","Shen"],
["Katarina","Kalista"],
["Zed","Caitlyn"],
["Viktor","Syndra"],
["Hecarim","Kayn"],
["Shaco","Jhin"],
["Xayah","Tristana"],
["Zed","Taliyah"],
["Draven","Twitch"],
["Fiora","LeeSin"],
["Pantheon","Xayah"],
["Katarina","Jinx"],
["Anivia","Ekko"],
["Yone","Gangplank"],
["Samira","Nami"],
["Varus","Irelia"],
["Zoe","Ahri"],
["Zoe","Ekko"],
["Udyr","Yasuo"],
["Evelynn","Brand"],
["Fiora","Pantheon"],
["Xayah","Samira"],
["Shaco","MissFortune"],
["RekSai","Gangplank"],
["Syndra","Gangplank"],
["Karthus","Rell"],
["Kaisa","Nilah"],
["Garen","Ekko"],
["Samira","Kayn"],
["Hecarim","Shaco"],
["Renekton","Jayce"],
["Jhin","Rakan"],
["Kassadin","Xerath"],
["Viktor","Irelia"],
["Leblanc","Aatrox"],
["Illaoi","Irelia"],
["Viktor","Azir"],
["KSante","Varus"],
["Gangplank","Aatrox"],
["Yone","Khazix"],
["Gwen","Zed"],
["Fizz","Zed"],
["Irelia","Lissandra"],
["Rengar","Evelynn"],
["Kayn","Lucian"],
["Sylas","Darius"],
["Draven","Xerath"],
["Garen","Zed"],
["Viego","Jayce"],
["Jax","AurelionSol"],
["Chogath","Poppy"],
["Irelia","Yasuo"],
["Nasus","Illaoi"],
["Hecarim","Talon"],
["Evelynn","Kayn"],
["Ornn","Naafiri"],
["Zeri","Sona"],
["Fizz","Xerath"],
["Cassiopeia","Viktor"],
["Zed","Vladimir"],
["Talon","Syndra"],
["Chogath","Shen"],
["Zeri","Yasuo"],
["Nocturne","TwistedFate"],
["Akali","Diana"],
["Nocturne","Jayce"],
["Gnar","Tryndamere"],
["Graves","Renekton"],
["Akali","Quinn"],
["Belveth","Zoe"],
["Cassiopeia","Corki"],
["Darius","Trundle"],
["Qiyana","Syndra"],
["Akali","Renekton"],
["Rumble","Neeko"],
["Qiyana","Caitlyn"],
["Camille","Aatrox"],
["Nasus","Yone"],
["Khazix","Varus"],
["Katarina","Nami"],
["Diana","Syndra"],
["Teemo","Tryndamere"],
["Kled","Gnar"],
["KSante","Poppy"],
["Leblanc","Orianna"],
["Fiddlesticks","Draven"],
["Illaoi","Sett"],
["Renekton","Aatrox"],
["Jax","DrMundo"],
["Zeri","Janna"],
["TahmKench","Illaoi"],
["Kaisa","Brand"],
["Diana","Ahri"],
["Kaisa","Nidalee"],
["JarvanIV","Viego"],
["Diana","Vladimir"],
["LeeSin","Lillia"],
["Mordekaiser","Kled"],
["Ahri","Yasuo"],
["KSante","AurelionSol"],
["Aatrox","Nasus"],
["Qiyana","Cassiopeia"],
["Talon","Leblanc"],
["Nasus","Yasuo"],
["Katarina","Khazix"],
["Riven","Rengar"],
["Varus","Lucian"],
["Warwick","Garen"],
["Garen","Fiora"],
["Talon","Ashe"],
["Graves","Katarina"],
["Darius","Poppy"],
["Varus","Sylas"],
["Camille","Riven"],
["Yone","Fiddlesticks"],
["LeeSin","Taliyah"],
["Kayn","Diana"],
["Riven","Xerath"],
["Ezreal","Karma"],
["Kayle","Gwen"],
["MonkeyKing","Illaoi"],
["Heimerdinger","Yone"],
["Udyr","Graves"],
["Gwen","Udyr"],
["Jayce","Fiora"],
["Cassiopeia","Kayle"],
["Ashe","Seraphine"],
["Samira","Talon"],
["Cassiopeia","Malzahar"],
["TahmKench","Mordekaiser"],
["Tristana","Pyke"],
["Samira","Taliyah"],
["Fiora","Kayle"],
["Camille","Akali"],
["Zeri","Nilah"],
["Kindred","Graves"],
["Taliyah","Cassiopeia"],
["Viego","Akshan"],
["Poppy","Tryndamere"],
["Tristana","Ahri"],
["Kaisa","Renekton"],
["LeeSin","Hecarim"],
["Viktor","Tristana"],
["Sylas","Viktor"],
["Belveth","LeeSin"],
["Lillia","Sion"],
["Gwen","Ezreal"],
["Kennen","Aatrox"],
["Poppy","Yasuo"],
["Ornn","Chogath"],
["Teemo","Riven"],
["Ekko","Orianna"],
["Zed","Jinx"],
["Draven","Seraphine"],
["Syndra","Xayah"],
["Graves","Xerath"],
["Vayne","Fizz"],
["Kindred","JarvanIV"],
["Akali","Warwick"],
["Lux","Xerath"],
["Tryndamere","Darius"],
["MonkeyKing","Gangplank"],
["Akali","Zed"],
["Darius","Caitlyn"],
["Jax","JarvanIV"],
["Ezreal","Pyke"],
["Lux","Yone"],
["Viego","Khazix"],
["Volibear","Khazix"],
["Tryndamere","Illaoi"],
["Vex","Yone"],
["Diana","Zed"],
["Udyr","Renekton"],
["Urgot","Quinn"],
["Ashe","Karthus"],
["Irelia","Kennen"],
["MasterYi","Cassiopeia"],
["RekSai","Zeri"],
["Kayn","Sylas"],
["KSante","Sett"],
["Kalista","Pyke"],
["Yone","Kennen"],
["Yasuo","Renekton"],
["Aphelios","Ezreal"],
["Sivir","Ezreal"],
["Nilah","Rengar"],
["Cassiopeia","MasterYi"],
["Riven","Gnar"],
["Sett","Garen"],
["Shen","Aatrox"],
["Darius","Heimerdinger"],
["Teemo","Volibear"],
["Camille","Illaoi"],
["Jinx","Vayne"],
["Vladimir","Xerath"],
["Gangplank","Yone"],
["RekSai","Jayce"],
["Jax","Kennen"],
["Cassiopeia","Xerath"],
["Sylas","KSante"],
["Qiyana","Fiora"],
["Aatrox","Akshan"],
["Aphelios","Draven"],
["Riven","Volibear"],
["Cassiopeia","RekSai"],
["Pantheon","Camille"],
["Akali","Chogath"],
["Samira","Seraphine"],
["Akali","Gwen"],
["Anivia","Zed"],
["Yone","Mordekaiser"],
["Ezreal","Orianna"],
["Talon","Fizz"],
["Swain","Akali"],
["Zeri","Ziggs"],
["Poppy","Gragas"],
["Jayce","Cassiopeia"],
["Malphite","Graves"],
["Xayah","Rell"],
["Camille","Kassadin"],
["Garen","Irelia"],
["Vayne","Garen"],
["Ornn","Renekton"],
["Twitch","Shaco"],
["Kaisa","Vex"],
["Yone","Malzahar"],
["Talon","Xerath"],
["Trundle","Jax"],
["KSante","Neeko"],
["Skarner","Syndra"],
["Nasus","Chogath"],
["Sylas","Fiora"],
["Karthus","Taliyah"],
["Gnar","Urgot"],
["Ashe","Soraka"],
["XinZhao","Yasuo"],
["Hecarim","Zed"],
["Illaoi","Riven"],
["Heimerdinger","Illaoi"],
["Zed","Akshan"],
["Viego","Talon"],
["JarvanIV","Ashe"],
["Ornn","Mordekaiser"],
["Rengar","Graves"],
["Darius","Teemo"],
["Riven","Cassiopeia"],
["Camille","Yasuo"],
["Quinn","Gangplank"],
["Zoe","Azir"],
["Kayn","Sion"],
["Aphelios","Hecarim"],
["MasterYi","Nautilus"],
["Olaf","Senna"],
["Yasuo","Kayle"],
["MasterYi","Belveth"],
["Taliyah","Irelia"],
["Varus","Aatrox"],
["Vayne","Nasus"],
["Warwick","Darius"],
["Ashe","Renekton"],
["Viktor","Swain"],
["Pantheon","Kaisa"],
["MasterYi","Orianna"],
["Jayce","Teemo"],
["Ezreal","Janna"],
["Qiyana","Hecarim"],
["Azir","Talon"],
["Vladimir","Kassadin"],
["Graves","Orianna"],
["Samira","Soraka"],
["Camille","Sion"],
["Karthus","Twitch"],
["Gangplank","Volibear"],
["Lux","Kaisa"],
["Garen","Shen"],
["Pantheon","TwistedFate"],
["Leblanc","Neeko"],
["Vayne","Draven"],
["Shen","Darius"],
["Mordekaiser","Rumble"],
["Riven","Syndra"],
["Fiora","Poppy"],
["Nasus","Azir"],
["Fiddlesticks","Vayne"],
["Yone","Chogath"],
["Trundle","Gangplank"],
["Sett","Mordekaiser"],
["Darius","Akshan"],
["Sett","Yorick"],
["Olaf","DrMundo"],
["Akali","Vex"],
["Cassiopeia","Kennen"],
["Evelynn","Ahri"],
["Nilah","Ashe"],
["Yorick","Yone"],
["Qiyana","Talon"],
["Gwen","Gragas"],
["Jhin","Ekko"],
["Ashe","Blitzcrank"],
["LeeSin","Nidalee"],
["Volibear","Gwen"],
["Garen","Ahri"],
["Akali","Ryze"],
["Samira","Shaco"],
["Camille","Khazix"],
["Rengar","Akshan"],
["KSante","Yone"],
["Varus","KSante"],
["Rumble","Garen"],
["Ahri","Lissandra"],
["Mordekaiser","Quinn"],
["Azir","AurelionSol"],
["Senna","Pyke"],
["Yone","Jax"],
["Riven","Azir"],
["Kaisa","Sion"],
["Akali","Senna"],
["Yasuo","Kalista"],
["Khazix","Nocturne"],
["Cassiopeia","Draven"],
["Irelia","Anivia"],
["Mordekaiser","Olaf"],
["Qiyana","Ezreal"],
["Azir","Vladimir"],
["Cassiopeia","Akali"],
["Fizz","Zoe"],
["Gangplank","Yasuo"],
["Sylas","Diana"],
["MasterYi","Ezreal"],
["Olaf","Karma"],
["Cassiopeia","Leblanc"],
["Malphite","Riven"],
["Kassadin","Talon"],
["Renekton","JarvanIV"],
["Urgot","Mordekaiser"],
["Mordekaiser","Kayle"],
["Akshan","Vayne"],
["Akali","Pyke"],
["Nilah","Tristana"],
["Irelia","Varus"],
["MonkeyKing","Tryndamere"],
["Ezreal","Samira"],
["Xayah","Ekko"],
["Akshan","Darius"],
["Mordekaiser","Vladimir"],
["Akali","Malzahar"],
["Udyr","Fiddlesticks"],
["MonkeyKing","Garen"],
["Zac","Malphite"],
["Poppy","Jayce"],
["Ezreal","Zed"],
["Sett","Gnar"],
["Nasus","Gangplank"],
["DrMundo","Sion"],
["MissFortune","Thresh"],
["Irelia","Ashe"],
["Yone","Annie"],
["Nasus","Urgot"],
["MonkeyKing","Pantheon"],
["Nilah","Ezreal"],
["Xayah","Veigar"],
["KSante","Nidalee"],
["Yone","Akshan"],
["Shen","Kled"],
["Akali","Hecarim"],
["Diana","Draven"],
["Cassiopeia","Nautilus"],
["Aatrox","Heimerdinger"],
["Tristana","Vladimir"],
["KSante","Taliyah"],
["Udyr","Fiora"],
["Darius","Hecarim"],
["Mordekaiser","Pantheon"],
["Irelia","Karthus"],
["Yone","Kindred"],
["Tryndamere","Xerath"],
["Hecarim","Khazix"],
["RekSai","Qiyana"],
["Kindred","Yasuo"],
["Ornn","Kayle"],
["Khazix","Aphelios"],
["Pantheon","Ashe"],
["Pantheon","Draven"],
["Aatrox","Quinn"],
["Jax","Viktor"],
["Yasuo","Camille"],
["Teemo","Sylas"],
["Kennen","Renekton"],
["Leblanc","Darius"],
["Aphelios","Sylas"],
["Trundle","Olaf"],
["Mordekaiser","Warwick"],
["Fizz","Orianna"],
["Volibear","Gnar"],
["Aatrox","Renekton"],
["Cassiopeia","Olaf"],
["Warwick","Irelia"],
["Illaoi","TahmKench"],
["RekSai","Leblanc"],
["Yone","Jinx"],
["Irelia","Aatrox"],
["Rumble","Vladimir"],
["Viego","Twitch"],
["Fiora","Irelia"],
["Kaisa","Ashe"],
["Cassiopeia","Camille"],
["Warwick","Renekton"],
["Pantheon","Ornn"],
["Kaisa","Swain"],
["Rumble","Rengar"],
["Quinn","Yasuo"],
["Viego","Gnar"],
["Zoe","Sylas"],
["Nocturne","JarvanIV"],
["Gangplank","KSante"],
["Vladimir","Talon"],
["Viego","Belveth"],
["Fiora","Nasus"],
["Akshan","Karthus"],
["Akali","Zac"],
["Gwen","Orianna"],
["Olaf","Shen"],
["Tristana","Irelia"],
["Akali","Udyr"],
["Tristana","Lux"],
["Draven","Caitlyn"],
["Veigar","Yone"],
["Talon","MasterYi"],
["Kennen","Kled"],
["Syndra","Fizz"],
["Malphite","Vayne"],
["Qiyana","Varus"],
["Ornn","Zed"],
["Gwen","Rumble"],
["Khazix","Fizz"],
["Sion","Shyvana"],
["Samira","Vi"],
["Vayne","Udyr"],
["Viego","XinZhao"],
["Illaoi","Kassadin"],
["Lillia","LeeSin"],
["Irelia","TwistedFate"],
["Draven","Rell"],
["Vayne","Yasuo"],
["Fiora","Mordekaiser"],
["Yasuo","Ekko"],
["Jax","Gangplank"],
["Yone","RekSai"],
["Diana","Yasuo"],
["Khazix","Pyke"],
["Illaoi","Malphite"],
["Pantheon","AurelionSol"],
["Gwen","Shen"],
["Yone","Belveth"],
["Xayah","Alistar"],
["Olaf","Jax"],
["Volibear","Cassiopeia"],
["Rengar","Nidalee"],
["Katarina","Kassadin"],
["Gangplank","Riven"],
["MasterYi","Yasuo"],
["Zac","Jax"],
["Riven","Fizz"],
["Vayne","Rengar"],
["Jax","Gnar"],
["Lillia","Fiddlesticks"],
["Kennen","Khazix"],
["Yasuo","Ivern"],
["Swain","Veigar"],
["Fiddlesticks","Fiora"],
["Zed","Velkoz"],
["Kalista","Karthus"],
["Trundle","Yasuo"],
["Syndra","Jayce"],
["Ezreal","Fizz"],
["Jax","Rumble"],
["Kaisa","Tristana"],
["Vayne","Varus"],
["Pantheon","Ahri"],
["Vayne","Jhin"],
["Tristana","Ashe"],
["Rengar","Aatrox"],
["Volibear","Naafiri"],
["LeeSin","Sion"],
["MissFortune","Ezreal"],
["Kayn","Tristana"],
["Diana","Evelynn"],
["Talon","Akali"],
["Olaf","Rumble"],
["Sett","Naafiri"],
["Olaf","Nasus"],
["Volibear","Nidalee"],
["Kled","Camille"],
["Gnar","Zed"],
["Pantheon","Kled"],
["Khazix","Graves"],
["Yone","Gwen"],
["Gwen","Tryndamere"],
["Akali","Tryndamere"],
["Rumble","Sion"],
["KogMaw","Samira"],
["Kayn","Jinx"],
["Viego","Pyke"],
["Olaf","Kaisa"],
["Neeko","Tristana"],
["Samira","Braum"],
["Rengar","Viego"],
["TahmKench","KSante"],
["Malphite","Darius"],
["Azir","Orianna"],
["Pantheon","Zoe"],
["Kayn","Akali"],
["Ornn","Teemo"],
["Kled","Ekko"],
["Vayne","Bard"],
["Zeri","Vayne"],
["Jhin","Kaisa"],
["Gragas","Malphite"],
["Kayle","Tryndamere"],
["Ashe","Caitlyn"],
["Leblanc","Fizz"],
["Syndra","Ekko"],
["Zeri","Syndra"],
["Kled","Gragas"],
["Nasus","Samira"],
["Cassiopeia","Shen"],
["Yasuo","Tristana"],
["Tryndamere","Gnar"],
["Lillia","Caitlyn"],
["Draven","Nilah"],
["Pantheon","Volibear"],
["Shaco","Evelynn"],
["Riven","Pantheon"],
["Warwick","Sylas"],
["Tristana","Qiyana"],
["Senna","Xayah"],
["Darius","Qiyana"],
["Renekton","Graves"],
["Jax","Sett"],
["KogMaw","Ezreal"],
["Kassadin","Vladimir"],
["Xayah","Blitzcrank"],
["Renekton","Diana"],
["Fiora","RekSai"],
["MissFortune","Senna"],
["Irelia","Draven"],
["Viego","Ekko"],
["Jax","Kalista"],
["Jayce","Darius"],
["Riven","Yone"],
["Fiddlesticks","Xayah"],
["Khazix","Senna"],
["Velkoz","Orianna"],
["Vayne","LeeSin"],
["Vladimir","Olaf"],
["Katarina","Velkoz"],
["Viego","Ivern"],
["Mordekaiser","Katarina"],
["Renekton","Volibear"],
["Heimerdinger","Fiora"],
["Kalista","Soraka"],
["Jhin","Vayne"],
["Yone","Xerath"],
["Samira","Kalista"],
["Jayce","Yasuo"],
["Graves","Belveth"],
["Singed","Pantheon"],
["Fiddlesticks","Neeko"],
["Renekton","Yasuo"],
["Zeri","Taliyah"],
["Olaf","Sett"],
["Khazix","Zed"],
["Xayah","Aatrox"],
["Tryndamere","Akali"],
["Samira","JarvanIV"],
["Kayle","Akshan"],
["Fiddlesticks","Graves"],
["Olaf","Ahri"],
["Vayne","AurelionSol"],
["Renekton","Vladimir"],
["Vayne","Akshan"],
["Sett","Renekton"],
["Riven","Olaf"],
["Jayce","Taliyah"],
["Vayne","Pantheon"],
["Kayle","Shen"],
["Qiyana","Irelia"],
["Camille","Tristana"],
["Xayah","Nidalee"],
["Varus","Taliyah"],
["Rengar","Renekton"],
["Irelia","Aphelios"],
["Zeri","Xayah"],
["Aatrox","DrMundo"],
["Akshan","Kennen"],
["Sylas","Azir"],
["Ornn","DrMundo"],
["DrMundo","Gangplank"],
["Gragas","Chogath"],
["Twitch","Khazix"],
["Samira","Sylas"],
["Akshan","Caitlyn"],
["Vayne","Thresh"],
["Jayce","Lucian"],
["Kaisa","Renata"],
["Jax","Taliyah"],
["Akshan","Hecarim"],
["Azir","Jax"],
["Renekton","Udyr"],
["Cassiopeia","Lissandra"],
["Zed","Malzahar"],
["Jax","Quinn"],
["Olaf","Yorick"],
["Viego","Nautilus"],
["MissFortune","Lulu"],
["Sylas","Riven"],
["Jax","Yasuo"],
["Renekton","Teemo"],
["Irelia","Rengar"],
["Yasuo","Viego"],
["Yasuo","Kennen"],
["Zeri","Sivir"],
["Kayn","Orianna"],
["RekSai","Jax"],
["Zeri","Thresh"],
["Qiyana","Viktor"],
["Aphelios","Rakan"],
["KSante","Kled"],
["Jinx","Janna"],
["MonkeyKing","Urgot"],
["Rengar","MasterYi"],
["MasterYi","Gnar"],
["Yasuo","Zed"],
["Leblanc","Viktor"],
["Aatrox","Hecarim"],
["Vayne","Volibear"],
["Heimerdinger","Yasuo"],
["KSante","Nasus"],
["Illaoi","Darius"],
["Irelia","Tristana"],
["Nasus","DrMundo"],
["Viego","Vladimir"],
["KSante","Ezreal"],
["Karthus","Samira"],
["Yorick","Fiora"],
["TwistedFate","Tristana"],
["Camille","Tryndamere"],
["Rengar","Senna"],
["Khazix","Xerath"],
["Tryndamere","Malzahar"],
["Draven","MissFortune"],
["Irelia","Shyvana"],
["Akali","KSante"],
["Kayn","Fiora"],
["Riven","Gragas"],
["Camille","Katarina"],
["Renekton","Karma"],
["Rengar","Poppy"],
["Nasus","Akali"],
["Renekton","Rumble"],
["Udyr","Khazix"],
["MonkeyKing","Graves"],
["Rumble","JarvanIV"],
["Pantheon","Darius"],
["Tryndamere","Rumble"],
["Yasuo","Swain"],
["Tristana","Sylas"],
["Darius","Riven"],
["Twitch","Vayne"],
["Ahri","Yone"],
["Evelynn","Nocturne"],
["Xayah","Thresh"],
["Fizz","Vex"],
["Akali","Fizz"],
["Riven","Taliyah"],
["Sett","Tristana"],
["Jayce","Yone"],
["Akali","JarvanIV"],
["Hecarim","Jinx"],
["Vayne","Darius"],
["Twitch","Jinx"],
["Udyr","Tryndamere"],
["Fiddlesticks","Talon"],
["Velkoz","Akali"],
["Belveth","Kayn"],
["KSante","Maokai"],
["Ezreal","Yone"],
["Aatrox","JarvanIV"],
["Hecarim","Leblanc"],
["Katarina","Veigar"],
["Rengar","Pantheon"],
["TahmKench","Jax"],
["Velkoz","Zed"],
["Gwen","Teemo"],
["Fiora","Leblanc"],
["Fiora","Nidalee"],
["Yasuo","Poppy"],
["Olaf","Teemo"],
["Akshan","KSante"],
["Viego","Xayah"],
["Ashe","Janna"],
["Fiora","Gnar"],
["Khazix","Ziggs"],
["Mordekaiser","Darius"],
["Teemo","Mordekaiser"],
["Kennen","Akshan"],
["Viego","MissFortune"],
["Sylas","Senna"],
["Twitch","Nilah"],
["Urgot","Akshan"],
["Olaf","Samira"],
["Yasuo","Fiddlesticks"],
["Yorick","Gangplank"],
["Varus","Caitlyn"],
["Olaf","AurelionSol"],
["Aatrox","Viktor"],
["Irelia","Nasus"],
["Nocturne","Samira"],
["Irelia","Malphite"],
["Xayah","Zoe"],
["Nocturne","Shaco"],
["Illaoi","Aatrox"],
["Kled","Udyr"],
["Kennen","Sylas"],
["TahmKench","Kayle"],
["Shen","Sion"],
["Viego","Lillia"],
["Irelia","Ryze"],
["Urgot","Chogath"],
["Gwen","Maokai"],
["Kayn","Ahri"],
["Viego","Fiddlesticks"],
["Viktor","Pantheon"],
["Zed","Cassiopeia"],
["Samira","Blitzcrank"],
["Gwen","Kennen"],
["Olaf","Aatrox"],
["Pantheon","Teemo"],
["Talon","Fiddlesticks"],
["Shyvana","Jayce"],
["Cassiopeia","Ezreal"],
["Yone","Kalista"],
["Yone","Garen"],
["Jayce","Katarina"],
["Pantheon","Kassadin"],
["Akshan","Vex"],
["Darius","Nocturne"],
["Rengar","Riven"],
["Qiyana","Naafiri"],
["Cassiopeia","Ashe"],
["Renekton","Gragas"],
["Sett","Rengar"],
["Lillia","Belveth"],
["Varus","Riven"],
["Yone","Shen"],
["Ekko","Kayn"],
["Kaisa","Aatrox"],
["Illaoi","MasterYi"],
["Kennen","Talon"],
["Olaf","Xerath"],
["Mordekaiser","Sylas"],
["Nasus","Naafiri"],
["Illaoi","Heimerdinger"],
["Kassadin","Neeko"],
["Fizz","Malzahar"],
["Belveth","Fiora"],
["Jax","Swain"],
["Trundle","Poppy"],
["Renekton","Riven"],
["Talon","Irelia"],
["Kayn","Quinn"],
["Varus","Jayce"],
["Nilah","Yasuo"],
["Gnar","Gragas"],
["Vladimir","Rengar"],
["Camille","Poppy"],
["Annie","Lissandra"],
["Corki","Yone"],
["Ornn","Singed"],
["Ekko","Ashe"],
["Akali","Pantheon"],
["Olaf","Taliyah"],
["Darius","Viktor"],
["Ashe","KogMaw"],
["Cassiopeia","Irelia"],
["Gragas","Quinn"],
["Graves","Vayne"],
["Pantheon","Yone"],
["Ekko","AurelionSol"],
["Illaoi","Jayce"],
["Lissandra","Jayce"],
["Warwick","Yone"],
["Vladimir","Poppy"],
["Gwen","Mordekaiser"],
["Draven","Yasuo"],
["Rengar","Orianna"],
["Zed","Kled"],
["Shyvana","Sion"],
["Varus","Poppy"],
["Yasuo","Warwick"],
["Veigar","Ezreal"],
["Draven","Alistar"],
["Zed","Ezreal"],
["Vex","Xerath"],
["Sylas","Kennen"],
["Kled","Garen"],
["Khazix","Maokai"],
["Jax","Chogath"],
["Heimerdinger","Sion"],
["KogMaw","Draven"],
["Khazix","Gangplank"],
["Warwick","Fiora"],
["Rengar","Varus"],
["Akshan","Vladimir"],
["Garen","Sion"],
["Varus","Nautilus"],
["Samira","Jinx"],
["Annie","Leblanc"],
["Pantheon","Sylas"],
["Volibear","Aatrox"],
["Camille","MonkeyKing"],
["Draven","Kayn"],
["Sett","Warwick"],
["Irelia","JarvanIV"],
["Kled","Teemo"],
["Darius","Malphite"],
["Cassiopeia","Yasuo"],
["Renekton","Chogath"],
["Irelia","Gragas"],
["Hecarim","Tristana"],
["MasterYi","Rengar"],
["Twitch","Rakan"],
["Ashe","Aphelios"],
["Aatrox","Kled"],
["Yone","Vladimir"],
["Fiddlesticks","Khazix"],
["Yasuo","Neeko"],
["Akali","Jax"],
["Yasuo","Veigar"],
["Rumble","Riven"],
["Yone","Naafiri"],
["Katarina","Aatrox"],
["Swain","Rumble"],
["Qiyana","Rengar"],
["Akali","Graves"],
["Yasuo","JarvanIV"],
["Poppy","KSante"],
["MonkeyKing","Renekton"],
["Fiora","Tristana"],
["Zoe","Syndra"],
["Kayle","Garen"],
["Katarina","Lux"],
["DrMundo","Camille"],
["Shaco","Rengar"],
["Xayah","Nautilus"],
["Ornn","Aatrox"],
["Kled","Akali"],
["Fiora","Maokai"],
["Fizz","TwistedFate"],
["Vayne","Ornn"],
["JarvanIV","Taliyah"],
["Yone","Graves"],
["Qiyana","Swain"],
["Olaf","Garen"],
["Heimerdinger","Gwen"],
["Gwen","Tristana"],
["Yasuo","Annie"],
["Kennen","Ahri"],
["Jax","Talon"],
["Zed","Veigar"],
["Aphelios","Taliyah"],
["Yasuo","Belveth"],
["Chogath","Yasuo"],
["Darius","Sett"],
["Yasuo","Jinx"],
["Ezreal","Gangplank"],
["Zeri","Riven"],
["Annie","Akali"],
["Akali","Kayle"],
["Renekton","Darius"],
["Akali","Urgot"],
["Viego","RekSai"],
["Senna","Ezreal"],
["Volibear","Camille"],
["Cassiopeia","Zed"],
["LeeSin","Graves"],
["Shaco","Syndra"],
["Khazix","Vayne"],
["Kayn","Syndra"],
["Yone","Gnar"],
["Garen","Caitlyn"],
["Anivia","Cassiopeia"],
["Vladimir","Qiyana"],
["Akali","Karthus"],
["Lissandra","Leblanc"],
["Zeri","MissFortune"],
["Singed","Kayle"],
["Yone","Qiyana"],
["Olaf","JarvanIV"],
["Warwick","Olaf"],
["Fiddlesticks","Jax"],
["Yasuo","Jayce"],
["Neeko","Qiyana"],
["Katarina","Akshan"],
["Kaisa","Twitch"],
["Ashe","Jinx"],
["Gnar","Shen"],
["Rumble","Volibear"],
["Nilah","Kaisa"],
["Diana","Hecarim"],
["Yasuo","Rumble"],
["XinZhao","Kindred"],
["Zed","Talon"],
["Senna","Ashe"],
["Yone","TahmKench"],
["Cassiopeia","Jayce"],
["Poppy","Kled"],
["Draven","Bard"],
["Cassiopeia","Zoe"],
["Renekton","XinZhao"],
["Aatrox","Ashe"],
["Kayle","Jayce"],
["Yasuo","Ezreal"],
["Diana","Shaco"],
["Pantheon","Diana"],
["Cassiopeia","Ahri"],
["Yasuo","Kayn"],
["Rumble","Illaoi"],
["Malphite","Singed"],
["Graves","Lillia"],
["Riven","Malphite"],
["MasterYi","Zac"],
["Gragas","Irelia"],
["Akali","Varus"],
["Vi","Khazix"],
["Yasuo","LeeSin"],
["KSante","Trundle"],
["Pantheon","Senna"],
["Olaf","Quinn"],
["Zac","Camille"],
["Tristana","Samira"],
["Zeri","Senna"],
["Shaco","Vayne"],
["Ashe","Khazix"],
["Jax","Shen"],
["Samira","Xerath"],
["Poppy","Chogath"],
["Vayne","Nautilus"],
["Samira","Ekko"],
["Zeri","Seraphine"],
["Leblanc","Tristana"],
["Jax","Ornn"],
["Pantheon","DrMundo"],
["Warwick","Quinn"],
["Yone","Sion"],
["Sylas","Blitzcrank"],
["Illaoi","Garen"],
["Olaf","Gnar"],
["Syndra","Ziggs"],
["MonkeyKing","Irelia"],
["KSante","Teemo"],
["Twitch","Ezreal"],
["Illaoi","Warwick"],
["Kalista","Caitlyn"],
["Rengar","Seraphine"],
["Leblanc","Vladimir"],
["Rengar","RekSai"],
["Rengar","Garen"],
["Evelynn","Vayne"],
["AurelionSol","Syndra"],
["Darius","Aatrox"],
["Camille","Rumble"],
["JarvanIV","AurelionSol"],
["Teemo","Camille"],
["Jax","Nidalee"],
["Draven","Jinx"],
["Akali","Samira"],
["Yasuo","Pantheon"],
["Camille","Naafiri"],
["Cassiopeia","Swain"],
["Belveth","Kaisa"],
["Diana","Khazix"],
["Anivia","Azir"],
["Diana","Ekko"],
["Gangplank","Quinn"],
["Jayce","Vladimir"],
["Katarina","Tristana"],
["Vladimir","Neeko"],
["Chogath","Yone"],
["Aatrox","Rengar"],
["Talon","Jinx"],
["Riven","Rumble"],
["Vayne","Leona"],
["Khazix","MissFortune"],
["Ekko","Akali"],
["Aatrox","Gnar"],
["LeeSin","Aatrox"],
["Tryndamere","Quinn"],
["Kaisa","LeeSin"],
["Kassadin","Syndra"],
["Diana","Yone"],
["Ahri","Leblanc"],
["Sion","Gangplank"],
["Gwen","Illaoi"],
["Samira","Zeri"],
["Gwen","Olaf"],
["Ornn","Sett"],
["Pantheon","Lux"],
["Warwick","Riven"],
["Samira","Lux"],
["Belveth","Akshan"],
["Aatrox","Taliyah"],
["Hecarim","Gangplank"],
["Leblanc","Xerath"],
["Darius","Nidalee"],
["Qiyana","TwistedFate"],
["Sett","Cassiopeia"],
["Vayne","Graves"],
["Rumble","Pantheon"],
["Renekton","Pyke"],
["Mordekaiser","Akshan"],
["Sett","Urgot"],
["Khazix","Rengar"],
["Aatrox","Kennen"],
["Jhin","Karthus"],
["Garen","Taliyah"],
["Gragas","Taliyah"],
["Renekton","Taliyah"],
["Varus","Vayne"],
["Talon","Katarina"],
["Gragas","Jax"],
["Zed","Ornn"],
["Varus","Vladimir"],
["Yone","Tristana"],
["Volibear","Zed"],
["Zed","Ekko"],
["Quinn","Poppy"],
["Warwick","Gangplank"],
["Yasuo","Kindred"],
["Talon","Camille"],
["MasterYi","Jax"],
["Kayn","Yasuo"],
["Varus","Fizz"],
["Graves","Taliyah"],
["Nasus","Sion"],
["Ezreal","Camille"],
["Aatrox","Poppy"],
["Twitch","Samira"],
["Katarina","Karma"],
["Akshan","Sion"],
["Caitlyn","Talon"],
["Jax","Vladimir"],
["Fizz","Kaisa"],
["Poppy","Urgot"],
["Ashe","Samira"],
["Talon","Jayce"],
["Gwen","Ryze"],
["Sion","Malphite"],
["Xayah","Jhin"],
["Fizz","Jayce"],
["Urgot","Ornn"],
["Xayah","Kalista"],
["Viego","Vayne"],
["Teemo","Aatrox"],
["Varus","Draven"],
["Cassiopeia","Qiyana"],
["Kassadin","Nautilus"],
["Akali","Lillia"],
["Rumble","Orianna"],
["Renekton","Viktor"],
["Gragas","Gwen"],
["MissFortune","Vayne"],
["Yasuo","Zeri"],
["Fizz","Naafiri"],
["Gangplank","Akali"],
["Yone","Urgot"],
["Kayle","Yasuo"],
["Draven","Janna"],
["Pantheon","Fizz"],
["Ashe","Leona"],
["Hecarim","Nidalee"],
["Nocturne","Tristana"],
["MonkeyKing","Riven"],
["Swain","Malphite"],
["Leblanc","Annie"],
["KSante","Aatrox"],
["Anivia","Yasuo"],
["Jax","Volibear"],
["Leblanc","Renekton"],
["Gangplank","Yorick"],
["Neeko","Draven"],
["Kayle","Ornn"],
["Evelynn","Ezreal"],
["Poppy","Kayle"],
["Darius","Nasus"],
["Tryndamere","Irelia"],
["Riven","Shen"],
["Singed","Gangplank"],
["Chogath","Warwick"],
["Akshan","Orianna"],
["Pantheon","Talon"],
["Vladimir","Vayne"],
["Poppy","Akali"],
["Udyr","Nunu"],
["Hecarim","Senna"],
["Jinx","Nautilus"],
["Samira","Janna"],
["Sion","Tristana"],
["Viego","Camille"],
["Camille","Irelia"],
["Camille","Renekton"],
["Riven","Camille"],
["Swain","Fiora"],
["Evelynn","Katarina"],
["Kayle","Malphite"],
["Camille","Hecarim"],
["Teemo","Garen"],
["Belveth","Syndra"],
["Yasuo","Akali"],
["Shyvana","Jax"],
["Yasuo","Darius"],
["Aphelios","Jhin"],
["Belveth","Yasuo"],
["Warwick","Ornn"],
["AurelionSol","Lissandra"],
["Rengar","Ziggs"],
["Pantheon","Azir"],
["Gragas","Darius"],
["Fiora","Renekton"],
["Rumble","Jayce"],
["Viego","Qiyana"],
["Garen","Viktor"],
["MasterYi","Kalista"],
["Akali","Volibear"],
["Zed","Chogath"],
["Khazix","RekSai"],
["Teemo","Fiora"],
["Yasuo","Aphelios"],
["Sylas","Viego"],
["Leblanc","Kassadin"],
["LeeSin","Xerath"],
["Khazix","Shaco"],
["Malzahar","Sylas"],
["Cassiopeia","Belveth"],
["Ivern","Garen"],
["Lux","Jayce"],
["Samira","Nautilus"],
["Rengar","Samira"],
["Camille","Volibear"],
["Samira","Lissandra"],
["Khazix","Taliyah"],
["Cassiopeia","Orianna"],
["Aatrox","Sett"],
["Sett","Taliyah"],
["Katarina","Ryze"],
["Illaoi","Gragas"],
["Yasuo","Varus"],
["Pantheon","Taliyah"],
["Swain","Varus"],
["Qiyana","Fizz"],
["Jayce","Neeko"],
["Aatrox","Ekko"],
["Swain","Aphelios"],
["Jinx","Karma"],
["JarvanIV","Xayah"],
["Trundle","Pantheon"],
["Pantheon","Vayne"],
["Viego","Cassiopeia"],
["Akali","Aphelios"],
["MasterYi","Ekko"],
["Mordekaiser","Chogath"],
["Gwen","Gangplank"],
["Zed","Graves"],
["Leblanc","Lissandra"],
["Urgot","Akali"],
["Aatrox","Gragas"],
["Aatrox","Varus"],
["Lux","Fizz"],
["Pantheon","Samira"],
["Aphelios","MissFortune"],
["Kaisa","Kayn"],
["Sion","Chogath"],
["Akali","MonkeyKing"],
["Twitch","Jayce"],
["Gnar","Yasuo"],
["Katarina","Graves"],
["Kalista","Lucian"],
["Poppy","Graves"],
["Xayah","Nilah"],
["Nocturne","Yone"],
["Gnar","Malphite"],
["Vayne","Nocturne"],
["Hecarim","Ekko"],
["Darius","Ashe"],
["Nasus","Vladimir"],
["Aatrox","Pantheon"],
["Olaf","Cassiopeia"],
["Annie","Ahri"],
["Gnar","Nasus"],
["Evelynn","Zed"],
["Kaisa","Karthus"],
["Qiyana","Azir"],
["Kaisa","Nocturne"],
["Aatrox","Karthus"],
["Hecarim","Caitlyn"],
["Zed","Katarina"],
["Xayah","Draven"],
["Illaoi","DrMundo"],
["Jax","KSante"],
["Kayn","Khazix"],
["KSante","Ashe"],
["Illaoi","Aphelios"],
["Kalista","Jinx"],
["Yasuo","Lux"],
["Riven","Qiyana"],
["Graves","Aatrox"],
["Irelia","Vladimir"],
["Tristana","Akshan"],
["Ekko","Shaco"],
["Draven","Lulu"],
["Talon","Nunu"],
["MissFortune","Caitlyn"],
["Ezreal","Nami"],
["Irelia","Lucian"],
["Ekko","Sylas"],
["Jax","Ryze"],
["Trundle","Irelia"],
["Darius","Singed"],
["Fiora","Zac"],
["MonkeyKing","Darius"],
["Anivia","Taliyah"],
["Lillia","Graves"],
["Warwick","Malphite"],
["TahmKench","Tryndamere"],
["Shen","Volibear"],
["Katarina","Fizz"],
["Annie","Yone"],
["Sylas","Seraphine"],
["Trundle","Zed"],
["JarvanIV","Akshan"],
["KSante","Jayce"],
["Anivia","Yone"],
["Talon","Samira"],
["Qiyana","Jayce"],
["Vayne","Malphite"],
["Sion","Riven"],
["Yasuo","Jhin"],
["Olaf","Ashe"],
["Azir","Taliyah"],
["Kaisa","Nami"],
["Gangplank","Kayn"],
["Sett","Trundle"],
["Zed","Gwen"],
["Talon","Riven"],
["Kalista","Nautilus"],
["Yasuo","Seraphine"],
["Zed","TwistedFate"],
["Darius","Pantheon"],
["Kaisa","Ziggs"],
["TahmKench","Aatrox"],
["Gwen","Sion"],
["Warwick","Sett"],
["MasterYi","RekSai"],
["Riven","Chogath"],
["Qiyana","Corki"],
["Xayah","Vayne"],
["Vayne","Seraphine"],
["Irelia","LeeSin"],
["RekSai","Ahri"],
["Warwick","Gnar"],
["Gnar","Riven"],
["Warwick","Volibear"],
["Twitch","Senna"],
["Irelia","Maokai"],
["Nocturne","Varus"],
["Katarina","Akali"],
["Belveth","Zed"],
["Singed","Volibear"],
["Ekko","Katarina"],
["Zed","Xerath"],
["Malphite","Kayle"],
["Aatrox","Maokai"],
["Olaf","Kindred"],
["MasterYi","Evelynn"],
["Renekton","AurelionSol"],
["Sylas","Galio"],
["Draven","Soraka"],
["Nilah","Zed"],
["Fizz","Lissandra"],
["Leblanc","Riven"],
["Belveth","Karthus"],
["Garen","KSante"],
["Yone","Vi"],
["Vayne","TahmKench"],
["Renekton","Syndra"],
["Olaf","Ryze"],
["Camille","Azir"],
["Renekton","Azir"],
["Yone","Ashe"],
["Zed","Leblanc"],
["Akshan","Zoe"],
["Akali","XinZhao"],
["Yone","Twitch"],
["KSante","Rengar"],
["Trundle","Mordekaiser"],
["Trundle","Gwen"],
["Illaoi","Vayne"],
["Garen","Zac"],
["Olaf","Belveth"],
["Akali","Xerath"],
["Draven","Samira"],
["Renekton","MonkeyKing"],
["Qiyana","Lissandra"],
["Graves","Karthus"],
["Heimerdinger","Darius"],
["Lissandra","Ahri"],
["Ezreal","Jax"],
["Jayce","Malzahar"],
["Jinx","Zeri"],
["Sylas","Chogath"],
["Graves","Akali"],
["Twitch","Pyke"],
["Swain","AurelionSol"],
["Twitch","Tristana"],
["Qiyana","Ashe"],
["Ashe","Alistar"],
["Vladimir","TwistedFate"],
["Draven","Nautilus"],
["Irelia","Diana"],
["Pantheon","KSante"],
["Samira","Quinn"],
["Hecarim","Lux"],
["Gangplank","Gwen"],
["Sett","Akshan"],
["Cassiopeia","Vex"],
["Akali","Kled"],
["Illaoi","Gwen"],
["Malphite","Jax"],
["Vex","Sylas"],
["Yone","Poppy"],
["Rengar","Draven"],
["Cassiopeia","Ryze"],
["Aatrox","Vayne"],
["Mordekaiser","Riven"],
["Urgot","KSante"],
["Kindred","Rengar"],
["Malphite","Gnar"],
["Yone","Lissandra"],
["Yasuo","Urgot"],
["Irelia","Veigar"],
["Katarina","Cassiopeia"],
["Nocturne","Evelynn"],
["Akali","Riven"],
["Gragas","Vladimir"],
["Fiora","Teemo"],
["Draven","Lucian"],
["Zoe","Tristana"],
["Nocturne","Senna"],
["Riven","Singed"],
["Ekko","Hecarim"],
["Yone","Azir"],
["Twitch","Zeri"],
["Kayn","Darius"],
["LeeSin","Rengar"],
["Jax","Xerath"],
["Rumble","Katarina"],
["Teemo","Akshan"],
["Kalista","Twitch"],
["Tryndamere","Mordekaiser"],
["Darius","Gnar"],
["Tristana","Vayne"],
["XinZhao","Ashe"],
["Gnar","Kayle"],
["TahmKench","Zac"],
["Graves","Syndra"],
["Rengar","Qiyana"],
["Aatrox","Caitlyn"],
["Fizz","Qiyana"],
["Garen","Kayn"],
["Cassiopeia","Fiora"],
["Gangplank","Urgot"],
["Leblanc","Yone"],
["Illaoi","Akshan"],
["Trundle","Sett"],
["Zeri","Draven"],
["Yasuo","Ornn"],
["Gnar","KSante"],
["Caitlyn","Leblanc"],
["Pantheon","Olaf"],
["Nilah","Nautilus"],
["Renekton","Lillia"],
["Leblanc","Camille"],
["Poppy","Akshan"],
["Malphite","Gangplank"],
["Hecarim","Vayne"],
["Varus","Graves"],
["Yasuo","Lulu"],
["Kayn","Zoe"],
["Darius","Viego"],
["Garen","Camille"],
["Tryndamere","Cassiopeia"],
["Kled","Orianna"],
["Ornn","Kennen"],
["Gwen","Poppy"],
["Mordekaiser","Gnar"],
["Ezreal","Kindred"],
["Pantheon","Shaco"],
["Leblanc","Vayne"],
["Viktor","Sylas"],
["Qiyana","Xayah"],
["Samira","Diana"],
["Pantheon","MissFortune"],
["Yone","Caitlyn"],
["Gragas","Rumble"],
["Katarina","Gangplank"],
["Riven","Irelia"],
["Zeri","Qiyana"],
["Pantheon","JarvanIV"],
["Vayne","Fiora"],
["Karma","Jayce"],
["Sett","Akali"],
["Pantheon","Aatrox"],
["Fiora","Ahri"],
["Tryndamere","Poppy"],
["Khazix","Karthus"],
["Urgot","Sett"],
["Pantheon","Akshan"],
["Irelia","KSante"],
["Kayle","Sett"],
["Sylas","AurelionSol"],
["Darius","Renekton"],
["Irelia","Senna"],
["Yone","Anivia"],
["Kayn","MissFortune"],
["LeeSin","Pantheon"],
["Aatrox","Talon"],
["Belveth","Aatrox"],
["Vladimir","Tryndamere"],
["Jax","Akali"],
["Malphite","Ahri"],
["MissFortune","Kaisa"],
["Anivia","Vladimir"],
["Sylas","Veigar"],
["Caitlyn","Senna"],
["Yone","XinZhao"],
["Ekko","Sion"],
["Zed","Riven"],
["Kayn","Qiyana"],
["Vayne","Talon"],
["Draven","Yone"],
["Gwen","Kayle"],
["Jayce","Karthus"],
["Akali","Belveth"],
["KSante","Malphite"],
["Gnar","Darius"],
["Malphite","Ashe"],
["Xerath","Jayce"],
["KSante","Chogath"],
["Hecarim","Kaisa"],
["Jayce","Kayle"],
["Kayn","Evelynn"],
["Ezreal","Akshan"],
["Irelia","Gangplank"],
["Hecarim","Aphelios"],
["Gangplank","Nasus"],
["Yone","Rammus"],
["Taliyah","Ziggs"],
["Irelia","Swain"],
["Jax","Kindred"],
["KSante","Singed"],
["Riven","Varus"],
["Gnar","Vladimir"],
["Camille","DrMundo"],
["Varus","Belveth"],
["RekSai","Rengar"],
["Sylas","Ornn"],
["Warwick","Teemo"],
["Jayce","Ziggs"],
["Jax","Hecarim"],
["Yasuo","MonkeyKing"],
["Ahri","Cassiopeia"],
["Zeri","Lucian"],
["Jhin","Thresh"],
["Kaisa","Braum"],
["MasterYi","Nidalee"],
["Caitlyn","Ezreal"],
["KSante","TwistedFate"],
["Yasuo","Rakan"],
["Samira","Ziggs"],
["Gwen","Malphite"],
["Ezreal","Seraphine"],
["Akali","Kennen"],
["Kennen","Akali"],
["Kennen","Taliyah"],
["Viktor","Lux"],
["Draven","Yuumi"],
["Syndra","Fiora"],
["Yone","Leblanc"],
["Renekton","Pantheon"],
["Rumble","Syndra"],
["Rumble","Ornn"],
["Olaf","Kled"],
["Akshan","Jhin"],
["Jinx","Ashe"],
["Gnar","Hecarim"],
["Ornn","MonkeyKing"],
["Akali","Jhin"],
["Kassadin","AurelionSol"],
["Camille","Jax"],
["Yone","Singed"],
["Ashe","Jhin"],
["Senna","Tristana"],
["Fiora","Orianna"],
["Varus","Tristana"],
["Shaco","Hecarim"],
["Nocturne","Ekko"],
["Gragas","Fiora"],
["Gwen","Garen"],
["Tristana","Seraphine"],
["Rengar","Zed"],
["Riven","Maokai"],
["Camille","Kaisa"],
["Aatrox","Zed"],
["RekSai","Fiddlesticks"],
["Graves","Kaisa"],
["Garen","Riven"],
["Malphite","Talon"],
["Kaisa","Milio"],
["Rengar","Aphelios"],
["Jax","Cassiopeia"],
["Sett","Ornn"],
["RekSai","Zed"],
["Samira","Brand"],
["Viego","Gangplank"],
["Pantheon","Gnar"],
["Renekton","TahmKench"],
["Pantheon","Kindred"],
["Sett","Varus"],
["Gangplank","DrMundo"],
["LeeSin","Belveth"],
["Akali","Aatrox"],
["Malphite","Syndra"],
["Akali","Vayne"],
["Renekton","KSante"],
["Tryndamere","Azir"],
["Kassadin","Fizz"],
["Yasuo","Mordekaiser"],
["Aphelios","Lucian"],
["Akshan","Gnar"],
["Darius","Yone"],
["Yone","Zoe"],
["Khazix","Ahri"],
["Darius","Vladimir"],
["Samira","Lucian"],
["Darius","Graves"],
["Yasuo","Sylas"],
["Aatrox","Darius"],
["Yasuo","Twitch"],
["Jax","Orianna"],
["Xayah","Lux"],
["Yasuo","Taliyah"],
["Nocturne","Taliyah"],
["MasterYi","Malphite"],
["Vladimir","Gnar"],
["Kayn","Talon"],
["Jayce","Talon"],
["Irelia","MonkeyKing"],
["Kaisa","Kalista"],
["Ekko","Xerath"],
["Draven","Jhin"],
["Vayne","Karthus"],
["Trundle","Warwick"],
["Ekko","Varus"],
["Shaco","Samira"],
["Olaf","Maokai"],
["Rengar","Caitlyn"],
["Annie","Tristana"],
["Samira","Milio"],
["Kled","Olaf"],
["Jax","Zed"],
["Jinx","Lucian"],
["Gnar","Kled"],
["MonkeyKing","Sylas"],
["Ekko","Nidalee"],
["Zeri","Jhin"],
["Caitlyn","Thresh"],
["Kaisa","Ekko"],
["Darius","Syndra"],
["Garen","Malphite"],
["Malphite","Irelia"],
["Jhin","Jinx"],
["Mordekaiser","Ornn"],
["Olaf","Poppy"],
["Cassiopeia","Syndra"],
["Zed","AurelionSol"],
["Udyr","Hecarim"],
["Kennen","Karthus"],
["Aatrox","Tryndamere"],
["Malphite","Kalista"],
["Graves","Ekko"],
["Gwen","Aatrox"],
["Riven","Gangplank"],
["Yasuo","Teemo"],
["Olaf","Hecarim"],
["Zac","Poppy"],
["Graves","Draven"],
["Renekton","Swain"],
["Renekton","Kayn"],
["KSante","Irelia"],
["Malphite","Warwick"],
["Kassadin","Tristana"],
["Sett","Kayle"],
["Tryndamere","Hecarim"],
["Garen","Mordekaiser"],
["Pantheon","MasterYi"],
["Riven","Lucian"],
["Mordekaiser","Gangplank"],
["Nilah","Aphelios"],
["Draven","Ahri"],
["Zeri","Varus"],
["Aatrox","Fizz"],
["Garen","Syndra"],
["Pantheon","Qiyana"],
["Garen","Nasus"],
["LeeSin","Sett"],
["Draven","Jayce"],
["Kayn","Kindred"],
["JarvanIV","Zed"],
["Aphelios","Khazix"],
["Katarina","Kayn"],
["MasterYi","Jinx"],
["Evelynn","Kaisa"],
["Urgot","Varus"],
["Leblanc","Singed"],
["Varus","Maokai"],
["Ornn","Pantheon"],
["Ezreal","Lux"],
["Camille","Fiora"],
["Heimerdinger","Aatrox"],
["KSante","Tryndamere"],
["Rengar","Tristana"],
["Ekko","Kassadin"],
["MasterYi","Vi"],
["Pantheon","Tristana"],
["Tristana","Talon"],
["Tryndamere","Zed"],
["Yone","Illaoi"],
["Kled","Illaoi"],
["Vayne","Tryndamere"],
["Fiora","Gragas"],
["Gragas","Tryndamere"],
["Fiora","Karthus"],
["Kalista","Vayne"],
["Viego","Shaco"],
["Ornn","KSante"],
["Kaisa","Nunu"],
["Qiyana","Shaco"],
["Pantheon","Jayce"],
["Nasus","Olaf"],
["Rengar","Fizz"],
["Pantheon","Nasus"],
["Jax","Kled"],
["Irelia","Cassiopeia"],
["Camille","Trundle"],
["Ornn","Urgot"],
["Garen","Gwen"],
["Jayce","Poppy"],
["Graves","Yone"],
["RekSai","Syndra"],
["Darius","Orianna"],
["Yasuo","Cassiopeia"],
["Renekton","Fiora"],
["Kayn","Viktor"],
["Tristana","Gangplank"],
["Renekton","Olaf"],
["Lux","Syndra"],
["Leblanc","Gnar"],
["Yasuo","Anivia"],
["Kaisa","Lucian"],
["Riven","Kled"],
["Talon","Viktor"],
["Singed","Gwen"],
["Swain","Sylas"],
["Swain","Riven"],
["Heimerdinger","Gnar"],
["Twitch","TwistedFate"],
["Hecarim","Sylas"],
["Warwick","Kennen"],
["Tristana","Ezreal"],
["Zed","Akali"],
["LeeSin","Renekton"],
["Syndra","Ezreal"],
["Singed","KSante"],
["KSante","Gragas"],
["Irelia","Vayne"],
["Camille","Olaf"],
["Vayne","Hecarim"],
["Yasuo","Pyke"],
["Sylas","Ekko"],
["Riven","Leblanc"],
["Velkoz","Ashe"],
["Shyvana","Yone"],
["Ashe","Lucian"],
["Irelia","Talon"],
["Hecarim","Belveth"],
["Mordekaiser","MonkeyKing"],
["Urgot","Jax"],
["MonkeyKing","Kaisa"],
["Illaoi","Gnar"],
["Zeri","JarvanIV"],
["Qiyana","LeeSin"],
["Zac","Aatrox"],
["Zed","Annie"],
["Khazix","Xayah"],
["Vladimir","Taliyah"],
["Riven","LeeSin"],
["Riven","JarvanIV"],
["Malzahar","Yasuo"],
["Kalista","Tristana"],
["Malphite","Urgot"],
["Gangplank","Tryndamere"],
["Heimerdinger","Leblanc"],
["Jax","Nasus"],
["Camille","Yorick"],
["Neeko","Orianna"],
["Volibear","Irelia"],
["Irelia","Twitch"],
["Camille","Fizz"],
["Pantheon","Riven"],
["RekSai","Quinn"],
["Fiora","Rammus"],
["Garen","Ezreal"],
["Sett","Gwen"],
["Ashe","Nami"],
["Singed","Jayce"],
["Diana","Katarina"],
["Fizz","Yasuo"],
["KSante","Malzahar"],
["Vayne","Ziggs"],
["Urgot","Yasuo"],
["Draven","Karthus"],
["Poppy","Darius"],
["Sylas","Hecarim"],
["Nocturne","Yasuo"],
["XinZhao","Kaisa"],
["Riven","Ashe"],
["Rengar","MissFortune"],
["Ornn","Poppy"],
["Vladimir","Irelia"],
["Akshan","Kled"],
["Shyvana","Fiora"],
["Rengar","Kindred"],
["Viktor","Zed"],
["Tristana","Katarina"],
["Gwen","Singed"],
["Teemo","Darius"],
["Garen","Olaf"],
["Kennen","Olaf"],
["Olaf","Sejuani"],
["Zeri","Alistar"],
["Garen","Rumble"],
["Katarina","Camille"],
["Gragas","Camille"],
["Kalista","Varus"],
["Qiyana","Gragas"],
["Udyr","Gangplank"],
["Yone","Teemo"],
["Urgot","Illaoi"],
["Hecarim","Syndra"],
["Ahri","Ekko"],
["Malphite","Chogath"],
["Teemo","Gnar"],
["Yasuo","Diana"],
["Fiora","Rengar"],
["Karthus","Rengar"],
["Akali","Ahri"],
["Akali","Leblanc"],
["Udyr","Sion"],
["Gnar","Ornn"],
["Renekton","Poppy"],
["Samira","Karthus"],
["Sylas","Nasus"],
["Brand","Katarina"],
["Senna","Yasuo"],
["Zed","Kassadin"],
["Kled","Darius"],
["Singed","Garen"],
["Teemo","KSante"],
["Ashe","Sylas"],
["Illaoi","Yone"],
["Jhin","Samira"],
["LeeSin","Varus"],
["Rumble","Viktor"],
["Darius","Yorick"],
["Jax","Warwick"],
["Udyr","Karthus"],
["Olaf","Sion"],
["Taliyah","Jax"],
["Warwick","Camille"],
["Yasuo","Draven"],
["Renekton","Mordekaiser"],
["Gangplank","Gragas"],
["LeeSin","Qiyana"],
["Katarina","Xerath"],
["Riven","RekSai"],
["Sion","Jax"],
["Sylas","Sett"],
["Garen","Tryndamere"],
["Sett","TahmKench"],
["Fizz","Vladimir"],
["Pantheon","Mordekaiser"],
["Hecarim","Nautilus"],
["Ahri","Kassadin"],
["Renekton","Tristana"],
["Jax","Yorick"],
["LeeSin","MasterYi"],
["Garen","Yasuo"],
["Riven","Nilah"],
["Akali","Cassiopeia"],
["Gnar","Mordekaiser"],
["Vi","Taliyah"],
["Cassiopeia","JarvanIV"],
["Gwen","Yasuo"],
["Twitch","Jhin"],
["Rumble","Akali"],
["Fiora","Darius"],
["Cassiopeia","Samira"],
["Pantheon","Warwick"],
["Xerath","Syndra"],
["Warwick","Jayce"],
["Yone","Vex"],
["Talon","Kaisa"],
["Zed","Lucian"],
["Pantheon","Sion"],
["Chogath","Sett"],
["Cassiopeia","Lucian"],
["Tryndamere","Kled"],
["Renekton","DrMundo"],
["Qiyana","Seraphine"],
["Viego","Maokai"],
["Sett","Illaoi"],
["Darius","Naafiri"],
["Kled","Rumble"],
["Cassiopeia","Naafiri"],
["Swain","Tryndamere"],
["Rengar","Taliyah"],
["Tristana","Taliyah"],
["Akali","Sett"],
["Azir","Gwen"],
["Jayce","Orianna"],
["XinZhao","Draven"],
["Talon","Annie"],
["Ezreal","Pantheon"],
["Aatrox","Cassiopeia"],
["Riven","Talon"],
["TahmKench","Darius"],
["KSante","Ahri"],
["Cassiopeia","Malphite"],
["Rumble","Jax"],
["Irelia","Ornn"],
["LeeSin","Diana"],
["Cassiopeia","Nidalee"],
["Renekton","Sion"],
["Leblanc","Quinn"],
["Singed","Yasuo"],
["Darius","Cassiopeia"],
["MonkeyKing","Syndra"],
["Ezreal","Riven"],
["Darius","Karma"],
["Renekton","Maokai"],
["Darius","Leblanc"],
["Fiora","Trundle"],
["Nocturne","Syndra"],
["Gragas","Riven"],
["Ekko","Karma"],
["Vex","Leblanc"],
["Yasuo","Milio"],
["Evelynn","Ekko"],
["Vex","Qiyana"],
["Qiyana","Ekko"],
["Yone","Ryze"],
["Khazix","Soraka"],
["Ekko","Nunu"],
["Zeri","Kaisa"],
["XinZhao","Yone"],
["Belveth","Rumble"],
["Talon","Orianna"],
["Kennen","Yone"],
["Aphelios","Varus"],
["Kayle","Aatrox"],
["Akshan","Yasuo"],
["Yasuo","Qiyana"],
["Nilah","Vayne"],
["Kindred","Kaisa"],
["Hecarim","Ahri"],
["RekSai","Xayah"],
["Graves","Khazix"],
["Ezreal","Leona"],
["Teemo","Malphite"],
["Rengar","Zoe"],
["Belveth","Xerath"],
["Garen","Udyr"],
["Vladimir","Malzahar"],
["Gwen","Pantheon"],
["Viego","Irelia"],
["Ezreal","Qiyana"],
["Olaf","Nidalee"],
["Akshan","Graves"],
["Mordekaiser","RekSai"],
["JarvanIV","Nunu"],
["Lux","Talon"],
["Camille","Ornn"],
["Riven","Tristana"],
["Rengar","Khazix"],
["Graves","Fiora"],
["Evelynn","Samira"],
["Aphelios","Jinx"],
["Zeri","Blitzcrank"],
["Nasus","Shen"],
["Kled","Renekton"],
["Renekton","Nasus"],
["Ahri","Talon"],
["Belveth","Taliyah"],
["Gwen","Xerath"],
["Vex","Yasuo"],
["Karthus","Zed"],
["Udyr","Belveth"],
["Vayne","Urgot"],
["Teemo","Gwen"],
["Ekko","Rengar"],
["Caitlyn","Blitzcrank"],
["Akali","Yasuo"],
["Urgot","Rumble"],
["Gnar","Sion"],
["Xerath","Ahri"],
["Garen","Rengar"],
["Zed","Syndra"],
["Gwen","DrMundo"],
["Varus","Leblanc"],
["Kaisa","Heimerdinger"],
["Fiora","Kled"],
["Fiora","Olaf"],
["Ornn","Illaoi"],
["Tristana","Varus"],
["JarvanIV","Ekko"],
["Gwen","Nasus"],
["Olaf","Udyr"],
["Riven","Trundle"],
["KSante","Darius"],
["Garen","Chogath"],
["Kled","Gwen"],
["Volibear","Mordekaiser"],
["Irelia","Neeko"],
["RekSai","Yasuo"],
["Kindred","Jinx"],
["KSante","TahmKench"],
["Zeri","Nami"],
["Rumble","Ahri"],
["Riven","Viktor"],
["Yorick","Darius"],
["Viego","Evelynn"],
["Chogath","Malphite"],
["Ekko","Cassiopeia"],
["Ezreal","Bard"],
["Belveth","Yone"],
["Irelia","Yone"],
["TahmKench","Fiora"],
["Khazix","Kayn"],
["Vayne","Rumble"],
["Hecarim","Xerath"],
["Vayne","Taliyah"],
["Xerath","Viktor"],
["Velkoz","Nautilus"],
["Khazix","MasterYi"],
["Zeri","Akali"],
["Ahri","Sylas"],
["KSante","JarvanIV"],
["Jayce","Garen"],
["Olaf","Ornn"],
["Lissandra","Talon"],
["Yone","Sylas"],
["MasterYi","Viego"],
["Yone","Jayce"],
["Ezreal","Renekton"],
["Nasus","Gwen"],
["Jayce","Xerath"],
["Khazix","Diana"],
["Viego","Kayn"],
["Syndra","Quinn"],
["Rumble","Urgot"],
["Rumble","TahmKench"],
["Zed","Yone"],
["MasterYi","Yone"],
["MonkeyKing","Gwen"],
["Aatrox","Graves"],
["Gangplank","Kaisa"],
["Syndra","Azir"],
["Sylas","Graves"],
["Fiora","Yasuo"],
["Fiora","TwistedFate"],
["LeeSin","Shaco"],
["Graves","LeeSin"],
["Teemo","Kennen"],
["Nocturne","LeeSin"],
["Yone","Kayle"],
["Poppy","Aatrox"],
["Graves","Sylas"],
["KSante","Garen"],
["KSante","Akali"],
["Syndra","Khazix"],
["Yorick","Illaoi"],
["Riven","Mordekaiser"],
["Talon","Qiyana"],
["MonkeyKing","Jax"],
["Nasus","Karthus"],
["Katarina","Ahri"],
["Urgot","Gnar"],
["Akali","Yorick"],
["Ezreal","Veigar"],
["Tristana","Graves"],
["Katarina","Vladimir"],
["Samira","Fiddlesticks"],
["Olaf","Vayne"],
["Fiddlesticks","Karthus"],
["Vayne","Kennen"],
["Poppy","Shyvana"],
["Fiddlesticks","Aphelios"],
["KSante","MonkeyKing"],
["Kassadin","Akali"],
["Akali","LeeSin"],
["Aatrox","Riven"],
["Fizz","Leblanc"],
["Vayne","Teemo"],
["Talon","Chogath"],
["Sylas","Renekton"],
["Vladimir","Fiora"],
["Fiora","Garen"],
["Tryndamere","KSante"],
["Gragas","Talon"],
["Jayce","AurelionSol"],
["TahmKench","Gnar"],
["Mordekaiser","Irelia"],
["Akali","Ornn"],
["Kled","Yasuo"],
["Jayce","Anivia"],
["Singed","Chogath"],
["Tristana","Kalista"],
["Samira","Thresh"],
["Poppy","JarvanIV"],
["Vayne","Kaisa"],
["Pantheon","Veigar"],
["MissFortune","Tristana"],
["Draven","Sivir"],
["Illaoi","Rumble"],
["Azir","Qiyana"],
["Kaisa","Kindred"],
["Urgot","Camille"],
["Garen","Volibear"],
["Kayn","Fizz"],
["Kennen","Rumble"],
["Kled","Singed"],
["Mordekaiser","Trundle"],
["Fiora","Volibear"],
["Kennen","Quinn"],
["Poppy","Talon"],
["Illaoi","Mordekaiser"],
["Olaf","Ivern"],
["Kaisa","Kennen"],
["Shaco","Diana"],
["Irelia","Hecarim"],
["Samira","Varus"],
["Tristana","Renekton"],
["Teemo","Renekton"],
["Khazix","Kindred"],
["Rumble","Taliyah"],
["Kled","Mordekaiser"],
["Illaoi","Ekko"],
["Kled","Irelia"],
["Draven","Nocturne"],
["Shyvana","Irelia"],
["Cassiopeia","Teemo"],
["Akshan","Naafiri"],
["Yone","Zed"],
["JarvanIV","Qiyana"],
["Mordekaiser","Yasuo"],
["Syndra","Draven"],
["Nocturne","Kayn"],
["Kayn","Shaco"],
["Kaisa","Graves"],
["LeeSin","Tristana"],
["Rengar","Illaoi"],
["Darius","Jax"],
["Swain","Ezreal"],
["Darius","Mordekaiser"],
["Kaisa","Aphelios"],
["Irelia","Khazix"],
["Olaf","TahmKench"],
["Trundle","Karthus"],
["Volibear","Shyvana"],
["Vex","Ahri"],
["Udyr","Jax"],
["Gnar","DrMundo"],
["Kaisa","Quinn"],
["Evelynn","Kled"],
["Olaf","Akali"],
["Zed","Samira"],
["Akshan","Diana"],
["MasterYi","Samira"],
["Garen","Kennen"],
["Vladimir","Gwen"],
["Renekton","Malphite"],
["Yone","Pantheon"],
["Kaisa","Orianna"],
["Caitlyn","Jinx"],
["Aatrox","Camille"],
["Gwen","Rengar"],
["Darius","Kaisa"],
["Sylas","Jax"],
["Neeko","Lissandra"],
["Fiora","Shen"],
["Viego","Kaisa"],
["Kayn","Irelia"],
["Nasus","Sylas"],
["Taliyah","Caitlyn"],
["Tryndamere","Kaisa"],
["Yasuo","Sion"],
["Gnar","Camille"],
["Jhin","Senna"],
["Jax","Khazix"],
["Diana","AurelionSol"],
["Zed","Tristana"],
["Akali","Nidalee"],
["Kindred","Lucian"],
["Lux","Senna"],
["Yone","Hecarim"],
["Urgot","Olaf"],
["Akshan","Pantheon"],
["Gwen","Jayce"],
["Xayah","Senna"],
["Viego","Yasuo"],
["Viego","Kindred"],
["Hecarim","Graves"],
["Tryndamere","Gragas"],
["Yasuo","Trundle"],
["Ornn","Jax"],
["KSante","Graves"],
["TahmKench","Yone"],
["Diana","Orianna"],
["Riven","Warwick"],
["MasterYi","Gangplank"],
["Volibear","Talon"],
["Yone","Taliyah"],
["Ezreal","Blitzcrank"],
["Katarina","Taliyah"],
["Belveth","Lillia"],
["Zed","Anivia"],
["Vayne","Caitlyn"],
["Sylas","RekSai"],
["Twitch","Lux"],
["Yone","Samira"],
["Kayn","Draven"],
["Draven","Fiora"],
["Akali","Malphite"],
["Jax","Illaoi"],
["Jayce","Singed"],
["Ahri","Lux"],
["Jayce","Viktor"],
["Akali","Zoe"],
["Camille","Rengar"],
["Ezreal","Belveth"],
["KSante","Viktor"],
["Vladimir","Hecarim"],
["Olaf","Tryndamere"],
["Senna","Seraphine"],
["Olaf","Zed"],
["Jayce","Lillia"],
["Illaoi","Urgot"],
["Gwen","Leblanc"],
["KogMaw","Ashe"],
["Viego","Darius"],
["Zeri","Jinx"],
["Sylas","Rumble"],
["Samira","Sona"],
["Nasus","Vayne"],
["Camille","Shen"],
["Pantheon","Ezreal"],
["Rumble","Zed"],
["Yone","Malphite"],
["Kindred","Gragas"],
["Riven","Ahri"],
["TahmKench","Garen"],
["Teemo","Gragas"],
["Talon","Nidalee"],
["Rumble","Trundle"],
["Aatrox","Teemo"],
["Kayn","Yone"],
["Malphite","Mordekaiser"],
["Gragas","Kayle"],
["Ashe","Swain"],
["Warwick","Gwen"],
["Fiddlesticks","Katarina"],
["Vayne","Leblanc"],
["Sett","Singed"],
["Darius","Zed"],
["Rumble","Fiora"],
["Akali","Annie"],
["LeeSin","Kaisa"],
["Kayn","Graves"],
["Akali","Lux"],
["Ekko","Kled"],
["Kindred","Kalista"],
["Talon","Varus"],
["Yasuo","Vayne"],
["Olaf","Yone"],
["Jax","Udyr"],
["Graves","Jayce"],
["Camille","Chogath"],
["Vayne","Ekko"],
["Jax","Renekton"],
["MasterYi","Talon"],
["Twitch","Syndra"],
["Warwick","Pantheon"],
["Poppy","Singed"],
["Xayah","Khazix"],
["Kaisa","Katarina"],
["Irelia","Kled"],
["Yone","Kassadin"],
["Viego","Nidalee"],
["Jinx","Rakan"],
["Azir","Xerath"],
["MonkeyKing","Chogath"],
["Warwick","Aatrox"],
["Shaco","Twitch"],
["Garen","Jayce"],
["Aphelios","Pyke"],
["Kayle","Jax"],
["Sion","Fiora"],
["Zed","Yasuo"],
["Azir","Sylas"],
["Aatrox","Kayle"],
["Rengar","LeeSin"],
["TahmKench","Gangplank"],
["Renekton","Kled"],
["Udyr","Jayce"],
["Jayce","Ornn"],
["Akali","Viktor"],
["Yasuo","Gnar"],
["Singed","Kled"],
["Warwick","Kayle"],
["Twitch","Taliyah"],
["Chogath","Riven"],
["Irelia","Brand"],
["Viego","Vex"],
["Azir","Yone"],
["Ekko","Azir"],
["Ornn","Shen"],
["Samira","Rakan"],
["Varus","Olaf"],
["KSante","Pantheon"],
["Cassiopeia","Sylas"],
["Swain","Xayah"],
["Gwen","Draven"],
["Graves","Nidalee"],
["MasterYi","Jayce"],
["Katarina","Leblanc"],
["Irelia","Quinn"],
["Tristana","Draven"],
["Riven","Gwen"],
["Lillia","Hecarim"],
["Vayne","Karma"],
["Poppy","Gangplank"],
["Maokai","Nasus"],
["Anivia","Orianna"],
["Kindred","Taliyah"],
["Draven","Nami"],
["Nasus","Tristana"],
["Vayne","Yone"],
["Darius","Neeko"],
["Tristana","Kaisa"],
["Illaoi","Zed"],
["Jax","Darius"],
["Leblanc","Talon"],
["Viego","Gragas"],
["Kaisa","Soraka"],
["MonkeyKing","Kayn"],
["Fiora","Akali"],
["Yasuo","Janna"],
["Diana","Irelia"],
["Zed","KSante"],
["Aatrox","Kayn"],
["Rengar","Twitch"],
["Ekko","Darius"],
["Singed","Shen"],
["Pantheon","Gragas"],
["Yasuo","Khazix"],
["Malphite","Sett"],
["Sion","Jayce"],
["Sett","Kayn"],
["Kaisa","Hecarim"],
["Draven","Ashe"],
["Xerath","Jhin"],
["Ashe","Kalista"],
["Mordekaiser","Sion"],
["Volibear","Kayn"],
["MasterYi","Akshan"],
["Nasus","Katarina"],
["Irelia","Xerath"],
["Tryndamere","Naafiri"],
["Evelynn","JarvanIV"],
["Ezreal","Leblanc"],
["Talon","KSante"],
["Jhin","Zeri"],
["Vladimir","Graves"],
["Riven","Orianna"],
["Samira","Tristana"],
["Irelia","Kayle"],
["Gwen","Ornn"],
["Yone","DrMundo"],
["Cassiopeia","Xayah"],
["Zed","Rengar"],
["Udyr","Camille"],
["Talon","Fiora"],
["Yasuo","Tryndamere"],
["Tristana","Zeri"],
["Tryndamere","Yorick"],
["Shaco","Ekko"],
["MonkeyKing","Akshan"],
["Renekton","Qiyana"],
["Darius","Tryndamere"],
["Xayah","Karma"],
["Qiyana","Annie"],
["Ezreal","Ahri"],
["Ezreal","Jinx"],
["Ashe","Rakan"],
["Pantheon","Rengar"],
["Yorick","Sett"],
["Ziggs","Ezreal"],
["Leblanc","Taliyah"],
["Talon","AurelionSol"],
["Akshan","Camille"],
["Qiyana","Ziggs"],
["Tryndamere","DrMundo"],
["Kayn","Ezreal"],
["Darius","Katarina"],
["Kled","Nasus"],
["Gangplank","Rumble"],
["Ashe","Twitch"],
["Yasuo","Katarina"],
["Tryndamere","Akshan"],
["RekSai","Aphelios"],
["Urgot","Darius"],
["Yorick","Aatrox"],
["Rumble","Varus"],
["Jax","Rengar"],
["MasterYi","Udyr"],
["Shaco","Yone"],
["KSante","Rumble"],
["Aatrox","KSante"],
["Karma","Aatrox"],
["Khazix","Fiddlesticks"],
["Azir","Akali"],
["Tristana","Azir"],
["Taliyah","Akali"],
["Urgot","Garen"],
["Ashe","Zed"],
["Darius","Vex"],
["Varus","Aphelios"],
["Irelia","Gnar"],
["Quinn","Jayce"],
["Twitch","Lucian"],
["Kled","Graves"],
["Twitch","Olaf"],
["Ekko","Taliyah"],
["Akshan","Tristana"],
["Gangplank","Darius"],
["Riven","Seraphine"],
["Chogath","Talon"],
["Yone","Tryndamere"],
["Tryndamere","Pantheon"],
["Graves","Viktor"],
["Kennen","Jax"],
["Tryndamere","Vladimir"],
["Irelia","Teemo"],
["Kaisa","Yone"],
["Vex","Tristana"],
["Tryndamere","Ezreal"],
["Gwen","Swain"],
["MonkeyKing","Malphite"],
["Ezreal","Sona"],
["Khazix","Katarina"],
["Urgot","Ekko"],
["Vladimir","Camille"],
["Gwen","Syndra"],
["Garen","Kayle"],
["Kennen","Ashe"],
["Kayn","Jax"],
["Ezreal","Neeko"],
["Aatrox","Kaisa"],
["Jax","MasterYi"],
["Nasus","Poppy"],
["Sylas","Talon"],
["Nasus","Zed"],
["Akali","Twitch"],
["Kassadin","Ekko"],
["RekSai","Taliyah"],
["Talon","Ahri"],
["Kaisa","Gwen"],
["JarvanIV","Karthus"],
["Ezreal","Talon"],
["Yone","Veigar"],
["Olaf","Shyvana"],
["Kassadin","Karthus"],
["Shen","Kayle"],
["Quinn","Yone"],
["Akshan","Akali"],
["Hecarim","Malzahar"],
["Jax","Viego"],
["Jinx","MissFortune"],
["Ahri","Vladimir"],
["Irelia","Katarina"],
["KSante","Volibear"],
["Olaf","Viego"],
["Yorick","Singed"],
["Talon","Vayne"],
["Darius","Fiora"],
["Jax","Syndra"],
["KSante","Cassiopeia"],
["Singed","Fiora"],
["Kaisa","Karma"],
["KSante","Syndra"],
["KSante","Orianna"],
["Darius","Diana"],
["Azir","Leblanc"],
["Diana","Ziggs"],
["Graves","Gragas"],
["Sett","Irelia"],
["Warwick","Kled"],
["Nocturne","Lillia"],
["Aphelios","Xerath"],
["Yorick","Jayce"],
["Darius","Garen"],
["Akshan","Taliyah"],
["Tristana","Milio"],
["Hecarim","Neeko"],
["RekSai","Cassiopeia"],
["Gwen","Taliyah"],
["Vayne","Fiddlesticks"],
["Kayn","Akshan"],
["Jinx","Aphelios"],
["Kindred","Ahri"],
["Gnar","Trundle"],
["Talon","Veigar"],
["Talon","Vladimir"],
["MasterYi","Senna"],
["Olaf","Swain"],
["Samira","Leona"],
["Ornn","Olaf"],
["Shen","Jax"],
["Poppy","Gwen"],
["Ezreal","RekSai"],
["Pantheon","Garen"],
["Leblanc","AurelionSol"],
["Sion","Veigar"],
["Volibear","DrMundo"],
["Riven","Draven"],
["Poppy","Renekton"],
["AurelionSol","Azir"],
["Kaisa","Vladimir"],
["Velkoz","Sylas"],
["Kaisa","Maokai"],
["Hecarim","Fiora"],
["Xayah","Ezreal"],
["Chogath","Volibear"],
["Renekton","Neeko"],
["Tryndamere","Yone"],
["Quinn","Vayne"],
["Tristana","Yone"],
["Kayle","Darius"],
["Udyr","Yone"],
["Varus","Kled"],
["Cassiopeia","Taliyah"],
["RekSai","LeeSin"],
["Gangplank","Sylas"],
["Ornn","Irelia"],
["Garen","Pantheon"],
["Jayce","Tryndamere"],
["Belveth","Renekton"],
["Tryndamere","Kayn"],
["Rumble","Kayn"],
["Jayce","Naafiri"],
["Kaisa","Syndra"],
["Garen","Katarina"],
["Fizz","Akali"],
["Kassadin","Gwen"],
["Poppy","Taliyah"],
["Twitch","Nautilus"],
["Xayah","Sylas"],
["Fizz","Taliyah"],
["Kaisa","Xerath"],
["Camille","Ahri"],
["Vladimir","Riven"],
["Poppy","Khazix"],
["Katarina","Ashe"],
["Renekton","Nocturne"],
["Zeri","Milio"],
["Illaoi","Karthus"],
["Akali","Orianna"],
["Taliyah","Ahri"],
["Yorick","Garen"],
["Heimerdinger","Gangplank"],
["Kayle","Vladimir"],
["JarvanIV","MissFortune"],
["MissFortune","Aphelios"],
["Rumble","Kled"],
["RekSai","Caitlyn"],
["Yorick","Riven"],
["Zeri","Pyke"],
["Jax","Garen"],
["Vayne","Sion"],
["Kennen","Fiora"],
["Gangplank","Mordekaiser"],
["Kalista","Milio"],
["Talon","Caitlyn"],
["Swain","Ashe"],
["Zed","Xayah"],
["Yasuo","Riven"],
["Akali","Shaco"],
["Urgot","Volibear"],
["Illaoi","Camille"],
["Samira","Pantheon"],
["Zac","Tryndamere"],
["Aatrox","Warwick"],
["Gangplank","Camille"],
["Swain","Taliyah"],
["Urgot","Poppy"],
["Viego","Lux"],
["Belveth","JarvanIV"],
["Leblanc","Ahri"],
["Akshan","Ornn"],
["Jayce","Quinn"],
["Ornn","Gwen"],
["Leblanc","Ekko"],
["Hecarim","Ashe"],
["Tristana","Janna"],
["Pantheon","Orianna"],
["TwistedFate","Zed"],
["Hecarim","Kindred"],
["Poppy","Fizz"],
["KSante","Camille"],
["Sion","Camille"],
["Vayne","Olaf"],
["Neeko","Warwick"],
["Yasuo","Sett"],
["Ashe","Kled"],
["Vayne","Ashe"],
["Graves","Sion"],
["LeeSin","JarvanIV"],
["Syndra","Zed"],
["Katarina","Syndra"],
["Tristana","TwistedFate"],
["Sylas","Cassiopeia"],
["Sylas","Zed"],
["Gragas","Rengar"],
["Zac","Fiora"],
["Syndra","Gragas"],
["JarvanIV","Syndra"],
["Trundle","Darius"],
["Xerath","Karthus"],
["Yasuo","Xayah"],
["Diana","Taliyah"],
["LeeSin","Ekko"],
["Kennen","Riven"],
["Volibear","Gragas"],
["Akshan","Rumble"],
["Sett","Darius"],
["Yasuo","Zoe"],
["Rumble","Graves"],
["Ezreal","Khazix"],
["Viego","Hecarim"],
["Kalista","Ashe"],
["Lux","Qiyana"],
["Ornn","Malphite"],
["Katarina","Yasuo"],
["Samira","Jayce"],
["Ahri","Azir"],
["Samira","Camille"],
["Akali","Swain"],
["Tryndamere","Karthus"],
["Taliyah","Graves"],
["Gnar","Irelia"],
["Ornn","Akali"],
["Quinn","Illaoi"],
["Fiora","Cassiopeia"],
["Olaf","Graves"],
["Katarina","Sylas"],
["Sylas","LeeSin"],
["Sylas","Lux"],
["Akali","Sylas"],
["Anivia","Katarina"],
["Kaisa","Blitzcrank"],
["Riven","Fiora"],
["Fiddlesticks","Jhin"],
["Camille","Jinx"],
["Riven","AurelionSol"],
["Rengar","Lux"],
["Khazix","Sivir"],
["Rengar","Talon"],
["Aatrox","Gangplank"],
["Xayah","Galio"],
["Rumble","Talon"],
["Garen","Heimerdinger"],
["Urgot","Jayce"],
["KSante","Sylas"],
["Fiora","Singed"],
["Ekko","Ahri"],
["Kled","Kayle"],
["Kindred","Khazix"],
["TahmKench","Irelia"],
["Gragas","Jayce"],
["Camille","Singed"],
["Urgot","Nasus"],
["Leblanc","Sion"],
["Jayce","Kassadin"],
["Tristana","Sion"],
["Fizz","Azir"],
["Camille","Nidalee"],
["Lucian","Pyke"],
["Khazix","Lux"],
["MissFortune","Seraphine"],
["Ekko","Caitlyn"],
["Zac","Kaisa"],
["Nilah","Draven"],
["Xerath","Anivia"],
["Gwen","Irelia"],
["Fiora","Illaoi"],
["Gwen","Akshan"],
["Sylas","Varus"],
["Lissandra","Qiyana"],
["Ekko","Ezreal"],
["Graves","Gangplank"],
["Senna","Vayne"],
["Volibear","Jayce"],
["Irelia","Ahri"],
["Jayce","Lux"],
["Khazix","Ashe"],
["Kalista","Alistar"],
["Yone","Trundle"],
["Darius","Volibear"],
["Leblanc","Akali"],
["Kassadin","Yone"],
["Aatrox","Garen"],
["Diana","Karthus"],
["Urgot","Irelia"],
["Ezreal","Ziggs"],
["Diana","Xayah"],
["Diana","Aatrox"],
["Volibear","Olaf"],
["Irelia","Malzahar"],
["Garen","Swain"],
["Gragas","Nasus"],
["Graves","Yasuo"],
["JarvanIV","Xerath"],
["Fiddlesticks","Irelia"],
["Rengar","Jinx"],
["Gnar","Ahri"],
["Aatrox","Naafiri"],
["Kayn","Hecarim"],
["JarvanIV","Graves"],
["Ornn","Akshan"],
["Twitch","Neeko"],
["Sylas","Taliyah"],
["Zed","MasterYi"],
["Xayah","Aphelios"],
["Camille","Akshan"],
["Yone","Brand"],
["Talon","Ziggs"],
["Urgot","Trundle"],
["Jax","Aphelios"],
["Jax","Azir"],
["Darius","Quinn"],
["Kled","Malphite"],
["Yasuo","Gragas"],
["Darius","Maokai"],
["Jayce","Qiyana"],
["Kennen","Gragas"],
["Nasus","KSante"],
["Varus","Karma"],
["Ornn","Karthus"],
["Malphite","Jayce"],
["Shen","Fiora"],
["Gwen","Talon"],
["Samira","Viego"],
["Ahri","Katarina"],
["Graves","MasterYi"],
["Kaisa","Seraphine"],
["Kalista","Rakan"],
["Kled","Cassiopeia"],
["Xerath","Taliyah"],
["Zed","Jhin"],
["Kaisa","Caitlyn"],
["Quinn","Aatrox"],
["Garen","Veigar"],
["Twitch","Zed"],
["Sylas","Aatrox"],
["Khazix","Yasuo"],
["Tryndamere","Vayne"],
["Varus","Gragas"],
["Teemo","Quinn"],
["Camille","Ezreal"],
["Neeko","Zed"],
["Nocturne","Naafiri"],
["Akali","Viego"],
["Viego","Tristana"],
["KSante","Urgot"],
["Pantheon","Sejuani"],
["Azir","Syndra"],
["Khazix","Tristana"],
["Rumble","Udyr"],
["Gwen","Neeko"],
["Vayne","Kayle"],
["Camille","Ekko"],
["Cassiopeia","Chogath"],
["Garen","Gangplank"],
["Samira","Rengar"],
["Akshan","Kayn"],
["Pantheon","Kennen"],
["Evelynn","Fiora"],
["Leblanc","Jayce"],
["Gwen","Khazix"],
["Riven","Urgot"],
["Ashe","MissFortune"],
["Kayle","Irelia"],
["Illaoi","Chogath"],
["Warwick","Nidalee"],
["Kayn","Varus"],
["Ashe","Taliyah"],
["Garen","Warwick"],
["Twitch","Sivir"],
["Fiora","Ezreal"],
["Gwen","Varus"],
["Veigar","Taliyah"],
["Gwen","Fiddlesticks"],
["Vladimir","Diana"],
["Sett","Riven"],
["Riven","Zac"],
["Xayah","Rakan"],
["Diana","Senna"],
["Syndra","Xerath"],
["Zed","Ryze"],
["Camille","Quinn"],
["Zac","Darius"],
["Sylas","Twitch"],
["Taliyah","JarvanIV"],
["Urgot","Tryndamere"],
["Qiyana","Yone"],
["MasterYi","Blitzcrank"],
["Swain","Yone"],
["Vladimir","Garen"],
["Cassiopeia","Volibear"],
["Yone","Jhin"],
["Xayah","Katarina"],
["Ornn","Volibear"],
["Darius","KSante"],
["RekSai","Ashe"],
["Draven","Kaisa"],
["Talon","Yasuo"],
["Vladimir","Aatrox"],
["Vayne","Orianna"],
["Yone","Aphelios"],
["Kennen","Syndra"],
["Volibear","LeeSin"],
["Fiddlesticks","Xerath"],
["Akali","Jinx"],
["Fiora","Yone"],
["Yone","Maokai"],
["Nocturne","Ashe"],
["Udyr","Gwen"],
["KSante","Karthus"],
["Varus","Thresh"],
["Cassiopeia","Yorick"],
["Xayah","Talon"],
["Kindred","Lux"],
["Irelia","Viego"],
["Viego","Katarina"],
["Ahri","Syndra"],
["Akali","Fiddlesticks"],
["Evelynn","MissFortune"],
["Fizz","Pantheon"],
["JarvanIV","Jhin"],
["Yone","Lux"],
["Graves","Zed"],
["Tristana","Yasuo"],
["Tryndamere","Gangplank"],
["Rumble","Khazix"],
["Katarina","Rumble"],
["Irelia","Graves"],
["Varus","Gangplank"],
["Gangplank","Trundle"],
["Gnar","Renekton"],
["Senna","Samira"],
["Katarina","Naafiri"],
["Kayle","Fizz"],
["Riven","Jayce"],
["Veigar","Jayce"],
["Syndra","Viktor"],
["Katarina","Gwen"],
["Zeri","Ashe"],
["Tristana","Zed"],
["Hecarim","Yone"],
["Jayce","Aatrox"],
["Khazix","Neeko"],
["Jinx","Kaisa"],
["Talon","Taliyah"],
["Poppy","Camille"],
["Ezreal","Twitch"],
["Samira","Senna"],
["Ashe","Jayce"],
["Akali","Caitlyn"],
["Samira","Khazix"],
["Riven","Khazix"],
["Kennen","Camille"],
["Nocturne","Twitch"],
["Ashe","LeeSin"],
["Trundle","Khazix"],
["Sett","MasterYi"],
["Darius","MonkeyKing"],
["Syndra","Vladimir"],
["Irelia","Shen"],
["Irelia","Akshan"],
["Akshan","Teemo"],
["Kaisa","Varus"],
["Vayne","Morgana"],
["Twitch","Varus"],
["Viego","Sylas"],
["Irelia","Kaisa"],
["Vi","Twitch"],
["Vayne","Lissandra"],
["Draven","Talon"],
["Vladimir","Lucian"],
["Zed","Neeko"],
["Viego","Akali"],
["Akshan","Malzahar"],
["Annie","Taliyah"],
["Yorick","Camille"],
["Illaoi","Singed"],
["Rumble","Karthus"],
["TahmKench","Riven"],
["Aatrox","Qiyana"],
["Varus","Ashe"],
["Kayn","Ekko"],
["Sett","MonkeyKing"],
["Akali","Taliyah"],
["Riven","Sion"],
["MonkeyKing","Gnar"],
["Belveth","Khazix"],
["Garen","Kled"],
["Yasuo","Singed"],
["Ekko","TwistedFate"],
["Nasus","Malphite"],
["Urgot","Aatrox"],
["Warwick","Mordekaiser"],
["Aatrox","Singed"],
["KSante","Jax"],
["Nilah","Jinx"],
["Aphelios","Zed"],
["Zac","Yone"],
["Gwen","Hecarim"],
["Samira","Yone"],
["Malzahar","Leblanc"],
["Evelynn","Nidalee"],
["Akali","Syndra"],
["Jayce","Belveth"],
["Nasus","Viktor"],
["Akali","Akshan"],
["DrMundo","Darius"],
["Rumble","Olaf"],
["Ezreal","Swain"],
["Vayne","Aatrox"],
["Irelia","Riven"],
["Talon","MissFortune"],
["Darius","LeeSin"],
["Jhin","Taliyah"],
["Zac","Sion"],
["Ezreal","Fiddlesticks"],
["Kayle","Olaf"],
["Nasus","Kassadin"],
["Akshan","Khazix"],
["Akali","Gnar"],
["Darius","Nilah"],
["Khazix","Irelia"],
["Nasus","Kennen"],
["Yone","Udyr"],
["Khazix","Evelynn"],
["Yone","Heimerdinger"],
["Shaco","Jayce"],
["LeeSin","Quinn"],
["Trundle","Sion"],
["Urgot","Pantheon"],
["Veigar","Zed"],
["Cassiopeia","Sett"],
["Neeko","Varus"],
["Udyr","Akali"],
["Renekton","Shyvana"],
["DrMundo","Zed"],
["Kayn","JarvanIV"],
["Aphelios","Caitlyn"],
["Graves","Ezreal"],
["Talon","Zoe"],
["Yorick","Irelia"],
["Poppy","Vladimir"],
["Tristana","Annie"],
["Qiyana","Orianna"],
["Sylas","Ashe"],
["Vladimir","Gangplank"],
["Quinn","Darius"],
["KogMaw","Senna"],
["Jayce","Malphite"],
["Jayce","Kennen"],
["Leblanc","Yasuo"],
["Malphite","KSante"],
["Fizz","Ahri"],
["Tryndamere","Jax"],
["Kaisa","Jinx"],
["LeeSin","Khazix"],
["Maokai","Renekton"],
["Tryndamere","Riven"],
["Jayce","TwistedFate"],
["Pantheon","Ziggs"],
["Malphite","Pantheon"],
["Hecarim","Vladimir"],
["Irelia","Zeri"],
["RekSai","Gwen"],
["Syndra","Orianna"],
["Rengar","Belveth"],
["Ezreal","Vayne"],
["Zoe","Kaisa"],
["Sylas","JarvanIV"],
["Vayne","Viktor"],
["Kayn","Karthus"],
["Karma","Ahri"],
["Darius","Kennen"],
["Nasus","Tryndamere"],
["Olaf","Jinx"],
["Fizz","Sylas"],
["Pyke","Kaisa"],
["Gangplank","Ornn"],
["Tryndamere","Sylas"],
["Warwick","Akshan"],
["Rengar","Quinn"],
["Camille","Malphite"],
["Kennen","Garen"],
["Lillia","Khazix"],
["Irelia","MissFortune"],
["Vladimir","Akshan"],
["Xayah","Kaisa"],
["Riven","Lillia"],
["Sett","Jax"],
["Kaisa","Pantheon"],
["Renekton","Shen"],
["Khazix","Talon"],
["Yasuo","Gangplank"],
["Fizz","LeeSin"],
["Kaisa","Zed"],
["Sylas","Maokai"],
["Ornn","Quinn"],
["Sett","Diana"],
["Shaco","LeeSin"],
["Warwick","Akali"],
["Vayne","Sett"],
["Katarina","Orianna"],
["Shen","Illaoi"],
["Anivia","Xerath"],
["Aphelios","Kaisa"],
["Akshan","Malphite"],
["Akshan","Fiora"],
["Vayne","Lucian"],
["Ezreal","Lulu"],
["Caitlyn","Kaisa"],
["Riven","Garen"],
["Sivir","Kaisa"],
["Kayn","Tryndamere"],
["Camille","Sett"],
["Volibear","Darius"],
["Talon","Yone"],
["Akshan","Kaisa"],
["Akali","Kassadin"],
["TwistedFate","Ahri"],
["Darius","Vayne"],
["Ivern","Rumble"],
["Shaco","Nidalee"],
["JarvanIV","Caitlyn"],
["Jayce","Leblanc"],
["Kennen","Leblanc"],
["Singed","Vladimir"],
["Darius","Chogath"],
["Warwick","Illaoi"],
["Sion","Illaoi"],
["Kennen","Kaisa"],
["Fizz","Viktor"],
["Darius","Nautilus"],
["Darius","Urgot"],
["Mordekaiser","Malphite"],
["Volibear","Malphite"],
["Singed","Aatrox"],
["AurelionSol","Orianna"],
["Syndra","Ahri"],
["Jinx","Milio"],
["Teemo","Sion"],
["Yorick","Olaf"],
["Fizz","Kayle"],
["Katarina","Talon"],
["Samira","Zed"],
["Camille","Mordekaiser"],
["Akshan","Ezreal"],
["Gwen","Graves"],
["JarvanIV","Talon"],
["Jayce","Pantheon"],
["Kaisa","Jhin"],
["Garen","Darius"],
["Ahri","Akali"],
["Riven","Vex"],
["Hecarim","Lillia"],
["MasterYi","Ashe"],
["Akali","Shen"],
["Akali","AurelionSol"],
["Zoe","Neeko"],
["Varus","Sivir"],
["MonkeyKing","Taliyah"],
["Volibear","Illaoi"],
["Gragas","Garen"],
["Sylas","Kaisa"],
["Belveth","Ahri"],
["Gwen","Shyvana"],
["Qiyana","Kayn"],
["Quinn","Kennen"],
["Viego","Senna"],
["Vayne","Kalista"],
["Renekton","Ashe"],
["MonkeyKing","Rengar"],
["Viego","Diana"],
["Taliyah","Gangplank"],
["Sylas","Pyke"],
["Caitlyn","Draven"],
["Evelynn","Belveth"],
["Aphelios","Senna"],
["Vayne","Xerath"],
["Volibear","Pantheon"],
["Riven","Graves"],
["Ekko","Viktor"],
["Nocturne","Sylas"],
["Fiora","Sett"],
["Zed","Ahri"],
["Cassiopeia","Hecarim"],
["Udyr","MasterYi"],
["Khazix","Viktor"],
["Tryndamere","Kassadin"],
["Ahri","Tryndamere"],
["Kayle","Sion"],
["Olaf","Fiora"],
["Xayah","MissFortune"],
["Sett","Graves"],
["Aatrox","Kindred"],
["Ashe","Kaisa"],
["Zed","Viego"],
["Katarina","Jayce"],
["Caitlyn","Vayne"],
["Syndra","Ashe"],
["Aphelios","Fiora"],
["Trundle","Syndra"],
["Twitch","Rengar"],
["Heimerdinger","Irelia"],
["LeeSin","Vayne"],
["Jax","Graves"],
["Yasuo","Orianna"],
["Sivir","Xayah"],
["Evelynn","Syndra"],
["Rumble","Gwen"],
["Nasus","Sett"],
["Yone","Draven"],
["Kled","Yorick"],
["XinZhao","Belveth"],
["Tristana","Neeko"],
["Nocturne","Draven"],
["Kayn","Senna"],
["Renekton","Kayle"],
["Gwen","Sylas"],
["Evelynn","Diana"],
["Ekko","Viego"],
["Kaisa","Zeri"],
["JarvanIV","Evelynn"],
["Vladimir","Karthus"],
["Renekton","Singed"],
["Yone","Lucian"],
["Yasuo","Karthus"],
["Urgot","Singed"],
["Gwen","Cassiopeia"],
["Kayn","Xayah"],
["Samira","Gangplank"],
["Tryndamere","Camille"],
["KSante","Fiora"],
["Pantheon","Cassiopeia"],
["Kaisa","Sona"],
["Mordekaiser","Renekton"],
["Yasuo","Shaco"],
["Zoe","Fizz"],
["Fiora","Shyvana"],
["Cassiopeia","Talon"],
["Garen","Shyvana"],
["Camille","Qiyana"],
["Yasuo","Lucian"],
["Jax","Katarina"],
["Neeko","Lucian"],
["Jax","Fiora"],
["Twitch","Ahri"],
["Xayah","Sivir"],
["Riven","Zed"],
["Jax","Xayah"],
["Yone","Ezreal"],
["Qiyana","Vladimir"],
["Zed","Pantheon"],
["Gangplank","Kled"],
["Olaf","KSante"],
["Evelynn","Twitch"],
["Yone","Shyvana"],
["Kayn","Aatrox"],
["Vladimir","Viktor"],
["Rengar","Katarina"],
["Kennen","Gangplank"],
["Jayce","Gangplank"],
["Xayah","Yone"],
["Senna","Lucian"],
["Kaisa","Azir"],
["Kayn","Kayle"],
["Gangplank","Pantheon"],
["Vi","Ezreal"],
["Zed","Diana"],
["Kayle","Fiora"],
["Rumble","Akshan"],
["KSante","Naafiri"],
["Jayce","Riven"],
["MonkeyKing","Poppy"],
["MasterYi","Hecarim"],
["Ashe","Pyke"],
["Riven","Senna"],
["Zed","Camille"],
["Shyvana","Aatrox"],
["Irelia","Akali"],
["Renekton","Senna"],
["Poppy","Yone"],
["Ekko","Qiyana"],
["Rengar","Gragas"],
["Gwen","Yorick"],
["Poppy","Malphite"],
["Vi","Akshan"],
["Taliyah","Xerath"],
["Xayah","Jayce"],
["Urgot","Malphite"],
["Tristana","Cassiopeia"],
["MasterYi","Thresh"],
["Sett","Shen"],
["Zed","Ashe"],
["Talon","JarvanIV"],
["Xayah","Zeri"],
["Cassiopeia","Karthus"],
["Samira","Yasuo"],
["Ekko","Zed"],
["Kaisa","Ahri"],
["Xayah","Caitlyn"],
["Olaf","LeeSin"],
["Yone","AurelionSol"],
["Camille","Twitch"],
["Tristana","Xayah"],
["Jayce","Kaisa"],
["Zoe","Orianna"],
["Gragas","Syndra"],
["Kayle","Gnar"],
["Azir","Illaoi"],
["MasterYi","LeeSin"],
["Malphite","Gragas"],
["KSante","Gnar"],
["Ezreal","Lissandra"],
["Gnar","Gangplank"],
["TahmKench","Malphite"],
["Draven","Taliyah"],
["Aatrox","Volibear"],
["Khazix","JarvanIV"],
["Akshan","Zed"],
["Khazix","Darius"],
["KogMaw","Kaisa"],
["Viego","Karthus"],
["Syndra","Kaisa"],
["Hecarim","TwistedFate"],
["Riven","Vayne"],
["Kaisa","Samira"],
["Riven","Vladimir"],
["Kassadin","Ezreal"],
["Cassiopeia","Graves"],
["Sylas","Orianna"],
["Ashe","Senna"],
["Akshan","Fizz"],
["Pantheon","Maokai"],
["Kassadin","Vex"],
["Yone","Kaisa"],
["Ornn","Darius"],
["Maokai","Gnar"],
["Kaisa","Diana"],
["Irelia","Orianna"],
["Kled","KSante"],
["Mordekaiser","Karthus"],
["Varus","Ezreal"],
["Fiddlesticks","Rengar"],
["Rengar","Leblanc"],
["Yone","Yasuo"],
["Malphite","Gwen"],
["Tristana","Kennen"],
["Camille","Corki"],
["Belveth","Kindred"],
["Hecarim","Riven"],
["Tryndamere","Graves"],
["Olaf","Twitch"],
["Khazix","Yone"],
["Twitch","Kaisa"],
["Riven","Karthus"],
["TahmKench","Sett"],
["Rumble","Ezreal"],
["Sett","KSante"],
["Cassiopeia","Garen"],
["Renekton","Leblanc"],
["Riven","Kassadin"],
["Zoe","Talon"],
["Garen","Karthus"],
["Taliyah","Yone"],
["Viego","Riven"],
["Fiora","Kassadin"],
["Annie","Kaisa"],
["Trundle","Ornn"],
["Aatrox","Vladimir"],
["MissFortune","Karthus"],
["Riven","Naafiri"],
["Rengar","Kayle"],
["Varus","Pyke"],
["Talon","Graves"],
["Akali","Teemo"],
["Ashe","Brand"],
["Katarina","Karthus"],
["Mordekaiser","Nasus"],
["Riven","KSante"],
["Quinn","Warwick"],
["Viego","Ashe"],
["Pantheon","Chogath"],
["Varus","Orianna"],
["Twitch","Swain"],
["Gragas","Kled"],
["Akali","Fiora"],
["Warwick","Syndra"],
["Caitlyn","Ashe"],
["Kaisa","Gragas"],
["Kennen","Sion"],
["Cassiopeia","Kaisa"],
["Hecarim","JarvanIV"],
["Ekko","Lucian"],
["Kaisa","Jayce"],
["Cassiopeia","Gwen"],
["Katarina","Ezreal"],
["Samira","Hecarim"],
["Twitch","Aphelios"],
["Samira","Jhin"],
["Fiora","Nocturne"],
["Azir","Aatrox"],
["Olaf","Diana"],
["Warwick","Kaisa"],
["Talon","Lissandra"],
["Vladimir","Renekton"],
["Volibear","Lillia"],
["Chogath","Renekton"],
["Shyvana","Garen"],
["Khazix","Vi"],
["Viego","Xerath"],
["Chogath","Syndra"],
["Pantheon","Jhin"],
["Kindred","Ekko"],
["Olaf","Fiddlesticks"],
["Jayce","Warwick"],
["Yasuo","Nautilus"],
["Graves","Hecarim"],
["Katarina","Nautilus"],
["Zac","Jayce"],
["Cassiopeia","TwistedFate"],
["Sylas","Garen"],
["Chogath","Sylas"],
["Swain","Kaisa"],
["Zac","Pantheon"],
["Jayce","Olaf"],
["Volibear","Tryndamere"],
["Swain","Katarina"],
["Shyvana","Riven"],
["Evelynn","Rengar"],
["Kalista","Ezreal"],
["Olaf","MonkeyKing"],
["Khazix","Gwen"],
["Gwen","Fiora"],
["Draven","Ezreal"],
["Udyr","Aatrox"],
["Talon","TwistedFate"],
["Zed","Rumble"],
["Irelia","Illaoi"],
["Cassiopeia","Viego"],
["Diana","Tristana"],
["Shaco","Kaisa"],
["Camille","Kayle"],
["Cassiopeia","Diana"],
["Kennen","Katarina"],
["Yasuo","Syndra"],
["Khazix","Vex"],
["Aphelios","Samira"],
["Yasuo","Illaoi"],
["Twitch","Sona"],
["Zed","Quinn"],
["Riven","Viego"],
["Darius","Kayn"],
["XinZhao","Lillia"],
["Tryndamere","Varus"],
["Qiyana","Akali"],
["Ezreal","Jhin"],
["Fiddlesticks","Lucian"],
["Senna","Zeri"],
["Talon","Cassiopeia"],
["Olaf","Viktor"],
["Volibear","Rumble"],
["Shaco","Akshan"],
["Jinx","Ezreal"],
["Ornn","Kled"],
["Riven","Neeko"],
["Yone","Swain"],
["Gnar","Rumble"],
["Karthus","Ashe"],
["Leblanc","Syndra"],
["Sion","Kayle"],
["Neeko","Camille"],
["Talon","Xayah"],
["Evelynn","Caitlyn"],
["Kayn","Gwen"],
["Camille","Lissandra"],
["Kayn","Taliyah"],
["Jayce","Irelia"],
["LeeSin","Jayce"],
["Yone","Fiora"],
["Zeri","Twitch"],
["Vayne","Aphelios"],
["Quinn","Camille"],
["Samira","Akali"],
["Jhin","Lux"],
["Yone","MasterYi"],
["Katarina","Qiyana"],
["Riven","Shyvana"],
["Shen","Gwen"],
["Twitch","Akali"],
["Qiyana","Kassadin"],
["Irelia","Udyr"],
["Akali","Sion"],
["Aatrox","Leblanc"],
["Akshan","Renekton"],
["Viego","Nocturne"],
["Yasuo","Volibear"],
["Syndra","TwistedFate"],
["Vladimir","AurelionSol"],
["Zoe","Yone"],
["Katarina","Ivern"],
["Sylas","Leblanc"],
["Rengar","Xerath"],
["Nasus","Gragas"],
["Diana","Talon"],
["Shen","Olaf"],
["Evelynn","Karthus"],
["Camille","Orianna"],
["Malphite","Teemo"],
["Shen","Gnar"],
["Poppy","Garen"],
["Akali","Poppy"],
["Leblanc","Seraphine"],
["Belveth","Gragas"],
["TahmKench","Pantheon"],
["Jayce","Sion"],
["Swain","Sion"],
["Kayle","Yone"],
["Kindred","Hecarim"],
["Heimerdinger","Jax"],
["Yasuo","Velkoz"],
["Twitch","Milio"],
["Azir","Sion"],
["Nasus","Fiora"],
["Varus","Jax"],
["Viego","Draven"],
["Irelia","Kayn"],
["Jinx","Twitch"],
["Kennen","Gnar"],
["Elise","Kaisa"],
["Riven","Kindred"],
["Taliyah","Qiyana"],
["Akali","MissFortune"],
["Nocturne","Khazix"],
["Kayle","Nasus"],
["Kaisa","Talon"],
["Jax","TahmKench"],
["Kassadin","Jayce"],
["Cassiopeia","Ekko"],
["Samira","Ashe"],
["Cassiopeia","Gragas"],
["Nocturne","Aphelios"],
["Gwen","TwistedFate"],
["Jayce","Mordekaiser"],
["Caitlyn","Twitch"],
["LeeSin","Zed"],
["Azir","Yasuo"],
["Irelia","Singed"],
["Viego","Ahri"],
["Pantheon","Tryndamere"],
["Vayne","KogMaw"],
["Yone","Volibear"],
["RekSai","Xerath"],
["Warwick","Kayn"],
["Samira","Bard"],
["Cassiopeia","KSante"],
["Aatrox","Azir"],
["Renekton","Hecarim"],
["Rumble","Yorick"],
["Renekton","Kaisa"],
["KSante","Olaf"],
["Jax","Gragas"],
["Fiora","Ornn"],
["Sett","Gangplank"],
["Zed","Fiora"],
["Sett","Yasuo"],
["Yasuo","Leblanc"],
["Evelynn","Yasuo"],
["Gwen","Vex"],
["Vayne","Riven"],
["Rumble","Ekko"],
["Rengar","Shaco"],
["Rumble","Chogath"],
["Viego","Renekton"],
["Olaf","Naafiri"],
["Jinx","Nilah"],
["Viego","Zed"],
["Zac","Ornn"],
["Riven","Renekton"],
["Renekton","Quinn"],
["Syndra","Zoe"],
["Darius","XinZhao"],
["Talon","Kindred"],
["Irelia","Sylas"],
["Jax","Olaf"],
["Illaoi","Talon"],
["Neeko","Taliyah"],
["Cassiopeia","Aatrox"],
["Aatrox","Jayce"],
["Gwen","TahmKench"],
["Ashe","Nilah"],
["Garen","Qiyana"],
["Graves","Kayn"],
["Syndra","Taliyah"],
["Riven","Karma"],
["Karthus","Syndra"],
["Jhin","Caitlyn"],
["Ahri","Veigar"],
["Sylas","TwistedFate"],
["MasterYi","Aphelios"],
["KSante","Zac"],
["Ahri","Tristana"],
["Sett","Poppy"],
["Talon","LeeSin"],
["Vayne","Blitzcrank"],
["Renekton","Zac"],
["Ornn","Syndra"],
["Yasuo","Corki"],
["Leblanc","Azir"],
["Riven","MonkeyKing"],
["Sylas","Evelynn"],
["DrMundo","Renekton"],
["Vayne","MasterYi"],
["Singed","Illaoi"],
["Chogath","Quinn"],
["Zeri","Rakan"],
["Kindred","Nocturne"],
["Aphelios","Vayne"],
["Zed","Vex"],
["Belveth","Shaco"],
["Shen","Gangplank"],
["Zed","Shaco"],
["KSante","Mordekaiser"],
["Tristana","Kayn"],
["Aphelios","Karthus"],
["Sylas","Gnar"],
["Taliyah","Draven"],
["Viktor","Ahri"],
["Volibear","Urgot"],
["Ekko","Lux"],
["Fiora","Riven"],
["KogMaw","Xayah"],
["Viktor","Renekton"],
["Darius","Rengar"],
["Olaf","Zac"],
["Sion","Gnar"],
["Mordekaiser","Zed"],
["Jayce","Sylas"],
["Qiyana","Singed"],
["Kayn","Zed"],
["Renekton","Karthus"],
["Khazix","Kassadin"],
["Shen","Garen"],
["Shaco","Caitlyn"],
["Khazix","Jax"],
["Yasuo","Chogath"],
["KSante","Kayle"],
["Sylas","Vladimir"],
["Malphite","LeeSin"],
["Pantheon","Katarina"],
["Malphite","Rengar"],
["Leblanc","Katarina"],
["Urgot","Cassiopeia"],
["Urgot","Renekton"],
["Tryndamere","Urgot"],
["Gnar","Sylas"],
["Khazix","Hecarim"],
["Mordekaiser","Graves"],
["Taliyah","Varus"],
["Garen","Akshan"],
["Yasuo","TwistedFate"],
["Ekko","Riven"],
["Zoe","Lux"],
["Zac","Syndra"],
["Irelia","Velkoz"],
["Ashe","Lulu"],
["Sejuani","Poppy"],
["Rumble","Kaisa"],
["Samira","Twitch"],
["Ezreal","Akali"],
["Darius","Akali"],
["Vayne","Xayah"],
["Ekko","Rumble"],
["MasterYi","Garen"],
["Trundle","Yone"],
["Draven","KogMaw"],
["Ekko","Camille"],
["Tryndamere","TwistedFate"],
["Khazix","Belveth"],
["Darius","Rell"],
["Ornn","Jayce"],
["Graves","Evelynn"],
["Viktor","Orianna"],
["Hecarim","Pyke"],
["Olaf","Darius"],
["Aatrox","Syndra"],
["Gragas","Karthus"],
["Warwick","Tryndamere"],
["Fiora","Gangplank"],
["Camille","Zac"],
["Jhin","Lucian"],
["Khazix","Leblanc"],
["Xerath","Senna"],
["Irelia","Karma"],
["Syndra","Lux"],
["Zeri","Talon"],
["Vladimir","Sett"],
["Illaoi","Renekton"],
["Katarina","Senna"],
["Evelynn","Talon"],
["Riven","Kaisa"],
["Karthus","Nautilus"],
["JarvanIV","Nidalee"],
["Rengar","Camille"],
["Pantheon","Akali"],
["Zed","Garen"],
["Ornn","Sion"],
["Xayah","Jinx"],
["Xerath","Zed"],
["Quinn","Riven"],
["Gwen","Gnar"],
["Aatrox","Akali"],
["Draven","Blitzcrank"],
["Garen","Ryze"],
["Kaisa","Thresh"],
["Katarina","Galio"],
["Zed","Khazix"],
["Qiyana","Zoe"],
["Qiyana","Katarina"],
["Hecarim","Nunu"],
["Tryndamere","Sett"],
["Gangplank","Renekton"],
["Akali","Kaisa"],
["Leblanc","Fiora"],
["Malphite","Rumble"],
["Yasuo","Samira"],
["Ornn","Camille"],
["Swain","Jinx"],
["Rumble","Gragas"],
["Viego","Yone"],
["Trundle","Riven"],
["Qiyana","Aatrox"],
["Katarina","Jhin"],
["Olaf","Skarner"],
["Lissandra","Zed"],
["Darius","Gwen"],
["Akshan","Ashe"],
["Cassiopeia","Tryndamere"],
["Jax","Kayle"],
["Trundle","Camille"],
["Jax","Kaisa"],
["Graves","Twitch"],
["Rumble","Cassiopeia"],
["Twitch","Ashe"],
["Irelia","Lux"],
["Rengar","Xayah"],
["Karthus","Ezreal"],
["Viego","Syndra"],
["Poppy","Trundle"],
["Taliyah","Swain"],
["Kaisa","Poppy"],
["Yone","Sett"],
["Graves","Viego"],
["Gnar","Singed"],
["Syndra","Aatrox"],
["Jayce","Sett"],
["Kaisa","Lillia"],
["Riven","Twitch"],
["Kaisa","Irelia"],
["Aatrox","Mordekaiser"],
["Hecarim","Akali"],
["Evelynn","Taliyah"],
["Ezreal","Cassiopeia"],
["Diana","Vex"],
["Chogath","Sion"],
["Viego","Viktor"],
["Warwick","Shen"],
["Urgot","Yone"],
["Kassadin","Ahri"],
["Talon","Pantheon"],
["JarvanIV","Aphelios"],
["Katarina","Viego"],
["Taliyah","Talon"],
["Varus","Fiora"],
["Nocturne","Kaisa"],
["Gragas","Tristana"],
["Volibear","Kayle"],
["Trundle","Tryndamere"],
["Nocturne","RekSai"],
["Gangplank","Poppy"],
["Illaoi","Vladimir"],
["RekSai","Talon"],
["Urgot","Sylas"],
["Rumble","Teemo"],
["Karthus","Graves"],
["Diana","Xerath"],
["Sett","Fiora"],
["Lucian","Kaisa"],
["Ashe","Darius"],
["Diana","Cassiopeia"],
["Chogath","Taliyah"],
["Sylas","Ziggs"],
["Irelia","Qiyana"],
["Tristana","Lissandra"],
["Vayne","Illaoi"],
["Olaf","Gragas"],
["Akali","Ezreal"],
["Heimerdinger","KSante"],
["Ekko","Quinn"],
["Jax","Maokai"],
["Yasuo","Fizz"],
["Gwen","Kled"],
["Jax","Karthus"],
["Mordekaiser","Yone"],
["Kaisa","RekSai"],
["Yone","Rumble"],
["Graves","Talon"],
["MonkeyKing","Fiora"],
["Samira","Pyke"],
["Gwen","Riven"],
["Jayce","Hecarim"],
["Yone","LeeSin"],
["Gangplank","Olaf"],
["Tryndamere","Udyr"],
["Warwick","Nasus"],
["RekSai","Fiora"],
["Ashe","Zeri"],
["Taliyah","Aatrox"],
["Pantheon","Poppy"],
["Malphite","Naafiri"],
["Ekko","Akshan"],
["Ivern","Khazix"],
["Aatrox","Olaf"],
["Renekton","Tryndamere"],
["Akali","Jayce"],
["Sett","Aatrox"],
["Vladimir","Ziggs"],
["Rumble","Vex"],
["Fiora","Chogath"],
["MasterYi","Lux"],
["Talon","Diana"],
["Caitlyn","Ziggs"],
["Akali","Lissandra"],
["Aatrox","TahmKench"],
["Riven","Quinn"],
["KSante","Gwen"],
["Taliyah","Orianna"],
["Diana","Ashe"],
["Heimerdinger","Kaisa"],
["Zed","Hecarim"],
["MasterYi","Khazix"],
["Kaisa","Amumu"],
["Draven","Varus"],
["Graves","Rengar"],
["Samira","Ahri"],
["Talon","Evelynn"],
["Gnar","Volibear"],
["JarvanIV","Katarina"],
["Malphite","Volibear"],
["Khazix","Gragas"],
["Rengar","Ashe"],
["Trundle","Garen"],
["Olaf","Tristana"],
["Pantheon","Quinn"],
["Sylas","Gwen"],
["Kaisa","Bard"],
["Khazix","Pantheon"],
["Illaoi","Ashe"],
["Jayce","Khazix"],
["Katarina","Ziggs"],
["Belveth","Jayce"],
["Darius","Illaoi"],
["Chogath","Olaf"],
["Olaf","Gangplank"],
["Karthus","Tristana"],
["Ezreal","Syndra"],
["Yone","Lillia"],
["MasterYi","Syndra"],
["Kassadin","Ashe"],
["Viego","Swain"],
["Rumble","Kayle"],
["Talon","Velkoz"],
["Garen","Neeko"],
["Rengar","Syndra"],
["Rengar","Jayce"],
["Tristana","Karthus"],
["Akali","Azir"],
["Rengar","Olaf"],
["Yorick","Sion"],
["Jax","Ezreal"],
["Irelia","Fiora"],
["Ashe","Xerath"],
["Yasuo","Kassadin"],
["Riven","Kayle"],
["Olaf","Lillia"],
["Yone","Varus"],
["Sylas","Vayne"],
["Fiddlesticks","MissFortune"],
["Ornn","Nasus"],
["Vayne","Samira"],
["Tristana","Jayce"],
["MasterYi","JarvanIV"],
["Singed","Gnar"],
["Syndra","Graves"],
["Graves","Rumble"],
["Samira","Caitlyn"],
["Talon","Khazix"],
["Twitch","Yone"],
["Pantheon","Ekko"],
["Riven","Nidalee"],
["Cassiopeia","Mordekaiser"],
["Akali","Lucian"],
["Samira","Alistar"],
["Garen","Urgot"],
["Olaf","Heimerdinger"],
["Shen","Jayce"],
["Aatrox","Shen"],
["Katarina","Viktor"],
["Illaoi","Syndra"],
["Ornn","Zac"],
["Neeko","Akali"],
["Draven","Braum"],
["Rengar","Kaisa"],
["Garen","Orianna"],
["Pantheon","Twitch"],
["Ezreal","Hecarim"],
["Nocturne","Diana"],
["MasterYi","Malzahar"],
["Jax","Leblanc"],
["Heimerdinger","Gragas"],
["Akali","Khazix"],
["Vayne","Nami"],
["Jax","Camille"],
["Aatrox","Jax"],
["Kennen","Ornn"],
["Quinn","Irelia"],
["Zoe","TwistedFate"],
["Darius","Warwick"],
["Kaisa","Riven"],
["Kaisa","Rell"],
["Tristana","KSante"],
["Rumble","Yasuo"],
["Neeko","Syndra"],
["Graves","Shaco"],
["Tryndamere","Tristana"],
["Fizz","Gangplank"],
["Viktor","Akshan"],
["MissFortune","Sivir"],
["Kaisa","Singed"],
["Ekko","Neeko"],
["Olaf","Varus"],
["Azir","Lissandra"],
["Tryndamere","Ahri"],
["Vladimir","Darius"],
["Vladimir","Rumble"],
["Jax","Poppy"],
["KSante","Karma"],
["Draven","Xayah"],
["Azir","Zed"],
["Vayne","Sylas"],
["Volibear","Trundle"],
["Zed","Kayle"],
["Ryze","Yone"],
["Renekton","Twitch"],
["Leblanc","Tryndamere"],
["Sett","Malphite"],
["Zeri","Zed"],
["Qiyana","Anivia"],
["Akshan","Rengar"],
["Jhin","Sivir"],
["Riven","Kayn"],
["Elise","Hecarim"],
["Poppy","Pantheon"],
["LeeSin","Yone"],
["Shaco","Senna"],
["Kaisa","Lux"],
["Akshan","Leblanc"],
["Gnar","Jayce"],
["Rengar","Maokai"],
["Malphite","Quinn"],
["Khazix","Jayce"],
["Darius","Jayce"],
["Gangplank","Naafiri"],
["Samira","Syndra"],
["Kennen","Gwen"],
["Akali","Velkoz"],
["Vex","Talon"],
["Rengar","Akali"],
["Renekton","Ekko"],
["Nocturne","Talon"],
["Zeri","Sylas"],
["Zed","Draven"],
["Ekko","Fizz"],
["Sylas","Swain"],
["LeeSin","Talon"],
["Rumble","Gangplank"],
["Malphite","Kennen"],
["Garen","Jax"],
["Qiyana","Senna"],
["Ezreal","Aatrox"],
["Singed","Irelia"],
["Qiyana","Lucian"],
["Shaco","Graves"],
["Olaf","Yasuo"],
["Jayce","Varus"],
["Fizz","Katarina"],
["Yasuo","Caitlyn"],
["Tryndamere","Talon"],
["Samira","Ezreal"],
["XinZhao","Kayn"],
["KSante","Sion"],
["Ahri","Orianna"],
["Katarina","Thresh"],
["Caitlyn","Karma"],
["Kassadin","Leblanc"],
["Mordekaiser","Cassiopeia"],
["Fiora","Graves"],
["Aatrox","LeeSin"],
["Aatrox","Fiora"],
["Fiora","Xayah"],
["Irelia","Heimerdinger"],
["Tristana","Pantheon"],
["Varus","Jhin"],
["Fiddlesticks","Caitlyn"],
["Xayah","Janna"],
["Darius","Sion"],
["Irelia","Urgot"],
["Yone","Xayah"],
["Poppy","Ornn"],
["Leblanc","Sylas"],
["Ezreal","Nilah"],
["Nunu","Syndra"],
["Riven","TwistedFate"],
["Yone","Fizz"],
["Gangplank","Fiora"],
["Sylas","Malzahar"],
["Sylas","Mordekaiser"],
["KSante","DrMundo"],
["Kindred","Aatrox"],
["Pantheon","Gwen"],
["Jayce","Akshan"],
["Camille","Kayn"],
["Mordekaiser","Gwen"],
["Shaco","Seraphine"],
["Sion","Darius"],
["Riven","Sett"],
["Quinn","Garen"],
["Jayce","KSante"],
["Kayle","Renekton"],
["Teemo","Ornn"],
["Syndra","Vex"],
["Rumble","Belveth"],
["Talon","Shaco"],
["Ezreal","MasterYi"],
["MasterYi","Sylas"],
["Cassiopeia","Vayne"],
["Evelynn","LeeSin"],
["Kled","Gangplank"],
["Twitch","Seraphine"],
["Karma","Jax"],
["Heimerdinger","Camille"],
["Vayne","Zeri"],
["Varus","Akshan"],
["Syndra","Akshan"],
["Hecarim","Seraphine"],
["Tryndamere","Nasus"],
["Urgot","Gangplank"],
["Zed","Gangplank"],
["Camille","Kennen"],
["LeeSin","Kindred"],
["Rumble","Lillia"],
["Zeri","Samira"],
["KSante","Akshan"],
["Sylas","Tristana"],
["Shen","Riven"],
["Zed","Kennen"],
["Yasuo","Jax"],
["Volibear","KSante"],
["Ekko","Annie"],
["Warwick","Singed"],
["Heimerdinger","Riven"],
["Teemo","Urgot"],
["Yasuo","Aatrox"],
["Fiora","Jax"],
["Riven","Ezreal"],
["Darius","Varus"],
["Jayce","Rumble"],
["Akali","Yone"],
["Fiora","Jayce"],
["Renekton","Ahri"],
["Karthus","Draven"],
["Pantheon","Nidalee"],
["JarvanIV","Lucian"],
["Rengar","Tryndamere"],
["Fiora","TahmKench"],
["Singed","Yone"],
["Yone","TwistedFate"],
["Ornn","Riven"],
["Khazix","Kalista"],
["Yasuo","Ziggs"],
["Taliyah","Leblanc"],
["Shaco","Karthus"],
["Qiyana","Veigar"],
["Singed","Riven"],
["Irelia","Yorick"],
["Ezreal","Xayah"],
["Aatrox","RekSai"],
["Khazix","Caitlyn"],
["Teemo","Vladimir"],
["Rumble","Fiddlesticks"],
["Twitch","Sylas"],
["Chogath","Garen"],
["Zeri","Yone"],
["Rumble","Ryze"],
["Darius","Rammus"],
["Qiyana","Kaisa"],
["Kayle","Yorick"],
["Garen","Gnar"],
["Taliyah","Sylas"],
["Shaco","Kindred"],
["Kassadin","Viktor"],
["Kled","Tryndamere"],
["Fizz","Talon"],
["Illaoi","Poppy"],
["Vayne","Nidalee"],
["Renekton","Samira"],
["Kindred","Ezreal"],
["Olaf","Caitlyn"],
["Jinx","Samira"],
["Olaf","Vladimir"],
["Renekton","Sett"],
["Talon","Swain"],
["Azir","Anivia"],
["Rumble","Kennen"],
["Samira","Morgana"],
["Kled","Aatrox"],
["Sylas","Kassadin"],
["Evelynn","Jhin"],
["Zed","Kayn"],
["Poppy","Riven"],
["Tristana","Ziggs"],
["Syndra","Diana"],
["Xayah","Karthus"],
["Aatrox","Yasuo"],
["Jax","Evelynn"],
["Zed","Aatrox"],
["Ahri","Qiyana"],
["Kayn","Samira"],
["Tristana","Fizz"],
["Kled","Khazix"],
["Yone","Viktor"],
["Riven","Swain"],
["Syndra","Sylas"],
["Kindred","Lillia"],
["Tryndamere","Renekton"],
["Kindred","Karthus"],
["Zac","Illaoi"],
["Renekton","Naafiri"],
["Camille","Viktor"],
["Viktor","Ezreal"],
["Belveth","Rengar"],
["Garen","Trundle"],
["Anivia","Irelia"],
["Illaoi","Kayle"],
["Aatrox","Ezreal"],
["Ezreal","Jayce"],
["Swain","Orianna"],
["Volibear","Nasus"],
["Caitlyn","Tristana"],
["Xayah","Qiyana"],
["Yasuo","Thresh"],
["KSante","Draven"],
["Fiddlesticks","Ahri"],
["Zac","Renekton"],
["Yone","Corki"],
["Fiddlesticks","Tristana"],
["Varus","Twitch"],
["Teemo","Kled"],
["Vladimir","Swain"],
["Hecarim","Rengar"],
["Viktor","Yasuo"],
["Zoe","Jayce"],
["Yone","Pyke"],
["Leblanc","Irelia"],
["Vayne","Neeko"],
["Fizz","AurelionSol"],
["Gragas","Sion"],
["Rengar","Ezreal"],
["Malphite","Sion"],
["Taliyah","Zed"],
["Lux","Sylas"],
["Kennen","Yasuo"],
["Zed","Tryndamere"],
["Samira","Draven"],
["Aatrox","Irelia"],
["Irelia","Azir"],
["Shen","Yone"],
["Qiyana","Lux"],
["Urgot","Kayle"],
["Illaoi","Quinn"],
["Irelia","Jax"],
["Poppy","Nasus"],
["Draven","Rakan"],
["Kaisa","Fiddlesticks"],
["Kennen","Orianna"],
["Riven","Darius"],
["Caitlyn","Varus"],
["Nocturne","Nunu"],
["Samira","Lillia"],
["RekSai","Riven"],
["Leblanc","Rumble"],
["Vladimir","Katarina"],
["Gragas","Zed"],
["Fiora","Taliyah"],
["Kayn","Xerath"],
["Vladimir","Fizz"],
["Hecarim","Thresh"],
["RekSai","Yone"],
["Zed","Kaisa"],
["Vayne","Jax"],
["JarvanIV","Ezreal"],
["Talon","Rumble"],
["Jinx","Draven"],
["Fizz","Karthus"],
["Akshan","Garen"],
["Vi","Gangplank"],
["Akali","Talon"],
["Rumble","Yone"],
["Akshan","Qiyana"],
["Swain","Aatrox"],
["Gwen","MonkeyKing"],
["Lucian","Vayne"],
["Olaf","Azir"],
["Evelynn","Hecarim"],
["Sion","Singed"],
["Akshan","Singed"],
["Riven","Sylas"],
["Varus","Jinx"],
["Anivia","Ahri"],
["Belveth","MasterYi"],
["Urgot","Sion"],
["Karma","Irelia"],
["Akali","Anivia"],
["Aatrox","MonkeyKing"],
["Volibear","Graves"],
["Sylas","Lissandra"],
["Akshan","Sylas"],
["Pantheon","Shen"],
["Nasus","Renekton"],
["Zed","Azir"],
["Vayne","Katarina"],
["Gwen","Kaisa"],
["Syndra","Akali"],
["Ezreal","Kaisa"],
["Syndra","Naafiri"],
["Singed","Sett"],
["Khazix","XinZhao"],
["Katarina","Vex"],
["Gragas","Yone"],
["Talon","Vex"],
["MasterYi","Zed"],
["Pantheon","Renekton"],
["Viego","Gwen"],
["Khazix","Ezreal"],
["Ezreal","Caitlyn"],
["Sion","Gragas"],
["Cassiopeia","Gnar"],
["JarvanIV","Rengar"],
["Pantheon","Sett"],
["Trundle","Renekton"],
["Malphite","Zed"],
["Caitlyn","Jhin"],
["Vayne","Janna"],
["Kalista","Kaisa"],
["Vayne","Syndra"],
["Draven","Hecarim"],
["Teemo","Pantheon"],
["Kled","Sion"],
["Ziggs","Orianna"],
["Lissandra","Sylas"],
["Jayce","Akali"],
["Aphelios","Thresh"],
["Talon","Rengar"],
["Sion","Akshan"],
["Quinn","Renekton"],
["Poppy","Rumble"],
["Malphite","Yone"],
["Xayah","Twitch"],
["Draven","Tristana"],
["Jhin","Ezreal"],
["Cassiopeia","Azir"],
["Tryndamere","Taliyah"],
["Renekton","Khazix"],
["Tryndamere","Singed"],
["Rumble","Gnar"],
["Teemo","Chogath"],
["Volibear","Renekton"],
["Tristana","Akali"],
["RekSai","Graves"],
["Vayne","Renekton"],
["Camille","Yone"],
["Warwick","KSante"],
["Udyr","Vladimir"],
["Malphite","Poppy"],
["Ekko","Tristana"],
["Kalista","Draven"],
["Irelia","Rumble"],
["Kaisa","Leona"],
["Qiyana","AurelionSol"],
["Tristana","Senna"],
["Singed","Poppy"],
["Kennen","Vayne"],
["Sylas","Jayce"],
["Rumble","Hecarim"],
["Hecarim","Katarina"],
["Zed","Qiyana"],
["MissFortune","Jhin"],
["Heimerdinger","Renekton"],
["Camille","Warwick"],
["Irelia","Taliyah"],
["Twitch","Azir"],
["Cassiopeia","Quinn"],
["Cassiopeia","Vladimir"],
["Yone","KSante"],
["Gwen","Karthus"],
["Khazix","Viego"],
["Ashe","Gangplank"],
["Xayah","Taliyah"],
["Jhin","Ashe"],
["Fiora","MasterYi"],
["Draven","Swain"],
["Hecarim","Lucian"],
["Garen","DrMundo"],
["JarvanIV","Fiddlesticks"],
["Nasus","Camille"],
["MasterYi","Xerath"],
["Sett","Syndra"],
["Gnar","Fiora"],
["Riven","Aatrox"],
["Hecarim","Twitch"],
["Camille","Zoe"],
["Nasus","Riven"],
["Rumble","Camille"],
["Qiyana","Taliyah"],
["Lux","Zed"],
["Mordekaiser","KSante"],
["Sylas","Kayn"],
["Fiora","Tryndamere"],
["Darius","MasterYi"],
["Yone","Karma"],
["Tristana","Ekko"],
["Kled","Jayce"],
["Rumble","Mordekaiser"],
["Gwen","Kayn"],
["Gnar","Illaoi"],
["Shaco","Zed"],
["Irelia","Camille"],
["Ezreal","Kalista"],
["Renekton","Katarina"],
["Velkoz","Xerath"],
["Ziggs","Yone"],
["Fiora","JarvanIV"],
["Nasus","Singed"],
["Katarina","Yone"],
["Jayce","Jax"],
["Kennen","Darius"],
["Sylas","Malphite"],
["Hecarim","Jayce"],
["Kalista","Janna"],
["Rumble","Vayne"],
["Shaco","Khazix"],
["Gwen","Sett"],
["Rumble","Darius"],
["Yone","Warwick"],
["Ryze","Tristana"],
["Nocturne","Xayah"],
["Ezreal","Lucian"],
["Fiora","DrMundo"],
["Azir","Diana"],
["Draven","Senna"],
["Pantheon","Aphelios"],
["Yone","Quinn"],
["Illaoi","Khazix"],
["Renekton","Garen"],
["Renekton","Viego"],
["Tryndamere","Fiora"],
["Tristana","Twitch"],
["Olaf","Irelia"],
["Kaisa","Ezreal"],
["Varus","Kalista"],
["Talon","Azir"],
["Jayce","Camille"],
["Ezreal","JarvanIV"],
["Rumble","Irelia"],
["Draven","Syndra"],
["Olaf","Malphite"],
["Twitch","Hecarim"],
["Ornn","Tryndamere"],
["Poppy","Jax"],
["Olaf","Camille"],
["Zeri","Katarina"],
["Aphelios","Twitch"],
["Katarina","Azir"],
["Sett","Karthus"],
["Neeko","Yone"],
["Viego","Graves"],
["Karthus","Aphelios"],
["MasterYi","Riven"],
["XinZhao","Camille"],
["Jayce","Twitch"],
["Evelynn","Quinn"],
["Poppy","Sett"],
["Samira","Karma"],
["Evelynn","Fiddlesticks"],
["Aatrox","Orianna"],
["Ezreal","Varus"],
["Urgot","Zed"],
["AurelionSol","Sylas"],
["Fiora","Vladimir"],
["Singed","Camille"],
["Teemo","Rumble"],
["Cassiopeia","Nilah"],
["Sion","Olaf"],
["Jax","Yone"],
["Tryndamere","Neeko"],
["Gwen","JarvanIV"],
["Tristana","Xerath"],
["Vayne","Sivir"],
["Caitlyn","Rell"],
["Gragas","Pantheon"],
["Varus","Yone"],
["Katarina","TwistedFate"],
["Pantheon","Urgot"],
["Syndra","Jinx"],
["Kayle","Gangplank"],
["Yone","Olaf"],
["Darius","Camille"],
["Pantheon","Kayn"],
["DrMundo","Jayce"],
["Nasus","Jayce"],
["Garen","Akali"],
["Akshan","Kayle"],
["Ashe","Draven"],
["Graves","Quinn"],
["Diana","Ezreal"],
["MasterYi","Varus"],
["Irelia","Pantheon"],
["Kayn","Kaisa"],
["Tryndamere","Malphite"],
["Ashe","Nautilus"],
["Akshan","Xerath"],
["Jax","Sion"],
["Xayah","Lucian"],
["Syndra","Yasuo"],
["Varus","Senna"],
["Rumble","Sett"],
["Gangplank","Jax"],
["Ezreal","Soraka"],
["Fiora","Hecarim"],
["TahmKench","Sion"],
["Olaf","Nocturne"],
["Zeri","Kalista"],
["LeeSin","Viego"],
["Jayce","Kled"],
["JarvanIV","Rumble"],
["Akali","Neeko"],
["Jax","Jayce"],
["Neeko","Senna"],
["Talon","Sion"],
["Nasus","Syndra"],
["Jax","Irelia"],
["Illaoi","KSante"],
["Kalista","Jhin"],
["Xayah","Nami"],
["Veigar","Ahri"],
["Zoe","Leblanc"],
["Rengar","JarvanIV"],
["Volibear","Syndra"],
["Ezreal","Taliyah"],
["Katarina","Swain"],
["KSante","Vladimir"],
["Renekton","Camille"],
["Kled","Vladimir"],
["Irelia","Fizz"],
["Kalista","Zeri"],
["Zac","Gangplank"],
["Qiyana","Xerath"],
["Vayne","Nilah"],
["Irelia","Ekko"],
["Kindred","Talon"],
["Xerath","Lux"],
["Ashe","Leblanc"],
["Fiora","Aatrox"],
["Vladimir","Ekko"],
["Cassiopeia","Ziggs"],
["Volibear","Jax"],
["Shen","KSante"],
["Jayce","Rengar"],
["Gragas","Ezreal"],
["Poppy","Volibear"],
["Fiora","Vayne"],
["Cassiopeia","Shaco"],
["Annie","Zed"],
["Sett","Rumble"],
["Nocturne","Gangplank"],
["Illaoi","Kled"],
["Riven","Tryndamere"],
["Katarina","MissFortune"],
["Khazix","Draven"],
["Jax","Mordekaiser"],
["Katarina","Neeko"],
["Volibear","Kled"],
["Irelia","Jhin"],
["Ekko","Talon"],
["Riven","Annie"],
["JarvanIV","Kaisa"],
["Zed","Vayne"],
["Olaf","Singed"],
["Gangplank","Sion"],
["Yasuo","KogMaw"],
["Gnar","Leblanc"],
["Annie","Syndra"],
["Ahri","Xerath"],
["MonkeyKing","Tristana"],
["Kennen","KSante"],
["Fizz","Galio"],
["Yasuo","Brand"],
["Sylas","Tryndamere"],
["Syndra","Lucian"],
["Talon","Anivia"],
["Renekton","Gnar"],
["Yone","Kayn"],
["Renekton","Ryze"],
["Sylas","Caitlyn"],
["Viego","Ezreal"],
["TahmKench","Teemo"],
["Heimerdinger","Cassiopeia"],
["Xayah","Hecarim"],
["Renekton","Kindred"],
["Leblanc","Anivia"],
["Khazix","Renekton"],
["Qiyana","Gangplank"],
["Nasus","Rumble"],
["Zed","Gragas"],
["KSante","Swain"],
["Kaisa","JarvanIV"],
["Xerath","Caitlyn"],
["KSante","Vayne"],
["Qiyana","Sylas"],
["Lucian","Ezreal"],
["Mordekaiser","Illaoi"],
["Diana","Sylas"],
["Twitch","Graves"],
["Tristana","Caitlyn"],
["Akshan","Twitch"],
["Zed","Irelia"],
["MonkeyKing","Camille"],
["Yone","Ekko"],
["Velkoz","Ahri"],
["Udyr","Olaf"],
["Singed","Renekton"],
["Caitlyn","Yone"],
["Volibear","Akali"],
["Hecarim","Taliyah"],
["Xerath","Kaisa"],
["Zoe","AurelionSol"],
["Yasuo","Kaisa"],
["Talon","Tristana"],
["Vladimir","Pantheon"],
["Ezreal","Lillia"],
["Gragas","Sylas"],
["Katarina","Pyke"],
["Ashe","Sivir"],
["Kennen","Cassiopeia"],
["Belveth","Hecarim"],
["Olaf","Illaoi"],
["Talon","Quinn"],
["Nasus","Jax"],
["Fiora","Syndra"],
["Cassiopeia","Katarina"],
["Akali","Kayn"],
["Jayce","Tristana"],
["Fiora","Kaisa"],
["Varus","Rakan"],
["Rengar","Gangplank"],
["Renekton","Gangplank"],
["Akshan","TwistedFate"],
["Pantheon","Zac"],
["Illaoi","Graves"],
["Sylas","Akshan"],
["Gragas","Warwick"],
["Pantheon","Udyr"],
["Volibear","Garen"],
["Graves","Xayah"],
["Teemo","Yasuo"],
["Zed","Gnar"],
["Qiyana","Graves"],
["Khazix","Nidalee"],
["Pantheon","Graves"],
["Yasuo","Hecarim"],
["Jhin","Aphelios"],
["Caitlyn","Sivir"],
["Ezreal","Aphelios"],
["Aatrox","Urgot"],
["Darius","Ezreal"],
["Malphite","Shyvana"],
["Camille","Syndra"],
["Qiyana","Rumble"],
["Darius","TahmKench"],
["Olaf","Ekko"],
["MasterYi","Karthus"],
["Anivia","Corki"],
["Jinx","Yasuo"],
["Teemo","Irelia"],
["Irelia","Renekton"],
["Camille","Darius"],
["Irelia","Olaf"],
["Pantheon","Karthus"],
["Leblanc","Akshan"],
["Kalista","Bard"],
["Viktor","Vladimir"],
["Kled","Poppy"],
["Camille","Gwen"],
["Pantheon","Syndra"],
["Singed","Zed"],
["Viego","Rengar"],
["Yone","Katarina"],
["Samira","Evelynn"],
["Fiddlesticks","Twitch"],
["Khazix","Jhin"],
["Renekton","Kassadin"],
["Mordekaiser","Zac"],
["Gnar","Gwen"],
["Kalista","Braum"],
["Kaisa","Neeko"],
["Diana","Leblanc"],
["Aatrox","Yorick"],
["Kindred","XinZhao"],
["Gwen","LeeSin"],
["Twitch","Caitlyn"],
["Shaco","Kayn"],
["Volibear","Sett"],
["Nocturne","Orianna"],
["Mordekaiser","Volibear"],
["Leblanc","Vex"],
["Xerath","Draven"],
["Nasus","Ornn"],
["Nasus","Graves"],
["Rengar","Karthus"],
["Trundle","Fiora"],
["Belveth","RekSai"],
["Poppy","Ekko"],
["Yorick","KSante"],
["Camille","Jayce"],
["Akali","Garen"],
["LeeSin","Camille"],
["Tristana","Jinx"],
["Tristana","Aphelios"],
["Volibear","Akshan"],
["Kled","Ornn"],
["Syndra","Rumble"],
["Gwen","Jax"],
["Evelynn","Khazix"],
["Zeri","Aphelios"],
["Jayce","Gragas"],
["Rumble","Sylas"],
["Katarina","Zed"],
["Pantheon","Neeko"],
["Jax","Aatrox"],
["Ornn","Gnar"],
["Warwick","Cassiopeia"],
["Yasuo","Yone"],
["Pantheon","Vladimir"],
["Rumble","Tryndamere"],
["Vladimir","Naafiri"],
["Vayne","TwistedFate"],
["LeeSin","Xayah"],
["Evelynn","Anivia"],
["Akali","Nasus"],
["Hecarim","Akshan"],
["Riven","Teemo"],
["Fiora","Zed"],
["Hecarim","Karma"],
["Illaoi","Kayn"],
["KogMaw","Twitch"],
["Tryndamere","Yasuo"],
["LeeSin","Akshan"],
["Akali","Katarina"],
["Irelia","Sion"],
["Warwick","Sion"],
["Khazix","Kled"],
["Jayce","Renekton"],
["Yone","Velkoz"],
["LeeSin","Twitch"],
["Yone","Sejuani"],
["Rengar","Yasuo"],
["Akshan","Ekko"],
["Urgot","Fiora"],
["Yone","Akali"],
["Darius","Udyr"],
["Garen","Poppy"],
["KSante","Illaoi"],
["Pantheon","Yasuo"],
["Fiddlesticks","KogMaw"],
["Vayne","Irelia"],
["Vayne","Rakan"],
["Ezreal","Rengar"],
["Rengar","Heimerdinger"],
["Darius","Shyvana"],
["Vayne","Kayn"],
["Garen","Yone"],
["Darius","Ahri"],
["MissFortune","Xayah"],
["Viego","Veigar"],
["Pantheon","Naafiri"],
["Yone","Nocturne"],
["Renekton","Orianna"],
["Illaoi","Cassiopeia"],
["Varus","Kayn"],
["Illaoi","Tristana"],
["Gwen","Vayne"],
["Katarina","LeeSin"],
["Leblanc","Zed"],
["Ezreal","Thresh"],
["Jax","Qiyana"],
["Kaisa","Zilean"],
["Vayne","Renata"],
["Olaf","Syndra"],
["Evelynn","Ziggs"],
["Camille","Vayne"],
["Lillia","Garen"],
["Camille","Nasus"],
["MonkeyKing","Karthus"],
["Irelia","Mordekaiser"],
["Xayah","Ziggs"],
["Yone","Evelynn"],
["Akshan","Gwen"],
["Sett","Yone"],
["Vladimir","Kaisa"],
["Kayn","AurelionSol"],
["Pantheon","Irelia"],
["Fiddlesticks","Jayce"],
["Riven","Hecarim"],
["Garen","Vladimir"],
["Kalista","Senna"],
["Tristana","MissFortune"],
["Illaoi","Neeko"],
["Fizz","Ezreal"],
["Viego","Varus"],
["Jax","MonkeyKing"],
["Teemo","Sett"],
["Gwen","Darius"],
["Zoe","Renekton"],
["Aatrox","Shaco"],
["Akshan","Quinn"],
["Gwen","Volibear"],
["Swain","Zed"],
["Jax","LeeSin"],
["Leblanc","Jax"],
["Jax","Gwen"],
["Kayn","Twitch"],
["JarvanIV","Khazix"],
["Akali","Seraphine"],
["Sett","Zed"],
["Fiddlesticks","Akshan"],
["Sylas","Syndra"],
["Akali","Illaoi"],
["Yone","Karthus"],
["Nasus","Darius"],
["Gragas","KSante"],
["Jayce","Ahri"],
["Cassiopeia","Sion"],
["MonkeyKing","Sion"],
["Yasuo","Ahri"],
["Varus","Darius"],
["Katarina","Anivia"],
["Ashe","Syndra"],
["Pantheon","Lucian"],
["Ahri","Irelia"],
["Vayne","Warwick"],
["Jax","Lillia"],
["Olaf","Anivia"],
["Kassadin","Sylas"],
["Aphelios","JarvanIV"],
["Samira","Kaisa"],
["Kalista","Yone"],
["Akali","TwistedFate"],
["Kalista","Nilah"],
["Vladimir","Yone"],
["Aatrox","Diana"],
["Yasuo","Rengar"],
["Yone","Renekton"],
["Kayn","Garen"],
["Ezreal","Rell"],
["AurelionSol","Kaisa"],
["Cassiopeia","Kled"],
["Malzahar","Vladimir"],
["Fizz","Yone"],
["Akali","Camille"],
["Graves","Lux"],
["Qiyana","Malzahar"],
["Diana","TwistedFate"],
["Gwen","Yone"],
["Kaisa","Akali"],
["Lillia","Shaco"],
["Zeri","Ezreal"],
["Gnar","Aatrox"],
["Gangplank","Chogath"],
["Ezreal","Ekko"],
["Volibear","Warwick"],
["Viego","Janna"],
["Kennen","Sett"],
["Senna","Kaisa"],
["Kassadin","Kennen"],
["Aatrox","Tristana"],
["Garen","Kaisa"],
["Gragas","Gnar"],
["Fiddlesticks","Vladimir"],
["Hecarim","Ezreal"],
["Pantheon","Varus"],
["Hecarim","Jhin"],
["Volibear","Poppy"],
["Mordekaiser","Tryndamere"],
["Zed","Viktor"],
["Vi","Kaisa"],
["Urgot","Riven"],
["Riven","Akshan"],
["Camille","KSante"],
["Rengar","Jhin"],
["Kayn","Caitlyn"],
["Sylas","Anivia"],
["Olaf","Kennen"],
["Samira","Aphelios"],
["Yone","Seraphine"],
["MasterYi","Irelia"],
["Yone","Ornn"],
["Darius","Lillia"],
["MasterYi","Kaisa"],
["MasterYi","Tristana"],
["Jayce","Ashe"],
["Irelia","Poppy"],
["Jax","Vayne"],
["Ornn","Garen"],
["Vladimir","Cassiopeia"],
["Karthus","Sylas"],
["Irelia","Chogath"],
["Kled","Akshan"],
["Belveth","Ezreal"],
["MonkeyKing","Jayce"],
["Jax","Riven"],
["Chogath","Mordekaiser"],
["Jax","Sylas"],
["Vayne","JarvanIV"],
["MasterYi","Graves"],
["Akali","Heimerdinger"],
["Zeri","Karma"],
["Udyr","Diana"],
["Irelia","Gwen"],
["Kassadin","Renekton"],
["AurelionSol","Vladimir"],
["Sion","KSante"],
["Khazix","Twitch"],
["Khazix","Velkoz"],
["Akali","Maokai"],
["DrMundo","Yone"],
["Taliyah","Lissandra"],
["Rumble","Singed"],
["Riven","Yasuo"],
["Fiora","Talon"],
["Shaco","Tristana"],
["Syndra","Vayne"],
["Aatrox","Ornn"],
["Khazix","Quinn"],
["Yasuo","Lissandra"],
["Vladimir","Akali"],
["Talon","Zed"],
["AurelionSol","Taliyah"],
["Mordekaiser","Fiora"],
["Velkoz","Taliyah"],
["TahmKench","Akshan"],
["Vayne","Mordekaiser"],
["Kaisa","Xayah"],
["MonkeyKing","Khazix"],
["Zeri","Khazix"],
["Akali","Ekko"],
["Zed","Sion"],
["KSante","Shen"],
["Tryndamere","MonkeyKing"],
["Cassiopeia","Darius"],
["Darius","Ornn"],
["Ryze","Azir"],
["Warwick","Yasuo"],
["Varus","Janna"],
["Jayce","Zed"],
["Neeko","Irelia"],
["Gwen","Warwick"],
["Xayah","Seraphine"],
["Sett","Katarina"],
["Yone","Darius"],
["Aatrox","Udyr"],
["AurelionSol","Ezreal"],
["Vex","Zed"],
["Swain","Camille"],
["Taliyah","Jayce"],
["Zed","LeeSin"],
["RekSai","Karthus"],
["Udyr","Irelia"],
["Yasuo","Talon"],
["Zed","Jax"],
["Jinx","Jhin"],
["Lucian","Ashe"],
["KSante","Talon"],
["Viego","Azir"],
["Samira","Sivir"],
["Kaisa","Illaoi"],
["Riven","Caitlyn"],
["Cassiopeia","Pantheon"],
["Pantheon","Gangplank"],
["Katarina","Twitch"],
["Cassiopeia","Anivia"],
["Irelia","DrMundo"],
["Yasuo","Senna"],
["Ashe","Yone"],
["Caitlyn","Bard"],
["Caitlyn","MissFortune"],
["Gwen","Naafiri"],
["Irelia","Caitlyn"],
["Sett","Jayce"],
["Syndra","AurelionSol"],
["Jayce","Graves"],
["Akali","Evelynn"],
["Heimerdinger","Anivia"],
["Renekton","Lissandra"],
["Vladimir","Jax"],
["Malphite","Renekton"],
["Olaf","Akshan"],
["Olaf","Rengar"],
["RekSai","Tristana"],
["Mordekaiser","Gragas"],
["Darius","DrMundo"],
["Gangplank","Cassiopeia"],
["Yasuo","Azir"],
["Draven","Ziggs"],
["Ezreal","Irelia"],
["Yone","Rengar"],
["Renekton","Yorick"],
["Kennen","Irelia"],
["Ornn","Yone"],
["Quinn","Tryndamere"],
["Sett","Tryndamere"],
["Darius","Xayah"],
["Ornn","Gangplank"],
["Poppy","Kennen"],
["Ekko","Yone"],
["Fiora","Fizz"],
["Graves","XinZhao"],
["Nocturne","Hecarim"],
["Ahri","Neeko"],
["Katarina","Riven"],
["Vladimir","Orianna"],
["Fiddlesticks","Jinx"],
["Kayle","Camille"],
["Khazix","Janna"],
["Kaisa","Cassiopeia"],
["Kaisa","Khazix"],
["Chogath","Gnar"],
["Sylas","Gangplank"],
["Hecarim","Yasuo"],
["Zed","Swain"],
["Gangplank","Jayce"],
["Yasuo","Shyvana"],
["Nasus","Cassiopeia"],
["Darius","Kayle"],
["Hecarim","Viktor"],
["Kayn","Gangplank"],
["Gnar","Graves"],
["Yasuo","Malphite"],
["MasterYi","Camille"],
["Kayn","Riven"],
["Akshan","Syndra"],
["Jax","Teemo"],
["Fiddlesticks","Syndra"],
["Caitlyn","Aphelios"],
["Vayne","Soraka"],
["Azir","Darius"],
["Malphite","Fiora"],
["Udyr","Rumble"],
["Aatrox","Sion"],
["Ezreal","Ashe"],
["Vex","Ekko"],
["TahmKench","Kennen"],
["Zeri","Caitlyn"],
["Pantheon","Xerath"],
["Shen","Irelia"],
["Vayne","Lux"],
["Fiora","Katarina"],
["KSante","Ekko"],
["Akali","Vladimir"],
["Pantheon","Illaoi"],
["Vayne","Gwen"],
["Rumble","LeeSin"],
["Zed","Pyke"],
["MissFortune","Jinx"],
["Yasuo","KSante"],
["Akali","Karma"],
["Ashe","Bard"],
["Cassiopeia","Jax"],
["Fiddlesticks","Ezreal"],
["Katarina","Rengar"],
["Trundle","Volibear"],
["Fiora","Akshan"],
["Gwen","KSante"],
["Volibear","Gangplank"],
["Kalista","Thresh"],
["Ahri","Taliyah"],
["LeeSin","Jax"],
["Ekko","MasterYi"],
["Karthus","Varus"],
["Sylas","Velkoz"],
["Evelynn","Xayah"],
["Akali","Darius"],
["Malphite","Kled"],
["DrMundo","Riven"],
["Poppy","Sion"],
["Kaisa","KogMaw"],
["Kindred","Viego"],
["Yasuo","Vex"],
["Cassiopeia","Tristana"],
["Pantheon","Zed"],
["Graves","JarvanIV"],
["Sett","Volibear"],
["Graves","Akshan"],
["Swain","Qiyana"],
["Fiora","Varus"],
["Xayah","Zed"],
["Vladimir","Syndra"],
["RekSai","Hecarim"],
["Irelia","AurelionSol"],
["Akshan","Aatrox"],
["Olaf","Trundle"],
["Tryndamere","Ornn"],
["Aphelios","Janna"],
["Riven","Heimerdinger"],
["Khazix","Nami"],
["MonkeyKing","Kayle"],
["Ezreal","Kayn"],
["Warwick","Poppy"],
["Volibear","XinZhao"],
["Irelia","Naafiri"],
["Yone","Riven"],
["Jinx","Akali"],
["Velkoz","Syndra"],
["Varus","Samira"],
["Illaoi","Yorick"],
["Ashe","Vayne"],
["Illaoi","Sion"],
["Ashe","Xayah"],
["Nasus","Kled"],
["Samira","Taric"],
["MasterYi","Aatrox"],
["Jayce","Vayne"],
["Sylas","Xerath"],
["Hecarim","LeeSin"],
["Vladimir","Leblanc"],
["Warwick","Tristana"],
["Akshan","Azir"],
["Sett","Nasus"],
["Pantheon","Yorick"],
["Lucian","Rakan"],
["Fiora","Xerath"],
["Garen","Renekton"],
["Zed","Lux"],
["Azir","Kaisa"],
["Malphite","Tristana"],
["Yasuo","Ashe"],
["Samira","Graves"],
["Vayne","Anivia"],
["Qiyana","Kayle"],
["RekSai","Jinx"],
["Gwen","Quinn"],
["Kaisa","Fiora"],
["Ezreal","Zyra"],
["Sylas","Neeko"],
["Zed","Orianna"],
["Gwen","Ekko"],
["Garen","Tristana"],
["Garen","Leblanc"],
["Rumble","Tristana"],
["Zeri","Rell"],
["Olaf","Riven"],
["Jayce","Kindred"],
["Fizz","Swain"],
["Vladimir","Jayce"],
["Malphite","Vladimir"],
["Mordekaiser","Jax"],
["Varus","Shen"],
["Kassadin","Zed"],
["Renekton","Ornn"],
["Chogath","Zed"],
["Darius","Draven"],
["Aphelios","Cassiopeia"],
["Irelia","Leblanc"],
["Yasuo","Kled"],
["Malphite","Yasuo"],
["Renekton","Fiddlesticks"],
["Zed","Jayce"],
["Gwen","Renekton"],
["Akali","Naafiri"],
["Nilah","Samira"],
["Evelynn","Varus"],
["Fiora","AurelionSol"],
["Riven","Poppy"],
["Rumble","Aatrox"],
["LeeSin","Ezreal"],
["Shaco","Ezreal"],
["Xerath","Orianna"],
["Mordekaiser","Urgot"],
["Lux","Ahri"],
["Volibear","Rengar"],
["Riven","Illaoi"],
["Qiyana","Akshan"],
["Kaisa","Yasuo"],
["Fiora","Qiyana"],
["Jinx","Xayah"],
["Belveth","Ashe"],
["Renekton","Talon"],
["Fiddlesticks","Kaisa"],
["Jax","Pantheon"],
["Talon","Twitch"],
["Malphite","DrMundo"],
["Sett","LeeSin"],
["Renekton","Zed"],
["Viego","Poppy"],
["Katarina","Lissandra"],
["Jayce","Gwen"],
["Yone","Nidalee"],
["Akali","Galio"],
["Gnar","Vayne"],
["Urgot","Graves"],
["Vladimir","Zed"],
["Twitch","Orianna"],
["Kassadin","Lux"],
["Akshan","Illaoi"],
["Samira","Vayne"],
["Qiyana","Zed"],
["Khazix","Kaisa"],
["Fizz","Irelia"],
["Irelia","Zed"],
["Swain","Renekton"],
["Ezreal","Xerath"],
["Kayle","KSante"],
["Kaisa","Camille"],
["Sivir","Nautilus"],
["Fiora","Gwen"],
["LeeSin","Syndra"],
["Tryndamere","Nocturne"],
["Shaco","Gwen"],
["Vayne","Naafiri"],
["Varus","JarvanIV"],
["Ezreal","Sylas"],
["MasterYi","Taliyah"],
["Talon","Ezreal"],
["Sett","Olaf"],
["Varus","AurelionSol"],
["Renekton","Nidalee"],
["MasterYi","MissFortune"],
["MonkeyKing","Sett"],
["Gwen","Diana"],
["Ekko","Kaisa"],
["Gragas","Leblanc"],
["Gwen","Sejuani"],
["Jax","Fiddlesticks"],
["Ezreal","Nautilus"],
["Illaoi","Teemo"],
["LeeSin","Sylas"],
["Malphite","Aatrox"],
["KSante","Renekton"],
["Rengar","Kayn"],
["Talon","Hecarim"],
["Sylas","Zoe"],
["Samira","Xayah"],
["Sett","Pantheon"],
["Ezreal","Milio"],
["Kayle","Sylas"],
["Anivia","Akshan"],
["Viego","Taliyah"],
["Ezreal","Diana"],
["Syndra","Kennen"],
["Renekton","Trundle"],
["Khazix","Lillia"],
["Mordekaiser","Poppy"],
["Warwick","Rumble"],
["Warwick","Viego"],
["Talon","Draven"],
["Yone","Thresh"],
["Garen","Singed"],
["Cassiopeia","Fizz"],
["Kaisa","Veigar"],
["Khazix","Riven"],
["Talon","Nocturne"],
["Yasuo","Irelia"],
["Kayn","Olaf"],
["Akali","Tristana"],
["Fiora","Draven"],
["Talon","Gragas"],
["Talon","Senna"],
["Fiora","MonkeyKing"],
["Illaoi","Ornn"],
["Fiora","Sylas"],
["Ezreal","Yasuo"],
["Zeri","Gangplank"],
["Viego","Jinx"],
["Darius","Fizz"],
["Rengar","Cassiopeia"],
["Shaco","Irelia"],
["Rengar","Ekko"],
["Volibear","Yone"],
["Gangplank","Zed"],
["Gwen","Ashe"],
["Viego","Seraphine"],
["Tristana","Kassadin"],
["Taliyah","LeeSin"],
["Sylas","Yasuo"],
["Zed","Twitch"],
["Camille","Leblanc"],
["Rengar","Lucian"],
["Zoe","Anivia"],
["Kayn","RekSai"],
["Fiora","Quinn"],
["Vladimir","KSante"],
["Ryze","Jayce"],
["Samira","Anivia"],
["Zeri","Lulu"],
["MonkeyKing","Neeko"],
["Yone","Camille"],
["Varus","Xayah"],
["Ekko","Karthus"],
["Chogath","Jayce"],
["Teemo","Olaf"],
["Zed","Renekton"],
["Caitlyn","Samira"],
["Kayle","Riven"],
["Evelynn","Tristana"],
["Ezreal","MissFortune"],
["Jayce","Volibear"],
["Illaoi","Olaf"],
["Belveth","Leblanc"],
["Evelynn","Ashe"],
["Warwick","Rengar"],
["Camille","Gragas"],
["Gangplank","Garen"],
["MasterYi","Kayn"],
["Zac","Riven"],
["Fiora","KSante"],
["Nasus","Mordekaiser"],
["Xayah","Lissandra"],
["Swain","Samira"],
["Darius","Shen"],
["Camille","MasterYi"],
["Kled","Rengar"],
["Evelynn","Jayce"],
["Jayce","Azir"],
["Hecarim","Varus"],
["Neeko","Shaco"],
["Fiddlesticks","Zed"],
["Chogath","Vladimir"],
["Tristana","Orianna"],
["MasterYi","Twitch"],
["Garen","Sett"],
["Vladimir","Ahri"],
["Sylas","Sion"],
["Fizz","Cassiopeia"],
["Samira","Neeko"],
["Fiora","Ekko"],
["Gangplank","Sett"],
["Fiora","Kayn"],
["Talon","Akshan"],
["Aatrox","Sylas"],
["Zed","Varus"],
["LeeSin","Ashe"],
["Ekko","Vladimir"],
["Varus","Akali"],
["Zed","Sylas"],
["Cassiopeia","Rumble"],
["Aatrox","Shyvana"],
["Samira","Kindred"],
["Yone","Alistar"],
["Viego","JarvanIV"],
["Rengar","Pyke"],
["Fiddlesticks","Varus"],
["Tristana","Syndra"],
["Camille","Xerath"],
["Kaisa","Velkoz"],
["Jinx","Thresh"],
["Lux","Leblanc"],
["Fiddlesticks","Taliyah"],
["Mordekaiser","Shen"],
["Camille","Cassiopeia"],
["Varus","Zeri"],
["Darius","Kled"],
["Darius","Gangplank"],
["Syndra","Qiyana"],
["Vayne","Rell"],
["Talon","Kayn"],
["Rengar","Kled"],
["Shen","Chogath"],
["Yone","Nasus"],
["Illaoi","Jax"],
["MonkeyKing","Yone"],
["Aatrox","Malphite"],
["Draven","Graves"],
["Vayne","Tristana"],
["Yasuo","Karma"],
["Talon","Sivir"],
["Aatrox","Illaoi"],
["Karthus","Khazix"],
["KSante","Zed"],
["TwistedFate","Syndra"],
["Samira","Aatrox"],
["Draven","JarvanIV"],
["Draven","Leona"],
["LeeSin","Evelynn"],
["Ezreal","Evelynn"],
["Yone","Irelia"],
["Ornn","Vladimir"],
["Warwick","Vladimir"],
["Graves","Ashe"],
["Tryndamere","Orianna"],
["Camille","Garen"],
["Katarina","Kaisa"],
["Poppy","Mordekaiser"],
["Udyr","Kaisa"],
["Gragas","Renekton"],
["Katarina","Ekko"],
["Xayah","Syndra"],
["Fiora","Camille"],
["Riven","Ivern"],
["Cassiopeia","Ornn"],
["Vladimir","Malphite"],
["Taliyah","Tristana"],
["Kennen","Zed"],
["Viego","Brand"],
["Shyvana","Yasuo"],
["Vayne","Annie"],
["Gnar","Kennen"],
["Ahri","Kaisa"],
["Kled","Fiora"],
["Cassiopeia","Akshan"],
["Viktor","Malzahar"],
["Olaf","MissFortune"],
["Ashe","Karma"],
["Samira","Akshan"],
["Sion","Kled"],
["Ahri","Jayce"],
["Tristana","Naafiri"],
["Fiora","Neeko"],
["Illaoi","Gangplank"],
["Syndra","Neeko"],
["Kennen","Tryndamere"],
["Samira","Rell"],
["Varus","Chogath"],
["XinZhao","Senna"],
["Khazix","Azir"],
["Evelynn","Lillia"],
["Ornn","Gragas"],
["Jax","Singed"],
["Ezreal","Maokai"],
["Katarina","Caitlyn"],
["Vladimir","Quinn"],
["Katarina","Renekton"],
["Syndra","Camille"],
["Ezreal","Sivir"],
["Chogath","Teemo"],
["Syndra","Tristana"],
["Viego","Vi"],
["Pantheon","Trundle"],
["Khazix","Rell"],
["Fizz","Syndra"],
["Yone","Galio"],
["Sion","Renekton"],
["Olaf","Volibear"],
["Vladimir","Gragas"],
["Sion","Garen"],
["Volibear","Vayne"],
["KSante","Gangplank"],
["Katarina","Malzahar"],
["Ashe","Thresh"],
["Darius","Kindred"],
["Nilah","Pyke"],
["Kaisa","Shaco"],
["Yone","Syndra"],
["Sylas","Gragas"],
["Swain","Jayce"],
["Renekton","Jax"],
["Diana","Graves"],
["Gwen","Akali"],
["XinZhao","Taliyah"],
["Zed","Ziggs"],
["Gnar","Jax"],
["Kled","TahmKench"],
["Khazix","Ivern"],
["Jax","Trundle"],
["Illaoi","Sylas"],
["Kayle","Singed"],
["Teemo","Zed"],
["Kennen","Warwick"],
["Caitlyn","Karthus"],
["Riven","TahmKench"],
["Taliyah","Diana"],
["Camille","Zed"],
["Poppy","Zac"],
["Diana","Gangplank"],
["Akshan","Ahri"],
["Kayle","Kled"],
["Draven","Vayne"],
["Xerath","Ezreal"],
["Chogath","Gangplank"],
["Senna","Jhin"],
["Rengar","Fiora"],
["Chogath","KSante"],
["Renekton","Kennen"],
["Poppy","Irelia"],
["Khazix","Orianna"],
["Akali","Ziggs"],
["Renekton","Akali"],
["Heimerdinger","Ezreal"],
["Kennen","Jayce"],
["Caitlyn","Nautilus"],
["Twitch","Karma"],
["Vladimir","Tristana"],
["Garen","Sylas"],
["Kaisa","Taliyah"],
["Darius","Yasuo"],
["Elise","Kindred"],
["Veigar","Akshan"],
["Jhin","Twitch"],
["Aphelios","Kalista"],
["Gangplank","Malphite"],
["Teemo","Illaoi"],
["Olaf","Renekton"],
["Gangplank","Irelia"],
["Diana","Twitch"],
["Tristana","Leblanc"],
["Nasus","Akshan"],
["Tryndamere","Kayle"],
["Zac","KSante"],
["Riven","Xayah"],
["Aphelios","Ekko"],
["Yasuo","Naafiri"],
["Leblanc","Gwen"],
["Cassiopeia","Riven"],
["Qiyana","Leblanc"],
["Draven","Thresh"],
["MonkeyKing","Mordekaiser"],
["Olaf","Kayn"],
["Ornn","Rumble"],
["Renekton","Akshan"],
["Ekko","Renekton"],
["Tryndamere","Rengar"],
["Rengar","Ahri"],
["Aatrox","Gwen"],
["Garen","Malzahar"],
["Rumble","Nidalee"],
["Akali","Rengar"],
["Renekton","Vayne"],
["Nocturne","Karthus"],
["Hecarim","Karthus"],
["Zeri","Nautilus"],
["Rumble","Maokai"],
["Tryndamere","Sion"],
["Samira","Zyra"],
["Kassadin","Kaisa"],
["Jax","RekSai"],
["Jax","Diana"],
["TahmKench","Renekton"],
["Azir","Ahri"],
["Darius","Tristana"],
["Tryndamere","Syndra"],
["Yasuo","Quinn"],
["Volibear","Shen"],
["Poppy","Warwick"],
["Diana","Rengar"],
["Mordekaiser","Garen"],
["Khazix","Jinx"],
["Kled","Swain"],
["Sett","Sylas"],
["Shaco","Draven"],
["Garen","Xerath"],
["Fiora","Sion"],
["MasterYi","Renekton"],
["Nasus","Aatrox"],
["Talon","Lucian"],
["Shyvana","Renekton"],
["Darius","Fiddlesticks"],
["MasterYi","Kindred"],
["Sion","Yone"],
["Vladimir","Yasuo"],
["Sion","Ornn"],
["Renekton","Illaoi"],
["Tryndamere","Gwen"],
["Swain","Ahri"],
["MasterYi","Kayle"],
["Draven","Neeko"],
["Talon","Lux"],
["Vayne","Camille"],
["Belveth","Viktor"],
["Garen","Quinn"],
["KSante","Ornn"],
["Jayce","Shen"],
["Diana","Kaisa"],
["MonkeyKing","Aatrox"],
["Riven","Udyr"],
["Draven","Rengar"],
["Jinx","Varus"],
["TahmKench","Olaf"],
["Vayne","Gnar"],
["Tristana","Jhin"],
["Sion","Irelia"],
["Syndra","Rengar"],
["Draven","Sylas"],
["Kaisa","Gangplank"],
["Samira","MissFortune"],
["Irelia","Darius"],
["Yasuo","AurelionSol"],
["Varus","MissFortune"],
["Zac","Kayle"],
["Illaoi","LeeSin"],
["Yone","Vayne"],
["Talon","Jhin"],
["Camille","RekSai"],
["Kayn","Renekton"],
["Talon","Ekko"],
["Garen","Graves"],
["Gangplank","Kayle"],
["Irelia","Zoe"],
["Volibear","Ornn"],
["Gnar","Yone"],
["Lux","Taliyah"],
["Darius","Irelia"],
["Taliyah","Quinn"],
["Malphite","Sylas"],
["Yasuo","Nasus"],
["Gragas","Gangplank"],
["Illaoi","Yasuo"],
["Qiyana","Yasuo"],
["Olaf","Sylas"],
["Yasuo","Akshan"],
["Kaisa","Rengar"],
["Kayn","Vayne"],
["Jax","Tryndamere"],
["Nocturne","Rengar"],
["Poppy","Gnar"],
["Sylas","Qiyana"],
["Renekton","Vi"],
["Yone","Nautilus"],
["JarvanIV","Tristana"],
["Riven","Kennen"],
["Evelynn","Jinx"],
["Camille","Vladimir"],
["Sejuani","Aatrox"],
["Quinn","Taliyah"],
["Darius","Gragas"],
["Sett","Sion"],
["Khazix","Qiyana"],
["Talon","Sylas"],
["Chogath","Aatrox"],
["Mordekaiser","Camille"],
["Pantheon","Malzahar"],
["Darius","Taliyah"],
["Cassiopeia","Gangplank"],
["Neeko","Ahri"],
["Kindred","Yone"],
["Aatrox","Khazix"],
["Gwen","Vladimir"],
["Chogath","Jax"],
["Gangplank","Akshan"],
["Khazix","Syndra"],
["Sylas","Katarina"],
["Ekko","Xayah"],
["Fiora","Azir"],
["Trundle","KSante"],
["Jayce","Kayn"],
["Talon","Karthus"],
["Volibear","Fiora"],
["KSante","Leblanc"],
["Gangplank","Talon"],
["Zed","Zoe"],
["Rengar","Yone"],
["Sett","Kled"],
["Shaco","Fiddlesticks"],
["Pantheon","Leblanc"],
["Twitch","Ziggs"],
["Warwick","Graves"],
["Nocturne","Ezreal"],
["Riven","Katarina"],
["Syndra","Cassiopeia"],
["Ekko","Belveth"],
["Akshan","Jayce"],
["Ezreal","Zeri"],
["Kindred","Belveth"],
["Azir","Irelia"],
["Katarina","Annie"],
["Camille","Gnar"],
["Hecarim","Fizz"],
["Kalista","Karma"],
["Vayne","Quinn"],
["Samira","Cassiopeia"],
["Yone","Neeko"],
["Cassiopeia","Lux"],
["Viktor","Yone"],
["Vayne","MissFortune"],
["JarvanIV","Hecarim"],
["Cassiopeia","AurelionSol"],
["Aatrox","Rumble"],
["Riven","Nasus"],
["Irelia","Tryndamere"],
["Kled","Volibear"],
["AurelionSol","Illaoi"],
["Zeri","Karthus"],
["Udyr","Lillia"],
["JarvanIV","Lillia"],
["Teemo","Jayce"],
["Draven","Zeri"],
["Cassiopeia","Kassadin"],
["Gragas","Aatrox"],
["Udyr","Ahri"],
["Ashe","Taric"],
["Garen","Gragas"],
["Anivia","Viktor"],
["Rengar","Ivern"],
["Taliyah","Ezreal"],
["Mordekaiser","Orianna"],
["Nocturne","Viego"],
["Kaisa","Akshan"],
["Darius","JarvanIV"],
["Twitch","Draven"],
["Yone","Diana"],
["Aatrox","Swain"],
["Akali","Olaf"],
["Quinn","Jax"],
["Karma","Renekton"],
["Tryndamere","Jayce"],
["Swain","Naafiri"],
["Nasus","Pantheon"],
["Rumble","DrMundo"],
["Yasuo","Graves"],
["Zoe","Graves"],
["KSante","Warwick"],
["Malphite","Akali"],
["Syndra","Varus"],
["Darius","Khazix"],
["Cassiopeia","Renekton"],
["Viktor","Taliyah"],
["Kled","Riven"],
["Xayah","Akshan"],
["Qiyana","Tristana"],
["Jax","Ahri"],
["Jhin","Xayah"],
["Taliyah","Yasuo"],
["Qiyana","Gnar"],
["Nocturne","Nidalee"],
["Singed","Sion"],
["Gragas","Sett"],
["Hecarim","Orianna"],
["Lux","Vladimir"],
["XinZhao","Graves"],
["Varus","Rell"],
["Darius","Rumble"],
["Zoe","Irelia"],
["Vayne","Pyke"],
["Leblanc","Qiyana"],
["Teemo","Vayne"],
["Sylas","Udyr"],
["Yorick","Renekton"],
["Chogath","Kayle"],
["Renekton","Yone"],
["Jax","Neeko"],
["Sylas","Pantheon"],
["Mordekaiser","TahmKench"],
["Riven","XinZhao"],
["Zac","Gwen"],
["Warwick","Vayne"],
["KSante","Riven"],
["Draven","Karma"],
["Qiyana","Neeko"],
["Xayah","Pyke"],
["Yone","Zac"],
["Rumble","Renekton"],
["Zoe","Viktor"],
["Kennen","Viktor"],
["Sylas","Naafiri"],
["Vayne","Gragas"],
["Zed","Olaf"],
["Varus","Kaisa"],
["Gnar","Pantheon"],
["Renekton","Gwen"],
["Kaisa","Pyke"],
["Akali","Gragas"],
["MasterYi","Draven"],
["Olaf","Warwick"],
["Aphelios","Ashe"],
["Yone","Gragas"],
["Rumble","Kindred"],
["Aphelios","Rell"],
["Nasus","Gnar"],
["Jayce","Ekko"],
["Illaoi","Volibear"],
["Sylas","Ahri"],
["Draven","Pyke"],
["Darius","Zac"],
["MonkeyKing","Olaf"],
["Camille","Sylas"],
["Gwen","Viktor"],
["Vayne","Zed"],
["Zac","Irelia"],
["Katarina","Darius"],
["Sylas","Kayle"],
["Ahri","Ziggs"],
["Xayah","Fiora"],
["Cassiopeia","Illaoi"],
["LeeSin","Karthus"],
["Malphite","Olaf"],
["Gnar","Warwick"],
["Yasuo","Galio"],
["Camille","Karthus"],
["Kassadin","Azir"],
["Yasuo","Nilah"],
["Chogath","Darius"],
["Kassadin","Taliyah"],
["Kennen","Pantheon"],
["Nasus","Garen"],
["LeeSin","Irelia"],
["Fiddlesticks","Lux"],
["Shaco","Viego"],
["Mordekaiser","Jayce"],
["Yasuo","Heimerdinger"],
["Cassiopeia","Velkoz"],
["Annie","Yasuo"],
["Poppy","Illaoi"],
["Jayce","Ezreal"],
["Qiyana","Jax"],
["Gwen","Chogath"],
["Kassadin","Orianna"],
["Talon","Aatrox"],
["Vayne","Akali"],
["Draven","Khazix"],
["Kayn","Jayce"],
["Graves","Jax"],
["Aatrox","Yone"],
["Yone","Yorick"],
["Garen","Cassiopeia"],
["Yone","Cassiopeia"],
["Jayce","Fizz"],
["Udyr","Gragas"],
["Pantheon","Fiora"],
["Zoe","Akali"],
["Zeri","Xerath"],
["Gangplank","Warwick"],
["Renekton","Warwick"],
["Vladimir","RekSai"],
["Twitch","MissFortune"],
["Lillia","Renekton"],
["Kindred","Aphelios"],
["Kindred","Diana"],
["Khazix","Samira"],
["Tryndamere","Karma"],
["Quinn","Graves"],
["Udyr","Viego"],
["Illaoi","Tryndamere"],
["Zac","Zed"],
["Darius","Olaf"],
["Rengar","Nocturne"],
["Kaisa","Morgana"],
["RekSai","Kaisa"],
["Swain","Syndra"],
["Camille","Taliyah"],
["Vayne","DrMundo"],
["XinZhao","Rengar"],
["Kaisa","Janna"],
["Nilah","Xayah"],
["Pantheon","Rumble"],
["Olaf","Mordekaiser"],
["Vayne","KSante"],
["Shen","Tryndamere"],
["Nasus","Kayle"],
["Irelia","Volibear"],
["Garen","Ornn"],
["Tryndamere","Warwick"],
["Gnar","Karthus"],
["DrMundo","Jax"],
["Fiddlesticks","Samira"],
["Kayle","Illaoi"],
["Mordekaiser","Sett"]]