import {shareLogo} from "../icons/side_icons";
import {Stack, Box, Tooltip} from "@mui/material";
import theme from "../theme";
import ButtonBase from "@mui/material/ButtonBase";
import {useState} from "react";
import {useMainStore} from "../state";

const ShareHighlight = ({highlightId, size, text} : {highlightId:number, size:number, text:boolean}) => {
    const isOW = useMainStore(state => state.isOverwolf)
    const url = useMainStore(state => state.serverUrl)
    const [shared, setShared] = useState(false)
    const [hover, setHover] = useState(false)
    const icon = shareLogo(size, size, shared ? theme.palette.primary.main : hover ? '#FFFFFF' : theme.palette.secondary.lighter)

    const toggle = () => {
        let link = `${url}/playerHighlights/${highlightId}`
        if (isOW)
            overwolf.utils.placeOnClipboard(link)
        else
            navigator.clipboard.writeText(link)

        setShared(true)
        window.setTimeout(() => {
            setShared(false)
        }, 5000)
    }

    return (
        <Tooltip componentsProps={{tooltip: {sx: {bgcolor:'secondary.darker'}}}} open={shared} title={<Box className={"generalText"} sx={{fontSize:"14px"}}>Share link copied!</Box>}>
            <ButtonBase onClick={toggle} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                <Stack direction={"column"} spacing={0.5} alignItems="center">
                    {icon}
                    {text ? <Box className={"titleText"} sx ={{fontSize:"16px", color: shared ? 'primary.main' : hover ? "white" : "secondary.light"}}>Share</Box> : <></>}
                </Stack>
            </ButtonBase>
        </Tooltip>)
}


export default ShareHighlight