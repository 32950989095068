import {clearLogo, trashLogo} from "../icons/side_icons";
import {Stack, Box} from "@mui/material";
import theme from "../theme";
import ButtonBase from "@mui/material/ButtonBase";
import {useState} from "react";

const Clear = ({size, target} : {size:number, target:Function}) => {
    const [hover, setHover] = useState(false)
    const icon = clearLogo(size, size, hover ? theme.palette.primary.main : '#FFFFFF')

    const clear = (e:any) => {
        target(null)
        e.stopPropagation()
    }

    return (
        <ButtonBase sx={{position:'absolute', 'bottom':0, 'right':0}} onClick={clear} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            {icon}
        </ButtonBase>)
}


export default Clear