import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }
}

// A custom theme for this app
export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#FFE901',
    },
    secondary: {
      main: '#515151',
      darker: '#212121',
      dark: '#343333',
      light: '#77777A',
      lighter: '#C9C9C9'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#000000"
    }
  },
  zIndex: {
    tooltip:10000
  }
});

export default theme;
