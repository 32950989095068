import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
//@ts-ignore
import { createRoot } from 'react-dom/client';

const container : any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>);
