import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import {CardMedia, IconButton} from "@mui/material";
import {WINDOW_NAMES} from "../../utils/consts";

const MinimizeButton = () => {
    const [hover, setHover] = useState(false)

    return (
        <IconButton sx={{p:0, m:0}} onClick={() => overwolf.windows.getCurrentWindow((window) => {
                if (window.window.name == WINDOW_NAMES.INGAME)
                    overwolf.windows.hide(window.window.name)
                else
                    overwolf.windows.minimize(window.window.name)
            })
        }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
                <CardMedia sx={{
                    bgcolor:hover ? "secondary.main" : "none",
                    height:'30px',
                    width:'30px'}}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 22H9V23H21V22Z" fill={"#C9C9C9"}/>
                    </svg>
                </CardMedia>
        </IconButton>)
}

export default MinimizeButton