import {Box, CardMedia, Stack, Tooltip} from "@mui/material";
import {tree2color} from "../utils/general";
import * as React from "react";
import {TOOLTIP_STYLE} from "../utils/consts";
import {useMainStore} from "../state";

const Rune = ({runeId, size,  tooltipPosition="top"}:{runeId:string, size:string,  tooltipPosition?:any}) => {
    const base_url = '/assets/runes/perk-images/Styles'
    //@ts-ignore
    const rune = useMainStore.getState().metadata.general_runes[runeId]
    const tree = rune ? rune[0] : ""
    const name = rune ? rune[1] : ""
    const renamed_name = rune ? rune[2] : ""

    const runeTooltip = (<Stack direction="column" spacing={1}>
                            <Box className={"titleText"} sx={{color:"secondary.lighter", fontSize:'15px'}}>
                                {rune ? rune[3] : ""}
                            </Box>
                            <Box sx={{color:"secondary.lighter", fontSize:'12px', whiteSpace:'pre-line'}}>{rune ? rune[4] : ""}</Box>
                        </Stack>
                        )
    return <Tooltip placement={tooltipPosition} arrow={true} componentsProps={{
                    tooltip: TOOLTIP_STYLE,
                  }} title={runeTooltip} disableHoverListener={name == ""}>
        <CardMedia image={renamed_name === "" ? "" : `${base_url}/${tree}/${name}/${renamed_name}.png`}
                      sx={{width:size, bgcolor:"secondary.dark", height:size, border:`1px solid ${tree2color[tree]}`,
                          borderRadius:"50%"}} />
            </Tooltip>
}

export default Rune