import * as React from "react";
import {CardMedia, Container, IconButton, Modal, Paper, Stack, SvgIcon, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useMainStore} from "../state";
import {useState} from "react";
import OppositeGeneralButton from "./OppositeGeneralButton";
import {VERSION} from "../utils/consts";
import SKTextField from "./SKTextField";

const CHARACTER_LIMIT = 450;


const BugReportModal = ({open, setClosed} : {open:boolean, setClosed:Function}) => {
    const sendSupportTicket = useMainStore(state => state.sendSupportTicket)
    const [closeHover, setCloseHover] = useState(false)
    const [issue, setIssue] = useState("")
    const [email, setEmail] = useState("")

    const submit = () => {
        sendSupportTicket(`from: ${email}. issue: ${issue}. version: ${VERSION}`)
        window.setTimeout(() => {
            setClosed()
            setIssue("")
            setEmail("")
        }, 200)
    }

    return (<Modal onMouseDown={(e) => e.stopPropagation()} open={open} onClose={() => setClosed()} sx={{zIndex:10000}}>
          <Stack justifyContent="space-between" direction={"column"} sx={{outline:"none", boxShadow:"none", p:"20px", bgcolor:"secondary.darker", width:"495px", height:"356px",
                                    maxWidth:"495px", maxHeight:"356px", position:"absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)"}}
                 spacing={2}>
              <Stack sx={{pb:0.75}} direction={"row"} justifyContent={"space-between"}>
                <Box className={"titleText"}>Send feedback or report a bug</Box>
                     <IconButton sx={{p:0, m:0, position:"absolute", top:0, right:0}}
                                onClick={() => {setClosed(); setCloseHover(false)}}
                               onMouseEnter={() => setCloseHover(true)}
                               onMouseLeave={() => setCloseHover(false)}
                    >
                            <CardMedia sx={{
                                bgcolor:closeHover ? "primary.main" : "none",
                                height:'30px',
                                width:'30px'}}>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill={closeHover ? "black" : "#C9C9C9"} d="M15.5917 14.775L20.375 9.99167C20.6083 9.75833 20.6083 9.40833 20.375 9.175C20.1416 8.94167 19.7916 8.94167 19.5583 9.175L14.775 13.9583L9.99166 9.175C9.75833 8.94167 9.40833 8.94167 9.175 9.175C8.94167 9.40833 8.94167 9.75833 9.175 9.99167L13.9583 14.775L9.175 19.5583C8.94167 19.7917 8.94167 20.1417 9.175 20.375C9.29167 20.4917 9.40833 20.4917 9.64167 20.4917C9.875 20.4917 9.99166 20.4917 10.1083 20.375L14.8917 15.5917L19.675 20.375C19.7916 20.4917 19.9083 20.4917 20.1416 20.4917C20.375 20.4917 20.4916 20.4917 20.6083 20.375C20.8416 20.1417 20.8416 19.7917 20.6083 19.5583L15.5917 14.775Z" />
                                </svg>
                            </CardMedia>
                    </IconButton>
              </Stack>
              <SKTextField id="email-input"
                        placeholder="Email. *optional - so that we can contact you back :)"
                        onChange={(event : any) => {setEmail(event.target.value)}}
                           sx={{
                           'input::placeholder': {
                               color:"secondary.lighter",
                               opacity:1
                           },
                           'input:focus::placeholder': {
                               color:"secondary.lighter",
                               opacity:0.25
                           },
                           }}
                        />
              <Stack direction={"column"} spacing={0.5}>
                  <SKTextField id="report-input"
                            size="small"
                            placeholder="Write your message here. If you’re reporting a bug, please try to describe the bug and what you were doing when it occurred."
                            multiline
                            rows={6}
                            inputProps={{ maxLength: CHARACTER_LIMIT }}
                            onChange={(event : any) => {setIssue(event.target.value)}}
                           sx={{
                               'textarea:focus::placeholder': {
                                   color:"secondary.lighter",
                                   opacity:0.25
                               },
                           }}
                            />
                  <Stack direction={"row"} justifyContent={"flex-end"} sx={{width:"100%"}}>
                      <Box className={"generalText"} sx={{color:"secondary.main"}}>{CHARACTER_LIMIT - issue.length} characters left</Box>
                  </Stack>
              </Stack>
              <Stack sx={{width:"100%", pr:2, pt:0}} justifyContent={"center"} alignItems={"center"}>
                  <OppositeGeneralButton height="30px" disabled={issue.length == 0} clickCallback={() => {submit()}}>
                    <Box sx={{width:"55px"}} className={"smallTitle"}>Submit</Box>
                </OppositeGeneralButton>
              </Stack>
            </Stack>
            </Modal>
        )
}


export default BugReportModal