import create from 'zustand'
import {
    WINDOW_NAMES,
    PAGE_NAMES, VERSION, PICK_TYPE, HOTKEY_KEYS
} from './utils/consts'
import { share, isSupported } from "shared-zustand";
import { persist } from "zustand/middleware"
import { v4 as uuidv4 } from 'uuid'

const SETTINGS_KEY = `solokill-settings-v6`
let window_id;
let refreshVideosAbortController = new AbortController()
import { BroadcastChannel } from 'broadcast-channel';
import {swapKeysAndValues} from "./utils/raw_utils";
export const sk_channel = new BroadcastChannel('solokill');
import {resizeWindow, zipAppLogFolder} from "./utils/ow_utils";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

export const BASE_MATCHUP_METADATA = JSON.stringify({
      player_champion:null,
      enemy_champion:null,
      core_build:["", "", ""],
      summoner_spells:["none", "none"],
      late_game_build_options:["", "", "", ""],
      matchup_combos: [["none", "none", "none", "none", "none"], ["none", "none", "none", "none"]],
      general_runes: [["", "", ""], ["", "", ""], ["", "", ""], ["", "", ""], ["", "", ""], ["", "", ""]],
      stat_runes: ["", "", ""],
      template:true
})




const generateHotkey = (name:string, keyCode:number, modifiers:string[]) => {

    let assign_request : any = {
        name: '',
        virtualKey: keyCode,
        gameId: 5426,
        modifiers: {
        }}

    assign_request.name = `${name}_solokill`
    let all_modifiers : string[] = ["Ctrl", "Shift", "Alt"]
    modifiers.forEach((key : string, index:number) => {
        if (all_modifiers.includes(key)) {
            assign_request['modifiers'][key.toLowerCase()] = true
        }
    })

    return new Promise ((resolve, reject) => {

        overwolf.settings.hotkeys.assign(assign_request, (assign_res) => {
            console.log("generateHotkey", assign_res)
            if (assign_res.success) {
                resolve("ok")
            }
            else {
                useMainStore.getState().updateSettings(name, "", false)
                overwolf.settings.hotkeys.unassign({name: `${name}_solokill`, gameId: 5426}, console.log)
                reject()
            }
        })
    })


}

export const matchChampion = (name:string, allChampions:string[]) => {
    let match = allChampions.filter((champ:string) => {
        return champ.toLowerCase() == name.toLowerCase()
    })

    if (match.length == 1)
        return match[0]

    return null
}


export const setHotkey = (name : string, keyCode : number, modifiers : string[]) => {
    generateHotkey(name, keyCode, modifiers)
}

function initSettings() {
    let settings : any = localStorage.getItem(SETTINGS_KEY)
    console.log('init', settings)

    if (settings != null) {
        settings = JSON.parse(settings)

        // Compatability
        if (!settings.hasOwnProperty('autoLaunchInGameOverlay'))
            settings['autoLaunchInGameOverlay'] = true

        if (!settings.hasOwnProperty('pushChoices'))
            settings['pushChoices'] = {runes:true, spells:true, items:true}
        
        if (!settings.hasOwnProperty('overlayOnlyOnTab'))
            settings['overlayOnlyOnTab'] = false
        
        if (!settings.hasOwnProperty('showJungleTimers'))
            settings['showJungleTimers'] = true

        if (!settings.hasOwnProperty('seenFeatures'))
            settings['seenFeatures'] = []
        
        return settings
    }
    else {
        let initialSettings : any = {
            local_uuid:uuidv4(),
            autoLaunchWithLeague:true,
            autoLaunchChampSelectWindow:true,
            inGameHideShowKey:'Ctrl+Shift+S',
            autoLaunchSecondScreen:true,
            secondScreenSwitchKey:'Ctrl+Shift+K',
            rotateChampionSelectHotkey:'Ctrl+Shift+Z',
            inGameNotification:true,
            autoLaunchInGameOverlay:true,
            firstTime:true,
            autoplayVideos:false,
            summonerInfo: {
                display_name: undefined,
                platform_id: undefined,
                summoner_name: undefined,
                game_name:undefined,
                tag_line:undefined
                // summoner_name: "Shushan",
                // platform_id: "NA1",
                // display_name: "HideOnBush"
            },
            scaleFactor:1.0,
            ignoreMissingHotkeys:false,
            pushChoices: {runes:true, spells:true, items:true},
            overlayOnlyOnTab: false,
            showJungleTimers: true,
            seenFeatures: []
        }

        window.setTimeout(() => {
            generateHotkey("inGameHideShowKey", 83, ["Shift", "Ctrl"]).then(() => {
                generateHotkey("secondScreenSwitchKey", 75, ["Shift", "Ctrl"]).then(() => {
                    generateHotkey("rotateChampionSelectHotkey", 90, ["Shift", "Ctrl"])
                })
            })
        }, 1000)

        localStorage.setItem(SETTINGS_KEY, JSON.stringify(initialSettings))
        return initialSettings
    }
}

export const whichDesktopWindow = () => {
    if (window.location.pathname == '/twitchStream') {
        return WINDOW_NAMES.STREAM
    }
    
    if (window.location.pathname.includes('/playerHighlights')) {
        return WINDOW_NAMES.SHARED_HIGHLIGHT
    }

    if (window.location.pathname.includes('/killChanceDemo')) {
        return WINDOW_NAMES.KILL_CHANCE_DEMO
    }

    return WINDOW_NAMES.DESKTOP
}

export const useMainStore = create((set:any) => ({
        hideAds: false,
        activeWindow: WINDOW_NAMES.LOADING,
        windowVisible:true,
        gameInfo: {
          champSelect:false,
          inGame:false,
          resolution:[1920, 1080]
        },
        vidStats: {
            total:0,
            thisPatch:0
        },
        key2champion:{},
        champion2key:{},
        item2data:{},
    currentUser: null,
        videosMuted:true,
        isPhone:(/Mobi|Android/i.test(navigator.userAgent)),
        isOverwolf: typeof(overwolf) != 'undefined',
        desktopWindow: whichDesktopWindow(),
        serverUrl: ((typeof(overwolf) == 'undefined') && (window.location.hostname.includes('www.'))) ? 'https://www.solokill.gg' : 'https://solokill.gg',
        // serverUrl: 'http://localhost:5005', 
        allChampions: [],
        abilityDescriptions: {},
        allPatches: [],
        metadata: {stat_runes:{}, general_runes:{}, items:{}},
        playerChampions: [],
        playerMetadata: {
            puuid:"",
            profileIconId:"",
            summonerName:"Summoner Name",
            level: "",
            league: "",
            tier:"Tier",
            lp:"",
            lastNGamesChampions:[],
            flash_key:''
        },
        mainLoading:false,
        downloadKey:null,
        bestSoloKillers:[],
        allPhases: ['Early', 'Mid', 'Late'],
        allRegions: ['BR', 'EUNE', 'EUW', 'KR', 'LAN', 'LAS', 'NA', 'TR'],
        settings: initSettings(),
        initMainStore: async (attempt=0) => {
            let store = useMainStore.getState()
            console.log('initMainStore, setMainLoading=true')
            store.setMainLoading(true)
            let url = store.serverUrl
            let path = `${url}/initData`
            
            if (cookies.get('downloadKey')) {
                store.setDownloadKey(cookies.get('downloadKey'))
            }
            console.log(path)
            let resolved = 0
            let body = JSON.stringify({user_id:store.currentUser ? store.currentUser : store.settings.local_uuid})
            console.log('initMainStore call - body', body)
            fetch(path, {method:'POST', body:body}).then(res => res.json()).then(data => {
                console.log('initDataRecieved', data)
                console.log('key2champion', JSON.stringify(data[2]))
                set({allChampions: data[0],
                    abilityDescriptions: data[1],
                    key2champion: data[2],
                    champion2key:swapKeysAndValues(data[2]),
                    allPatches: data[3],
                    vidStats: {total:data[4][0], thisPatch:data[4][1]},
                    item2data: data[5],
                    bestSoloKillers: data[6],
                    metadata: data[7]
                    })
                resolved += 1
                console.log('initMainStore, setMainLoading=false')
                store.setMainLoading(false)
            }).catch(() => {
                console.log("failed fetching init data!")
                if (attempt < 3) {
                    window.setTimeout(() => {
                        store.initMainStore(attempt + 1) 
                    }, (attempt + 1) * 1000)
                }
                else if (store.isOverwolf) {
                    overwolf.windows.displayMessageBox({message_title:"Solokill couldn't start",
                       message_body:"Please verify that you have an active internet connection",
                       confirm_button_text:'Retry', cancel_button_text:'Cancel',
                       // @ts-ignore
                       message_box_icon:overwolf.windows.enums.MessagePromptIcon.ExclamationMark},
                       (res:any) => {
                            if (res.confirmed)
                                window.location.reload()
                            else
                                overwolf.windows.close(WINDOW_NAMES.BACKGROUND)
                       })
                }
            });
        },
        updateSettings: (key:string, value:any, registerIfHotkey=true) => {
            let ms = useMainStore.getState()
            let newSettings : any = JSON.parse(JSON.stringify(ms.settings))
            newSettings[key] = value
            localStorage.setItem(SETTINGS_KEY, JSON.stringify(newSettings))

            return set({settings:newSettings})

            // if ((HOTKEY_KEYS.includes(key)) && (registerIfHotkey))
            //     refreshHotkeys(newSettings)
        },
        updateSeenFeatures: (key : string) => { 
            let ms = useMainStore.getState()
            let f = ms.settings.seenFeatures
            f.push(key)
            ms.updateSettings('seenFeatures', f)
        },
        updateGameInfo: (key:string, value:any) => {
            let curr : any = JSON.parse(JSON.stringify(useMainStore.getState().gameInfo))
            curr[key] = value
            return set({gameInfo:curr})
        },
        setMainLoading: (loading:boolean) => {
            return set({mainLoading:loading})
        },
        setDownloadKey:(key:string) => {
            return set({downloadKey:key});
        },
        setVideosMuted: (videosMuted:boolean) => {
            return set({videosMuted:videosMuted})
        },
        setCurrentUser: (user:string) => {
            return set({currentUser:user})
        },
        setHideAds: (hide:boolean) => {
            return set({hideAds:hide})
        },
        setActiveWindow: (newWindow:string) => set(({ activeWindow: newWindow })),
        setWindowVisible: (windowVisible:boolean) => {
            console.log('window visibility changed to ', windowVisible)

            return set({windowVisible: windowVisible})
        },
        setSummonerInfo: (summonerInfo:any) => {
            let store = useMainStore.getState()
            let currentSummonerInfo = store.settings.summonerInfo
            summonerInfo.display_name = currentSummonerInfo?.display_name
            console.log('setSummonerinfo', summonerInfo)
            if ((summonerInfo.summoner_name != undefined && summonerInfo.platform_id != undefined) || (summonerInfo.puuid != undefined) || (summonerInfo.game_name != undefined)) {
                store.updateSettings("summonerInfo", summonerInfo)
            }
        },
        setPlayerDisplayName: (gameName:any) => {
            let store = useMainStore.getState()
            let summonerInfo = store.settings.summonerInfo
            summonerInfo.display_name = gameName
            store.updateSettings("summonerInfo", summonerInfo)
        },
        getSummonerData: () => {
            let store = useMainStore.getState()
            let url = store.serverUrl
            let path = `${url}/getPlayerData`
            let body = JSON.stringify({
                    id_pair:[store.settings.summonerInfo.game_name, store.settings.summonerInfo.tag_line],
                    summonerName: store.settings.summonerInfo.summoner_name,
                    region: store.settings.summonerInfo.platform_id,
                })
            console.log('getSummonerData, body:', body)
            fetch(path, {
                'method': 'POST',
                'body': body
            }).then(res => res.json()).then(data => {
                let playerMetadata = JSON.parse(data[1])
                let new_state = Object({playerChampions: JSON.parse(data[0]), playerMetadata: playerMetadata})
                let gameName = store.settings.summonerInfo.summoner_name
                if (playerMetadata.gameName) {
                    gameName = playerMetadata.gameName                    
                }
                store.setPlayerDisplayName(gameName)
                console.log(JSON.stringify(new_state))
                return set(new_state)
            }).catch(() => {console.log("failed fetching summoner data!")});;
        },
        viewed: (videoId : any) => {
            let store = useMainStore.getState()
            let path = `${store.serverUrl}/viewed/${videoId}/`
            console.log(path)
            fetch(path, {method:'POST', body:JSON.stringify({user_id:store.currentUser ? store.currentUser : store.settings.local_uuid})}).then(res => res.json()).then(data => {
                console.log(data)
            }).catch(() => {console.log("failed setting viewed")});
        },
        sendSupportTicket: async (ticket : string) => {
            let store = useMainStore.getState()
            let path = `${store.serverUrl}/supportTicket/`
            let logRes : any = ""
            try  {
                logRes = await zipAppLogFolder()
            }
            catch (e) {
                ticket.concat('---couldnt fetch logs---')
            }
            console.log(path)
            fetch(path, {method:'POST',
                              body:JSON.stringify({text:ticket,
                                  user_id:store.currentUser ? store.currentUser : store.settings.local_uuid,
                                  logs:logRes})}).then(res => res.json()).then(data => {
                console.log(data)
            }).catch(() => {console.log("failed sending ticket")});
        }
    }
))

let init_location = window.location.pathname.split('/')[1]

export const useDesktopStore = create((set:Function, get:any) => ({
    pageTitle:null,
    stateResetCounter:0,
    isClipPlaying:false,
    isIdle:false,
    loading:true,
    killChancePredictionInfo:undefined,
    activePage: ((!useMainStore.getState().isOverwolf) && (Object.values(PAGE_NAMES).includes(init_location))) ? init_location : PAGE_NAMES.MOST_POPULAR,
    innerPage:false,
    videoSpotlight: null,
    inGameEnemyChampions: [null, null, null, null, null],
    alliedChampions: [],
    predictedEnemyLanes: [null, null, null, null, null],
    draftPlayerLane:null,
    playerSelectedDraftLane:false,
    inGamePlayerLevel: null,
    isGuide:false,
    killer: null,
    victim: null,
    lane:null,
    regions: ['all'],
    patches: ['all'],
    phases: ['all'],
    multiKills:['all'],
    searchedSummonerName:"",
    navigating:!useMainStore.getState().isOverwolf,
    secondScreenAvailable:false,
    otpList: [],
    jungleCampStates: {},
    offeredAugments: [],
    matchupMetadata: JSON.parse(BASE_MATCHUP_METADATA),
    killChancePrediction:undefined,
    displayedVideos: Object.fromEntries(new Map<number, {id:number, killer:string, victim:string, viewed:boolean,
                                saved:boolean, vote:number, views:number,
                                phase:string, totalUp:number, totalDown:number,
                                region:string, summoner:string, rank:string, patch:string, context:any, filename:string,
                                duration:number, otpPlayrate:number}>()),
    champSelectOpen: false,
    champSelectAnchor: null,
    spellsFlipped:false,
    pickType:PICK_TYPE.BLIND,
    selectedOTPRow:undefined,
    positionInHighlightQueue:0,
    magicHighlights: [],
    magicHighlightsPlayer: null,
    setInnerPage: (innerPage:boolean) => {
        let new_state = Object({ innerPage: innerPage })
        if (!innerPage) {
            new_state.videoSpotlight = null
        }
        set(new_state)
    },
    setInGameEnemyChampions: (enemyChampions:any, refresh=true) => {
        console.log('setInGameEnemyChampions, enemyChampions:', enemyChampions)
        let ds = useDesktopStore.getState()
        set({inGameEnemyChampions:enemyChampions})
        if ((ds.victim == null) && (ds.killer != null) && (enemyChampions[0] != null)) {
            ds.setVictim(enemyChampions[0], refresh, refresh)
        }
    },
    setAlliedChampions: (alliedChampions:any) => {
        console.log('setAlliedChampions, alliedChampions:', alliedChampions)
        set({alliedChampions:alliedChampions})
    },
    fetchPredictedEnemyLanes: (enemyChampions:any, enemyBans:any) => {
        console.log('fetchPredictedEnemyLanes, enemyChampions:', enemyChampions, 'enemyBans', enemyBans)
        let store = useMainStore.getState()
        let path = `${store.serverUrl}/lanePrediction/`
        fetch(path, {method:'POST',
                body:JSON.stringify({champions:enemyChampions, bans:enemyBans})}).then(res => res.json()).then(predictedLanes => {
                set({predictedEnemyLanes:predictedLanes})
        }).catch(() => {console.log("failed getting predicted lanes!")});
    },
    setInGamePlayerLevel: (level:number) => set({inGamePlayerLevel:level}),
    setDraftPlayerLane: (lane:string, player=false) => {
        let ds = useDesktopStore.getState()

        // Rejecting any changes not made by the player if the player changed the draft role
        if ((ds.playerSelectedDraftLane) && (!player))
            return

        set({draftPlayerLane:lane})
    },
    setPlayerSelectedDraftLane: (playerSelected:boolean) => set({playerSelectedDraftLane:playerSelected}),
    setSearchedSummonerName: (searchedSummonerName:string, update=true) => {
        let ds = useDesktopStore.getState()
        set(({ searchedSummonerName: searchedSummonerName }))

        if (update)
            ds.refreshVideos()
    },
    setInGameEnemyToNext: () => {
      let s = useDesktopStore.getState()
      let curr_idx = s.victim == null ? -1 : s.inGameEnemyChampions.indexOf(s.victim)
      let actualLength = s.inGameEnemyChampions.filter((v) => v != null).length

      s.setVictim(s.inGameEnemyChampions[(curr_idx + 1) % actualLength], true, true)
    },
    setActivePage: (newPage:string) => set(({ activePage: newPage })),
    setIsIdle: (idle:boolean) => {
        return set({isIdle:idle})
    },
    setIsClipPlaying: (isClipPlaying:boolean) => {
        console.log('isClipPlaying', isClipPlaying)
        return set({isClipPlaying:isClipPlaying})
    },
    setOfferedAugments: (offeredAugments: any) => {
        return set({offeredAugments:offeredAugments})
    },
    setSpellsFlipped: (isFlipped:boolean) => set(({ spellsFlipped: isFlipped })),
    setIsGuide: (isGuide:boolean) => set(({ isGuide: isGuide })),
    setSecondScreenAvailable: (secondScreenAvailable:boolean) => set(({secondScreenAvailable:secondScreenAvailable})),
    setKiller: (killer: string | null, refresh=true) => {
        console.log('setKiller', killer, refresh)
        let ds = useDesktopStore.getState()
        if (ds.killer == killer) {
            console.log('skipping, same killer')
            return;
        }
        set ({killer:killer})
        if (refresh) {
            ds.refreshVideos().then(() => {
                ds.getMatchupMetadata(killer, ds.victim)
            })
        }
    },
    setNavigating: (navigating:boolean) => (set({navigating:navigating})),
    setLane: (lane:any) => (set({lane:lane})),
    setVictim: (victim:string | null, refresh=true, goToMatch=false)  => {
        console.log('setVictim', victim, refresh, goToMatch)
        let ds = useDesktopStore.getState()
        if (ds.victim == victim) {
            console.log('skipping, same victim')
            return;
        }
        set(({ victim: victim }))
        if (refresh) {
            ds.refreshVideos(goToMatch).then(() => {
                ds.getMatchupMetadata(ds.killer, victim)
            })
        }
    },
    // setBoth:(killer:string, victim:string, refresh=true, goToMatch=false) => {
    //     console.log('setBoth', killer, victim, refresh, goToMatch)
    //     let ds = useDesktopStore.getState()
    //
    //     if (!refresh) {
    //         set(({killer: killer, victim: victim}))
    //     }
    //     else {
    //         ds.refreshVideos(goToMatch).then(() => {
    //             ds.getMatchupMetadata(ds.killer, victim, ds.pickType == PICK_TYPE.ARAM)
    //         })
    //     }
    // },
    setRegions: ((regions:any[], update:boolean) => {
        let ds = useDesktopStore.getState()
        set(({ regions: regions }))
        if (regions.length == 0)
            return
        if (update)
            ds.refreshVideos()
    }),
    setPatches: ((patches:any[], update:boolean) => {
        let ds = useDesktopStore.getState()
        set(({ patches: patches }))
        if (patches.length == 0)
            return
        if (update)
            ds.refreshVideos()
    }),

    setPhases: ((phases:any[], update:boolean) => {
        let ds = useDesktopStore.getState()
        set(({ phases: phases }))
        if (phases.length == 0)
            return
        if (update)
            ds.refreshVideos()
    }),
    setMultiKills: ((multiKills:any[], update:boolean) => {
        let ds = useDesktopStore.getState()
        set(({ multiKills: multiKills }))
        if (multiKills.length == 0)
            return
        if (update)
            ds.refreshVideos()
    }),
    setChampSelectOpen: (open:boolean) => set(({champSelectOpen:open})),
    setChampSelectAnchor: (anchorElem:HTMLButtonElement | null) => set(({champSelectAnchor:anchorElem})),
    setSaved: (vimeoId:number, saved:boolean) => {
        set((state:any) => {
            let store = useMainStore.getState()
            let vids = Object.assign({}, state.displayedVideos);
            if (vids['v'+vimeoId]) {
                vids['v'+vimeoId].saved = saved
            }
            let path = `${store.serverUrl}/save/${vimeoId}/`
            console.log(path)
            fetch(path, {method:'POST', body:JSON.stringify({user_id:store.currentUser ? store.currentUser : store.settings.local_uuid})}).then(res => res.json()).then(data => {
                console.log(data)
            }).catch(() => {console.log("failed setting saved!")});

            return {displayedVideos:vids}
        })
    },
    setVote: (vimeoId:number, newVote:number) => {
        set((state:any) => {
            let store = useMainStore.getState()
            let vids = Object.assign({}, state.displayedVideos);
            if (vids['v'+vimeoId]) {
                vids['v'+vimeoId].vote = newVote
            }
            let path = `${store.serverUrl}/vote/${vimeoId}/${newVote}`
            fetch(path, {method:'POST', body:JSON.stringify({user_id:store.currentUser ? store.currentUser : store.settings.local_uuid})}).then(res => res.json()).then(data => {
                console.log(data)
            }).catch(() => {console.log("failed setting vote!")});

            return {displayedVideos:vids}
        })
    },
    setPageTitle: (title:string) => {
        return set({pageTitle:title})
    },
    setLoading: (loading:boolean) => {
        return set({loading:loading})
    },
    refreshVideos: async (goToMatch?:boolean, highlightVideo?:number) => {
        return new Promise((resolve, reject) => {
            goToMatch = goToMatch ? goToMatch : false

            let mainState = useMainStore.getState()
            let path = `${mainState.serverUrl}/videos`
            if (mainState.activeWindow == WINDOW_NAMES.KILL_CHANCE_DEMO) { 
                path = `${mainState.serverUrl}/demoMatchups`
            }
            let ds = useDesktopStore.getState()
            console.log('invoking', path)
            let savedOnly = ds.activePage==PAGE_NAMES.SAVED
             let body = JSON.stringify({'patches': ds.patches,
                        'regions': ds.regions,
                        'searchedSummonerName':ds.searchedSummonerName,
                        'phases': ds.phases, 'highlightVideo': highlightVideo ? highlightVideo : null,
                       'savedOnly':savedOnly, multiKills:ds.multiKills,
                        victim:ds.victim, killer:ds.killer,
                        multiKillMode: ds.activePage == PAGE_NAMES.MULTIKILL,
                        user_id:mainState.currentUser ? mainState.currentUser : mainState.settings.local_uuid,
                        summonerName:mainState.settings.summonerInfo.summoner_name,
                        region:mainState.settings.summonerInfo.platform_id,
                        streamMode:mainState.activeWindow == WINDOW_NAMES.STREAM,
                        version:2.0,
                        })
            console.log('refreshing videos state! body: ', body)
            console.log('refreshVideos, setLoading=true')
            ds.setLoading(true)

            // Abort on-going fetch requests
            refreshVideosAbortController.abort()
            refreshVideosAbortController = new AbortController()

            fetch(path, {'method':'POST', 'body':body, 'signal':refreshVideosAbortController.signal}).then(res => res.json()).then(data => {
                let data_obj = Object.fromEntries(data)
                let new_state = Object({displayedVideos: data_obj, loading:false})
                if ((ds.videoSpotlight != null) || (goToMatch)) {
                    if (data.length == 0) {
                        new_state.videoSpotlight = 0
                    }
                    else {
                        new_state.videoSpotlight = data[0][1].id
                    }
                    new_state.innerPage = true
                }
                // console.log(JSON.stringify(new_state))
                console.log('refreshVideos, setLoading=false')
                ds.setLoading(false)
                set(new_state)
                resolve(null)
            }).catch(() => {
                console.log("failed getting video list!")});
          });

    },
    getMatchupMetadata: async (killer:null | string, victim:null | string) => {
        return new Promise((resolve, reject) => {
           let ms = useMainStore.getState()
           let ds = useDesktopStore.getState()
           let pickType = ds.pickType
           if (pickType == PICK_TYPE.ARENA)
                victim = "arena"
           console.log('refreshing match metadata!')
            if (!killer || (!victim) || killer == victim) {
                // reject()
                return
            }

            if ((killer == ds.matchupMetadata.killer) && (victim == ds.matchupMetadata.victim)) {
                resolve(true)
                return
            }

            if (!victim)
                victim = ""

            let path = `${useMainStore.getState().serverUrl}/${ms.isOverwolf ? "guideFull" : "guide"}${pickType == PICK_TYPE.ARAM ? "/aram/" : pickType == PICK_TYPE.ARENA ? "/arena/" : "/"}${killer}/${victim}`

            if (pickType == PICK_TYPE.DRAFT)
                path += `/${ds.draftPlayerLane}`
            console.log('getMatchupMetadata', path)
            console.log('getMatchupMetadata, setLoading=true')
            ds.setLoading(true)

            fetch(path).then(res => res.json()).then(data => {
                let new_state = Object({matchupMetadata: data, loading:false})
                console.log('getMatchupMetadata, setLoading=false')
                ds.setLoading(false)
                set(new_state)
                resolve(true)
            }).catch(() => {console.log("failed getting matchup metadata in state!")});
        })
    },
    getOTPList: async () => {
        return new Promise((resolve, reject) => {
           let ms = useMainStore.getState()
           let ds = useDesktopStore.getState()
           console.log('fetching OTP data')
           
            let path = `${useMainStore.getState().serverUrl}/otpList`
            ds.setLoading(true)

            fetch(path).then(res => res.json()).then(data => {
                ds.setLoading(false)
                set({otpList:data})
                if (ds.searchedSummonerName) {
                    let matching_otps = data.filter((v : any) => v.summoner_id == ds.searchedSummonerName)
                    console.log(matching_otps, 'matching_otps')
                    if (matching_otps)
                        ds.setSelectedOTPRow(matching_otps[0])
                }
                resolve(true)
            }).catch(() => {console.log("failed getting OTP list")});
        })
    },
    getMatchupMetadataByLane: async (killer:null | string, lane:null | string) => {
       let ds = useDesktopStore.getState()
        return ds.getMatchupMetadata(killer, "all-" + lane?.toLowerCase())
    },
    getAramMatchupMetadata: async (killer:null | string, victim:any) => {
       let ds = useDesktopStore.getState()
        return ds.getMatchupMetadata(killer, isNaN(victim) ? victim : "all-aram-" + victim)
    },
    setPickType: (pickType:any) => {
      return set({pickType:pickType})
      // return set({pickType:PICK_TYPE.BLIND})
    },
    setKillChancePrediction: (killChance:any, newPredInfo:any) => {
        return set({killChancePrediction:killChance, killChancePredictionInfo:newPredInfo})
    },
    resetState: () => {
        let curr_state = useDesktopStore.getState()
        return set({isGuide:false, stateResetCounter:curr_state.stateResetCounter + 1,
            inGameEnemyChampions: [null, null, null, null, null],
            predictedEnemyLanes: [null, null, null, null, null],
            alliedChampions: [],
            draftPlayerLane:null,
            playerSelectedDraftLane:false,
            killer:null, victim:null,
            inGamePlayerLevel: null, lane:null,
            jungleCampStates: {},
            killChancePredictionInfo:undefined,
            killChancePrediction:undefined,
            selectedOTPRow:undefined,
            regions:["all"], searchedSummonerName:"", patches:['all'], phases:['all'], multiKills:['all'], champSelectOpen:false,
            matchupMetadata:JSON.parse(BASE_MATCHUP_METADATA), champSelectAnchor:null, displayedVideos: {},
            videoSpotlight:null, innerPage:false, loading:true, full_guide:{}})
    },
    setVideoSpotlight: (vimeoId:any) => {
        console.log(vimeoId)
        let s = useDesktopStore.getState()

        if (vimeoId == null) {
            return set({innerPage:false})
        }
        else {
            // let vid = s.displayedVideos[vimeoId]
            return set({videoSpotlight:vimeoId, innerPage:true})
        }
    },
    setVideoSpotlightClean: (vimeoId : any) => {
      return set({videoSpotlight:vimeoId})
    },
    setSelectedOTPRow: (row : any) => {
        return set({selectedOTPRow:row})
    },
    goToNextVideo: () => {
        console.log('GoToNextVideo triggered!')
        let s = useDesktopStore.getState()
        let keys = Object.keys(s.displayedVideos);
        let nextIndex = keys.indexOf('v' + s.videoSpotlight) + 1;
        console.log(s.displayedVideos[keys[nextIndex]].id)
        return set({videoSpotlight:s.displayedVideos[keys[nextIndex]].id})
    },
    goToPreviousVideo: () => {
        console.log('goToPreviousVideo triggered!')
        let s = useDesktopStore.getState()
        let keys = Object.keys(s.displayedVideos);
        let nextIndex = keys.indexOf('v' + s.videoSpotlight) - 1;
        console.log(s.displayedVideos[keys[nextIndex]].id)
        return set({videoSpotlight:s.displayedVideos[keys[nextIndex]].id})
    },
    preparePlayerDataForPrediction: (player_data : any, color : string) => {
        let itemData : any = useMainStore.getState().item2data
        let out : any = {}
        out[`${color}_champion`] = player_data.championName.replace(' ', '')
        out[`${color}_items`] = player_data.items.filter((v:any) => !v.consumable && v.slot != 6).map((v : any) => v.itemID)
        out[`${color}_gold`] = out[`${color}_items`].reduce((partial_sum : number, itemID : any) => partial_sum + itemData[itemID.toString()][2], 0)
        out[`${color}_level`] = player_data.level
        out[`${color}_general_runes`] = (player_data.runes.keystone.id).toString()
        out[`${color}_summoner`] = Object.values(player_data.summonerSpells).map((v : any) => v.displayName)
        // console.log('preparePlayerDataForPrediction', JSON.stringify(player_data), JSON.stringify(out))
        return out
    },
    getKillChancePrediction: (player_info : any, enemy_info : any) => {
            let store = useMainStore.getState()
            let ds = useDesktopStore.getState()
            let newPredInfo = Object.assign({}, player_info, enemy_info)

            if (JSON.stringify(newPredInfo) == JSON.stringify(ds.killChancePredictionInfo)) {
                console.log('getKillChancePrediction - no change in prediction input')
                return;
            }

            let path = `${store.serverUrl}/killChancePrediction`
            console.log(path)
            fetch(path, {method:'POST',
                              body:JSON.stringify(Object.assign({}, player_info, enemy_info))}).then(res => res.json()).then(data => {
                console.log('getKillChancePrediction', data)
                ds.setKillChancePrediction(data[0], newPredInfo)
            }).catch(() => {console.log("get kill chance failed")});
    },
    getMagicHighlight: (uuid : string) => {
        let store = useMainStore.getState()
        let path = `${store.serverUrl}/getHighlight/`
        console.log(path)
        fetch(path, {method:'POST',
            body:JSON.stringify({uuid:uuid})}).then(res => res.json()).then((data) => {
                return set({magicHighlights: data['highlights'],
                            magicHighlightsPlayer: data['player_data']})
        }).catch(() => {console.log("failed fetching highlight for uuid")});
    },
    updateMagicHighlights: () => {
        let store = useMainStore.getState()
        let puuid = store.playerMetadata.puuid
        let path = `${store.serverUrl}/getHighlights/`
        console.log(path)
        fetch(path, {method:'POST',
            body:JSON.stringify({puuid:puuid})}).then(res => res.json()).then((data) => {
                return set({positionInHighlightQueue:data['positionInQueue'],
                            magicHighlights: data['highlights'],
                            magicHighlightsPlayer: data['player_data']})
        }).catch(() => {console.log("failed updating position in highlight queue")});
    },
    queueHighlight: () => {
        let store = useMainStore.getState()
        let ds = useDesktopStore.getState()
        let puuid = store.playerMetadata.puuid
        let path = `${store.serverUrl}/queueHighlight/`
        console.log(path)
        fetch(path, {method:'POST',
            body:JSON.stringify({puuid:puuid})}).then(res => res.json()).then(() => {
            ds.updateMagicHighlights()
        }).catch(() => {console.log("failed getting queue highlight")});
    }
}))

// progressive enhancement check.
if ("BroadcastChannel" in globalThis /* || isSupported() */) {
    if (useMainStore.getState().isOverwolf) {
        const muteHidden = (state: overwolf.windows.WindowStateChangedEvent | overwolf.windows.GetWindowStateResult) => {
            console.log('muteHidden', JSON.stringify(state))
            if (state.window_state_ex) {
                let isVisible = ["normal", "maximized"].includes(state.window_state_ex)
                useMainStore.getState().setWindowVisible(isVisible)
            }
        }

        overwolf.windows.getCurrentWindow((current) => {
            window_id = current.window.id
            overwolf.windows.getWindowState(current.window.id, (state) => {
                muteHidden(state)
            })
            overwolf.windows.onStateChanged.addListener((new_state) => {
                current.window.id == new_state.window_id && muteHidden(new_state)
            })


        share("playerChampions", useMainStore, {initialize: true});
        share("playerMetadata", useMainStore, {initialize: true});
        share("currentUser", useMainStore, {initialize: true});
        share("settings", useMainStore, {initialize: true})
        share("gameInfo", useMainStore, {initialize:true})

            // Other share types are specific to the champ-select -> ingame flow and don't need to be shared with desktop
            if (current.window.name != WINDOW_NAMES.DESKTOP) {
                share("videoSpotlight", useDesktopStore, {initialize: true});
                share("displayedVideos", useDesktopStore, {initialize: true});
                share("lane", useDesktopStore, {initialize: true});
                share("killer", useDesktopStore, {initialize: true});
                share("victim", useDesktopStore, {initialize: true});
                share("champSelectOpen", useDesktopStore, {initialize: true});
                share("inGameEnemyChampions", useDesktopStore, {initialize: true});
                share("alliedChampions", useDesktopStore, {initialize: true});
                share("secondScreenAvailable", useDesktopStore, {initialize: true})
                share("inGamePlayerLevel", useDesktopStore, {initialize: true});
                share("matchupMetadata", useDesktopStore, {initialize: true});
                share("pickType", useDesktopStore, {initialize: true});
                share("predictedEnemyLanes", useDesktopStore, {initialize:true})
                share("draftPlayerLane", useDesktopStore, {initialize:true})
                share("playerSelectedDraftLane", useDesktopStore, {initialize:true})
                share("killChancePrediction", useDesktopStore, {initialize:true})
                share("killChancePredictionInfo", useDesktopStore, {initialize:true})
                share("offeredAugments", useDesktopStore, {initialize:true})
            }
        })
    }
}