import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Badge, Box, Stack } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { alpha } from "@mui/material";
import theme from "../theme";
import FeatureHighlightTooltip from "../components/FeatureHighlightTooltip";

export const mainLogo =
    (<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" fill="#FFE901"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2023 18.7577C36.2189 15.9962 32.148 14.2865 27.6766 14.2865C18.5813 14.2865 11.1434 21.3606 11.1434 29.9707C11.1434 34.5431 13.1643 38.6761 16.3875 41.5855L20.7461 37.2244C20.3758 35.2062 20.1983 32.7898 20.1983 29.9707C20.1983 19.6628 22.6641 15.2162 27.6766 15.2162C31.5378 15.2162 33.7811 17.8758 34.6854 23.2771L39.2023 18.7577Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7997 41.6756C23.7832 44.4373 27.8543 46.1472 32.3259 46.1472C41.4212 46.1472 48.8591 39.0731 48.8591 30.4629C48.8591 25.8904 46.838 21.7572 43.6146 18.8478L39.2563 23.2086C39.6266 25.2269 39.8042 27.6435 39.8042 30.4629C39.8042 40.7709 37.3384 45.2175 32.3259 45.2175C28.4645 45.2175 26.2212 42.5576 25.3169 37.1558L20.7997 41.6756Z" fill="black"/>
        <rect x="29.521" y="24.1451" width="7.57673" height="7.57673" rx="3.78837" transform="rotate(45.7146 29.521 24.1451)" fill="black"/>
        </svg>)

export const flexLogo = (width:string, height:string) =>
    (<svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" fill="#FFE901"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2023 18.7577C36.2189 15.9962 32.148 14.2865 27.6766 14.2865C18.5813 14.2865 11.1434 21.3606 11.1434 29.9707C11.1434 34.5431 13.1643 38.6761 16.3875 41.5855L20.7461 37.2244C20.3758 35.2062 20.1983 32.7898 20.1983 29.9707C20.1983 19.6628 22.6641 15.2162 27.6766 15.2162C31.5378 15.2162 33.7811 17.8758 34.6854 23.2771L39.2023 18.7577Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7997 41.6756C23.7832 44.4373 27.8543 46.1472 32.3259 46.1472C41.4212 46.1472 48.8591 39.0731 48.8591 30.4629C48.8591 25.8904 46.838 21.7572 43.6146 18.8478L39.2563 23.2086C39.6266 25.2269 39.8042 27.6435 39.8042 30.4629C39.8042 40.7709 37.3384 45.2175 32.3259 45.2175C28.4645 45.2175 26.2212 42.5576 25.3169 37.1558L20.7997 41.6756Z" fill="black"/>
        <rect x="29.521" y="24.1451" width="7.57673" height="7.57673" rx="3.78837" transform="rotate(45.7146 29.521 24.1451)" fill="black"/>
        </svg>)

export const settingsLogo = (fillColor : string|undefined) => (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.3333 16.6833V13.65H20.7667C20.65 13.1833 20.4167 12.7167 20.1833 12.25L22.05 10.3833L19.8333 8.28333L17.9667 10.15C17.5 9.91667 17.0333 9.68333 16.5667 9.56667V7H13.5333V9.56667C13.0667 9.68333 12.6 9.91667 12.1333 10.15L10.5 8.28333L8.28333 10.5L10.15 12.3667C9.91667 12.8333 9.68333 13.3 9.56667 13.7667H7V16.8H9.56667C9.68333 17.2667 9.91667 17.7333 10.15 18.2L8.28333 19.8333L10.3833 21.9333L12.25 20.0667C12.7167 20.3 13.1833 20.5333 13.65 20.65V23.3333H16.6833V20.7667C17.15 20.65 17.6167 20.4167 18.0833 20.1833L19.95 22.05L22.05 19.95L20.1833 18.0833C20.4167 17.6167 20.65 17.15 20.7667 16.6833H23.3333ZM15.1667 18.4333C13.4167 18.4333 11.9 17.0333 11.9 15.1667C11.9 13.4167 13.3 11.9 15.1667 11.9C17.0333 11.9 18.4333 13.3 18.4333 15.1667C18.4333 16.9167 16.9167 18.4333 15.1667 18.4333Z" fill={fillColor}/>
</svg>
)

export const thumbsUpLogo = (fillColor : string|undefined) => <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60"/>
<path d="M24.8272 27.3705C29.4908 22.7553 30.1713 18.8792 28.6008 17C28.6008 17 40.4717 21.8665 33.4892 33.1538C32.2939 35.0705 29.3088 37.3345 30.8695 42C30.8695 42 22.399 39.6343 22.0137 34C21.8874 32.1523 22.6306 29.9531 24.8272 27.3705Z" fill={fillColor}/>
<path d="M32.0369 41.7704C31.7817 40.8236 30.643 38.7614 34.1152 34.6598C35.9706 32.4737 37.2404 27.6712 36.6221 26.5245C36.6221 26.5245 42.5481 34.3323 35.69 39.0083C31.6235 41.7879 32.0368 41.7701 32.0368 41.7701L32.0369 41.7704Z" fill={fillColor}/>
</svg>


export const playLogo = (fillColor : string|undefined) => (<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60"/>
<path d="M28.8708 24.9733L28.75 24.9004V24.7593V22.2407V22.0996L28.8708 22.0267L40.8708 14.7859L41.25 14.5572V15V18.4292V18.5748L41.1234 18.6467L32.5722 23.4998L41.1232 28.342L41.25 28.4138V28.5595V32V32.4428L40.8708 32.2141L28.8708 24.9733Z" fill={fillColor} stroke={fillColor} stroke-width="0.5"/>
<path d="M32.1292 35.9733L32.25 35.9004V35.7593V33.2407V33.0996L32.1292 33.0267L20.1292 25.7859L19.75 25.5572V26V29.4292V29.5748L19.8766 29.6467L28.4278 34.4998L19.8768 39.342L19.75 39.4138V39.5595V43V43.4428L20.1292 43.2141L32.1292 35.9733Z" fill={fillColor} stroke={fillColor} stroke-width="0.5"/>
</svg>)


export const plusLogo = (fillColor : string|undefined) => (<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60"/>
<path d="M28.4998 19.4998L33.7497 19.5005L31.5 40.4995L25.4997 40.4999L28.4998 19.4998Z" fill={fillColor}/>
<path d="M40.5 27.0002L39 31.6057L19.5 31.6057L21 27.0002L40.5 27.0002Z" fill={fillColor}/>
</svg>
)


export const otpLogo = (fillColor : string|undefined) => {
    let invisibleBadge = (new Date() >= new Date('July 1, 2023'));
    return <Stack sx={{height:60, width:60}}><Badge invisible={invisibleBadge} color="secondary" badgeContent={"NEW!"} sx={{'& .MuiBadge-badge':{top:10}}}><SvgIcon sx={{height:32, width:32, color:fillColor, position:"absolute", left:15, top:14}}><EmojiEventsIcon/></SvgIcon></Badge></Stack>
}

export const matchHistoryLogo = (fillColor : string|undefined) => (
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60"/>
    <path d="M40 20L38.5 24L19 24L20.5 20L40 20Z" fill={fillColor}/>
    <path d="M40 27L38.5 31L19 31L20.5 27L40 27Z" fill={fillColor}/>
    <path d="M40 34L38.5 38L19 38L20.5 34L40 34Z" fill={fillColor}/>
</svg>
)

export const magicHighlightsLogo = (fillColor : string|undefined) => (
    <FeatureHighlightTooltip tooltipText={<Stack direction="column" sx={{width:'275px'}}><Box>New! Solokill makes a magic highlight of <Box component="span" sx={{fontWeight:"bold"}}>YOUR</Box> best plays.</Box><Box sx={{fontStyle:'italic', color:'primary.main', fontWeight:700}}>With zero effort, and zero FPS impact! </Box></Stack>} featureName={"magic-highlights"}>
        <Stack sx={{width:'60px', height:'60px'}} alignItems="center" justifyContent="center">
        <svg width='32' height='32' fill={fillColor} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
        <g>
            <g>
                <polygon points="450.596,125.669 483.57,28.451 386.347,61.421 304.074,0.022 305.386,102.673 221.569,161.94 319.6,192.422 
                    350.077,290.446 409.349,206.635 512,207.945 		"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M290.462,221.56l-39.383-12.247L10.684,449.708c-14.245,14.245-14.245,37.342,0,51.587
                    c14.244,14.244,37.339,14.244,51.585,0l240.416-240.416L290.462,221.56z"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="287.882" y="360.828" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -178.3797 327.4832)" width="36.471" height="36.473"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="456.628" y="297.996" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -84.5264 428.4027)" width="36.472" height="36.475"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="105.494" y="190.604" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -111.4333 148.6604)" width="36.476" height="36.476"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="175.484" y="29.589" transform="matrix(0.7071 -0.7071 0.7071 0.7071 22.921 150.99)" width="36.476" height="36.476"/>
            </g>
        </g>
        </svg>
    </Stack>
</FeatureHighlightTooltip>
)

export const logoText = (<svg width="134" height="24" viewBox="0 0 134 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.24022 24C12.8736 24 15.9533 21.101 15.9533 17.1707C15.9533 9.36585 4.13398 10.8432 4.13398 5.88153C4.13398 4.09756 5.52122 2.84321 7.51885 2.84321C10.2656 2.84321 12.9291 5.71429 15.5094 10.3972L14.6215 4.09756V3.54007C12.5684 2.62021 10.5153 2.20209 8.40669 2.20209C3.74555 2.20209 0.221959 5.12892 0.221959 9.14286C0.221959 16.7247 12.0135 14.9686 12.0135 20.5157C12.0135 22.1882 10.7373 23.3589 8.87835 23.3589C5.32701 23.3589 2.21959 19.8746 0 15.0244L0.998814 22.5505V23.108C2.91321 23.6376 5.90965 24 8.24022 24Z" fill="#FEFDFD"/>
<path d="M58.4513 23.6655V23.108L59.2282 14.8571L53.596 22.9965H48.1857V4.57143L49.8504 2.75958H40.8888L42.3316 4.57143V21.8537L40.8888 23.6655H58.4513Z" fill="#FEFDFD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M85.2282 23.6655H93.9678L92.5251 21.8537V12.8223L101.098 23.6655H108.673L98.5867 11.3728L107.785 2.75958H101.598L93.7776 10.4065H92.5251V4.57143L93.9678 2.75958H85.2282L86.6709 4.57143V21.8537L85.2282 23.6655ZM108.593 23.6655H116.445L115.335 22.2718V7.80488L108.205 10.7782L109.703 12.3484V22.2718L108.593 23.6655Z" fill="#FEFDFD"/>
<path d="M117.424 23.6655H125.275L124.166 22.2718V0L117.083 2.97329L118.533 3.87456V22.2718L117.424 23.6655Z" fill="#FEFDFD"/>
<path d="M126.148 23.6655H134L132.89 22.2718V0L125.777 2.97329L127.258 3.87456V22.2718L126.148 23.6655Z" fill="#FEFDFD"/>
<path d="M110.614 2.18784C111.638 1.18459 113.277 1.20526 114.276 2.23373C115.274 3.2622 115.254 4.90945 114.23 5.9127C113.206 6.91594 111.567 6.89528 110.568 5.86681C109.57 4.83834 109.59 3.19109 110.614 2.18784Z" fill="#FEFDFD"/>
<path d="M78.2123 5.78922C76.1885 4.02713 73.4954 2.94669 70.5491 2.94669C64.3001 2.94669 59.1898 7.80701 59.1898 13.7227C59 17 60.4874 19.5144 62.5743 21.5003L65.7237 18.3362C65.5126 17.0273 65.411 15.4902 65.411 13.7227C65.411 6.64053 67.1052 3.58548 70.5491 3.58548C73.1046 3.58548 74.6286 5.28111 75.2924 8.72277L78.2123 5.78922Z" fill="#FEFDFD"/>
<path d="M66.0147 21.1446C68.0385 22.9067 70.7317 23.9872 73.678 23.9872C79.927 23.9872 85.0373 19.1268 85.0373 13.2111C85.0373 10.1742 83.7397 7.41941 81.6527 5.4335L78.5034 8.59752C78.7144 9.90653 78.816 11.4436 78.816 13.2111C78.816 20.2933 77.1219 23.3484 73.678 23.3484C71.1225 23.3484 69.5984 21.6527 68.9346 18.211L66.0147 21.1446Z" fill="#FEFDFD"/>
<path d="M34.5607 5.78922C32.5369 4.02713 29.8438 2.94669 26.8974 2.94669C20.6485 2.94669 15.5382 7.80701 15.5382 13.7227C15.5382 16.7596 16.8358 19.5144 18.9227 21.5003L22.0721 18.3362C21.861 17.0273 21.7594 15.4902 21.7594 13.7227C21.7594 6.64053 23.4536 3.58548 26.8974 3.58548C29.453 3.58548 30.977 5.28111 31.6408 8.72277L34.5607 5.78922Z" fill="#FEFDFD"/>
<path d="M22.3629 21.1443C24.3866 22.9063 27.0797 23.9867 30.0259 23.9867C36.2749 23.9867 41.3852 19.1264 41.3852 13.2107C41.3852 10.1739 40.0877 7.41914 38.0008 5.43325L34.8513 8.59745C35.0624 9.90637 35.1639 11.4433 35.1639 13.2107C35.1639 20.2928 33.4698 23.3479 30.0259 23.3479C27.4705 23.3479 25.9465 21.6524 25.2826 18.2109L22.3629 21.1443Z" fill="#FEFDFD"/>
</svg>
)

export const flexLogoText = (width:string, height:string) => (<svg width={width} height={height} viewBox="0 0 134 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.24022 24C12.8736 24 15.9533 21.101 15.9533 17.1707C15.9533 9.36585 4.13398 10.8432 4.13398 5.88153C4.13398 4.09756 5.52122 2.84321 7.51885 2.84321C10.2656 2.84321 12.9291 5.71429 15.5094 10.3972L14.6215 4.09756V3.54007C12.5684 2.62021 10.5153 2.20209 8.40669 2.20209C3.74555 2.20209 0.221959 5.12892 0.221959 9.14286C0.221959 16.7247 12.0135 14.9686 12.0135 20.5157C12.0135 22.1882 10.7373 23.3589 8.87835 23.3589C5.32701 23.3589 2.21959 19.8746 0 15.0244L0.998814 22.5505V23.108C2.91321 23.6376 5.90965 24 8.24022 24Z" fill="#FEFDFD"/>
<path d="M58.4513 23.6655V23.108L59.2282 14.8571L53.596 22.9965H48.1857V4.57143L49.8504 2.75958H40.8888L42.3316 4.57143V21.8537L40.8888 23.6655H58.4513Z" fill="#FEFDFD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M85.2282 23.6655H93.9678L92.5251 21.8537V12.8223L101.098 23.6655H108.673L98.5867 11.3728L107.785 2.75958H101.598L93.7776 10.4065H92.5251V4.57143L93.9678 2.75958H85.2282L86.6709 4.57143V21.8537L85.2282 23.6655ZM108.593 23.6655H116.445L115.335 22.2718V7.80488L108.205 10.7782L109.703 12.3484V22.2718L108.593 23.6655Z" fill="#FEFDFD"/>
<path d="M117.424 23.6655H125.275L124.166 22.2718V0L117.083 2.97329L118.533 3.87456V22.2718L117.424 23.6655Z" fill="#FEFDFD"/>
<path d="M126.148 23.6655H134L132.89 22.2718V0L125.777 2.97329L127.258 3.87456V22.2718L126.148 23.6655Z" fill="#FEFDFD"/>
<path d="M110.614 2.18784C111.638 1.18459 113.277 1.20526 114.276 2.23373C115.274 3.2622 115.254 4.90945 114.23 5.9127C113.206 6.91594 111.567 6.89528 110.568 5.86681C109.57 4.83834 109.59 3.19109 110.614 2.18784Z" fill="#FEFDFD"/>
<path d="M78.2123 5.78922C76.1885 4.02713 73.4954 2.94669 70.5491 2.94669C64.3001 2.94669 59.1898 7.80701 59.1898 13.7227C59 17 60.4874 19.5144 62.5743 21.5003L65.7237 18.3362C65.5126 17.0273 65.411 15.4902 65.411 13.7227C65.411 6.64053 67.1052 3.58548 70.5491 3.58548C73.1046 3.58548 74.6286 5.28111 75.2924 8.72277L78.2123 5.78922Z" fill="#FEFDFD"/>
<path d="M66.0147 21.1446C68.0385 22.9067 70.7317 23.9872 73.678 23.9872C79.927 23.9872 85.0373 19.1268 85.0373 13.2111C85.0373 10.1742 83.7397 7.41941 81.6527 5.4335L78.5034 8.59752C78.7144 9.90653 78.816 11.4436 78.816 13.2111C78.816 20.2933 77.1219 23.3484 73.678 23.3484C71.1225 23.3484 69.5984 21.6527 68.9346 18.211L66.0147 21.1446Z" fill="#FEFDFD"/>
<path d="M34.5607 5.78922C32.5369 4.02713 29.8438 2.94669 26.8974 2.94669C20.6485 2.94669 15.5382 7.80701 15.5382 13.7227C15.5382 16.7596 16.8358 19.5144 18.9227 21.5003L22.0721 18.3362C21.861 17.0273 21.7594 15.4902 21.7594 13.7227C21.7594 6.64053 23.4536 3.58548 26.8974 3.58548C29.453 3.58548 30.977 5.28111 31.6408 8.72277L34.5607 5.78922Z" fill="#FEFDFD"/>
<path d="M22.3629 21.1443C24.3866 22.9063 27.0797 23.9867 30.0259 23.9867C36.2749 23.9867 41.3852 19.1264 41.3852 13.2107C41.3852 10.1739 40.0877 7.41914 38.0008 5.43325L34.8513 8.59745C35.0624 9.90637 35.1639 11.4433 35.1639 13.2107C35.1639 20.2928 33.4698 23.3479 30.0259 23.3479C27.4705 23.3479 25.9465 21.6524 25.2826 18.2109L22.3629 21.1443Z" fill="#FEFDFD"/>
</svg>
)

export const smallVs = (width:string, height:string) => (<svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9758 1.21464L13.6304 0H9.81324L11.4843 1.198L8.7156 7.97005H8.45347L5.19332 1.198L6.79882 0H0L1.19594 1.21464L7.0118 13.178L11.9758 1.21464Z" fill="white"/>
                        <path d="M16.8742 20C19.6101 20 21.4286 18.2696 21.4286 15.9235C21.4286 11.2646 14.4495 12.1464 14.4495 9.18469C14.4495 8.1198 15.2687 7.37105 16.4482 7.37105C18.0701 7.37105 19.6429 9.08486 21.1665 11.8802L20.6422 8.1198V7.78702C19.4299 7.23794 18.2176 6.98835 16.9725 6.98835C14.2202 6.98835 12.1396 8.73544 12.1396 11.1314C12.1396 15.6572 19.1022 14.609 19.1022 17.9201C19.1022 18.9185 18.3486 19.6173 17.251 19.6173C15.154 19.6173 13.3191 17.5374 12.0085 14.6423L12.5983 19.1348V19.4676C13.7287 19.7837 15.498 20 16.8742 20Z" fill="white"/>
                        </svg>
                        )

export const largeVs = (width:string, height:string) => (<svg width={width} height={height} viewBox="0 0 368 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M185.45 10.075L187.975 8.25H182.15L184.7 10.05L180.475 20.225H180.075L175.1 10.05L177.55 8.25H167.175L169 10.075L177.875 28.05L185.45 10.075Z" fill="white"/>
<path d="M192.925 38.3C197.1 38.3 199.875 35.7 199.875 32.175C199.875 25.175 189.225 26.5 189.225 22.05C189.225 20.45 190.475 19.325 192.275 19.325C194.75 19.325 197.15 21.9 199.475 26.1L198.675 20.45V19.95C196.825 19.125 194.975 18.75 193.075 18.75C188.875 18.75 185.7 21.375 185.7 24.975C185.7 31.775 196.325 30.2 196.325 35.175C196.325 36.675 195.175 37.725 193.5 37.725C190.3 37.725 187.5 34.6 185.5 30.25L186.4 37V37.5C188.125 37.975 190.825 38.3 192.925 38.3Z" fill="white"/>
<rect x="164" y="26" width="0.999998" height="155" transform="rotate(90 164 26)" fill="#515151"/>
<rect x="9.62134" y="26.152" width="1" height="11.4954" transform="rotate(128.481 9.62134 26.152)" fill="#515151"/>
<rect x="204" y="26.999" width="0.999998" height="155" transform="rotate(-90 204 26.999)" fill="#515151"/>
<rect x="358.379" y="26.847" width="1" height="11.4954" transform="rotate(-51.5194 358.379 26.847)" fill="#515151"/>
</svg>
)

export const championEmpty = (width:string, height:string) => (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" fill="#343333"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8292 12.9135C23.9354 11.1168 21.3239 10 18.4507 10C12.7015 10 8 14.4716 8 19.9141C8 22.8491 9.31735 25.4977 11.4101 27.3408L14.0985 24.6509C13.845 23.3451 13.7236 21.7673 13.7236 19.9141C13.7236 13.3984 15.2822 10.5877 18.4507 10.5877C20.928 10.5877 22.3517 12.3198 22.9062 15.8382L25.8292 12.9135Z" fill="#292929"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8292 12.9135C23.9354 11.1168 21.3239 10 18.4507 10C12.7015 10 8 14.4716 8 19.9141C8 22.8491 9.31735 25.4977 11.4101 27.3408L14.0985 24.6509C13.845 23.3451 13.7236 21.7673 13.7236 19.9141C13.7236 13.3984 15.2822 10.5877 18.4507 10.5877C20.928 10.5877 22.3517 12.3198 22.9062 15.8382L25.8292 12.9135Z" fill="black" fill-opacity="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0107 27.5863C15.9045 29.3832 18.5162 30.5001 21.3895 30.5001C27.1386 30.5001 31.8401 26.0286 31.8401 20.5861C31.8402 17.6509 30.5227 15.0022 28.4298 13.1591L25.7416 15.8488C25.9951 17.1546 26.1166 18.7327 26.1166 20.5861C26.1166 27.1017 24.5579 29.9124 21.3895 29.9124C18.912 29.9124 17.4883 28.1802 16.9339 24.6615L14.0107 27.5863Z" fill="#292929"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0107 27.5863C15.9045 29.3832 18.5162 30.5001 21.3895 30.5001C27.1386 30.5001 31.8401 26.0286 31.8401 20.5861C31.8402 17.6509 30.5227 15.0022 28.4298 13.1591L25.7416 15.8488C25.9951 17.1546 26.1166 18.7327 26.1166 20.5861C26.1166 27.1017 24.5579 29.9124 21.3895 29.9124C18.912 29.9124 17.4883 28.1802 16.9339 24.6615L14.0107 27.5863Z" fill="black" fill-opacity="0.2"/>
    <rect x="19.6165" y="16.412" width="4.78927" height="4.78927" rx="2.39464" transform="rotate(45.7146 19.6165 16.412)" fill="#292929"/>
    <rect x="19.6165" y="16.412" width="4.78927" height="4.78927" rx="2.39464" transform="rotate(45.7146 19.6165 16.412)" fill="black" fill-opacity="0.2"/>
    </svg>
)

export const championEmptyHover = (width:string, height:string) => (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" fill="#515151"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.8292 12.9135C23.9354 11.1168 21.3239 10 18.4507 10C12.7015 10 8 14.4716 8 19.9141C8 22.8491 9.31735 25.4977 11.4101 27.3408L14.0985 24.6509C13.845 23.3451 13.7236 21.7673 13.7236 19.9141C13.7236 13.3984 15.2822 10.5877 18.4507 10.5877C20.928 10.5877 22.3517 12.3198 22.9062 15.8382L25.8292 12.9135Z" fill="#292929"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.8292 12.9135C23.9354 11.1168 21.3239 10 18.4507 10C12.7015 10 8 14.4716 8 19.9141C8 22.8491 9.31735 25.4977 11.4101 27.3408L14.0985 24.6509C13.845 23.3451 13.7236 21.7673 13.7236 19.9141C13.7236 13.3984 15.2822 10.5877 18.4507 10.5877C20.928 10.5877 22.3517 12.3198 22.9062 15.8382L25.8292 12.9135Z" fill="black" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0109 27.5862C15.9047 29.3831 18.5163 30.5 21.3897 30.5C27.1388 30.5 31.8403 26.0284 31.8403 20.5859C31.8403 17.6508 30.5229 15.002 28.4299 13.159L25.7417 15.8487C25.9953 17.1545 26.1167 18.7325 26.1167 20.5859C26.1167 27.1016 24.5581 29.9123 21.3897 29.9123C18.9122 29.9123 17.4885 28.1801 16.9341 24.6613L14.0109 27.5862Z" fill="#292929"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0109 27.5862C15.9047 29.3831 18.5163 30.5 21.3897 30.5C27.1388 30.5 31.8403 26.0284 31.8403 20.5859C31.8403 17.6508 30.5229 15.002 28.4299 13.159L25.7417 15.8487C25.9953 17.1545 26.1167 18.7325 26.1167 20.5859C26.1167 27.1016 24.5581 29.9123 21.3897 29.9123C18.9122 29.9123 17.4885 28.1801 16.9341 24.6613L14.0109 27.5862Z" fill="black" fill-opacity="0.2"/>
<rect x="19.6167" y="16.4121" width="4.78927" height="4.78927" rx="2.39464" transform="rotate(45.7146 19.6167 16.4121)" fill="#292929"/>
<rect x="19.6167" y="16.4121" width="4.78927" height="4.78927" rx="2.39464" transform="rotate(45.7146 19.6167 16.4121)" fill="black" fill-opacity="0.2"/>
</svg>
)

export const saveLogo = (width:number, height:number, fillColor:string|undefined) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.9999 3.00022L12.4998 3.00064L11 17L6.99979 17.0002L8.9999 3.00022Z" fill={fillColor}/>
        <path d="M17 8L16 11.0703L3 11.0703L4 8L17 8Z" fill={fillColor}/>
    </svg>
)

export const shareLogo = (width:number, height:number, fillColor:string|undefined) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9152 4L17 9.61364L10.9152 15.5227V11.3864C10.5324 11.3671 6.16667 10.5 2 17C3.85589 10.8783 6.90674 8.33637 10.9152 7.25V4Z" fill={fillColor}/>
    </svg>
)

export const dislikeLogo = (width:number, height:number, fillColor:string|undefined) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 12.4781C13.5 17.4781 13.5 18.4779 12.5 18.9779C11.9515 19.2521 10.9834 16.3785 9.5 13.9995C8.81969 12.9085 7.47163 11.8384 7 11L5 2.47806H17L20 7.49955V12.4781H13Z" fill={fillColor}/>
        <path d="M2.5 2.47852L5 11H2.5L0 2.47852H2.5Z" fill={fillColor}/>
    </svg>
)

export const likeLogo = (width:number, height:number, fillColor:string|undefined) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 7.52194C13.5 2.52194 13.5 1.52214 12.5 1.02214C11.9515 0.747905 10.9834 3.62146 9.5 6.00045C8.81969 7.0915 7.47163 8.16155 7 9L5 17.5219H17L20 12.5005V7.52194H13Z" fill={fillColor}/>
    <path d="M2.5 17.5215L5 9H2.5L0 17.5215H2.5Z" fill={fillColor}/>
    </svg>
)


export const swapLogo = (width:number, height:number, fillColor:string|undefined, bgcolor:string|undefined) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.7" width="20" height="20" fill={bgcolor} />
        <g clip-path="url(#clip0_544_3372)">
        <path d="M7.85693 11.8262V11.3262L7.64982 11.3262L7.50338 11.4726L7.85693 11.8262ZM3.02685 16.1563C3.02685 16.4324 3.2507 16.6563 3.52685 16.6563L8.02685 16.6563C8.30299 16.6563 8.52685 16.4324 8.52685 16.1563C8.52685 15.8801 8.30299 15.6563 8.02685 15.6563L4.02685 15.6563V11.6563C4.02685 11.3801 3.80299 11.1563 3.52685 11.1563C3.25071 11.1563 3.02685 11.3801 3.02685 11.6563L3.02685 16.1563ZM7.50338 11.4726L3.17329 15.8027L3.8804 16.5098L8.21048 12.1797L7.50338 11.4726ZM7.85693 12.3262L11.8262 12.3262L11.8262 11.3262L7.85693 11.3262V12.3262Z" fill={fillColor}/>
        <path d="M12.9085 8.93945V9.43945L13.1156 9.43945L13.262 9.29301L12.9085 8.93945ZM17.7385 4.60937C17.7385 4.33323 17.5147 4.10937 17.2385 4.10937L12.7385 4.10937C12.4624 4.10937 12.2385 4.33323 12.2385 4.60937C12.2385 4.88551 12.4624 5.10937 12.7385 5.10937L16.7385 5.10937V9.10937C16.7385 9.38551 16.9624 9.60937 17.2385 9.60937C17.5147 9.60937 17.7385 9.38551 17.7385 9.10937L17.7385 4.60937ZM13.262 9.29301L17.5921 4.96292L16.885 4.25582L12.5549 8.5859L13.262 9.29301ZM12.9085 8.43945L8.93921 8.43945L8.93921 9.43945L12.9085 9.43945V8.43945Z" fill={fillColor}/>
        </g>
        <defs>
        <clipPath id="clip0_544_3372">
        <rect width="18.8813" height="7.65458" transform="translate(19.7637 6.41309) rotate(135)"/>
        </clipPath>
        </defs>
    </svg>
)

export const trashLogo = (width:number, height:number, fillColor:string|undefined) => (
    
   <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="#C9C9C9" fill-opacity="0.7"/>
    <path d="M7.66667 7.66667V14.2H8.6V7.66667H7.66667Z" fill={fillColor}/>
    <path d="M11.4 14.2V7.66667H12.3333V14.2H11.4Z" fill={fillColor}/>
    <path d="M9.53333 7.66667V14.2H10.4667V7.66667H9.53333Z" fill={fillColor}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.73333 3H13.2667V4.86667H17V5.8H15.1333V17H4.86667V5.8H3V4.86667H6.73333V3ZM12.3333 3.93333V4.86667H7.66667V3.93333H12.3333ZM14.2 5.8H5.8V16.0667H14.2V5.8Z" fill={fillColor}/>
    </svg>
)

export const clearLogo = (width:number, height:number, fillColor:string|undefined) => (
    <SvgIcon sx={{height:height, width:width, color:fillColor ? fillColor : "secondary.main", backgroundColor:"rgba(0,0,0,0.75)", borderRadius:7}}><HighlightOffIcon/></SvgIcon>
)

export const lineSeperator = (width:number, height:number, color:string) => (
    <svg width={width} height={height} viewBox="0 0 105 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="105" y="0.998047" width="0.999999" height="105" transform="rotate(90 105 0.998047)" fill={color}/> </svg>
)

export const checkedIcon = (<SvgIcon sx={{p:0.25}}>
   <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1 5.2L7.5 12L4 8.5L5.3 7.2L7.3 9.3L11.7 4L13.1 5.2Z" fill="#FFE901"/>
<rect x="0.5" y="0.5" width="16" height="16" stroke="#515151"/>
</svg>
</SvgIcon>
)

export const uncheckedIcon = (<SvgIcon sx={{p:0.25}}>
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="16" height="16" stroke="#515151"/>
    </svg>
</SvgIcon>
)

export const cleanCheckedIcon = <SvgIcon sx={{p:0.25}}>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1 7.2L8.5 14L5 10.5L6.3 9.2L8.3 11.3L12.7 6L14.1 7.2Z" fill="#FFE901"/>
    </svg>
</SvgIcon>

export const backIcon = (<SvgIcon className={"backIcon"}>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="26" fill="white" fill-opacity="0.2"/>
        <path d="M8.7093 13.6815L8.62471 13.6305V13.5317V11.7687V11.6699L8.7093 11.6189L17.1093 6.55036L17.3747 6.39021V6.7002V9.10066V9.20256L17.2861 9.25286L11.3002 12.6501L17.2859 16.0396L17.3747 16.0898V16.1919V18.6002V18.9102L17.1093 18.75L8.7093 13.6815Z" stroke-width="0.35"/>
    </svg>
</SvgIcon>)


export const filterIcon = (size="30px") => (<SvgIcon className={"filterIcon"} sx={{height:size, width:size}} >
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 8L13 14L13 21L16 23L16 14L21 8L8 8Z"/>
    </svg>
</SvgIcon>)

export const laneIcon = (lane:string, color:string | undefined) => {
    switch (lane) {
        case "top":
            return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" fill="#343333"/>
                    <path d="M5.625 15.9375V5.625H15.9375L20.625 1.875H1.875V19.6875L5.625 15.9375ZM24.375 13.125V24.375H13.125L9.375 28.125H28.125V9.375L24.375 13.125Z" fill="#77777A"/>
                    <path d="M5.625 15.9375V5.625H15.9375L20.625 1.875H1.875V19.6875L5.625 15.9375Z" fill={color}/>
                    <path d="M28.125 1.875H22.5L1.875 22.5V28.125H7.5L28.125 6.5625V1.875Z" fill="#77777A"/>
                </svg>)
        case "jungle":
            return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1418_1412)">
                    <rect x="30" y="30" width="30" height="30" transform="rotate(-180 30 30)" fill="#343333"/>
                    <path d="M8.03494 3.75C8.03494 3.75 14.2625 10.8566 15.238 16.275C16.5433 23.5144 14.5923 25.703 14.5923 25.703L7.6534 19.0883C7.67811 17.4578 7.4063 15.8363 6.8512 14.303C5.94837 11.9286 3.75 9.79587 3.75 9.79587C3.75 9.79587 6.66812 11.2382 8.24737 12.7671C9.41011 14.0091 10.4825 15.3327 11.4562 16.7278C11.4562 16.7278 12.1074 15.1458 11.118 11.2913C10.3381 8.23479 8.03494 3.75 8.03494 3.75Z" fill={color}/>
                    <path d="M18.0876 20.2818C18.1086 21.4041 18.1267 21.8024 18.1211 22.3362L21.5256 19.0827C21.5022 17.4519 21.7755 15.8305 22.332 14.2974C23.2348 11.9314 25.4346 9.79727 25.4346 9.79727C25.4346 9.79727 22.2985 11.09 20.8534 12.728C20.3363 13.2814 19.8821 13.8824 19.4977 14.521C18.5189 16.2799 18.0313 18.2697 18.0862 20.2818H18.0876ZM18.4118 10.8496C19.0841 7.64921 21.1483 3.75 21.1483 3.75C21.1483 3.75 17.4461 7.98043 15.3022 12.3967C16.3213 13.7416 17.0952 15.2555 17.5887 16.869C17.5887 16.869 17.8654 13.4575 18.4132 10.851L18.4118 10.8496Z" fill="#77777A"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1418_1412">
                    <rect width="30" height="30" fill="white" transform="translate(30 30) rotate(-180)"/>
                    </clipPath>
                    </defs>
                    </svg>
                    )
        case "mid":
            return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" fill="#343333"/>
                        <path d="M5.625 15.9375V5.625H15.9375L20.625 1.875H1.875V19.6875L5.625 15.9375ZM24.375 13.125V24.375H13.125L9.375 28.125H28.125V9.375L24.375 13.125Z" fill="#77777A"/>
                        <path d="M28.125 1.875H22.5L1.875 22.5V28.125H7.5L28.125 6.5625V1.875Z" fill={color}/>
                    </svg>
                    )
        case "bottom":
            return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1418_1390)">
                        <rect x="30" y="30" width="30" height="30" transform="rotate(-180 30 30)" fill="#343333"/>
                        <path d="M24.375 14.0625L24.375 24.375L14.0625 24.375L9.375 28.125L28.125 28.125L28.125 10.3125L24.375 14.0625ZM5.625 16.875L5.625 5.625L16.875 5.625L20.625 1.875L1.875 1.875L1.875 20.625L5.625 16.875Z" fill="#77777A"/>
                        <path d="M24.375 14.0625L24.375 24.375L14.0625 24.375L9.375 28.125L28.125 28.125L28.125 10.3125L24.375 14.0625Z" fill={color}/>
                        <path d="M1.875 28.125L7.5 28.125L28.125 7.5L28.125 1.875L22.5 1.875L1.875 23.4375L1.875 28.125Z" fill="#77777A"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1418_1390">
                        <rect width="30" height="30" fill="white" transform="translate(30 30) rotate(-180)"/>
                        </clipPath>
                        </defs>
                    </svg>
                    )
        case "support":
            return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1418_1401)">
                        <rect x="30" y="30" width="30" height="30" transform="rotate(-180 30 30)" fill="#343333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1087 3.75H11.892L10.8041 5.20314L15.0002 10.3698L19.1965 5.20314L18.1087 3.75ZM10.027 8.75518H1.32373C2.5211 9.92879 3.90268 11.0334 5.36451 11.8229C5.9508 12.0124 6.45987 12.144 7.07408 12.1458H9.4053L6.91866 15.2135L10.9594 17.1511L12.5136 11.3385L10.027 8.75518ZM19.9733 8.75518H28.6767C27.4789 9.92722 26.0975 11.0347 24.6358 11.8229C24.0496 12.0124 23.5405 12.144 22.9264 12.1458H20.5951L23.0816 15.2135L19.0409 17.1511L17.4868 11.3385L19.9733 8.75518ZM18.2637 24.4167L15.7771 11.5C15.671 11.8671 15.3682 12.1098 15.0001 12.1458C14.6336 12.1081 14.3284 11.8657 14.223 11.5L11.7364 24.4167L15.0001 27L18.2637 24.4167Z" fill={color}/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2636 24.4167L15.777 11.5C15.6709 11.867 15.3681 12.1098 15 12.1458C14.6335 12.1081 14.3283 11.8656 14.2229 11.5L11.7363 24.4167L15 27L18.2636 24.4167Z" fill="#77777A"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1418_1401">
                        <rect width="30" height="30" fill="white" transform="translate(30 30) rotate(-180)"/>
                        </clipPath>
                        </defs>
                    </svg>
                    )
    }
}